import { TextField } from '@material-ui/core';
import { Option } from 'apps/option';
import { Api } from 'core';
import { FilterRequest } from 'core/models';
import React from 'react';
import { FormAutocomplete, FormAutocompleteProps } from '../FormAutocomplete';

export type OptionAutoCompleteSelectProps = Omit<FormAutocompleteProps, 'options'>;

const OptionAutoCompleteSelect: React.FC<OptionAutoCompleteSelectProps> = ({ onChange, ...autocompleteProps }) => {
  const [optionList, setOptionList] = React.useState<Option[]>([]);
  const [inputValue, setInputValue] = React.useState<string>();

  const pullOptions = async (filterOptionRequest?: FilterRequest) => {
    const response = await Api.Option.getOptions(filterOptionRequest);

    if (response.data) {
      setOptionList(response.data.data);
    }
  };

  React.useEffect(() => {
    pullOptions();
  }, []);

  const onQueryChage = (event, newInputValue: string) => {
    setInputValue(newInputValue);
    if (newInputValue && newInputValue.length > 0) {
      pullOptions({
        queryName: newInputValue,
      });
    } else {
      pullOptions();
    }
  };

  return (
    <FormAutocomplete
      {...autocompleteProps}
      size="small"
      options={optionList}
      getOptionLabel={(option) => option?.name}
      onChange={onChange}
      filterSelectedOptions
      inputValue={inputValue}
      onInputChange={onQueryChage}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Seçenek Seçimi"
          placeholder="Seçenekler"
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    />
  );
};

export default OptionAutoCompleteSelect;

import { Switch, Route, Redirect } from 'react-router-dom';
import React from 'react';
import LoginPage from './LoginPage';

const AuthPage = () => {
  return (
    <Switch>
      <Route path="/auth/login" component={LoginPage} />
      <Redirect from="/auth" exact to="/auth/login" />
      <Redirect to="/auth/login" />
    </Switch>
  );
};
export default AuthPage;

import { Card, Grid, CardContent, CardActions, Box, Typography, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import React from 'react';
import {
  Api,
  ClientAutoCompleteSelect,
  FormHeader,
  FormSelect,
  GenericMessages,
  ProductAutoCompleteSelect,
  SuccessButton,
} from 'core';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { showToast } from 'core/store';
import { Form, Formik } from 'formik';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';

const useStyles = makeStyles((theme) => {
  return {
    media: {
      height: 300,
    },
    bottomArea: {
      backgroundColor: grey[100],
    },
    bottomInfoText: {
      color: theme.palette.secondary.main,
      fontWeight: 300,
      textAlign: 'center',
    },

    infoChip: {
      marginRight: theme.spacing(1),
    },
    actionChip: {
      width: '100%',
    },
  };
});

const CreatePaynetLink = () => {
  const dispatch = useDispatch();
  const [showLoading, setShowLoading] = React.useState(false);
  const [paynetUrl, setPaynetUrl] = React.useState('');
  const formValidation = yup.object({
    // products: yup.array().of(
    //   yup.object().shape({
    //     id: yup.number().required('Name required'),
    //   }),
    // ),
    // client: yup.object().shape({
    //   id: yup.number().required('Name required'),
    // }),
  });
  const onSubmit = async (data) => {
    setShowLoading(true);
    const newPaynetLink: any = {
      clientId: data.client.id,
      productIds: data.products.map((product) => {
        return product.id;
      }),
    };
    try {
      const response = await Api.Order.createPaynetLink(newPaynetLink);
      setPaynetUrl(response.data.url);
      dispatch(
        showToast({
          message: `Paynet linki başarıyla oluşturuldu.`,
        }),
      );
    } catch (err) {
      dispatch(
        showToast({
          message: `Hata Oluştu`,
          severity: 'error',
        }),
      );
    }

    setShowLoading(false);
  };

  return (
    <Formik
      validationSchema={formValidation}
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={{
        products: [],
        client: {},
        communicationTypeId: '',
      }}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, errors, values, handleChange, setFieldValue }) => (
        <Form onSubmit={handleSubmit} id="form" noValidate>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <ProductAutoCompleteSelect
                    placeholder="Ürünler"
                    onChange={(event, products) => setFieldValue('products', products)}
                    error={errors.products != null}
                    helperText={errors.products as string}
                    value={values.products}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <ClientAutoCompleteSelect
                    placeholder="Müşteri Seçiniz"
                    optionLabelName="name"
                    equValueName="id"
                    onChange={(event, client) => setFieldValue('client', client)}
                    multiple={false}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormSelect
                    onChange={handleChange}
                    name="communicationTypeId"
                    value={values.communicationTypeId}
                    fullWidth
                    placeholder="İletişim Tipi"
                  >
                    <MenuItem value="1">Sms</MenuItem>
                    <MenuItem value="2">Email</MenuItem>
                  </FormSelect>
                </Grid>
              </Grid>
            </CardContent>

            <CardActions>
              <Box alignItems="center" justifyContent="flex-end" display="flex" className="w-100">
                <SuccessButton form="form" type="submit" startIcon={<SaveOutlinedIcon />} showLoading={showLoading}>
                  Paynet Linki Oluştur
                </SuccessButton>
              </Box>
            </CardActions>
            {paynetUrl && (
              <React.Fragment>
                <Grid container spacing={4} justify="center" alignItems="center">
                  <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom noWrap>
                      Paynet Linkiniz: {paynetUrl}
                    </Typography>
                  </Grid>
                </Grid>
              </React.Fragment>
            )}
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default CreatePaynetLink;

import { createAsyncThunk } from '@reduxjs/toolkit';
import { LOGIN, LoginActionPayload, REFRESH_USER_INFO } from './types';
import { UserInfo } from '../models';
import { AuthApi } from '../api';

export const loginActionThunk = createAsyncThunk(LOGIN, async ({ email, password }: LoginActionPayload) => {
  return {
    token: 'asdasdasdasd',
    userInfo: {
      fullName: 'test',
      email: 'test',
    },
  };
});

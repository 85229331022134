import ProductComment from 'apps/product/models/ProductComment';
import { Api, TranslatedMaterialTable } from 'core';
import { TableState } from 'core/models';
import React from 'react';
import { Link } from 'react-router-dom';

const ProductCommentsPage = () => {
  const [showLoading, setShowLoading] = React.useState(false);

  const tableState: TableState<ProductComment> = {
    columns: [
      {
        title: 'Ürün',
        render: (rowData) => {
          return <Link to={`/crm/product/detail/${rowData.productId}`}>{rowData.product.name}</Link>;
        },
      },
      {
        title: 'Yorum',
        field: 'comment',
      },
      {
        title: 'Puan',
        field: 'rate',
      },
    ],
  };

  return (
    <TranslatedMaterialTable
      isLoading={showLoading}
      data={async (q) => {
        setShowLoading(true);
        const comments = await Api.Product.getComments({
          page: q.page,
        });
        setShowLoading(false);
        if (comments.data) {
          return {
            data: comments.data.data,
            totalCount: comments.data.totalCount,
            page: q.page,
          };
        }

        return {
          data: [],
          totalCount: 0,
          page: 0,
        };
      }}
      columns={tableState.columns}
      title="Ürün Yorumları"
    />
  );
};

export default ProductCommentsPage;

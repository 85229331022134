import { Switch, Route } from 'react-router-dom';
import React from 'react';
import {
  CreateMenuPage,
  MenuListPage,
  CreateSectionPage,
  CreateSliderPage,
  CreateInformationPage,
  SectionListPage,
  SliderListPage,
  CreateBannerSliderPage,
  CreateAssemblyPage,
  CreateAssemblyThemePage,
  ExcelProductUpdatePage,
  CreateXmlExportPage,
  B2BPanelPage,
  XmlServicePage,
  TempMenuCreatePage,
  ListAssemblyThemePage,
} from '../pages';
import { BannerSliderListPage } from '../pages/BannerSliderListPage';
import { ListAssembliesPage } from '../pages/ListAssembliesPage';

interface DesignLayoutProps {
  match: any;
}

const BlogLayout: React.FC<DesignLayoutProps> = ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.path}/menu/create/`} component={CreateMenuPage} />
      <Route exact path={`${match.path}/section/create/`} component={CreateSectionPage} />
      <Route exact path={`${match.path}/section/update/:id`} component={CreateSectionPage} />
      <Route exact path={`${match.path}/section/list/`} component={SectionListPage} />

      <Route exact path={`${match.path}/slider/create/`} component={CreateSliderPage} />
      <Route exact path={`${match.path}/slider/update/:id`} component={CreateSliderPage} />
      <Route exact path={`${match.path}/slider/list/`} component={SliderListPage} />

      <Route exact path={`${match.path}/bannerSlider/create/`} component={CreateBannerSliderPage} />
      <Route exact path={`${match.path}/bannerSlider/list/`} component={BannerSliderListPage} />

      <Route exact path={`${match.path}/informationPage/create/`} component={CreateInformationPage} />
      <Route exact path={`${match.path}/modules/`} component={CreateMenuPage} />
      <Route exact path={`${match.path}/modules/assembly/update/:id`} component={CreateAssemblyPage} />
      <Route exact path={`${match.path}/modules/assembly/theme/update/:id`} component={CreateAssemblyThemePage} />

      <Route exact path={`${match.path}/modules/assembly/`} component={ListAssembliesPage} />
      <Route exact path={`${match.path}/modules/assembly/create`} component={CreateAssemblyPage} />
      <Route exact path={`${match.path}/modules/assembly/update/:id`} component={CreateAssemblyPage} />

      <Route exact path={`${match.path}/modules/assemblyTheme`} component={ListAssemblyThemePage} />
      <Route exact path={`${match.path}/modules/assemblyTheme/create`} component={CreateAssemblyThemePage} />
      <Route exact path={`${match.path}/modules/assemblyTheme/update/:id`} component={CreateAssemblyThemePage} />

      <Route exact path={`${match.path}/modules/excelProductUpdate`} component={ExcelProductUpdatePage} />
      <Route exact path={`${match.path}/modules/xmlExport`} component={CreateXmlExportPage} />
      <Route exact path={`${match.path}/modules/xmlService`} component={XmlServicePage} />
      <Route exact path={`${match.path}/menu/list`} component={MenuListPage} />
      <Route exact path={`${match.path}/b2bpanel/`} component={B2BPanelPage} />
      <Route exact path={`${match.path}/tempMenuCreate/`} component={TempMenuCreatePage} />
    </Switch>
  );
};

export default BlogLayout;

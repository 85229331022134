import React from 'react';
import { Box, Card, CardActions, CardContent, Grid } from '@material-ui/core';
import * as yup from 'yup';
import { GenericMessages } from 'core';
import { CategoryAutoCompleteSelect, FormHeader, SuccessButton } from 'core/components';
import { Formik } from 'formik';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';

const MarketPlaceSyncAllCategoryAttributePage = () => {
  const [showLoading] = React.useState(false);
  const schema = yup.object({
    categoryId: yup.number().required(GenericMessages.CANNOT_BE_EMPTY),
  });
  const onSubmit = async () => {
    // setShowLoading(true);
    // try {
    //   }
    //   dispatch(
    //     showToast({
    //       message: `Başarıyla oluşturuldu!}`,
    //     }),
    //   );
    // } catch (e) {
    //   dispatch(
    //     showToast({
    //       message: `Hata oluştu`,
    //       severity: 'error',
    //     }),
    //   );
    // }
    // setShowLoading(false);
  };
  return (
    <React.Fragment>
      <Card>
        <Formik
          onSubmit={onSubmit}
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            categoryId: '',
          }}
        >
          {({ handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit} id="form" noValidate>
              <CardContent>
                <Grid container spacing={3}>
                  <FormHeader title="Pazaryeri Kategori Toplu Özellik Atama" />

                  <Grid item xs={6}>
                    <CategoryAutoCompleteSelect
                      placeholder="Kategori Seçiniz"
                      optionLabelName="name"
                      equValueName="id"
                      onChange={(event, category) => setFieldValue('categoryId', category)}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Box alignItems="center" justifyContent="flex-end" display="flex" className="w-100">
                  <SuccessButton form="form" type="submit" startIcon={<SaveOutlinedIcon />} showLoading={showLoading}>
                    Kaydet
                  </SuccessButton>
                </Box>
              </CardActions>
            </form>
          )}
        </Formik>
      </Card>
    </React.Fragment>
  );
};

export default MarketPlaceSyncAllCategoryAttributePage;

import { CreateOrUpdateCampaignForm } from 'apps/campaign';
import React from 'react';

const CreateCampaignPage = () => {
  return (
    <React.Fragment>
      <CreateOrUpdateCampaignForm />
    </React.Fragment>
  );
};

export default CreateCampaignPage;

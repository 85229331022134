import { Collapse } from '@material-ui/core';
import React from 'react';
import { LoadingSpinner } from '../LoadingSpinner';

export interface HideableLoadingSpinnerProps {
  show: boolean;
  TransitionComponent?: any;
  message?: string;
}

const HideableLoadingSpinner: React.FC<HideableLoadingSpinnerProps> = ({ TransitionComponent, show, message }) => {
  return (
    <TransitionComponent in={show}>
      <LoadingSpinner message={message} />
    </TransitionComponent>
  );
};

HideableLoadingSpinner.defaultProps = {
  TransitionComponent: Collapse,
};
export default HideableLoadingSpinner;

import React from 'react';
import { TableState } from 'core/models';
import { FormSelect, FormTextField, SupplierAutoCompleteSelect, TranslatedMaterialTable } from 'core/components';
import { Product, ProductFilterRequest } from 'apps/product/models';
import { Edit, Visibility as VisibilityIcon } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { Filter as FilterIcon } from 'react-feather';
import { Api, showToast } from 'core';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';

const ProductListPage = () => {
  const [productFilter, setProductFilter] = React.useState<ProductFilterRequest & { suppliers?: any[] }>({});
  const tableRef = React.useRef<any>();
  const [showLoading, setShowLoading] = React.useState(false);
  const [focusedProduct, setFocusedProduct] = React.useState<Product>();
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [showFilterDialog, setShowFilterDialog] = React.useState<boolean>(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const [tableState] = React.useState<TableState<Product>>({
    columns: [
      { title: 'Ürün', field: 'name', cellStyle: { whiteSpace: 'nowrap' } },
      { title: 'Ürün Kodu', field: 'model', cellStyle: { whiteSpace: 'nowrap' } },
      {
        title: 'Fiyat',
        render: (val) => `${val.price} ₺`,
        cellStyle: { whiteSpace: 'nowrap' },
      },
      { title: 'Stok', field: 'quantity', cellStyle: { whiteSpace: 'nowrap' } },
    ],

    actions: [
      {
        tooltip: 'Detaylar',
        icon: (props) => <VisibilityIcon {...props} />,
        onClick: (event, rowData) => {
          const product = rowData as Product;
          history.push(`detail/${product.id}`);
        },
      },
      {
        tooltip: 'Düzenle',
        icon: (props) => <Edit {...props} />,
        onClick: (event, rowData) => {
          const product = rowData as any;
          history.push(`/crm/products/update/${product.id}`);
        },
      },
      () => ({
        icon: 'delete',
        onClick: (event, rowData) => {
          setFocusedProduct(rowData as Product);
          setShowDeleteDialog(true);
        },
      }),
      {
        icon: (props) => <FilterIcon {...props} />,
        tooltip: 'Filtrele',
        isFreeAction: true,
        onClick: (event) => {
          setShowFilterDialog(true);
        },
      },
    ],
  });

  const handleFilterDialogClose = () => {
    setShowFilterDialog(false);
  };

  const confirmDelete = async () => {
    setShowLoading(true);
    setShowDeleteDialog(false);

    if (focusedProduct) {
      try {
        const response = await Api.Product.deleteProduct(focusedProduct.id);
        if (response) {
          dispatch(
            showToast({
              message: `Ürün Silindi`,
            }),
          );
          if (tableRef.current) tableRef.current.onQueryChange();
        }
      } catch (err) {
        setShowLoading(false);
        dispatch(
          showToast({
            message: ` hata oluştu`,
            severity: 'error',
          }),
        );
      }
    }

    setShowLoading(false);
  };

  const onFilterSubmit = (data) => {
    const newProductFilter = {
      ...productFilter,
      ...data,
    };
    setProductFilter(newProductFilter);
  };

  React.useEffect(
    () =>
      tableRef?.current?.onQueryChange({
        page: 0,
      }),
    [productFilter],
  );

  return (
    <React.Fragment>
      <TranslatedMaterialTable
        tableRef={tableRef}
        isLoading={showLoading}
        actions={tableState.actions}
        columns={tableState.columns}
        options={{
          debounceInterval: 1000,
        }}
        data={(q) =>
          new Promise((resolve) => {
            setShowLoading(true);
            const filterData: any = {
              ...productFilter,
              page: q.page,
              queryName: q.search,
            };
            if (filterData.suppliers) {
              filterData.supplierIds = filterData.suppliers.map((supplier) => supplier.id);
              delete filterData.suppliers;
            }
            Api.Product.getProducts(filterData)
              .then((response) => response.data)
              .then((data) => {
                if (data) {
                  setShowLoading(false);
                  resolve({
                    page: q.page,
                    totalCount: data.totalCount,
                    data: data.data,
                  });
                }
              });
          })
        }
        title="Ürün Listesi"
      />
      <Dialog open={showDeleteDialog} onClose={() => setShowDeleteDialog(false)}>
        <DialogTitle>Ürünü Sil</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <b>{focusedProduct && focusedProduct.name}</b> adlı ürünü silmek istediğinize emin misiniz?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteDialog(false)} color="secondary">
            İptal
          </Button>
          <Button onClick={confirmDelete} color="primary" autoFocus>
            Sil
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showFilterDialog} onClose={handleFilterDialogClose}>
        <DialogTitle>Filtrele</DialogTitle>
        <Formik
          onSubmit={onFilterSubmit}
          initialValues={{
            minPrice: productFilter.minPrice || '',
            maxPrice: productFilter.maxPrice || '',
            suppliers: productFilter.suppliers || [],
          }}
        >
          {({ setFieldValue, handleChange, values, handleSubmit }) => (
            <form noValidate onSubmit={handleSubmit} id="filterForm">
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormTextField
                      name="minPrice"
                      label="Min. Fiyat"
                      placeholder="Min. Fiyat"
                      onChange={handleChange}
                      value={values.minPrice}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField
                      name="maxPrice"
                      label="Max. Fiyat"
                      placeholder="Max. Fiyat"
                      onChange={handleChange}
                      value={values.maxPrice}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <Box px={1}>
                      <SupplierAutoCompleteSelect
                        multiple
                        placeholder="Tedarikçi"
                        fullWidth
                        value={values.suppliers}
                        onChange={(event, values) => setFieldValue('suppliers', values)}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </DialogContent>
            </form>
          )}
        </Formik>

        <DialogActions>
          <Button onClick={handleFilterDialogClose} color="secondary">
            Kapat
          </Button>
          <Button onClick={handleFilterDialogClose} color="primary" autoFocus form="filterForm" type="submit">
            Ara
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default ProductListPage;

import React from 'react';
import { Sync } from '@material-ui/icons';
import { CategorySync } from 'apps/order';
import { Api } from 'core';
import { FormDialog, TranslatedMaterialTable } from 'core/components';
import { TableState } from 'core/models';

const N11CategorySyncPage = () => {
  const [showCategorySyncDialog, setShowCategorySyncDialog] = React.useState(false);
  const [showLoading, setShowLoading] = React.useState(false);

  const [products, setProducts] = React.useState<any[]>([]);
  const tableRef = React.useRef<any>();

  const handleCategorySyncDialogClose = () => {
    setShowCategorySyncDialog(false);
  };
  const handleCategorySyncDialogOpen = () => {
    setShowCategorySyncDialog(true);
  };

  const [tableState] = React.useState<TableState<any>>({
    columns: [
      { title: 'Mağaza Kategori Adı', field: 'categoryName', cellStyle: { whiteSpace: 'nowrap' } },
      { title: 'N11 Kategori Adı', field: 'marketplaceCategoryName', cellStyle: { whiteSpace: 'nowrap' } },
      { title: 'Ekstra Kar Marjı', field: 'extraProfit', cellStyle: { whiteSpace: 'nowrap' } },
      { title: 'Teslimat Şablonu', field: 'shipmentTemplate', cellStyle: { whiteSpace: 'nowrap' } },
    ],

    actions: [
      {
        tooltip: 'Eşleştir',
        icon: (props) => <Sync {...props} />,
        onClick: (event, rowData) => {
          const data = rowData as any[];
          setProducts(data);
          handleCategorySyncDialogOpen();
        },
      },
    ],
  });
  return (
    <React.Fragment>
      <FormDialog
        handleClose={handleCategorySyncDialogClose}
        open={showCategorySyncDialog}
        formId="N11"
        title="Kategori Eşitle"
        showLoading={showLoading}
      >
        <CategorySync
          formId="N11"
          productsData={products}
          setShowCategorySyncDialog={setShowCategorySyncDialog}
          setShowLoading={setShowLoading}
          onComplete={() => {
            return tableRef.current && tableRef.current.onQueryChange();
          }}
          n11category
        />
      </FormDialog>
      <TranslatedMaterialTable
        isLoading={showLoading}
        tableRef={tableRef}
        actions={tableState.actions}
        columns={tableState.columns}
        options={{
          selection: true,
          debounceInterval: 1000,
        }}
        data={(q) =>
          new Promise((resolve) => {
            setShowLoading(true);
            const filterData: any = {
              page: q.page,
              queryName: q.search,
            };
            Api.Order.getN11MatchedCategories(filterData)
              .then((response) => response.data)
              .then((data) => {
                if (data) {
                  setShowLoading(false);
                  resolve({
                    page: q.page,
                    totalCount: data.totalCount,
                    data: data.data,
                  });
                }
              });
          })
        }
        title="N11 Ürün Listesi"
      />
    </React.Fragment>
  );
};

export default N11CategorySyncPage;

import { Box, CircularProgress } from '@material-ui/core';
import React from 'react';

export interface LoadingSpinnerProps {
  message?: string;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ message }) => {
  return (
    <Box display="flex" alignItems="center">
      <CircularProgress className="mr-2" /> {message}
    </Box>
  );
};

LoadingSpinner.defaultProps = {
  message: 'Yükleniyor',
};

export default React.memo(LoadingSpinner);

import React from 'react';
import { fade, makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Menu,
  MenuItem,
  Badge,
  InputBase,
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Divider,
  Box,
  Button,
  Hidden,
  useMediaQuery,
  SnackbarContent,
} from '@material-ui/core';
import {
  AccountCircle,
  Menu as MenuIcon,
  Notifications as NotificationsIcon,
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon,
  Search as SearchIcon,
  Shop as ShopIcon,
  ExitToApp as ExitToAppIcon,
} from '@material-ui/icons';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import clsx from 'clsx';
import { toggleDrawer, toggleMobileDrawer } from 'core/store';
import { useHistory } from 'react-router-dom';
import { MenuBlockInfo } from 'core/types';
import { MenuBlock, ProductAutoCompleteSelect, SearchProductAutoCompleteSelect } from 'core/components';
import { userSidebarMenuList } from 'apps/user';
import { storeSidebarMenuList } from 'apps/store/layout';

import orderSidebarMenuList from 'apps/order/layout/OrderSidebarMenuList';
import blogSidebarMenuList from 'apps/blog/layout/BlogSidebarMenuList';
import { crmSidebarMenuList } from 'apps/crm';
import { warehouseSideBarMenuList } from 'apps/warehouse';
import homeSidebarMenuList from 'apps/dashboard/layout/HomeSidebarMenuList';
import reportSidebarMenuList from 'apps/report/layout/ReportSidebarMenuList';
import { designSidebarMenuList } from 'apps/design';
import { logout } from 'apps/auth';
import { MainLayoutRouter } from './MainLayoutRouter';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '200%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: '20%',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    width: '100%',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerOpen: {
    width: `calc(100% - ${drawerWidth}px)`,
  },
  snackBar: {
    [theme.breakpoints.down('xs')]: {
      bottom: 90,
    },
    marginLeft: '20px',
  },
}));

const MainLayout: React.FC = () => {
  const menuBlockInfos: MenuBlockInfo[] = [
    homeSidebarMenuList,
    crmSidebarMenuList,
    orderSidebarMenuList,
    designSidebarMenuList,
    warehouseSideBarMenuList,
    blogSidebarMenuList,
    //  clientSidebarMenuList,
    userSidebarMenuList,
    storeSidebarMenuList,
    reportSidebarMenuList,
  ];

  const classes = useStyles();
  const theme = useTheme();
  const smDownMatches = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    // userInfo,
    isDrawerOpen,
    // notifications,
    // unreadCount,
    isMobileDrawerOpen,
    // announcements,
    // showFullscreenLoading,
  } = useSelector(
    (state: any) => ({
      // userInfo: state.auth.userInfo,
      isDrawerOpen: state.core.isDrawerOpen,
      // notifications: state.core.notifications,
      // unreadCount: state.core.unreadCount,
      isMobileDrawerOpen: state.core.isMobileDrawerOpen,
      // announcements: state.core.announcements,
      // showFullscreenLoading: state.core.showFullscreenLoading,
    }),
    shallowEqual,
  );
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [notificationAnchorEl, setNotificationAnchorEl] = React.useState<null | HTMLElement>(null);
  const [announcementAnchorEl, setAnnouncementAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isNotificationMenuOpen, setIsNotificationMenuOpen] = React.useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isAnnouncementMenuOpen, setIsAnnouncementMenuOpen] = React.useState(false);
  const [updateVersionDialog] = React.useState(false);
  const history = useHistory();

  const [searchQuery, setSearchQuery] = React.useState('');

  const pullNotifications = async () => {
    /* const response = await Api.getNotifications();
    if (response.data) dispatch(setNotifications(response.data));
    setTimeout(pullNotifications, 5000); */
  };

  React.useEffect(() => {
    /* if (process.env.REACT_APP_BASE_URL) {
      const socket = socketIOClient(`${process.env.REACT_APP_BASE_URL}/utils`, {
        reconnection: true,
        'reconnectionDelay': 1000,
        'reconnectionDelayMax': 5000,
        'reconnectionAttempts': Infinity,
      });

      socket.on('hasUpdate', () => {
        setUpdateVersionDialog(true);
      });
    } */
  }, []);

  const updateVersion = async () => {
    // const response = await Api.User.updateVersion();
    // setUpdateVersionDialog(false);
    // window.location.reload();
  };

  const action = (
    <Button color="primary" size="small" onClick={updateVersion}>
      GÜNCELLE
    </Button>
  );

  const pullAnnouncements = async () => {
    // const response = await Api.Dealer.getDealerAnnounces();
    // if (response.data) dispatch(setAnnouncements(response.data));
  };
  React.useEffect(() => {
    pullAnnouncements();
  }, []);

  React.useEffect(() => {
    pullNotifications();
  }, []);

  React.useEffect(() => {
    if (smDownMatches) {
      dispatch(toggleDrawer(false));
    }
  }, [smDownMatches]);

  // eslint-disable-next-line no-lone-blocks
  {
    /* const onSearchQueryInputKeyUp = (event) => {
    if (event.keyCode === 13) {
      // Cancel the default action, if needed
      event.preventDefault();
      // Trigger the button element with a click
      Api.Stock.findStockWithQuery(searchQuery)
        .then((response) => response.data)
        .then((data) => {
          if (data) {
            history.push(`/stocks/${data.id}`);
          } else {
            dispatch(
              showToastAction({
                severity: 'warning',
                message: 'Bu plaka ve şase no ile araç bulunamamıştır!',
                anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'center',
                },
              }),
            );
          }
        });
    }
  }; */
  }

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleDrawerOpen = () => {
    if (smDownMatches) {
      dispatch(toggleMobileDrawer(true));
    } else {
      dispatch(toggleDrawer(true));
    }
  };

  const handleDrawerClose = () => {
    if (smDownMatches) {
      dispatch(toggleMobileDrawer(false));
    } else {
      dispatch(toggleDrawer(false));
    }
  };
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
    setNotificationAnchorEl(e.currentTarget);
    setIsNotificationMenuOpen(true);
  };

  const handleAnnouncementMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
    setAnnouncementAnchorEl(e.currentTarget);
    setIsAnnouncementMenuOpen(true);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const goProfile = () => {
    history.push('/my/profile');
    handleMenuClose();
  };

  const toStore = () => {
    history.push('/my/store');
    handleMenuClose();
  };

  /* const showPaymentDialog = () => {
    dispatch(showBalancePaymentDialog({ show: true, typeId: PaymentTypeEnum.BALANCE }));
    handleMenuClose();
  }; */

  const menuId = 'primary-search-account-menu';
  const notificationMenuId = 'notification-list-menu';

  const logoutClicked = () => {
    dispatch(logout());
    handleMenuClose();
  };
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Hidden smUp implementation="css">
        <MenuItem onClick={handleNotificationMenuOpen}>
          <IconButton color="inherit">
            <Badge badgeContent={0} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <p>Bildirimler</p>
        </MenuItem>
      </Hidden>
      <MenuItem onClick={goProfile}>
        <IconButton color="inherit">
          <Badge badgeContent={0} color="secondary">
            <AccountCircle />
          </Badge>
        </IconButton>
        Hesabım
      </MenuItem>

      <MenuItem onClick={toStore}>
        <IconButton color="inherit">
          <Badge badgeContent={0} color="secondary">
            <ShopIcon />
          </Badge>
        </IconButton>
        Mağazam
      </MenuItem>

      {/* <MenuItem onClick={showPaymentDialog}>
        <IconButton color="inherit">
          <Badge badgeContent={0} color="secondary">
            <MoneyIcon />
          </Badge>
        </IconButton>
        Bakiye Yükle
    </MenuItem> */}

      <MenuItem onClick={logoutClicked}>
        <IconButton color="inherit">
          <Badge badgeContent={0} color="secondary">
            <ExitToAppIcon />
          </Badge>
        </IconButton>
        Çıkış Yap
      </MenuItem>
    </Menu>
  );

  const announcementsList = (
    <Menu
      anchorEl={announcementAnchorEl}
      open={isAnnouncementMenuOpen}
      onClose={() => {
        setIsAnnouncementMenuOpen(false);
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={notificationMenuId}
    >
      {/* {announcements.length > 0 &&
        announcements.map((announcement: AnnounceDto) => {
          return (
            <MenuItem key={announcement.id}>
              <small>
                {announcement.message} <br />
                <em style={{ float: 'right' }}>{moment(announcement.createdAt).format('DD/MM/YYYY')}</em>
              </small>
            </MenuItem>
          );
        })} */}
      {/* {announcements.length === 0 && (
        <MenuItem>
          <small>Hiç duyurunuz bulunmamakta!</small>
        </MenuItem>
      )} */}
    </Menu>
  );

  const notificationList = (
    <Menu
      anchorEl={notificationAnchorEl}
      open={isNotificationMenuOpen}
      onClose={() => {
        setIsNotificationMenuOpen(false);
      }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={notificationMenuId}
    >
      {/* }
      {notifications.length > 0 &&
        notifications.map((notification: AppNotification) => {
          return (
            <MenuItem
              key={notification.id}
              onClick={() => {
                Api.setNotificationAsRead(notification.id);
                window.open(notification.linkTo, '_blank');
              }}
              style={{ backgroundColor: notification.isRead ? 'inherit' : '#dfe4ea' }}
            >
              <small>
                {notification.message} <br />
                <em style={{ float: 'right' }}>{moment(notification.createdAt).format('DD/MM/YYYY')}</em>
              </small>
            </MenuItem>
          );
        })} */}
      {/* {notifications.length === 0 && (
        <MenuItem>
          <small>Hiç bildiriminiz bulunmamakta!</small>
        </MenuItem>
      )} */}
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton aria-label="show 11 new notifications" color="inherit">
          <Badge badgeContent={0} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const searchBarChange = (e) => {
    console.log(e);
    if (e && e.id) history.push(`/crm/product/detail/${e.id}`);
  };

  const drawerContent = (
    <React.Fragment>
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </div>
      <Divider />
      {menuBlockInfos.map((menuBlockInfo, index) => {
        /* if (menuBlockInfo.permissions) {
          if (!userInfo.hasPermissions(menuBlockInfo.permissions)) {
            return null;
          }
        } */
        return (
          <React.Fragment key={menuBlockInfo.id}>
            <MenuBlock menuBlockInfo={menuBlockInfo} />
            {index === menuBlockInfos.length - 1 && <Divider />}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: !smDownMatches && isDrawerOpen,
        })}
      >
        <Toolbar>
          <Box display="flex" alignItems="center" width="100%">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, !smDownMatches && isDrawerOpen && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <Hidden xsDown implementation="css">
              <Typography variant="h6" className="flex-grow-1" noWrap>
                ECMS
              </Typography>
            </Hidden>

            {updateVersionDialog && (
              <div className={classes.snackBar}>
                <SnackbarContent message="Sistemi güncellemek gerekiyor.Lütfen güncelleyiniz" action={action} />
              </div>
            )}

            <Box flexGrow="1" />
            <div>
              {/* <Button
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = 'https://ecms.tekbas.com/login';
                }}
              >
                Eski Siteye Git
              </Button> */}
            </div>
            <div className={classes.search}>
              {/* <InputBase
                placeholder="ürün ara…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
                value={searchQuery}
                onChange={(event) => setSearchQuery(event.target.value)}
              /> */}
              <SearchProductAutoCompleteSelect
                fullWidth
                multiple={false}
                onChange={(event, product) => {
                  searchBarChange(product);
                }}
              />
            </div>
            <Hidden xsDown implementation="css">
              <div>
                {/* <Badge
                    badgeContent={
                      announcements &&
                      announcements.filter(
                        (a) => moment().format('DD/MM/YYYY') === moment(a.createdAt).format('DD/MM/YYYY'),
                      ).length
                    }
                    color="secondary"
                  >
                    <Announcement />
                  </Badge> */}
                <Button
                  startIcon={<AccountCircle />}
                  onClick={handleProfileMenuOpen}
                  aria-controls={menuId}
                  color="inherit"
                >
                  Kullanıcı
                </Button>
              </div>
            </Hidden>
            <Hidden smUp implementation="css">
              <Box display="flex" flexDirection="row" alignItems="center">
                <IconButton onClick={handleProfileMenuOpen} aria-controls={menuId} color="inherit">
                  <AccountCircle />
                </IconButton>
              </Box>
            </Hidden>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMenu}
      {renderMobileMenu}
      <nav className={classes.drawer}>
        <Hidden smUp implementation="css">
          <Drawer
            className={classes.drawer}
            variant="temporary"
            anchor="left"
            open={isMobileDrawerOpen}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {drawerContent}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            variant="persistent"
            anchor="left"
            open={isDrawerOpen}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {drawerContent}
          </Drawer>
        </Hidden>
      </nav>
      {notificationList}
      {announcementsList}
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: !smDownMatches && isDrawerOpen,
          [classes.drawerOpen]: !smDownMatches && isDrawerOpen,
        })}
      >
        <div className={classes.drawerHeader} />
        <MainLayoutRouter />
      </main>
    </div>
  );
};

export default MainLayout;

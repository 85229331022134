/* eslint-disable react/jsx-key */
import React from 'react';

import { Api } from 'core';
import { Card, Grid, CardContent, Button, Menu, MenuItem } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { FormHeader, DetailFieldLabel, FormDialog, TranslatedMaterialTable } from 'core/components';
import { Supplier } from 'apps/supplier/models';

const SupplierDetailPage = () => {
  // @ts-ignore
  const { id } = useParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [supplier, setSupplier] = React.useState<Supplier>();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const pullSupplier = async () => {
    const response = await Api.Supplier.getSupplier(id);
    if (response.data) setSupplier(response.data);
  };

  React.useEffect(() => {
    pullSupplier();
  }, []);

  return (
    <React.Fragment>
      <Card>
        {supplier && (
          <CardContent>
            <Grid container spacing={3}>
              <Grid item lg={12} md={12} xs={12}>
                <Grid container justify="flex-end">
                  <Grid item lg={3} md={3} xs={12}>
                    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
                      İşlemler
                    </Button>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={handleClose}>Ürün Fiyatlandırma</MenuItem>
                      <MenuItem onClick={handleClose}>İskonto Ayarlama</MenuItem>
                    </Menu>
                  </Grid>
                </Grid>
              </Grid>

              <FormHeader title="Tedarikçi Bilgileri" />

              <Grid item lg={2} md={3} xs={12}>
                <DetailFieldLabel title="İsim" label={supplier?.name} />
              </Grid>
              <Grid item lg={2} md={3} xs={12}>
                <DetailFieldLabel title="Telefon No" label={supplier?.gsm} />
              </Grid>
              <Grid item lg={2} md={3} xs={12}>
                <DetailFieldLabel title="Email" label={supplier?.email} />
              </Grid>
              <Grid item lg={2} md={3} xs={12}>
                <DetailFieldLabel title="Adres" label={supplier?.address} />
              </Grid>
            </Grid>
          </CardContent>
        )}
      </Card>
    </React.Fragment>
  );
};

export default SupplierDetailPage;

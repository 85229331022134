import { CreateOrUpdateAssemblyLocation } from 'apps/design/models';
import { Api, TranslatedMaterialTable } from 'core';
import { TableState } from 'core/models';
import React from 'react';
import { useHistory } from 'react-router-dom';

const ListAssembliesPage = () => {
  const [showLoading, setShowLoading] = React.useState(false);
  const history = useHistory();
  const [tableState] = React.useState<TableState<CreateOrUpdateAssemblyLocation>>({
    columns: [{ title: 'Montaj', field: 'name', cellStyle: { whiteSpace: 'nowrap' } }],

    actions: [
      (rowData) => ({
        icon: 'edit',
        tooltip: 'Düzenle',
        onClick: (event, rowData) => {
          const req = rowData as CreateOrUpdateAssemblyLocation;
          history.push(`/design/modules/assembly/update/${req.id}`);
        },
      }),
    ],
  });

  return (
    <TranslatedMaterialTable
      isLoading={showLoading}
      actions={tableState.actions}
      columns={tableState.columns}
      data={(q) =>
        new Promise((resolve) => {
          setShowLoading(true);
          Api.Design.getAssemblyList({
            page: q.page,
            queryName: q.search,
          })
            .then((response) => response.data)
            .then((data) => {
              if (data) {
                resolve({
                  page: q.page,
                  totalCount: data.totalCount,
                  data: data.data,
                });
              }
            });
          setShowLoading(false);
        })
      }
      title="Montaj Yeri Listesi"
    />
  );
};

export default ListAssembliesPage;

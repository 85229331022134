import React from 'react';
import { List, ListSubheader } from '@material-ui/core';
import { MenuBlockInfo } from 'core/types';
import { MenuBlockItem } from './MenuBlockItem';

export interface MenuBlockProps {
  menuBlockInfo: MenuBlockInfo;
}

const MenuBlock: React.FC<MenuBlockProps> = ({ menuBlockInfo }) => {
  return (
    <List
      component="nav"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          {menuBlockInfo.title}
        </ListSubheader>
      }
    >
      {menuBlockInfo.child?.map((menuBlockChildrenInfo) => (
        <MenuBlockItem menuBlockChildrenInfo={menuBlockChildrenInfo} key={menuBlockChildrenInfo.id} />
      ))}
    </List>
  );
};

export default MenuBlock;

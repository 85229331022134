import { Formik, Form } from 'formik';
import React from 'react';
import {
  TextField,
  Grid,
  CardContent,
  Card,
  CardActions,
  Box,
  Switch,
  FormControlLabel,
  FormControl,
} from '@material-ui/core';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import {
  FormAutocomplete,
  FormHeader,
  StoreAutoCompleteSelect,
  SuccessButton,
  TranslatedMaterialTable,
  UploadButton,
} from 'core/components';
import GenericMessages from 'core/GenericMessages';
import TableState from 'core/models/TableState';
import { Autocomplete } from '@material-ui/lab';
import Store from 'apps/store/models/Store';
import { Api, showToast } from 'core';
import { ShipmentMethodDto } from 'apps/order/models';
import { useParams } from 'react-router-dom';

const CreateShipmentMethodForm = () => {
  const [showLoading, setShowLoading] = React.useState(false);
  const [shippingPhoto, setShippingPhoto] = React.useState();
  const [storeList, setStoreList] = React.useState<Store[]>([]);
  const [shipmentMethod, setShipmentMethod] = React.useState<ShipmentMethodDto>();
  const [desi, setDesi] = React.useState<any[]>([]);
  const shippingPhotoPreview = React.useRef(null);
  const dispatch = useDispatch();
  const desiRef = React.useRef<any>();
  const { id }: any = useParams();
  const inputLabelProps = {
    shrink: true,
  };

  const schema = yup.object({
    upperLimit: yup.number().required(GenericMessages.CANNOT_BE_EMPTY),
    dailyLimit: yup.number().required(GenericMessages.CANNOT_BE_EMPTY),
    lowerLimit: yup.number().required(GenericMessages.CANNOT_BE_EMPTY),
    amount: yup.number().required(GenericMessages.CANNOT_BE_EMPTY),
    name: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
  });

  const onCreateShippingMethod = async (values, { resetForm }) => {
    // if (desiRef.current.state.showAddRow || desiRef.current.state.lastEditingRow) {
    //     setShowLoading(false);
    //     dispatch(
    //       showToast({
    //         message: `Desi tablosunda kaydedilmeyen veri bulunmaktadır`,
    //         severity: 'error',
    //       }),
    //     );
    //     return;
    //   }
    setShowLoading(true);
    try {
      const newShipmentMethod: ShipmentMethodDto = {
        name: values.name,
        maxLimit: values.upperLimit,
        minLimit: values.lowerLimit,
        isActive: values.isActive,
        image: shippingPhoto || '',
        dailyLimit: values.dailyLimit,
        amount: values.amount,
        storeIds: values.storeIds.map((store) => store.id),
      };
      if (id) await Api.Order.updateShipmentMethod(id, newShipmentMethod);
      else await Api.Order.createShipmentMethod(newShipmentMethod);
      dispatch(
        showToast({
          message: `başarıyla ${id ? 'güncellendi!' : 'oluşturuldu!'}`,
        }),
      );
    } catch (e) {
      dispatch(
        showToast({
          message: `Hata oluştu`,
          severity: 'error',
        }),
      );
      setShowLoading(false);
    }
    setShowLoading(false);
  };
  const onShippingPhotoSelected = (setFieldValue) => (event) => {
    const { target } = event;

    if (target.files || target.files.length === 1) {
      const file = target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        const currentElem: any = shippingPhotoPreview.current;
        if (currentElem) {
          currentElem.src = e.target?.result;
          setFieldValue('image', e.target?.result);
        }
      };

      reader.readAsDataURL(file);
      setShippingPhoto(file);
    }
  };

  const pullStoreList = async () => {
    const response = await Api.Store.getStores();
    if (response.data.data) setStoreList(response.data.data);
  };

  React.useEffect(() => {
    pullStoreList();
  }, []);

  const pullShipmentMethod = async () => {
    const response = await Api.Order.getShipmentMethod(id);
    if (response.data) setShipmentMethod(response.data);
  };

  React.useEffect(() => {
    if (id) {
      pullShipmentMethod();
    }
  }, []);

  if (id && !shipmentMethod) {
    return null;
  }

  const desiTableState: TableState<any> = {
    columns: [
      {
        title: 'Desi Başlangıç',
        field: 'startDesi',
        validate: (rowData) =>
          !rowData.startDesi || rowData.startDesi.toString().length === 0 ? 'Desi Başlangıç Boş Girilemez' : '',
      },
      {
        title: 'Desi Bitiş',
        field: 'endDesi',
        validate: (rowData) =>
          !rowData.endDesi || rowData.endDesi.toString().length === 0 ? 'Desi Bitiş Boş Girilemez' : '',
      },
      {
        title: 'Fiyat(TL)',
        field: 'desiPrice',
        type: 'numeric',
        validate: (rowData) =>
          !rowData.desiPrice || rowData.desiPrice.toString().length === 0 ? 'Fiyat Boş Girilemez' : '',
      },
    ],
  };

  return (
    <React.Fragment>
      <Formik
        onSubmit={onCreateShippingMethod}
        validationSchema={schema}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
          name: shipmentMethod?.name,
          amount: shipmentMethod?.amount,
          lowerLimit: shipmentMethod?.minLimit,
          upperLimit: shipmentMethod?.maxLimit,
          dailyLimit: shipmentMethod?.dailyLimit,
          storeIds: shipmentMethod?.storeIds
            ? shipmentMethod.storeIds.map((storeId) => ({
                id: storeId,
              }))
            : [],
          isActive: shipmentMethod?.isActive,
          image: shipmentMethod?.image || '',
        }}
      >
        {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
          <Form onSubmit={handleSubmit} id="form" noValidate>
            <Card>
              <CardContent>
                <Grid direction="row" container spacing={5}>
                  <FormHeader title="Kargo Metodu Oluştur" />
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField
                      required
                      label="Adı"
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      InputLabelProps={inputLabelProps}
                      placeholder="Adı"
                      name="name"
                      onChange={handleChange}
                      value={values.name}
                      error={errors.name != null}
                      helperText={errors.name}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField
                      label="Tutar"
                      placeholder="Tutar"
                      variant="outlined"
                      name="amount"
                      type="number"
                      fullWidth
                      margin="dense"
                      InputLabelProps={inputLabelProps}
                      onChange={handleChange}
                      value={values.amount}
                      error={errors.amount != null}
                      helperText={errors.amount}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField
                      required
                      name="upperLimit"
                      label="Üst Limit"
                      placeholder="Üst Limit"
                      variant="outlined"
                      type="number"
                      InputLabelProps={inputLabelProps}
                      fullWidth
                      margin="dense"
                      onChange={handleChange}
                      value={values.upperLimit}
                      error={errors.upperLimit != null}
                      helperText={errors.upperLimit}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField
                      required
                      name="lowerLimit"
                      label="Alt Limit"
                      placeholder="Alt Limit"
                      variant="outlined"
                      type="number"
                      InputLabelProps={inputLabelProps}
                      fullWidth
                      margin="dense"
                      onChange={handleChange}
                      value={values.lowerLimit}
                      error={errors.lowerLimit != null}
                      helperText={errors.lowerLimit}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <TextField
                      required
                      name="dailyLimit"
                      label="Günlük Limit"
                      placeholder="Günlük Limit"
                      variant="outlined"
                      type="number"
                      InputLabelProps={inputLabelProps}
                      fullWidth
                      margin="dense"
                      onChange={handleChange}
                      value={values.dailyLimit}
                      error={errors.dailyLimit != null}
                      helperText={errors.dailyLimit}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormControl component="fieldset">
                      <FormControlLabel
                        value="end"
                        control={
                          <Switch
                            color="primary"
                            checked={values.isActive}
                            onChange={(e) => setFieldValue('isActive', e.target.checked)}
                          />
                        }
                        label="Durumu Seçiniz (Aktif veya Pasif)"
                        labelPlacement="end"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    {(shippingPhoto != null || values.image !== '') && (
                      <Grid container justify="center">
                        <img src={values.image} alt="" height={100} width={100} ref={shippingPhotoPreview} />
                      </Grid>
                    )}
                    <UploadButton
                      buttonProps={{
                        type: 'button',
                      }}
                      onChange={onShippingPhotoSelected(setFieldValue)}
                    >
                      Tedarikçi Görseli Yükle
                    </UploadButton>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <StoreAutoCompleteSelect
                      placeholder="Mağazaları Seçebilirsiniz"
                      optionLabelName="name"
                      equValueName="id"
                      onChange={(event, store) => setFieldValue('storeIds', store)}
                      error={errors.storeIds != null}
                      value={values.storeIds}
                      multiple
                      helperText={errors.storeIds as string}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Form>
        )}
      </Formik>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TranslatedMaterialTable
            title="Desi aralıkları ve fiyatları girebilir veya mevcut olanları silebilirsiniz.
  **Eğer sabit fiyat ise, başlangıç ve bitiş aralığı 0(sıfır) yazarak 1 tane ekleme yapınız"
            columns={desiTableState.columns}
            actions={desiTableState.actions}
            data={desi}
            tableRef={desiRef}
            options={{
              pageSize: Math.max(5, desi.length),
              search: false,
            }}
            editable={{
              isEditable: () => true,
              isDeletable: () => true,
              onRowAdd: (newData: any) => {
                return new Promise((resolve) => {
                  const newDesiData = [...desi];
                  newDesiData.push(newData);
                  setDesi(newDesiData);
                  resolve();
                });
              },
              onRowUpdate: (newData: any, oldData?: any) => {
                return new Promise((resolve) => {
                  const dataUpdate = [...desi];
                  const index = oldData.tableData.id;
                  dataUpdate[index] = newData;
                  setDesi([...dataUpdate]);
                  resolve();
                });
              },
              onRowDelete: (oldData: any) => {
                return new Promise((resolve) => {
                  const dataDelete = [...desi];
                  const index = oldData.tableData.id;
                  dataDelete.splice(index, 1);
                  setDesi([...dataDelete]);
                  resolve();
                });
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box alignItems="center" justifyContent="flex-end" display="flex" className="w-100">
            <SuccessButton form="form" type="submit" startIcon={<SaveOutlinedIcon />} showLoading={showLoading}>
              {id ? 'Kargo Methodu Güncelle' : 'Kargo Methodu Oluştur'}
            </SuccessButton>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default CreateShipmentMethodForm;

import React from 'react';
import { TableState } from 'core/models';
import {
  FormAutocomplete,
  FormDialog,
  FormSelect,
  FormTextField,
  ManufacturerAutoCompleteSelect,
  SupplierAutoCompleteSelect,
  TranslatedMaterialTable,
} from 'core/components';
import { Product, ProductFilterRequest } from 'apps/product/models';
import { ListAlt, Sync } from '@material-ui/icons';
import { BatchBrandModelForm } from 'apps/crm';
import { Api } from 'core';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
} from '@material-ui/core';
import { Filter as FilterIcon } from 'react-feather';
import { ProductMatchedVehicleDataListDialog } from '../../../product/components/ProductMatchedVehicleDataListDialog';

const BatchBrandModelPage = () => {
  const [showLoading, setShowLoading] = React.useState(false);
  const [productFilter, setProductFilter] = React.useState<
    ProductFilterRequest & { suppliers?: any[]; manufacturers?: any[] }
  >({});
  const [showDialogLoading, setShowDialogLoading] = React.useState(false);
  const tableRef = React.useRef<any>();
  const [showBatchBrandModelDialog, setShowBatchBrandModelDialog] = React.useState(false);
  const [showVehicleDataDialog, setShowVehicleDataDialog] = React.useState(false);
  const [products, setProducts] = React.useState<Product[]>([]);
  const [showFilterDialog, setShowFilterDialog] = React.useState<boolean>(false);
  const [productId, setProductId] = React.useState<any>();
  const [tableState] = React.useState<TableState<Product>>({
    columns: [{ title: 'Ürün', field: 'name', cellStyle: { whiteSpace: 'nowrap' } }],

    actions: [
      {
        tooltip: 'Eşleştir',
        icon: (props) => <Sync {...props} />,
        onClick: (event, rowData) => {
          const data = rowData as Product[];
          setProducts(data);
          // eslint-disable-next-line no-use-before-define
          handleBatchBrandModelDialogOpen();
        },
      },
      {
        icon: (props) => <FilterIcon {...props} />,
        tooltip: 'Filtrele',
        isFreeAction: true,
        onClick: (event) => {
          setShowFilterDialog(true);
        },
      },
      {
        tooltip: 'Eşleştirilmişleri Gör',
        position: 'row',
        icon: (props) => <ListAlt {...props} />,
        onClick: (event, rowData) => {
          const data = rowData as Product;
          setShowVehicleDataDialog(true);
          setProductId(data.id);
        },
      },
    ],
  });

  const handleFilterDialogClose = () => {
    setShowFilterDialog(false);
  };

  const handleBatchBrandModelDialogClose = () => {
    setShowBatchBrandModelDialog(false);
  };
  const handleBatchBrandModelDialogOpen = () => {
    setShowBatchBrandModelDialog(true);
  };

  const onFilterSubmit = (data) => {
    const newProductFilter = {
      ...productFilter,
      ...data,
    };
    setProductFilter(newProductFilter);
  };

  React.useEffect(
    () =>
      tableRef?.current?.onQueryChange({
        page: 0,
      }),
    [productFilter],
  );

  return (
    <React.Fragment>
      <FormDialog
        handleClose={handleBatchBrandModelDialogClose}
        open={showBatchBrandModelDialog}
        formId="batch"
        title="Marka Model Eşleştir"
        showLoading={showDialogLoading}
      >
        <BatchBrandModelForm
          formId="batch"
          products={products}
          setShowBatchBrandModelDialog={setShowBatchBrandModelDialog}
          setShowLoading={setShowDialogLoading}
        />
      </FormDialog>
      <ProductMatchedVehicleDataListDialog
        handleVehicleDataDialogClose={() => {
          setShowVehicleDataDialog(false);
        }}
        showVehicleDataDialog={showVehicleDataDialog}
        productId={productId}
      />
      <TranslatedMaterialTable
        isLoading={showLoading}
        tableRef={tableRef}
        actions={tableState.actions}
        columns={tableState.columns}
        options={{
          selection: true,
          debounceInterval: 1000,
        }}
        data={(q) =>
          new Promise((resolve) => {
            setShowLoading(true);
            const filterData: any = {
              ...productFilter,
              page: q.page,
              queryName: q.search,
            };
            if (filterData.suppliers) {
              filterData.supplierIds = filterData.suppliers.map((supplier) => supplier.id);
              delete filterData.suppliers;
            }
            if (filterData.manufacturers) {
              filterData.manufacturerIds = filterData.manufacturers.map((manufacturer) => manufacturer.id);
              delete filterData.manufacturers;
            }
            Api.Product.getProducts(filterData)
              .then((response) => response.data)
              .then((data) => {
                if (data) {
                  setShowLoading(false);
                  resolve({
                    page: q.page,
                    totalCount: data.totalCount,
                    data: data.data,
                  });
                }
              });
          })
        }
        title="Ürün Listesi"
      />
      <Dialog open={showFilterDialog} onClose={handleFilterDialogClose}>
        <DialogTitle>Filtrele</DialogTitle>
        <DialogContent>
          <Formik
            onSubmit={onFilterSubmit}
            initialValues={{
              manufacturers: productFilter.manufacturers || [],
              suppliers: productFilter.suppliers || [],
            }}
          >
            {({ setFieldValue, handleChange, values, handleSubmit }) => (
              <form noValidate onSubmit={handleSubmit} id="filterForm">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box px={5}>
                      <ManufacturerAutoCompleteSelect
                        fullWidth
                        value={values.manufacturers}
                        onChange={(event, value) => setFieldValue('manufacturers', value)}
                        multiple
                      />
                    </Box>
                  </Grid>

                  <Grid xs={12}>
                    <Box px={6}>
                      <SupplierAutoCompleteSelect
                        multiple
                        placeholder="Tedarikçi"
                        fullWidth
                        value={values.suppliers}
                        onChange={(event, values) => setFieldValue('suppliers', values)}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFilterDialogClose} color="secondary">
            Kapat
          </Button>
          <Button onClick={handleFilterDialogClose} color="primary" autoFocus form="filterForm" type="submit">
            Ara
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default BatchBrandModelPage;

import { TextField } from '@material-ui/core';
import { Category } from 'apps/crm/models';
import { Product } from 'apps/product/models';

import { Api } from 'core';
import { FilterRequest } from 'core/models';
import React from 'react';
import { FormAutocomplete, FormAutocompleteProps } from '../FormAutocomplete';

export type ProductAutoCompleteSelectProps = Omit<FormAutocompleteProps, 'options'>;

const ProductAutoCompleteSelect: React.FC<ProductAutoCompleteSelectProps> = ({ onChange, ...autocompleteProps }) => {
  const [productList, setProductList] = React.useState<Product[]>([]);
  const [inputValue, setInputValue] = React.useState<string>();

  const pullProducts = async (filterCategoryRequest?: FilterRequest) => {
    const response = await Api.Product.getProducts(filterCategoryRequest);
    if (response.data) setProductList(response.data.data);
  };

  React.useEffect(() => {
    pullProducts();
  }, []);

  const onQueryChage = (event, newInputValue: string) => {
    setInputValue(newInputValue);
    if (newInputValue && newInputValue.length > 0) {
      pullProducts({
        queryName: newInputValue,
      });
    } else {
      pullProducts();
    }
  };

  return (
    <FormAutocomplete
      {...autocompleteProps}
      size="small"
      options={productList}
      multiple={autocompleteProps.multiple}
      optionLabelName="name"
      equValueName="id"
      onChange={onChange}
      filterSelectedOptions
      inputValue={inputValue}
      onInputChange={onQueryChage}
      placeholder={autocompleteProps.placeholder}
    />
  );
};
ProductAutoCompleteSelect.defaultProps = {
  multiple: true,
};

export default ProductAutoCompleteSelect;

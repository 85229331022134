import { Switch, Route } from 'react-router-dom';
import React from 'react';
import { DashboardPage } from '../pages';

export interface HomeLayoutProps {
  match?: any;
}

const HomeLayout: React.FC<HomeLayoutProps> = ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.path}`} component={DashboardPage} />
    </Switch>
  );
};

export default HomeLayout;

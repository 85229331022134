import { TextField, Typography } from '@material-ui/core';
import { Category } from 'apps/crm/models';
import MPCategoryList from 'apps/order/models/MPCategoryList';
import { Product } from 'apps/product/models';

import { Api, HideableLoadingSpinner, HideableLoadingSpinnerBox } from 'core';
import { FilterRequest } from 'core/models';
import React from 'react';
import { FormAutocomplete, FormAutocompleteProps } from '../FormAutocomplete';

export type MPCategoryAutoCompleteSelectProps = Omit<FormAutocompleteProps, 'options'>;

const MPCategoryAutoCompleteSelect: React.FC<MPCategoryAutoCompleteSelectProps> = ({
  onChange,
  ...autocompleteProps
}) => {
  const [mpCategoryList, setMpCategoryList] = React.useState<MPCategoryList[]>([]);
  const [inputValue, setInputValue] = React.useState<string>();
  const [status, setStatus] = React.useState('success');
  const [isFirstLoad, setIsFirstLoad] = React.useState(true);

  const pullMpCategory = async (filterCategoryRequest?: FilterRequest) => {
    try {
      const response = await Api.Order.getMpCategory(autocompleteProps?.parentId);
      if (response.data) {
        setMpCategoryList(response.data);
        setStatus(response.status);
        setIsFirstLoad(false);
      }
    } catch (error) {
      setStatus('error');
    }
  };

  React.useEffect(() => {
    pullMpCategory();
  }, []);

  const onQueryChage = (event, newInputValue: string) => {
    setInputValue(newInputValue);
    if (newInputValue && newInputValue.length > 0) {
      pullMpCategory({
        queryName: newInputValue,
      });
    } else {
      pullMpCategory();
    }
  };
  if (status == 'error') {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <h5> En Alt Kategoriye Ulaştınız </h5>
      </div>
    );
  }
  return (
    <React.Fragment>
      {status != 'error' && (
        <FormAutocomplete
          {...autocompleteProps}
          size="small"
          showLoading={isFirstLoad}
          options={mpCategoryList}
          multiple={autocompleteProps.multiple}
          optionLabelName="name"
          equValueName="id"
          onChange={onChange}
          filterSelectedOptions
          inputValue={inputValue}
          onInputChange={onQueryChage}
          placeholder={autocompleteProps.placeholder}
        />
      )}
    </React.Fragment>
  );
};
MPCategoryAutoCompleteSelect.defaultProps = {
  multiple: false,
};

export default MPCategoryAutoCompleteSelect;

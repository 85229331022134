import { Switch, Route } from 'react-router-dom';
import React from 'react';
import { CreateContractPage, CreateStorePage, StoreListPage, ContractListPage, StoreDetailPage } from '../pages';

interface StoreLayoutProps {
  match: any;
}

const StoreLayout: React.FC<StoreLayoutProps> = ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.path}`} component={CreateStorePage} />
      <Route exact path={`${match.path}/update/:id`} component={CreateStorePage} />
      <Route exact path={`${match.path}/contract/`} component={CreateContractPage} />
      <Route exact path={`${match.path}/contract/list`} component={ContractListPage} />
      <Route exact path={`${match.path}/list`} component={StoreListPage} />
      <Route exact path={`${match.path}/detail/:id`} component={StoreDetailPage} />
    </Switch>
  );
};

export default StoreLayout;

/* eslint-disable react/jsx-key */
// @ts-nocheck
import React from 'react';

import { Api } from 'core';
import {
  Card,
  Grid,
  CardContent,
  Box,
  Button,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  CardHeader,
} from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import { FormHeader, DetailFieldLabel, FormDialog, TranslatedMaterialTable } from 'core/components';
import { Campaign } from 'apps/campaign/models';
import { Visibility } from '@material-ui/icons';

const CampaignDetailPage = () => {
  const { id } = useParams();
  const [campaign, setCampaign] = React.useState<Campaign>();
  const history = useHistory();
  const pullCampaign = async () => {
    const response = await Api.Campaign.getCampaign(id);
    if (response.data) setCampaign(response.data);
  };

  React.useEffect(() => {
    pullCampaign();
  }, []);

  return (
    <React.Fragment>
      <Card>
        {campaign && (
          <CardContent>
            <Grid container spacing={3}>
              <FormHeader title="Kampanya Bilgileri" />
              <Grid item lg={2} md={3} xs={12}>
                <DetailFieldLabel title="İsim" label={campaign.name} />
              </Grid>
              <Grid item lg={2} md={3} xs={12}>
                <DetailFieldLabel title="Aktiflik Durumu" label={campaign?.isActive ? 'Aktif' : 'Pasif'} />
              </Grid>
              <Grid item lg={2} md={3} xs={12}>
                <DetailFieldLabel title="Bitiş Tarihi" label={campaign?.expirationDate} />
              </Grid>
              <Grid item lg={2} md={3} xs={12}>
                <DetailFieldLabel title="Açıklama" label={campaign?.description} />
              </Grid>
              <Grid item xs={12}>
                <FormHeader title="Kampanya Ürün Listesi" />
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Ürün Detay</TableCell>
                        <TableCell align="right">İsim</TableCell>
                        <TableCell align="right">Fiyat</TableCell>
                        <TableCell align="right">Adet</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {campaign.products?.map((product) => (
                        <TableRow>
                          <TableCell>
                            <Button
                              endIcon={<Visibility />}
                              onClick={() => history.push(`/crm/product/detail/${product.id}`)}
                              color="primary"
                            />
                          </TableCell>
                          <TableCell align="right">{product?.name}</TableCell>
                          <TableCell align="right">{product?.price}</TableCell>
                          <TableCell align="right">{product?.quantity}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </CardContent>
        )}
      </Card>
    </React.Fragment>
  );
};

export default CampaignDetailPage;

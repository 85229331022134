import { FormDialog } from 'core';
import React from 'react';
import { ClientContact } from '../ClientContact';

export interface ClientFastContactDialogProps {
  showFastContact: any;
  onFastContactClose: () => void;
  formId: string;
  clientName?: string;
  clientId?: number;
}
const ClientFastContactDialog: React.FC<ClientFastContactDialogProps> = ({
  clientName,
  clientId,
  showFastContact,
  onFastContactClose,
  formId,
}) => {
  const [buttonShowLoading, setButtonShowLoading] = React.useState(false);

  return (
    <React.Fragment>
      <FormDialog
        open={showFastContact}
        handleClose={onFastContactClose}
        formId={formId}
        title={`${clientName} Hızlı İletişim`}
        showLoading={buttonShowLoading}
      >
        <ClientContact
          formId="clientContact"
          clientId={clientId}
          onCloseFastContact={onFastContactClose}
          setButtonShowLoading={setButtonShowLoading}
        />
      </FormDialog>
    </React.Fragment>
  );
};

export default ClientFastContactDialog;

import React from 'react';
import { Api, FormTextField, LoadingButton } from 'core';
import { Grid, Card, CardContent, Typography, Box } from '@material-ui/core';
import { Send } from '@material-ui/icons';
import { OrderNote } from 'apps/order';

interface OrderNoteListProps {
  notes: OrderNote[];
  id: number;
}

const OrderNoteList: React.FC<OrderNoteListProps> = ({ notes, id }) => {
  const [noteList, setNotesList] = React.useState<any[]>([]);
  const [note, setNotes] = React.useState('');
  const [showLoading, setShowLoading] = React.useState(false);

  const handleNewNotes = async () => {
    setShowLoading(true);
    if (note.length !== 0) {
      const response = await Api.Order.createOrderNote({ orderId: id, note });
      if (response.data) setNotesList([...noteList, response.data]);
      setNotes('');
    }
    setShowLoading(false);
  };

  const pullOrderNotes = async () => {
    try {
      const responseNote = await Api.Order.getOrderNotes(id);
      setNotesList(responseNote);
    } catch (e) {
      console.log('xx', e);
    }
  };

  React.useEffect(() => {
    pullOrderNotes();
  }, []);
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        {noteList.length > 0 &&
          noteList.map((note) => (
            <Card variant="outlined" className="mb-2" key={`clc${note.id}`}>
              <CardContent>
                {note.note}
                <Typography variant="subtitle2" />
              </CardContent>
            </Card>
          ))}

        {noteList.length === 0 && (
          <Card variant="outlined">
            <CardContent>
              <Typography variant="subtitle2">Not bulunamadı.</Typography>
            </CardContent>
          </Card>
        )}

        <Grid container xs={12}>
          <Grid item xs={12} md={10}>
            <FormTextField
              placeholder="Sipariş Notları"
              name="note"
              value={note}
              onChange={(e) => {
                setNotes(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={2} justify="center">
            <LoadingButton showLoading={showLoading} color="primary" onClick={handleNewNotes}>
              Gönder <Send />
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrderNoteList;

import {
  Archive,
  Equalizer,
  ListAlt,
  List,
  PostAdd,
  Settings,
  SelectAll,
  Attachment,
  Storefront,
} from '@material-ui/icons';
import { MenuBlockInfo } from 'core';
import React from 'react';
import { Codepen, Package, Map, CreditCard, Truck } from 'react-feather';

const orderSidebarMenuList: MenuBlockInfo = {
  id: 'orderMenu',
  title: 'Sipariş İşlemleri',
  child: [
    // {
    //   id: 'integration',
    //   icon: <Attachment />,
    //   label: 'Entegrasyonlar',
    //   child: [
    //     {
    //       id: 'googleAnalytics',
    //       icon: <Archive />,
    //       label: 'Google Analtytics',
    //       route: '/orders/integration/googleAnalytics',
    //     },
    //     {
    //       id: 'hotjar',
    //       route: '/orders/integration/hotjar',
    //       icon: <Archive />,
    //       label: 'Hotjar',
    //     },
    //   ],
    // },
    {
      id: 'marketplaces',
      icon: <SelectAll />,
      label: 'Pazar Yerleri',
      child: [
        {
          id: 'n11',
          icon: <Storefront />,
          label: 'N11',
          route: '/orders/marketplaces/n11',
          child: [
            {
              id: 'n11Products',
              route: '/orders/marketplaces/n11/products',
              icon: <List />,
              label: 'Ürünler',
            },
            {
              id: 'n11SyncCategory',
              route: '/orders/marketplaces/n11/syncCategory',
              icon: <ListAlt />,
              label: 'Kategori Eşitle',
            },
            {
              id: 'n11Settings',
              route: '/orders/marketplaces/n11/settings',
              icon: <Settings />,
              label: 'Ayarlar',
            },
          ],
        },
        {
          id: 'hepsiburada',
          icon: <Storefront />,
          label: 'HepsiBurada',
          route: '/orders/marketplaces/hepsiburada',
          child: [
            {
              id: 'hepsiburadaSyncCategory',
              route: '/orders/marketplaces/hepsiburada/syncCategory',
              icon: <ListAlt />,
              label: 'Kategori Eşitle',
            },
            {
              id: 'hepsiburadaSettings',
              route: '/orders/marketplaces/hepsiburada/settings',
              icon: <Settings />,
              label: 'Ayarlar',
            },
          ],
        },
        {
          id: 'trendyol',
          icon: <Storefront />,
          label: 'Trendyol',
          route: '/orders/marketplaces/trendyol',
          child: [
            {
              id: 'trendyolSyncCategory',
              route: '/orders/marketplaces/trendyol/syncCategory',
              icon: <ListAlt />,
              label: 'Kategori Eşitle',
            },
            {
              id: 'trendyolSyncProducer',
              route: '/orders/marketplaces/trendyol/syncProducer',
              icon: <ListAlt />,
              label: 'Üretici Eşitle',
            },
            {
              id: 'trendyolSettings',
              route: '/orders/marketplaces/trendyol/settings',
              icon: <Settings />,
              label: 'Ayarlar',
            },
          ],
        },
        {
          id: 'gittigidiyor',
          icon: <Storefront />,
          label: 'GittiGidiyor',
          route: '/orders/marketplaces/gittigidiyor',
          child: [
            {
              id: 'gittigidiyorSyncCategory',
              route: '/orders/marketplaces/gittigidiyor/syncCategory',
              icon: <ListAlt />,
              label: 'Kategori Eşitle',
            },
            {
              id: 'gittigidiyorSettings',
              route: '/orders/marketplaces/gittigidiyor/settings',
              icon: <Settings />,
              label: 'Ayarlar',
            },
          ],
        },
        {
          id: 'syncProduct',
          label: 'Toplu Ürün İşlemi',
          route: '/orders/marketplaces/syncProducts',
        },
        {
          id: 'batcjProduct',
          label: 'Toplu Kategori İşlemi',
          route: '/orders/marketplaces/syncAllCategoryAttributes',
        },
      ],
    },
    {
      id: 'supplier',
      icon: <Codepen />,
      label: 'Tedarikçi',
      child: [
        {
          id: 'createSupplier',
          icon: <PostAdd />,
          label: 'Tedarikçi Oluştur',
          route: '/orders/supplier/create',
        },
        {
          id: 'orderList',
          route: '/orders/supplier/list',
          icon: <List />,
          label: 'Tedarikçi Listesi',
        },
      ],
    },

    {
      id: 'shipping',
      icon: <Truck />,
      label: 'Kargo',
      child: [
        {
          id: 'createShipping',
          icon: <PostAdd />,
          label: 'Kargo Methodu Oluştur',
          route: '/orders/shipping/create',
        },
        {
          id: 'shippingList',
          route: '/orders/shipping/list',
          icon: <List />,
          label: 'Kargo Listesi',
        },
      ],
    },

    {
      id: 'paymentType',
      icon: <CreditCard />,
      label: 'Ödeme Methodları',
      child: [
        {
          id: 'paymentType',
          icon: <PostAdd />,
          label: 'Ödeme Yöntemi Ekle',
          route: '/orders/paymentType/create',
        },
        {
          id: 'paymentTypeList',
          route: '/orders/paymentType/list',
          icon: <List />,
          label: 'Ödeme Yöntem Listesi',
        },
        // {
        //   id: 'installment',
        //   route: '/orders/installment/create',
        //   icon: <PostAdd />,
        //   label: 'Taksit Oranı',
        // },
      ],
    },

    {
      id: 'producer',
      icon: <Package />,
      label: 'Üretici',
      child: [
        {
          id: 'createProducer',
          route: '/orders/producer/create',
          icon: <PostAdd />,
          label: 'Üretici Oluştur',
        },
        {
          id: 'producerList',
          route: '/orders/producer/list',
          icon: <List />,
          label: 'Üretici Listesi',
        },
      ],
    },
    {
      id: 'category',
      icon: <Map />,
      label: 'Kategori',
      child: [
        {
          id: 'createCategory',
          route: '/orders/category/create',
          icon: <PostAdd />,
          label: 'Kategori Oluştur',
        },
        {
          id: 'listCategory',
          route: '/orders/category/list/',
          icon: <List />,
          label: 'Kategori Listesi',
        },
      ],
    },
  ],
};

export default orderSidebarMenuList;

import { TextField } from '@material-ui/core';
import { Category } from 'apps/crm/models';
import { DesignSection } from 'apps/design/models';
import { Api } from 'core';
import { FilterRequest } from 'core/models';
import React from 'react';
import { FormAutocomplete, FormAutocompleteProps } from '../FormAutocomplete';

export type DesignSectionAutoCompleteSelectProps = Omit<FormAutocompleteProps, 'options'>;

const DesignSectionAutoCompleteSelect: React.FC<DesignSectionAutoCompleteSelectProps> = ({
  onChange,
  ...autocompleteProps
}) => {
  const [designSectionList, setDesignSectionList] = React.useState<DesignSection[]>([]);
  const [inputValue, setInputValue] = React.useState<string>();
  const [isFirstLoad, setIsFirstLoad] = React.useState(true);

  const pullDesignSectionList = async (filterCategoryRequest?: FilterRequest) => {
    const response = await Api.Design.getDesignSections(filterCategoryRequest);
    if (response.data) setDesignSectionList(response.data.data);
    setIsFirstLoad(false);
  };

  React.useEffect(() => {
    pullDesignSectionList();
  }, []);

  const onQueryChage = (event, newInputValue: string) => {
    setInputValue(newInputValue);
    if (newInputValue && newInputValue.length > 0) {
      pullDesignSectionList({
        queryName: newInputValue,
      });
    } else {
      pullDesignSectionList();
    }
  };

  return (
    <FormAutocomplete
      {...autocompleteProps}
      multiple={autocompleteProps.multiple}
      size="small"
      showLoading={isFirstLoad}
      options={designSectionList}
      optionLabelName="name"
      equValueName="id"
      onChange={onChange}
      filterSelectedOptions
      inputValue={inputValue}
      onInputChange={onQueryChage}
      placeholder={autocompleteProps.placeholder}
    />
  );
};

DesignSectionAutoCompleteSelect.defaultProps = {
  placeholder: 'CMS Bölüm',
};

export default DesignSectionAutoCompleteSelect;

import { CreateShipmentMethodForm } from 'apps/order';
import React from 'react';

const CreateShipmentMethodPage = () => {
  return (
    <React.Fragment>
      <CreateShipmentMethodForm />
    </React.Fragment>
  );
};

export default CreateShipmentMethodPage;

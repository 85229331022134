import { ButtonProps, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

export interface LoadingButtonProps extends ButtonProps {
  showLoading?: boolean;
  loadingText?: string;
}
const useStyles = makeStyles((theme) => {
  return {
    loading: {
      color: theme.palette.primary.contrastText,
      marginRight: '8px',
    },
  };
});

const LoadingButton: React.FC<LoadingButtonProps> = ({
  onClick,
  showLoading,
  children,
  loadingText,
  ...buttonProps
}) => {
  const loadingButtonStyle = useStyles();
  const onButtonClick = (event) => {
    if (!showLoading) {
      if (onClick) {
        onClick(event);
      }
    }
  };

  const { startIcon, endIcon, ...buttonPropsCleaned } = buttonProps;
  const _buttonProps = showLoading ? buttonPropsCleaned : buttonProps;

  return (
    <Button {..._buttonProps} onClick={onButtonClick}>
      {showLoading ? (
        <React.Fragment>
          <CircularProgress size={24} className={loadingButtonStyle.loading} /> {loadingText}
        </React.Fragment>
      ) : (
        <React.Fragment>{children}</React.Fragment>
      )}
    </Button>
  );
};

LoadingButton.defaultProps = {
  loadingText: 'Yükleniyor...',
  showLoading: false,
};

export default LoadingButton;

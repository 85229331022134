import React, { useEffect } from 'react';
import { TableState } from 'core/models';
import { TranslatedMaterialTable } from 'core/components';
import { Api } from 'core';
import { Store } from 'apps/store';
import { useHistory } from 'react-router-dom';
import { Visibility as VisibilityIcon } from '@material-ui/icons';
import { Chip } from '@material-ui/core';

const StoreListPage = () => {
  const [showLoading, setShowLoading] = React.useState(false);
  const history = useHistory();
  const [tableState] = React.useState<TableState<Store>>({
    columns: [
      { title: 'İsim', field: 'name', cellStyle: { whiteSpace: 'nowrap' } },
      {
        title: 'Logo',
        render: (val) => (val?.logo ? <img src={val?.logo} width="12%" /> : 'Yok'),
        cellStyle: { whiteSpace: 'nowrap' },
      },
    ],
    actions: [
      {
        tooltip: 'Detaylar',
        icon: (props) => <VisibilityIcon {...props} />,
        onClick: (event, rowData) => {
          const store = rowData as Store;
          history.push(`/store/detail/${store.id}`);
        },
      },
      {
        icon: 'edit',
        tooltip: 'Düzenle',
        onClick: (event, rowData) => {
          const store = rowData as Store;
          history.push(`/store/update/${store.id}`);
        },
      },
    ],
  });
  return (
    <TranslatedMaterialTable
      isLoading={showLoading}
      actions={tableState.actions}
      columns={tableState.columns}
      data={(q) =>
        new Promise((resolve) => {
          setShowLoading(true);
          Api.Store.getStores({
            page: q.page,
          })
            .then((response) => response.data)
            .then((data) => {
              if (data) {
                resolve({
                  page: q.page,
                  totalCount: data.totalCount,
                  data: data.data,
                });
              }
            });
          setShowLoading(false);
        })
      }
      title="Mağaza Listesi"
    />
  );
};
export default StoreListPage;

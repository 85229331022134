import React from 'react';

import { useParams } from 'react-router-dom';
import { Api } from 'core';
import { HideableLoadingSpinnerBox } from 'core/components';
import { Campaign, CreateOrUpdateCampaignForm } from 'apps/campaign';

const UpdateCampaignPage = () => {
  // @ts-ignore
  const { id } = useParams();
  const [campaign, setCampaign] = React.useState<Campaign>();
  const [loading, setLoading] = React.useState(false);
  const pullCampaign = async () => {
    setLoading(true);
    const response = await Api.Campaign.getCampaign(id);
    if (response.data) setCampaign(response.data);
    setLoading(false);
  };

  React.useEffect(() => {
    pullCampaign();
  }, []);
  if (loading) {
    return <HideableLoadingSpinnerBox />;
  }
  return <CreateOrUpdateCampaignForm campaign={campaign} />;
};

export default UpdateCampaignPage;

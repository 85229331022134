import React, { useEffect } from 'react';
import { TableState } from 'core/models';
import { Client } from 'apps/crm';
import { HideableLoadingSpinnerBox, TranslatedMaterialTable } from 'core/components';
import ProductSlider from 'apps/design/models/ProductSlider';
import { Api } from 'core';
import { useHistory } from 'react-router-dom';

const SliderListPage = () => {
  const [sliders, setSliders] = React.useState<ProductSlider[]>();
  const [showLoading, setShowLoading] = React.useState(false);
  const history = useHistory();

  const [tableState] = React.useState<TableState<ProductSlider>>({
    columns: [
      {
        title: 'Başlık',
        field: 'title',
      },
      {
        title: 'İsim',
        field: 'name',
      },
    ],
    actions: [
      {
        icon: 'edit',
        tooltip: 'Düzenle',
        onClick: (event, rowData) => {
          const productSlider = rowData as ProductSlider;
          history.push(`/design/slider/update/${productSlider.id}`);
        },
      },
    ],
  });

  return (
    <TranslatedMaterialTable
      columns={tableState.columns}
      actions={tableState.actions}
      isLoading={showLoading}
      data={(q) =>
        new Promise((resolve) => {
          setShowLoading(true);
          Api.Design.getProductSliders({
            page: q.page,
            queryName: q.search,
          })
            .then((response) => response.data)
            .then((data) => {
              if (data) {
                resolve({
                  page: q.page,
                  totalCount: data.totalCount,
                  data: data.data,
                });
              }
            });
          setShowLoading(false);
        })
      }
    />
  );
};
export default SliderListPage;

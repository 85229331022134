import { ecmsClient } from 'core';

const BASE_URL = '/store';
const BlogApi = {
  // async createStore(createStoreRequest: CreateStoreRequest): Promise<ApiResponse<CreateStoreResponse>> {
  //   const formData = new FormData();
  //   formData.append('showroom', createStoreRequest.showroom);
  //   formData.append('title', createStoreRequest.title);
  //   formData.append('name', createStoreRequest.dealerName);
  //   formData.append('companyName', createStoreRequest.dealerCompanyName);
  //   formData.append('taxOffice', createStoreRequest.dealerTaxOffice);
  //   formData.append('taxNo', createStoreRequest.dealerTaxNo.toString());
  //   formData.append('provinceId', createStoreRequest.dealerProvinceID.toString());
  //   formData.append('districtId', createStoreRequest.dealerDistrictID.toString());
  //   formData.append('gsm', createStoreRequest.dealerGsm.toString());
  //   formData.append('email', createStoreRequest.dealerEmail);
  //   formData.append('address', createStoreRequest.dealerAddress);
  //   formData.append('balance', createStoreRequest.dealerBalance.toString());
  //   formData.append('isActive', createStoreRequest.dealerIsActive.toString());
  //   formData.append('isIndividual', createStoreRequest.dealerIsIndividual.toString());
  //   formData.append('isEnterprise', createStoreRequest.dealerIsEnterprise.toString());
  //   formData.append('isFleet', createStoreRequest.dealerIsFleet.toString());
  //   if (createStoreRequest.dealerLogo) formData.append('dealerLogo', createStoreRequest.dealerLogo);
  //   if (createStoreRequest.dealerWebsite) formData.append('website', createStoreRequest.dealerWebsite.toString());
  //   const response = await ecmsClient.post(this.URL_CREATE_DEALER, formData);
  //   return response.data;
  // },
  // async updateStoreById(updateStore: any, id: number): Promise<ApiResponse<CreateStoreResponse>> {
  //   const formData = new FormData();
  //   formData.append('name', updateStore.name);
  //   formData.append('companyName', updateStore.companyName);
  //   formData.append('taxOffice', updateStore.taxOffice);
  //   formData.append('taxNo', updateStore.taxNo.toString());
  //   formData.append('provinceId', updateStore.provinceId.toString());
  //   formData.append('districtId', updateStore.districtId.toString());
  //   formData.append('gsm', updateStore.gsm.toString());
  //   formData.append('email', updateStore.email);
  //   formData.append('address', updateStore.address);
  //   formData.append('aboutUs', updateStore.aboutUs);
  //   formData.append('isIndividual', updateStore.isIndividual.toString());
  //   formData.append('isEnterprise', updateStore.isEnterprise.toString());
  //   formData.append('isFleet', updateStore.isFleet.toString());
  //   formData.append('file', updateStore.dealerLogo);
  //   if (updateStore.website) formData.append('website', updateStore.website.toString());
  //   const response = await ecmsClient.put(this.URL_UPDATE_DEALER_ID(id), formData);
  //   return response.data;
  // },
};

export default BlogApi;

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import React from 'react';
import { Api, FormTextField, GenericMessages } from 'core';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { showToast } from 'core/store';
import { Formik } from 'formik';

interface PartlyRefundProps {
  orderId: number;
  setDialog: (boolean) => void;
  dialog: boolean;
  setPartlyRefundDialog: (boolean) => void;
  partlyRefundDialog: boolean;
}

const useStyles = makeStyles((theme) => {
  return {
    media: {
      height: 300,
    },
    bottomArea: {
      backgroundColor: grey[100],
    },
    bottomInfoText: {
      color: theme.palette.secondary.main,
      fontWeight: 300,
      textAlign: 'center',
    },

    infoChip: {
      marginRight: theme.spacing(1),
    },
    actionChip: {
      width: '100%',
    },
  };
});

const PartlyRefund: React.FC<PartlyRefundProps> = ({ orderId, setDialog, dialog, setPartlyRefundDialog }) => {
  const dispatch = useDispatch();

  const formValidation = yup.object({
    amount: yup.number().required(GenericMessages.CANNOT_BE_EMPTY).typeError('Lütfen sayı giriniz'),
  });
  const onSubmit = async (data) => {
    const newAmount = data.amount.split('.').join('');
    try {
      const response = await Api.Order.orderRefund(orderId, 'partial', newAmount);
      if (response.status == 'success') {
        dispatch(
          showToast({
            message: `Başarıyla iade edildi`,
          }),
        );
        setDialog(false);
        setPartlyRefundDialog(false);
      } else {
        dispatch(
          showToast({
            message: `Hata oluştu`,
            severity: 'error',
          }),
        );
      }
    } catch (err) {
      dispatch(
        showToast({
          message: `Hata oluştu`,
          severity: 'error',
        }),
      );
    }
  };

  return (
    <Formik
      validationSchema={formValidation}
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={{
        amount: '',
      }}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, errors, values, handleChange }) => (
        <form noValidate onSubmit={handleSubmit} id="partlyRefund">
          <Grid container spacing={2}>
            <FormTextField
              size="medium"
              fullWidth
              multiline
              name="amount"
              placeholder="Miktar Giriniz"
              error={errors.amount != null}
              helperText={errors.amount}
              value={values.amount}
              onChange={handleChange}
            />
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default PartlyRefund;

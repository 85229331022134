import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import HideableLoadingSpinner from '../HideableLoadingSpinner/HideableLoadingSpinner';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      backgroundColor: fade(theme.palette.background.paper, 0.6),
      zIndex: 9999999,
      backdropFilter: 'blur(2px)',
    },
  };
});

const FullScreenLoadingSpinner = () => {
  const classes = useStyles();
  return (
    <Box
      width="100vw"
      height="100vh"
      justifyContent="center"
      alignItems="center"
      display="flex"
      position="fixed"
      className={classes.root}
    >
      <HideableLoadingSpinner show />
    </Box>
  );
};

export default FullScreenLoadingSpinner;

import { Integration } from 'apps/order/components';
import React from 'react';

const HotjarPage = () => {
  return (
    <React.Fragment>
      <Integration title="Hotjar" buttonName="Hotjar Ekle" />
    </React.Fragment>
  );
};

export default HotjarPage;

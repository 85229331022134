/* eslint-disable react/jsx-key */
// @ts-nocheck
import React from 'react';

import { Api } from 'core';
import { Card, Grid, CardContent } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { FormHeader, DetailFieldLabel, FormDialog, TranslatedMaterialTable } from 'core/components';
import { Manufacturer } from 'apps/crm';

const ProducerDetailPage = () => {
  const { id } = useParams();
  const [manufacturer, setManufacturer] = React.useState<Manufacturer>();

  const pullManufacturer = async () => {
    const response = await Api.Order.getManufacturer(id);
    if (response.data) setManufacturer(response.data);
  };

  React.useEffect(() => {
    pullManufacturer();
  }, []);

  return (
    <React.Fragment>
      <Card>
        {manufacturer && (
          <CardContent>
            <Grid container spacing={3}>
              <FormHeader title="Üretici Bilgileri" />
              <Grid item lg={2} md={3} xs={12}>
                <DetailFieldLabel title="İsim" label={manufacturer?.name} />
              </Grid>
              <Grid item lg={2} md={3} xs={12}>
                <DetailFieldLabel title="Şirket İsmi" label={manufacturer?.companyName} />
              </Grid>
              <Grid item lg={2} md={3} xs={12}>
                <DetailFieldLabel title="Menşe" label={manufacturer?.origin} />
              </Grid>
              <Grid item lg={2} md={3} xs={12}>
                <DetailFieldLabel title="Adres" label={manufacturer?.address} />
              </Grid>
              <Grid item lg={2} md={3} xs={12}>
                <DetailFieldLabel title="Tarihçe" label={manufacturer?.history} />
              </Grid>
            </Grid>
          </CardContent>
        )}
      </Card>
    </React.Fragment>
  );
};

export default ProducerDetailPage;

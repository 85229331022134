import { Category } from 'apps/crm';
import { ecmsClient } from 'core';
import { ApiResponse, FilterRequest, PageResponse } from 'core/models';
import {
  Assembly,
  AssemblyTheme,
  CreateOrUpdateAssemblyLocation,
  CreateOrUpdateAssemblyTheme,
  CreateOrUpdateProductSlider,
  CreateOrUpdateSection,
  DesignSection,
} from '../models';
import ProductSlider from '../models/ProductSlider';
import { CreateSectionPage } from '../pages';

const BASE_URL = '/design';
const DesignApi = {
  URL_CREATE_SECTION: `${BASE_URL}/section`,
  URL_UPDATE_SECTION: (id: number) => `${BASE_URL}/section/${id}`,
  URL_GET_SECTIONS: `${BASE_URL}/section`,

  URL_CREATE_MENU: `${BASE_URL}/menu`,

  URL_GET_SLIDERS: `${BASE_URL}/productSlider`,
  URL_CREATE_SLIDER: `${BASE_URL}/productSlider`,
  URL_UPDATE_SLIDER: (id: number) => `${BASE_URL}/productSlider/${id}`,

  URL_ASSEMBLY_LIST: `/assembly/`,
  URL_GET_ASSEMBLY: (id: number) => `/assembly/${id}`,
  URL_UPDATE_ASSEMBLY_LOCATION: (id: number) => `/assembly/${id}`,

  URL_ASSEMBLY_THEME: `/assembly/theme`,
  URL_ASSEMBLY_THEME_BY_ID: (id: number) => `/assembly/theme/${id}`,

  async createOrUpdateMenu(data) {
    const response = await ecmsClient.post(this.URL_CREATE_MENU, data);
    return response.data;
  },

  async createSectionPage(data: CreateOrUpdateSection) {
    const response = await ecmsClient.post(this.URL_CREATE_SECTION, data);
    return response.data;
  },

  async updateSectionPage(id: number, data: CreateOrUpdateSection) {
    const response = await ecmsClient.post(this.URL_UPDATE_SECTION(id), data);
    return response.data;
  },

  async getDesignSections(filterRequest?: FilterRequest): Promise<ApiResponse<PageResponse<DesignSection>>> {
    const response = await ecmsClient.get(this.URL_GET_SECTIONS, { params: { filterRequest } });
    return response.data;
  },

  async getDesignSection(id: number): Promise<ApiResponse<DesignSection>> {
    const response = await ecmsClient.get(this.URL_UPDATE_SECTION(id));
    return response.data;
  },
  async getProductSliders(filterRequest?: FilterRequest): Promise<ApiResponse<PageResponse<ProductSlider>>> {
    const response = await ecmsClient.get(this.URL_GET_SLIDERS, { params: filterRequest });
    return response.data;
  },

  async createProductSlider(createOrUpdateProductSlider: CreateOrUpdateProductSlider) {
    const response = await ecmsClient.post(this.URL_CREATE_SLIDER, createOrUpdateProductSlider);
    return response.data;
  },

  async updateProductSlider(id: number, createOrUpdateProductSlider: CreateOrUpdateProductSlider) {
    const response = await ecmsClient.post(this.URL_UPDATE_SLIDER(id), createOrUpdateProductSlider);
    return response.data;
  },

  async getProductSlider(id: number): Promise<ApiResponse<ProductSlider>> {
    const response = await ecmsClient.get(this.URL_UPDATE_SLIDER(id));
    return response.data;
  },

  async getAssembly(id: number): Promise<ApiResponse<Assembly>> {
    const response = await ecmsClient.get(this.URL_GET_ASSEMBLY(id));
    return response.data;
  },
  async getAssemblyList(filterRequest?: FilterRequest): Promise<ApiResponse<PageResponse<Assembly>>> {
    const response = await ecmsClient.get(this.URL_ASSEMBLY_LIST, { params: filterRequest });
    return response.data;
  },
  async createAssmblyLocation(data: Assembly) {
    const response = await ecmsClient.post(this.URL_ASSEMBLY_LIST, data);
    return response.data;
  },

  async updateAssmblyLocation(id: number, data: CreateOrUpdateAssemblyLocation) {
    const response = await ecmsClient.post(this.URL_UPDATE_ASSEMBLY_LOCATION(id), data);
    return response.data;
  },

  async getAssemblyTheme(id: number): Promise<ApiResponse<AssemblyTheme>> {
    const response = await ecmsClient.get(this.URL_ASSEMBLY_THEME_BY_ID(id));
    return response.data;
  },
  async getAssemblyThemeList(filterRequest?: FilterRequest): Promise<ApiResponse<PageResponse<AssemblyTheme>>> {
    const response = await ecmsClient.get(this.URL_ASSEMBLY_THEME, { params: filterRequest });
    return response.data;
  },
  async createAssmblyTheme(data: CreateOrUpdateAssemblyTheme) {
    const response = await ecmsClient.post(this.URL_ASSEMBLY_THEME, data);
    return response.data;
  },

  async updateAssmblyTheme(id: number, data: CreateOrUpdateAssemblyTheme) {
    const response = await ecmsClient.post(this.URL_ASSEMBLY_THEME_BY_ID(id), data);
    return response.data;
  },

  // async createStore(createStoreRequest: CreateStoreRequest): Promise<ApiResponse<CreateStoreResponse>> {
  //   const formData = new FormData();
  //   formData.append('showroom', createStoreRequest.showroom);
  //   formData.append('title', createStoreRequest.title);
  //   formData.append('name', createStoreRequest.dealerName);
  //   formData.append('companyName', createStoreRequest.dealerCompanyName);
  //   formData.append('taxOffice', createStoreRequest.dealerTaxOffice);
  //   formData.append('taxNo', createStoreRequest.dealerTaxNo.toString());
  //   formData.append('provinceId', createStoreRequest.dealerProvinceID.toString());
  //   formData.append('districtId', createStoreRequest.dealerDistrictID.toString());
  //   formData.append('gsm', createStoreRequest.dealerGsm.toString());
  //   formData.append('email', createStoreRequest.dealerEmail);
  //   formData.append('address', createStoreRequest.dealerAddress);
  //   formData.append('balance', createStoreRequest.dealerBalance.toString());
  //   formData.append('isActive', createStoreRequest.dealerIsActive.toString());
  //   formData.append('isIndividual', createStoreRequest.dealerIsIndividual.toString());
  //   formData.append('isEnterprise', createStoreRequest.dealerIsEnterprise.toString());
  //   formData.append('isFleet', createStoreRequest.dealerIsFleet.toString());
  //   if (createStoreRequest.dealerLogo) formData.append('dealerLogo', createStoreRequest.dealerLogo);
  //   if (createStoreRequest.dealerWebsite) formData.append('website', createStoreRequest.dealerWebsite.toString());
  //   const response = await ecmsClient.post(this.URL_CREATE_DEALER, formData);
  //   return response.data;
  // },
  // async updateStoreById(updateStore: any, id: number): Promise<ApiResponse<CreateStoreResponse>> {
  //   const formData = new FormData();
  //   formData.append('name', updateStore.name);
  //   formData.append('companyName', updateStore.companyName);
  //   formData.append('taxOffice', updateStore.taxOffice);
  //   formData.append('taxNo', updateStore.taxNo.toString());
  //   formData.append('provinceId', updateStore.provinceId.toString());
  //   formData.append('districtId', updateStore.districtId.toString());
  //   formData.append('gsm', updateStore.gsm.toString());
  //   formData.append('email', updateStore.email);
  //   formData.append('address', updateStore.address);
  //   formData.append('aboutUs', updateStore.aboutUs);
  //   formData.append('isIndividual', updateStore.isIndividual.toString());
  //   formData.append('isEnterprise', updateStore.isEnterprise.toString());
  //   formData.append('isFleet', updateStore.isFleet.toString());
  //   formData.append('file', updateStore.dealerLogo);
  //   if (updateStore.website) formData.append('website', updateStore.website.toString());
  //   const response = await ecmsClient.put(this.URL_UPDATE_DEALER_ID(id), formData);
  //   return response.data;
  // },
};

export default DesignApi;

import { Grid } from '@material-ui/core';
import React from 'react';
import UploadButton, { UploadButtonProps } from '../UploadButton/UploadButton';

export interface UploadButtonWithPreviewProps extends Omit<UploadButtonProps, 'onChange'> {
  src?: string;
  previewWidth?: number;
  previewHeight?: number;
  onPhotoChange: (file: any, data: any) => void;
  text: string;
}

const UploadButtonWithPreview: React.FC<UploadButtonWithPreviewProps> = ({
  src,
  previewWidth,
  previewHeight,
  onPhotoChange,
  text,
  ...uploadButtonProps
}) => {
  const imageRef = React.createRef<any>();

  const onPhotoSelected = (event) => {
    const { target } = event;

    if (target.files || target.files.length === 1) {
      const file = target.files[0];
      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const currentElem: any = imageRef.current;
          if (currentElem) {
            currentElem.src = e.target?.result;
            onPhotoChange(file, e.target?.result);
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const isSrcValid = React.useMemo(() => {
    return src && src != '';
  }, [src]);
  return (
    <React.Fragment>
      <Grid container justify="center">
        <img
          src={src}
          alt=""
          height={isSrcValid ? previewWidth : 0}
          width={isSrcValid ? previewHeight : 0}
          ref={imageRef}
        />
      </Grid>
      <UploadButton
        buttonProps={{
          type: 'button',
        }}
        onChange={onPhotoSelected}
        {...uploadButtonProps}
      >
        {text}
      </UploadButton>
    </React.Fragment>
  );
};

UploadButtonWithPreview.defaultProps = {
  previewHeight: 100,
  previewWidth: 100,
};

export default UploadButtonWithPreview;

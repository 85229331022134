/* eslint-disable import/no-anonymous-default-export */
import { ecmsClient } from 'core';
import { ApiResponse } from 'core/models';
import { ApiSessionResponse, LoginResponse, UserInfo } from '../models';
import axios from 'axios';

export default {
  URL_LOGIN: '/auth/login',
  URL_SESSION_TOKEN: '/api/v1/initSession/',
  URL_OAUTH_LOGIN: '/oauth/token',

  setBearerToken(token: string): void {
    ecmsClient.defaults.headers.Authorization = `Bearer ${token}`;
  },

  async login({ email, password }: { email: string; password: string }): Promise<ApiResponse<LoginResponse>> {
    const response = await ecmsClient.post(this.URL_LOGIN, {
      email,
      password,
    });

    return response.data;
  },

  async apiLogin(clientSecret: string, grantType: string, clientId: string) {
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}${this.URL_OAUTH_LOGIN}`, {
      client_secret: clientSecret,
      grant_type: grantType,
      client_id: clientId,
    });
    return response.data;
  },

  async createSessionToken(): Promise<ApiSessionResponse> {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}${this.URL_SESSION_TOKEN}`, {
      headers: {
        Authorization: ecmsClient.defaults.headers.Authorization,
      },
    });
    return response.data;
  },

  setSessionToken(token: string) {
    ecmsClient.defaults.headers.SessionToken = token;
  },
};

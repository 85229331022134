import {
  Chip,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import { ProductCreateOrUpdateContext } from 'apps/product/context';
import { CategoryAutoCompleteSelect, FormAutocomplete, FormTextField, StripedTable } from 'core/components';
import React from 'react';

interface CategoryStepProps {
  errors: any;
  values: any;
  setFieldValue: (field: string, value: any) => void;
  handleChange: (e: any) => void;
}

interface IMock {
  id: number;
  name: string;
}

interface CategoryAttribute {
  id: number;
  name: string;
  value: string;
  options: string[];
  category: IMock;
  isRequired: boolean;
  isMarketplaceSpec: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const CategoryStep: React.FC<CategoryStepProps> = ({ errors, values, setFieldValue, handleChange }) => {
  const classes = useStyles();
  const [categorySpecs, setCategorySpecs] = React.useState<CategoryAttribute[]>([]);
  const { pullShelfParts, clearShelfParts } = React.useContext(ProductCreateOrUpdateContext);

  const onCategoryChange = (categoryValues) => {
    setFieldValue('categories', categoryValues);
    if (categoryValues.length > 0) {
      if (values.warehouseId) {
        pullShelfParts(values.warehouseId, categoryValues[0].id);
      }
    } else {
      clearShelfParts();
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid item xs={12} md={12} lg={12}>
          <CategoryAutoCompleteSelect
            fullWidth
            onChange={(event, values) => onCategoryChange(values)}
            error={errors.categoryIds != null}
            helperText={errors.categoryIds}
            value={values.categories}
          />
        </Grid>
      </Grid>
      {/* <Grid item xs={12}>
        {categorySpecs.map((spec) => (
          <React.Fragment>
            <Typography>Özellikler: {spec.category.name}</Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Özellik Adı</TableCell>
                  <TableCell colSpan={3}>Değeri</TableCell>
                  <TableCell>Zorunlu mu?</TableCell>
                  <TableCell>Pazaryeri Özelliği mi?</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className={classes.tableRow}>
                  <TableCell>OEM</TableCell>
                  <TableCell colSpan={3}>
                    <FormTextField fullWidth />
                  </TableCell>
                  <TableCell>
                    {spec.isRequired ? <Chip label="Evet" color="primary" /> : <Chip label="Hayır" color="secondary" />}
                  </TableCell>
                  <TableCell>
                    {spec.isMarketplaceSpec ? (
                      <Chip label="Evet" color="primary" />
                    ) : (
                      <Chip label="Hayır" color="secondary" />
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </React.Fragment>
        ))}

        <StripedTable
          columns={[
            { field: 'name', name: 'Özellik Adı' },
            { name: 'Değeri', colspan: 3, render: (value) => <FormTextField fullWidth /> },
            {
              name: 'Zorunlu mu?',
              render: (value) =>
                value.isRequired ? <Chip label="Evet" color="primary" /> : <Chip label="Hayır" color="secondary" />,
            },
            {
              name: 'Pazaryeri Özelliği mi?',
              render: (value) =>
                value.isMarketplace ? <Chip label="Evet" color="primary" /> : <Chip label="Hayır" color="secondary" />,
            },
          ]}
          data={[
            {
              name: 'OEM',
              isRequired: true,
              isMarketplace: false,
            },
            {
              name: 'OEM',
              isRequired: true,
              isMarketplace: false,
            },
            {
              name: 'OEM',
              isRequired: true,
              isMarketplace: false,
            },
            {
              name: 'OEM',
              isRequired: true,
              isMarketplace: false,
            },
            {
              name: 'OEM',
              isRequired: true,
              isMarketplace: false,
            },
            {
              name: 'OEM',
              isRequired: true,
              isMarketplace: false,
            },
            {
              name: 'OEM',
              isRequired: true,
              isMarketplace: false,
            },
          ]}
        />
        </Grid> */}
    </Grid>
  );
};

export default CategoryStep;

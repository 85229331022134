import { Formik, Form } from 'formik';
import React from 'react';
import {
  TextField,
  Grid,
  CardContent,
  Card,
  CardActions,
  Box,
  Switch,
  FormControl,
  FormControlLabel,
} from '@material-ui/core';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { FormHeader, SuccessButton } from 'core/components';
import GenericMessages from 'core/GenericMessages';

interface IntegrationProps {
  title: string;
  buttonName: string;
}

const Integration: React.FC<IntegrationProps> = ({ title, buttonName }) => {
  const [showLoading, setShowLoading] = React.useState(false);
  const dispatch = useDispatch();
  const inputLabelProps = {
    shrink: true,
  };

  const schema = yup.object({
    isActive: yup.boolean(),
    description: yup.string(),
  });

  const onIntegrationSubmit = async (values, { resetForm }) => {
    setShowLoading(true);
    try {
      console.log('hh');
    } catch (e) {
      setShowLoading(false);
    }
  };

  return (
    <Formik
      onSubmit={onIntegrationSubmit}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        description: '',
        isActive: false,
      }}
    >
      {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
        <Form onSubmit={handleSubmit} id="form" noValidate>
          <Card>
            <CardContent>
              <FormHeader title={title} />
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormControlLabel
                      value="end"
                      control={
                        <Switch
                          color="primary"
                          checked={values.isActive}
                          onChange={(e) => setFieldValue('isActive', e.target.checked)}
                        />
                      }
                      label="Durumu Seçiniz (Aktif veya Pasif)"
                      labelPlacement="end"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    label="Açıklama"
                    variant="outlined"
                    multiline
                    rows={6}
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    placeholder="Açıklama"
                    name="description"
                    onChange={handleChange}
                    value={values.description}
                    error={errors.description != null}
                    helperText={errors.description}
                  />
                </Grid>
              </Grid>
            </CardContent>

            <CardActions>
              <Box alignItems="center" justifyContent="flex-end" display="flex" className="w-100">
                <SuccessButton form="form" type="submit" startIcon={<SaveOutlinedIcon />} showLoading={showLoading}>
                  {buttonName}
                </SuccessButton>
              </Box>
            </CardActions>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default Integration;

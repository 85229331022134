import { Formik, Form, FieldArray } from 'formik';
import React from 'react';
import { TextField, Grid, CardContent, Card, CardActions, Box, MenuItem, Typography, Button } from '@material-ui/core';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { FormHeader, FormSelect, FormTextField, SuccessButton } from 'core/components';
import GenericMessages from 'core/GenericMessages';
import { Add as AddIcon, Remove as RemoveIcon } from '@material-ui/icons';
import { v4 as uuidv4 } from 'uuid';
import { Api, showToast } from 'core';
import { Option } from 'apps/option/models';

interface CreateOrUpdateOptionFormProps {
  option?: Option;
}
type OptionProps = {
  option: string;
  key: string;
};

const CreateOrUpdateOptionForm: React.FC<CreateOrUpdateOptionFormProps> = ({ option }) => {
  const [showLoading, setShowLoading] = React.useState(false);
  const dispatch = useDispatch();
  const inputLabelProps = {
    shrink: true,
  };

  const createEmptyOptionInfo = (): OptionProps => {
    return {
      option: '',
      key: uuidv4(),
    };
  };

  const getErrorIndexError = (errors: any[] | undefined, index) => {
    return errors && errors.length > index ? errors[index] : {};
  };

  const schema = yup.object({
    optionName: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
    optionId: yup.number().required(GenericMessages.CANNOT_BE_EMPTY),
    addOption: yup.array().of(
      yup.object({
        option: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
      }),
    ),
  });

  const onOptionSubmit = async (data, { resetForm }) => {
    setShowLoading(true);

    try {
      const newOption = {
        name: data.optionName,
        // eslint-disable-next-line radix
        type: parseInt(data.optionId),
        languageId: 3,
        storeIds: [2],
        values: data.addOption.map((value) => value.option),
      };
      const response = option
        ? await Api.Option.updateOption(option.id, newOption)
        : await Api.Option.createOption(newOption);
      if (response) {
        dispatch(
          showToast({
            message: `başarıyla ${option ? 'güncellendi!' : 'oluşturuldu!'}`,
          }),
        );
      }
    } catch (e) {
      dispatch(
        showToast({
          message: `  hata  oluştu`,
          severity: 'error',
        }),
      );
    }
    setShowLoading(false);
  };

  const OptionComp = ({ values, handleChange, errors, arrayHelper, setFieldValue }) => {
    return (
      <Grid container spacing={2}>
        {values.addOption.map((option, index) => (
          <Grid item xs={12} key={`pi${option.key}`}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <FormTextField
                  placeholder="Seçenek"
                  fullWidth
                  value={option.option}
                  onChange={(e) => setFieldValue(`addOption.${index}.option`, e.target.value)}
                  name={`addOption.${index}.option`}
                  error={getErrorIndexError(errors.addOption as any[], index)?.option != null}
                  helperText={getErrorIndexError(errors.addOption as any[], index)?.option}
                />
              </Grid>
              <Grid item xs={1}>
                <Box display="flex" alignItems="center" height="100%">
                  <Button startIcon={<RemoveIcon />} onClick={() => arrayHelper.remove(index)} color="secondary">
                    Sil
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={1} />
            </Grid>
          </Grid>
        ))}
        <Grid item xs={12}>
          <Box display="flex" textAlign="right" alignItems="center" height="100%">
            <Button startIcon={<AddIcon />} onClick={() => arrayHelper.push(createEmptyOptionInfo())} color="primary">
              Seçenek Ekle
            </Button>
          </Box>
        </Grid>
      </Grid>
    );
  };

  return (
    <Formik
      onSubmit={onOptionSubmit}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        optionName: option ? option.name : '',
        optionId: option ? option.type : '',
        // eslint-disable-next-line no-return-assign
        addOption: option?.values
          ? option.values.map((item) => {
              return {
                option: item.value,
                key: uuidv4(),
              };
            })
          : [createEmptyOptionInfo],
      }}
    >
      {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
        <Form onSubmit={handleSubmit} id="form" noValidate>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <FormHeader title={option ? 'Seçenek Güncelleme' : 'Seçenek Bilgileri'} />
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    required
                    label="Seçenek Adı"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    placeholder="Seçenek Adı"
                    name="optionName"
                    onChange={handleChange}
                    value={values.optionName}
                    error={errors.optionName != null}
                    helperText={errors.optionName}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormSelect
                    onChange={handleChange}
                    name="optionId"
                    value={values.optionId}
                    fullWidth
                    placeholder="Seçenek Türü Seçiniz"
                  >
                    <MenuItem value="1">Select(Seçenek)</MenuItem>
                    <MenuItem value="2">Checkbox(Onay Kutusu)</MenuItem>
                    <MenuItem value="3">Radiobutton(Radio Butonu)</MenuItem>
                  </FormSelect>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                  <Typography variant="subtitle1">Değer Ekle</Typography>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <FieldArray
                    name="addOption"
                    render={(arrayHelper) => (
                      <OptionComp
                        values={values}
                        arrayHelper={arrayHelper}
                        errors={errors}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </CardContent>

            <CardActions>
              <Box alignItems="center" justifyContent="flex-end" display="flex" className="w-100">
                <SuccessButton form="form" type="submit" startIcon={<SaveOutlinedIcon />} showLoading={showLoading}>
                  Kaydet
                </SuccessButton>
              </Box>
            </CardActions>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default CreateOrUpdateOptionForm;

import React, { useEffect } from 'react';
import { Box, Card, CardActions, CardContent, Chip, Grid, MenuItem } from '@material-ui/core';
import * as yup from 'yup';
import { Api, GenericMessages } from 'core';

import {
  FormHeader,
  FormSelect,
  FormTextField,
  HideableLoadingSpinnerBox,
  ManufacturerAutoCompleteSelect,
  StoreAutoCompleteSelect,
  SuccessButton,
  SupplierAutoCompleteSelect,
  TranslatedMaterialTable,
} from 'core/components';
import { values } from 'lodash';
import { SourceTypeEnum } from 'apps/crm';
import { Formik } from 'formik';
import { Columns } from 'react-feather';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';

const ProductsBatchPage = () => {
  const [showLoading, setShowLoading] = React.useState(false);
  const [sourceTypeId, setSourceTypeId] = React.useState<number>(1);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSourceTypeId(event.target.value as number);
  };
  const pullBatchData = async () => {
    setShowLoading(false);
  };
  const schema = yup.object({
    sourceId: yup.number().required(GenericMessages.CANNOT_BE_EMPTY),
    value: yup.number().required(GenericMessages.CANNOT_BE_EMPTY),

    sourceType: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
    column: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
    operator: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
  });
  const onSubmit = async (values) => {
    // setSaveShowLoading(true);
    // try {
    //   const data = { ...values };
    //   delete data.assemblies;
    //   data.assemblyIds = values.assemblies.map((assembly) => assembly.id);
    //   if (id) {
    //     await Api.Design.updateAssmblyTheme(id, data);
    //   } else {
    //     await Api.Design.createAssmblyTheme(data);
    //   }
    //   dispatch(
    //     showToast({
    //       message: `Başarıyla ${assembly ? 'güncellendi!' : 'oluşturuldu!'}`,
    //     }),
    //   );
    //   history.push('/design/modules/assemblyTheme');
    // } catch (e) {
    //   dispatch(
    //     showToast({
    //       message: `Hata oluştu`,
    //       severity: 'error',
    //     }),
    //   );
    // }
    // setSaveShowLoading(false);
  };
  useEffect(() => {
    pullBatchData();
  }, [sourceTypeId]);

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <FormSelect
            name="sourceTypeId"
            onChange={handleChange}
            value={sourceTypeId}
            fullWidth
            placeholder="Seçenek Türü Seçiniz"
          >
            <MenuItem value="1">Tedarikçi Bazlı </MenuItem>
            <MenuItem value="2">Üretici Bazlı </MenuItem>
          </FormSelect>
        </Grid>
      </Grid>

      <Card>
        <Formik
          onSubmit={onSubmit}
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            sourceType: '',
            supplierId: '',
            manufacturerId: '',
            column: '',
            price: '',
            operator: '',
            valueOperator: '',
            value: '',
          }}
        >
          {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
            <form onSubmit={handleSubmit} id="form" noValidate>
              <CardContent>
                <Grid container spacing={3}>
                  <FormHeader title="Toplu Ürün İşlemleri" />
                  {sourceTypeId == SourceTypeEnum.SUPPLIER && (
                    <Grid item xs={7}>
                      <SupplierAutoCompleteSelect
                        style={{ marginTop: '8px' }}
                        fullWidth
                        value={values.supplierId}
                        onChange={(event, supp) => {
                          setFieldValue('supplierId', supp);
                          setFieldValue('manufacturerId', null);
                        }}
                      />
                    </Grid>
                  )}
                  {sourceTypeId == SourceTypeEnum.MANUFACTURER && (
                    <Grid item xs={7}>
                      <ManufacturerAutoCompleteSelect
                        style={{ marginTop: '8px' }}
                        fullWidth
                        value={values.manufacturerId}
                        onChange={(event, supp) => {
                          setFieldValue('manufacturerId', supp);
                          setFieldValue('supplierId', null);
                        }}
                      />
                    </Grid>
                  )}
                  {sourceTypeId == SourceTypeEnum.SUPPLIER && values.supplierId && (
                    <React.Fragment>
                      <Grid item xs={6}>
                        <FormSelect
                          name="column"
                          onChange={handleChange}
                          value={values.column}
                          fullWidth
                          label="Değiştirilmek İstenilen Alan"
                          placeholder="Değiştirilmek İstenen Alan"
                        >
                          <MenuItem value="price">Fiyat </MenuItem>
                          <MenuItem value="price">Stok </MenuItem>
                          <MenuItem value="price">Aktif </MenuItem>
                        </FormSelect>
                      </Grid>
                      <Grid item xs={6}>
                        <FormSelect
                          name="operator"
                          onChange={handleChange}
                          value={values.operator}
                          fullWidth
                          placeholder="Hangi İşlem Yapılsın?"
                        >
                          <MenuItem value="plus"> + </MenuItem>
                          <MenuItem value="minus"> - </MenuItem>
                          <MenuItem value="0"> 0 </MenuItem>
                        </FormSelect>
                      </Grid>
                      <Grid item xs={6}>
                        <FormSelect
                          name="valueOperator"
                          label="İşlem"
                          onChange={handleChange}
                          value={values.valueOperator}
                          fullWidth
                          placeholder="Hangi İşlem Yapılsın?"
                        >
                          <MenuItem value="percent"> % </MenuItem>
                          <MenuItem value="netPrice"> Net Fiyat </MenuItem>
                        </FormSelect>
                      </Grid>
                      <Grid item xs={6}>
                        <FormTextField
                          label="Fiyat"
                          placeholder="Fiyat"
                          type="number"
                          name="value"
                          onChange={handleChange}
                          value={values.value}
                        />
                      </Grid>
                    </React.Fragment>
                  )}
                  {sourceTypeId == SourceTypeEnum.MANUFACTURER && values.manufacturerId && (
                    <React.Fragment>
                      <Grid item xs={6}>
                        <FormSelect
                          name="column"
                          onChange={handleChange}
                          value={values.column}
                          fullWidth
                          label="Değiştirilmek İstenilen Alan"
                          placeholder="Değiştirilmek İstenen Alan"
                        >
                          <MenuItem value="price">Ürün Fiyatı </MenuItem>
                        </FormSelect>
                      </Grid>
                      <Grid item xs={6}>
                        <FormSelect
                          name="operator"
                          onChange={handleChange}
                          value={values.operator}
                          fullWidth
                          label="Operator"
                          placeholder="Hangi İşlem Yapılsın?"
                        >
                          <MenuItem value="plus"> + </MenuItem>
                          <MenuItem value="minus"> - </MenuItem>
                        </FormSelect>
                      </Grid>
                      <Grid item xs={6}>
                        <FormSelect
                          label="İşlem"
                          name="valueOperator"
                          onChange={handleChange}
                          value={values.valueOperator}
                          fullWidth
                          placeholder="Hangi Değerde İşlem Yapılsın?"
                        >
                          <MenuItem value="percent"> % </MenuItem>
                          <MenuItem value="netPrice"> Net Fiyat </MenuItem>
                        </FormSelect>
                      </Grid>
                      <Grid item xs={6}>
                        <FormTextField
                          label="Fiyat"
                          placeholder="Fiyat"
                          type="number"
                          name="value"
                          onChange={handleChange}
                          value={values.value}
                        />
                      </Grid>
                    </React.Fragment>
                  )}
                </Grid>
              </CardContent>{' '}
              <CardActions>
                <Box alignItems="center" justifyContent="flex-end" display="flex" className="w-100">
                  <SuccessButton form="form" type="submit" startIcon={<SaveOutlinedIcon />} showLoading={showLoading}>
                    Kaydet
                  </SuccessButton>
                </Box>
              </CardActions>
            </form>
          )}
        </Formik>
      </Card>

      {/*
        <Grid container>
          <Grid item xs={12}>
              <CardContent>
        
              </CardContent>
          </Grid>
        </Grid>
      </Grid> */}
    </React.Fragment>
  );
};

export default ProductsBatchPage;

import { TextField } from '@material-ui/core';
import { Category } from 'apps/crm/models';
import { CreateOrUpdateAssemblyLocation } from 'apps/design/models';
import { Api } from 'core';
import { FilterRequest } from 'core/models';
import { multiply } from 'lodash';
import React from 'react';
import { FormAutocomplete, FormAutocompleteProps } from '../FormAutocomplete';

export type AssemblyLocationAutoCompleteSelectProps = Omit<FormAutocompleteProps, 'options'>;

const AssemblyLocationAutoCompleteSelect: React.FC<AssemblyLocationAutoCompleteSelectProps> = ({
  onChange,
  ...autocompleteProps
}) => {
  const [storeList, setAssemblyLocationList] = React.useState<CreateOrUpdateAssemblyLocation[]>([]);
  const [inputValue, setInputValue] = React.useState<string>();
  const [isFirstLoad, setIsFirstLoad] = React.useState(true);
  const pullAssemblyLocationList = async (filterCategoryRequest?: FilterRequest) => {
    const response = await Api.Design.getAssemblyList(filterCategoryRequest);
    if (response.data) setAssemblyLocationList(response.data.data);
    setIsFirstLoad(false);
  };

  React.useEffect(() => {
    pullAssemblyLocationList();
  }, []);

  const onQueryChage = (event, newInputValue: string) => {
    setInputValue(newInputValue);
    if (newInputValue && newInputValue.length > 0) {
      pullAssemblyLocationList({
        queryName: newInputValue,
      });
    } else {
      pullAssemblyLocationList();
    }
  };

  return (
    <FormAutocomplete
      {...autocompleteProps}
      multiple={autocompleteProps.multiple}
      size="small"
      showLoading={isFirstLoad}
      options={storeList}
      optionLabelName="name"
      equValueName="id"
      onChange={onChange}
      filterSelectedOptions
      inputValue={inputValue}
      onInputChange={onQueryChage}
      placeholder="Montaj Yeri"
    />
  );
};
AssemblyLocationAutoCompleteSelect.defaultProps = {
  multiple: true,
};
export default AssemblyLocationAutoCompleteSelect;

import { TextField } from '@material-ui/core';
import { Category } from 'apps/crm/models';
import { Store } from 'apps/store';
import { Api } from 'core';
import { FilterRequest } from 'core/models';
import { multiply } from 'lodash';
import React from 'react';
import { FormAutocomplete, FormAutocompleteProps } from '../FormAutocomplete';

export type StoreAutoCompleteSelectProps = Omit<FormAutocompleteProps, 'options'>;

const StoreAutoCompleteSelect: React.FC<StoreAutoCompleteSelectProps> = ({ onChange, ...autocompleteProps }) => {
  const [storeList, setStoreList] = React.useState<Store[]>([]);
  const [inputValue, setInputValue] = React.useState<string>();
  const [isFirstLoad, setIsFirstLoad] = React.useState(true);
  const pullStoreList = async (filterCategoryRequest?: FilterRequest) => {
    const response = await Api.Store.getStores(filterCategoryRequest);
    if (response.data) setStoreList(response.data.data);
    setIsFirstLoad(false);
  };

  React.useEffect(() => {
    pullStoreList();
  }, []);

  const onQueryChage = (event, newInputValue: string) => {
    setInputValue(newInputValue);
    if (newInputValue && newInputValue.length > 0) {
      pullStoreList({
        queryName: newInputValue,
      });
    } else {
      pullStoreList();
    }
  };

  return (
    <FormAutocomplete
      {...autocompleteProps}
      multiple={autocompleteProps.multiple}
      size="small"
      showLoading={isFirstLoad}
      options={storeList}
      optionLabelName="name"
      equValueName="id"
      onChange={onChange}
      filterSelectedOptions
      inputValue={inputValue}
      onInputChange={onQueryChage}
      placeholder="Mağaza"
    />
  );
};
StoreAutoCompleteSelect.defaultProps = {
  multiple: true,
};
export default StoreAutoCompleteSelect;

import React, { useEffect } from 'react';
import { TableState } from 'core/models';
import { TranslatedMaterialTable } from 'core/components';
import { Api } from 'core';
import { useHistory } from 'react-router-dom';
import { Option } from 'apps/option/models';

const OptionListPage = () => {
  const [showLoading, setShowLoading] = React.useState(false);
  const history = useHistory();
  const [tableState] = React.useState<TableState<Option>>({
    columns: [{ title: 'İsim', field: 'name', cellStyle: { whiteSpace: 'nowrap' } }],
    actions: [
      {
        icon: 'edit',
        tooltip: 'Düzenle',
        onClick: (event, rowData) => {
          const option = rowData as Option;
          history.push(`/crm/option/update/${option.id}`);
        },
      },
    ],
  });
  return (
    <TranslatedMaterialTable
      isLoading={showLoading}
      actions={tableState.actions}
      columns={tableState.columns}
      data={(q) =>
        new Promise((resolve) => {
          setShowLoading(true);
          Api.Option.getOptions({
            page: q.page,
          })
            .then((response) => response.data)
            .then((data) => {
              if (data) {
                resolve({
                  page: q.page,
                  totalCount: data.totalCount,
                  data: data.data,
                });
              }
            });
          setShowLoading(false);
        })
      }
      title=""
    />
  );
};
export default OptionListPage;

/* eslint-disable react/button-has-type */
import { Card, CardHeader, Grid, CardContent, Box, Button, FormHelperText, InputAdornment } from '@material-ui/core';
import { Save as SaveIcon, Visibility as VisibilityIcon } from '@material-ui/icons';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as yup from 'yup';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FormTextField, SuccessButton } from 'core/components';

const CreateContractPage = () => {
  const [showLoading, setShowLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [localCKEditor, setLocalCKEditor] = React.useState<any>();
  const dispatch = useDispatch();

  const onSubmit = async (data) => {};

  const CreateContractSchema = yup.object({
    contractName: yup.string(),
    contractKeyName: yup.string(),
    contractDescription: yup.string(),
  });
  return (
    <React.Fragment>
      <Formik
        onSubmit={onSubmit}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={CreateContractSchema}
        initialValues={{
          contractName: '',
          contractKeyName: '',
          contractDescription: '',
        }}
      >
        {({ handleSubmit, handleChange, values, setFieldValue }) => (
          <form onSubmit={handleSubmit} noValidate id="form">
            <Card>
              <CardHeader
                title={
                  <React.Fragment>
                    Sözleşme İşlemleri
                    {/* <img src="/images/sahibinden.png" width={25} height={25} /> */}
                  </React.Fragment>
                }
              />
              <CardContent>
                <Grid container xs={12} spacing={5}>
                  <Grid item xs={12} md={6}>
                    <FormTextField
                      placeholder="Sözleşme Adı"
                      name="contractName"
                      value={values.contractName}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormTextField
                      placeholder="Anahtar Adı"
                      name="contractKeyName"
                      value={values.contractKeyName}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item xs={8}>
                    <CKEditor
                      placeholder="Sahibinden Açıklaması"
                      editor={ClassicEditor}
                      data={values.contractDescription}
                      onInit={(editor) => {
                        setLocalCKEditor(editor);
                      }}
                      name="contractDescription"
                      onChange={(event, editor) => {
                        const data = editor.getData();

                        setFieldValue('contractDescription', data);
                      }}
                    />
                    <FormHelperText>
                      Lütfen {'{'} {'}'} içlerine ellemeyiniz.
                    </FormHelperText>
                  </Grid>

                  <Grid item xs={4}>
                    <b>Kullanıcak Parametreler</b> <br />
                    <FormHelperText>Lütfen parametrelerin üzerine tıklayınız</FormHelperText>
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        localCKEditor.model.change((writer) => {
                          writer.insertText(
                            '{{$client.name}}',
                            localCKEditor.model.document.selection.getLastPosition(),
                          );
                        });
                      }}
                    >
                      Müşteri Adı
                    </Button>
                  </Grid>
                  <Grid xs={12}>
                    <Box display="flex" className="w-100" justifyContent="flex-end">
                      <SuccessButton startIcon={<SaveIcon />} showLoading={showLoading} form="form" type="submit">
                        Kaydet
                      </SuccessButton>
                    </Box>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default CreateContractPage;

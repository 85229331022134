import { Switch, Route } from 'react-router-dom';
import React from 'react';
import { WishlistReportPage } from '../pages';

interface ReportLayoutProps {
  match: any;
}

const ReportLayout: React.FC<ReportLayoutProps> = ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.path}/wishlistReport`} component={WishlistReportPage} />
    </Switch>
  );
};

export default ReportLayout;

import { Grid, MenuItem } from '@material-ui/core';
import { FormSelect, FormTextField, ManufacturerAutoCompleteSelect, SupplierAutoCompleteSelect } from 'core/components';
import React from 'react';

interface DataInfoStepProps {
  errors: any;
  values: any;
  setFieldValue: (field: string, value: any) => void;
  handleChange: (e: any) => void;
}

const DataInfoStep: React.FC<DataInfoStepProps> = ({ errors, values, setFieldValue, handleChange }) => {
  return (
    <Grid container spacing={2}>
      <Grid item md={6}>
        <ManufacturerAutoCompleteSelect
          fullWidth
          value={values.manufacturer}
          error={errors.manufacturer != null}
          helperText={errors.manufacturer}
          onChange={(event, value) => setFieldValue('manufacturer', value)}
        />
      </Grid>
      <Grid item md={6}>
        <FormTextField
          placeholder="En Az Alınacak Adet"
          name="minimumQuantity"
          type="number"
          onChange={handleChange}
          value={values.minimumQuantity}
          error={errors.minimumQuantity != null}
          helperText={errors.minimumQuantity}
        />
      </Grid>
      <Grid item md={6}>
        <FormSelect
          onChange={handleChange}
          name="canShip"
          value={values.canShip}
          fullWidth
          placeholder="Kargolanabilir mi?"
          label="Kargolanabilir mi?"
          error={errors.canShip != null}
          helperText={errors.canShip}
        >
          <MenuItem value="1">Evet</MenuItem>
          <MenuItem value="0">Hayır</MenuItem>
        </FormSelect>
      </Grid>
      <Grid item md={6}>
        <SupplierAutoCompleteSelect
          fullWidth
          value={values.supplier}
          error={errors.supplier != null}
          helperText={errors.supplier}
          onChange={(event, value) => setFieldValue('supplier', value)}
        />
      </Grid>
      <Grid item md={6}>
        <FormTextField
          placeholder="Fiyat"
          name="price"
          type="number"
          onChange={handleChange}
          value={values.price}
          error={errors.price != null}
          helperText={errors.price}
        />
      </Grid>
      <Grid item md={6}>
        <FormSelect
          onChange={handleChange}
          name="canAssemble"
          value={values.canAssemble}
          fullWidth
          placeholder="Montajlanabilir mi?"
          label="Montajlanabilir mi?"
          error={errors.canAssemble != null}
          helperText={errors.canAssemble}
        >
          <MenuItem value="1">Evet</MenuItem>
          <MenuItem value="0">Hayır</MenuItem>
        </FormSelect>
      </Grid>
      <Grid item md={6}>
        <FormTextField
          placeholder="Özel Fiyat"
          label="Özel Fiyat (varsa)"
          name="specialPrice"
          type="number"
          onChange={handleChange}
          value={values.specialPrice}
          error={errors.specialPrice != null}
          helperText={errors.specialPrice}
        />
      </Grid>
      <Grid item md={6}>
        <FormTextField
          placeholder="Garanti Süresi"
          label="Garanti Süresi (ay)"
          name="warrantyTime"
          type="number"
          onChange={handleChange}
          value={values.warrantyTime}
          error={errors.warrantyTime != null}
          helperText={errors.warrantyTime}
        />
      </Grid>
      <Grid item md={6}>
        <FormTextField
          placeholder="Alış Fiyatı"
          name="purchasePrice"
          type="number"
          onChange={handleChange}
          value={values.purchasePrice}
          error={errors.purchasePrice != null}
          helperText={errors.purchasePrice}
        />
      </Grid>
      <Grid item md={6}>
        <FormSelect
          onChange={handleChange}
          name="isOriginal"
          value={values.isOriginal}
          fullWidth
          placeholder="Orijinal mi?"
          label="Orijinal mi?"
          error={errors.isOriginal != null}
          helperText={errors.isOriginal}
        >
          <MenuItem value="1">Evet</MenuItem>
          <MenuItem value="0">Hayır</MenuItem>
        </FormSelect>
      </Grid>
      <Grid item md={6}>
        <FormTextField
          placeholder="Vergi Oranı"
          name="taxRate"
          type="number"
          onChange={handleChange}
          value={values.taxRate}
          error={errors.taxRate != null}
          helperText={errors.taxRate}
        />
      </Grid>
      <Grid item md={6}>
        <FormSelect
          onChange={handleChange}
          name="isAdvertisement"
          value={values.isAdvertisement}
          fullWidth
          placeholder="Çıkma Parça mı?"
          label="Çıkma Parça mı? (İlan)"
          error={errors.isAdvertisement != null}
          helperText={errors.isAdvertisement}
        >
          <MenuItem value="1">Evet</MenuItem>
          <MenuItem value="0">Hayır</MenuItem>
        </FormSelect>
      </Grid>
      <Grid item md={6}>
        <FormTextField
          placeholder="Kargo Desi Boyutu"
          name="shippingSize"
          type="number"
          onChange={handleChange}
          value={values.shippingSize}
          error={errors.shippingSize != null}
          helperText={errors.shippingSize}
        />
      </Grid>
      <Grid item md={6}>
        <FormTextField
          placeholder="Paket İçeriği"
          name="packageContents"
          onChange={handleChange}
          value={values.packageContents}
          error={errors.packageContents != null}
          helperText={errors.packageContents}
        />
      </Grid>
      <Grid item md={6}>
        <FormTextField
          placeholder="Kargo Hazırlanma Süresi"
          label="Kargo Hazırlanma Süresi (gün)"
          name="deliveryReadyTime"
          type="number"
          onChange={handleChange}
          value={values.deliveryReadyTime}
          error={errors.deliveryReadyTime != null}
          helperText={errors.deliveryReadyTime}
        />
      </Grid>
      <Grid item md={6}>
        <FormSelect
          onChange={handleChange}
          name="isDomestic"
          value={values.isDomestic}
          fullWidth
          placeholder="Yerli Üretim mi?"
          label="Yerli Üretim mi?"
          error={errors.isDomestic != null}
          helperText={errors.isDomestic}
        >
          <MenuItem value="1">Evet</MenuItem>
          <MenuItem value="0">Hayır</MenuItem>
        </FormSelect>
      </Grid>
      <Grid item md={6}>
        <FormSelect
          onChange={handleChange}
          name="isShippingFree"
          value={values.isShippingFree}
          fullWidth
          placeholder="Kargo Ücretiz mi?"
          label="Kargo Ücretsiz mi?"
          error={errors.isShippingFree != null}
          helperText={errors.isShippingFree}
        >
          <MenuItem value="1">Evet</MenuItem>
          <MenuItem value="2">Hayır</MenuItem>
        </FormSelect>
      </Grid>
      <Grid item md={6}>
        <FormTextField
          placeholder="Video Linki"
          name="videoLink"
          onChange={handleChange}
          value={values.videoLink}
          error={errors.videoLink != null}
          helperText={errors.videoLink}
        />
      </Grid>
    </Grid>
  );
};

export default DataInfoStep;

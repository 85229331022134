/* eslint-disable import/no-anonymous-default-export */
import { ecmsClient } from 'core';
import { ApiResponse, PageResponse, FilterRequest } from 'core/models';
import { format } from 'date-fns';
import { Campaign } from '../models';

const createCampaignCreateOrUpdateFormData = (campaignCreateOrUpdateData) => {
  const formData = new FormData();
  if (campaignCreateOrUpdateData.name) {
    formData.append('name', campaignCreateOrUpdateData.name);
  }
  if (campaignCreateOrUpdateData.description) {
    formData.append('description', campaignCreateOrUpdateData.description);
  }
  if (campaignCreateOrUpdateData.expirationDate) {
    console.log(format(campaignCreateOrUpdateData.expirationDate, 'MM/dd/yyyy'));
    formData.append('expirationDate', format(campaignCreateOrUpdateData.expirationDate, 'MM/dd/yyyy'));
  }
  if (campaignCreateOrUpdateData.isActive) {
    formData.append('isActive', campaignCreateOrUpdateData.isActive ? '1' : '0');
  }
  if (campaignCreateOrUpdateData.imageFile) {
    formData.append('imageFile', campaignCreateOrUpdateData.imageFile);
  }
  if (campaignCreateOrUpdateData.image) {
    formData.append('image', campaignCreateOrUpdateData.image);
  }
  if (campaignCreateOrUpdateData.languageId) {
    formData.append('languageId', campaignCreateOrUpdateData.languageId.toString());
  }
  if (campaignCreateOrUpdateData.image) {
    formData.append('storeId', campaignCreateOrUpdateData.storeId.toString());
  }
  if (campaignCreateOrUpdateData.products) {
    campaignCreateOrUpdateData.products.forEach((product) => {
      formData.append('productIds[]', product.id);
    });
  }
  return formData;
};
export default {
  URL_CAMPAIGNS: '/campaign/',
  URL_CREATE_CAMPAIGN: '/campaign/',
  URL_CAMPAIGN: (id: number) => `/campaign/${id}`,
  URL_UPDATE_CAMPAIGN: (id: number) => `/campaign/${id}`,

  async createCampaign(data?: any): Promise<ApiResponse<any>> {
    console.log(data);
    const formData = createCampaignCreateOrUpdateFormData(data);
    const response = await ecmsClient.post(this.URL_CREATE_CAMPAIGN, formData);
    return response.data;
  },

  async updateCampaign(id: number, data?: any): Promise<ApiResponse<any>> {
    const formData = createCampaignCreateOrUpdateFormData(data);
    const response = await ecmsClient.post(this.URL_UPDATE_CAMPAIGN(id), formData);
    return response.data;
  },

  async getCampaigns(filterRequest?: FilterRequest): Promise<ApiResponse<PageResponse<Campaign>>> {
    const response = await ecmsClient.get(this.URL_CAMPAIGNS, { params: { filterRequest } });
    return response.data;
  },
  async getCampaign(id: number): Promise<ApiResponse<Campaign>> {
    const response = await ecmsClient.get(this.URL_CAMPAIGN(id));
    return response.data;
  },
};

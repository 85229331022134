/* eslint-disable react/jsx-key */
// @ts-nocheck
import React from 'react';

import { Api } from 'core';
import { Card, Grid, CardContent } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { FormHeader, DetailFieldLabel, FormDialog, TranslatedMaterialTable } from 'core/components';
import { PaymentMethodDto } from 'apps/order/models';

const PaymentMethodDetailPage = () => {
  const { id } = useParams();
  const [paymentMethod, setPaymentMethod] = React.useState<PaymentMethodDto>();

  const pullPaymentMethod = async () => {
    const response = await Api.Order.getPaymentMethod(id);
    if (response.data) setPaymentMethod(response.data);
  };

  React.useEffect(() => {
    pullPaymentMethod();
  }, []);

  return (
    <React.Fragment>
      <Card>
        {paymentMethod && (
          <CardContent>
            <Grid container spacing={3}>
              <FormHeader title="Ödeme Methodu Bilgileri" />
              <Grid item lg={2} md={3} xs={12}>
                <DetailFieldLabel title="İsim" label={paymentMethod?.name} />
              </Grid>
              <Grid item lg={2} md={3} xs={12}>
                <DetailFieldLabel title="Aktiflik Durumu" label={paymentMethod?.isActive ? 'Aktif' : 'Pasif'} />
              </Grid>
              <Grid item lg={2} md={3} xs={12}>
                <DetailFieldLabel title="Maksimum Limit" label={paymentMethod?.maxLimit} />
              </Grid>
              <Grid item lg={2} md={3} xs={12}>
                <DetailFieldLabel title="Minimum Limit" label={paymentMethod?.minLimit} />
              </Grid>
              <Grid item lg={2} md={3} xs={12}>
                <DetailFieldLabel title="Açıklama" label={paymentMethod?.description} />
              </Grid>
            </Grid>
          </CardContent>
        )}
      </Card>
    </React.Fragment>
  );
};

export default PaymentMethodDetailPage;

import { Formik, Form } from 'formik';
import React from 'react';
import { Grid, CardContent, Card, CardActions, Box } from '@material-ui/core';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import * as yup from 'yup';
import { FormHeader, FormTextField, ProductAutoCompleteSelect, SuccessButton } from 'core/components';
import { Api, GenericMessages, showToast } from 'core';
import { CreateOrUpdateProductSlider } from 'apps/design/models';
import ProductSlider from 'apps/design/models/ProductSlider';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const CreateInformationPage = () => {
  const [showLoading, setShowLoading] = React.useState(false);
  const [productSlider, setProductSlider] = React.useState<ProductSlider>();
  const { id }: any = useParams();
  const dispatch = useDispatch();
  const schema = yup.object({
    name: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
    title: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
    products: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
  });

  const onCreateSliderSubmit = async (values, { resetForm }) => {
    setShowLoading(true);
    try {
      const data: CreateOrUpdateProductSlider = {
        name: values.name,
        title: values.title,
        productIds: values.products.map((product) => product.id),
        languageId: 3,
      };
      if (id) await Api.Design.updateProductSlider(id, data);
      else await Api.Design.createProductSlider(data);
      dispatch(
        showToast({
          message: `Başarıyla ${productSlider ? 'güncellendi!' : 'oluşturuldu!'}`,
        }),
      );
    } catch (e) {
      dispatch(
        showToast({
          message: `Hata oluştu'}`,
          severity: 'error',
        }),
      );
    }
    setShowLoading(false);
  };

  const pullProductSlider = async () => {
    const response = await Api.Design.getProductSlider(id);
    if (response.data) setProductSlider(response.data);
  };
  React.useEffect(() => {
    pullProductSlider();
  }, []);
  if (id && !productSlider) {
    return null;
  }

  return (
    <Formik
      onSubmit={onCreateSliderSubmit}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        name: productSlider?.name,
        title: productSlider?.title,
        products: productSlider?.products
          ? productSlider.products.map((product) => ({
              id: product.id,
              name: product.name,
            }))
          : [],
      }}
    >
      {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
        <Form onSubmit={handleSubmit} id="form" noValidate>
          <Card>
            <CardContent>
              <Grid direction="row" container spacing={5}>
                <FormHeader title="Slider Bilgileri" />
                <Grid item xs={12} md={6} lg={6}>
                  <FormTextField
                    required
                    label="Slider Başlığı"
                    fullWidth
                    placeholder="Slider Başlığı"
                    name="title"
                    onChange={handleChange}
                    value={values.title}
                    error={errors.title != null}
                    helperText={errors.title}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormTextField
                    required
                    label="Slider Adı"
                    fullWidth
                    placeholder="Slider Adı"
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    error={errors.name != null}
                    helperText={errors.name}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ProductAutoCompleteSelect
                    placeholder="Ürünler"
                    onChange={(event, products) => setFieldValue('products', products)}
                    error={errors.products != null}
                    helperText={errors.products as string}
                    value={values.products}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Box alignItems="center" justifyContent="flex-end" display="flex" className="w-100">
                <SuccessButton form="form" type="submit" startIcon={<SaveOutlinedIcon />} showLoading={showLoading}>
                  Slider Ekle
                </SuccessButton>
              </Box>
            </CardActions>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default CreateInformationPage;

import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { AuthState } from 'apps/auth/store';
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { AuthPage } from 'apps/auth/pages';
import { Snackbar, IconButton, Typography } from '@material-ui/core';
import createStore from 'store/store';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { hideToast, MainLayout } from './core';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  close: {
    padding: theme.spacing(0.5),
  },
}));

const Routes = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  const { showToast, toastProps } = useSelector(
    (state: any) => ({
      showToast: state.core.showToast,
      toastProps: state.core.toastProps,
    }),
    shallowEqual,
  );
  const handleToastClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    dispatch(hideToast());
  };
  const { isAuthorized } = useSelector(
    ({ auth }: { auth: AuthState }) => ({
      isAuthorized: auth.userInfo != null,
    }),
    shallowEqual,
  );
  return (
    <React.Fragment>
      {/* <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message={
          <IconButton
            aria-label="open"
            color="primary"
            onClick={() => window.open('https://ecms.tekbas.com', '_blank')}
          >
            <Typography variant="subtitle2">Hoşgeldiniz</Typography>
          </IconButton>
        }
        onExited={handleClose}
        action={
          <React.Fragment>
            <IconButton aria-label="close" color="secondary" className={classes.close} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      /> */}
      <Snackbar
        open={showToast}
        autoHideDuration={toastProps.autoHideDuration}
        onClose={handleToastClose}
        anchorOrigin={toastProps.anchorOrigin}
      >
        <Alert severity={toastProps.severity} onClose={handleToastClose}>
          {toastProps.message}
        </Alert>
      </Snackbar>
      <Switch>
        {!isAuthorized ? (
          /* Render auth page when user at `/auth` and not authorized. */
          <Route>
            <AuthPage />
          </Route>
        ) : (
          /* Otherwise redirect to root page (`/`) */
          <Redirect from="/auth" to="/" />
        )}

        {!isAuthorized ? (
          /* Redirect to `/auth` when user is not authorized */
          <Redirect to="/auth/login" />
        ) : (
          <MainLayout />
        )}
      </Switch>
    </React.Fragment>
  );
};

export default Routes;

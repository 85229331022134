import { Book, ListAlt, PostAdd, Report, Assessment } from '@material-ui/icons';
import { MenuBlockInfo } from 'core';
import React from 'react';
import { WishlistReportPage } from '../pages';

const reportSidebarMenuList: MenuBlockInfo = {
  id: 'reports',
  title: 'Raporlar',
  child: [
    /* {
      id: 'contract',
      icon: <Assessment />,
      label: 'Raporlar',
      child: [
        {
          id: 'reportClient',
          icon: <Report />,
          label: 'Müşteri Raporu',
          route: '/report/client',
        },
        {
          id: 'reportOrder',
          route: '/report/order',
          icon: <Report />,
          label: 'Sipariş Analiz Raporu',
        },
        {
          id: 'reportMarketplaces',
          route: '/report/marketplaces',
          icon: <Report />,
          label: 'Pazaryeri Raporu',
        },
        {
          id: 'productAnalysisReport',
          route: '/report/productAnalysis',
          icon: <Report />,
          label: 'Ürün Analiz Raporu',
        },
        {
          id: 'productOrderReport',
          route: '/report/productOrder',
          icon: <Report />,
          label: 'Ürün Sipariş Raporu',
        },
      ],
    }, */
    {
      id: 'wishlistMenu',
      icon: <Assessment />,
      label: 'İstek Listesi Raporu',
      route: '/report/wishlistReport',
    },
  ],
};

export default reportSidebarMenuList;

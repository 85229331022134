import { Formik, Form } from 'formik';
import React from 'react';
import { TextField, Grid, CardContent, Card, CardActions, Box } from '@material-ui/core';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import {
  FormHeader,
  FormTextField,
  HideableLoadingSpinnerBox,
  LabeledCheckbox,
  SaveButton,
  SuccessButton,
  TranslatedMaterialTable,
} from 'core/components';
import GenericMessages from 'core/GenericMessages';
import { Api, showToast } from 'core';
import { CreateOrUpdateAssemblyLocation } from 'apps/design/models';
import { TableState } from 'core/models';
import { useHistory, useParams } from 'react-router-dom';

const CreateAssemblyPage = () => {
  const [showLoading, setShowLoading] = React.useState(false);
  const [showSavingLoading, setShowSavingLoading] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { id }: any = useParams();
  const [assembly, setAssembly] = React.useState<CreateOrUpdateAssemblyLocation>();

  const pullAssembly = async () => {
    if (id) {
      setShowLoading(true);
      const response = await Api.Design.getAssembly(id);
      if (response.data) {
        setAssembly(response.data);
      }
      setShowLoading(false);
    }
  };
  React.useEffect(() => {
    pullAssembly();
  }, [id]);
  const schema = yup.object({
    address: yup.string().required('Adres Alanı Boş olamaz'),
    groupName: yup.string(),
    price: yup.number(),
    name: yup.string().required('Bu Boş Olamaz'),
    gsm: yup
      .string()
      .test('empty', GenericMessages.CANNOT_BE_EMPTY, (val: any) => val && val.toString().length > 0)
      .test('len', GenericMessages.PHONE_NO_LENGTH, (val: any) => val && val.toString().length === 10),
    authPerson: yup.string(),
    city: yup.string().required(GenericMessages.CANNOT_BE_EMPTY).typeError(GenericMessages.CANNOT_BE_EMPTY),
    province: yup.string().required(GenericMessages.CANNOT_BE_EMPTY).typeError(GenericMessages.CANNOT_BE_EMPTY),
  });

  const onAssemblyCreateSubmit = async (values, { resetForm }) => {
    setShowSavingLoading(true);
    try {
      if (id) await Api.Design.updateAssmblyLocation(id, values);
      else await Api.Design.createAssmblyLocation(values);
      dispatch(
        showToast({
          message: `Başarıyla ${assembly ? 'güncellendi!' : 'oluşturuldu!'}`,
        }),
      );
      history.push('/design/modules/assembly/');
    } catch (e) {
      dispatch(
        showToast({
          message: `Hata oluştu'}`,
          severity: 'error',
        }),
      );
    }
    setShowSavingLoading(false);
  };
  if (showLoading) {
    return <HideableLoadingSpinnerBox />;
  }
  return (
    <React.Fragment>
      <Card>
        <Formik
          onSubmit={onAssemblyCreateSubmit}
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            name: assembly?.name ? assembly.name : '',
            groupName: assembly?.groupName ? assembly.groupName : '',
            address: assembly?.address ? assembly.address : '',
            authPerson: assembly?.authPerson ? assembly.authPerson : '',
            city: assembly?.city ? assembly.city : '',
            price: assembly?.price ? assembly.price : '',
            province: assembly?.province ? assembly.province : '',
            isActive: assembly?.isActive ? assembly.isActive : false,
            gsm: assembly?.gsm ? assembly.gsm : '',
            gmapsLink: assembly?.gmapsLink ? assembly.gmapsLink : '',
          }}
        >
          {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
            <form onSubmit={handleSubmit} id="form" noValidate>
              <CardContent>
                <Grid direction="row" container spacing={5}>
                  <FormHeader title="Montaj Yeri Bilgileri" />
                  <Grid item xs={12} md={6} lg={6}>
                    <FormTextField
                      required
                      label="Adı"
                      fullWidth
                      placeholder="Adı"
                      name="name"
                      onChange={handleChange}
                      value={values.name}
                      error={errors.name != null}
                      helperText={errors.name}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormTextField
                      label="Yetkili Kişi"
                      placeholder="Yetkili Kişi"
                      name="authPerson"
                      fullWidth
                      onChange={handleChange}
                      value={values.authPerson}
                      error={errors.authPerson != null}
                      helperText={errors.authPerson}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormTextField
                      required
                      name="gsm"
                      label="Telefon numarası"
                      placeholder="Telefon numarası"
                      fullWidth
                      onChange={handleChange}
                      value={values.gsm}
                      error={errors.gsm != null}
                      helperText={errors.gsm}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormTextField
                      required
                      name="groupName"
                      label="Grup Adı"
                      placeholder="Grup Adı"
                      fullWidth
                      margin="dense"
                      onChange={handleChange}
                      value={values.groupName}
                      error={errors.groupName != null}
                      helperText={errors.groupName}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormTextField
                      required
                      name="city"
                      label="İl"
                      placeholder="Grup Adı"
                      fullWidth
                      margin="dense"
                      onChange={handleChange}
                      value={values.city}
                      error={errors.city != null}
                      helperText={errors.city}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormTextField
                      required
                      name="province"
                      label="İlçe"
                      placeholder="İlçe"
                      fullWidth
                      margin="dense"
                      onChange={handleChange}
                      value={values.province}
                      error={errors.province != null}
                      helperText={errors.province}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormTextField
                      required
                      name="price"
                      label="Fiyat"
                      placeholder="Fiyat"
                      fullWidth
                      margin="dense"
                      onChange={handleChange}
                      value={values.price}
                      error={errors.price != null}
                      helperText={errors.price}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <LabeledCheckbox
                      error={errors.isActive != null}
                      helperText={errors.isActive}
                      label="Aktif mi?"
                      checked={values?.isActive}
                      onChange={handleChange}
                      name="isActive"
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormTextField
                      error={errors.gmapsLink != null}
                      label="Harita Linki"
                      placeholder="Harita Linki(Google Maps)"
                      name="gmapsLink"
                      fullWidth
                      margin="dense"
                      onChange={handleChange}
                      value={values.gmapsLink}
                      helperText={errors.gmapsLink}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormTextField
                      multiline
                      rows={4}
                      name="address"
                      label="Adres"
                      placeholder="Adres"
                      fullWidth
                      margin="dense"
                      onChange={handleChange}
                      value={values.address}
                      error={errors.address != null}
                      helperText={errors.address}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </form>
          )}
        </Formik>
        <CardActions>
          <Box alignItems="center" justifyContent="flex-end" display="flex" className="w-100">
            <SaveButton form="form" type="submit" showLoading={showSavingLoading}>
              {id ? 'Montaj Yeri Güncelle' : 'Montaj Yeri Oluştur'}
            </SaveButton>
          </Box>
        </CardActions>
      </Card>
    </React.Fragment>
  );
};

export default CreateAssemblyPage;

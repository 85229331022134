import { DatePickerProps, KeyboardDatePicker } from '@material-ui/pickers';
import React from 'react';

export type TranslatedDatePickerProps = DatePickerProps;

const TranslatedDatePicker: React.FC<TranslatedDatePickerProps> = (props) => {
  return (
    <KeyboardDatePicker
      okLabel="Tamam"
      cancelLabel="İptal"
      margin="dense"
      inputVariant="outlined"
      {...props}
      invalidDateMessage="Lütfen geçerli bir tarih giriniz."
    />
  );
};

export default TranslatedDatePicker;

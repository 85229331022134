import { Product } from 'apps/product';
import { Api, TranslatedMaterialTable } from 'core';
import { TableState } from 'core/models';
import React from 'react';

const WishlistReportPage = () => {
  const [products, setProducts] = React.useState<Product[]>([]);
  const [showLoading, setShowLoading] = React.useState(true);

  const [tableState] = React.useState<TableState<Product>>({
    columns: [
      {
        title: 'Ürün Adı',
        field: 'name',
      },
      {
        title: 'Miktarı',
        field: 'productCount',
        type: 'numeric',
      },
    ],
  });

  React.useEffect(() => {
    Api.Report.getWishlistReport().then((response) => {
      if (response && response.data) {
        setProducts(response.data);
      }
      setShowLoading(false);
    });
  }, []);
  return (
    <TranslatedMaterialTable
      data={products}
      columns={tableState.columns}
      isLoading={showLoading}
      title="İstek Listesi Raporu"
    />
  );
};

export default WishlistReportPage;

/* eslint-disable radix */
import { Formik, Form } from 'formik';
import React from 'react';
import { TextField, Grid, CardContent, Card, CardActions, Box, MenuItem } from '@material-ui/core';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { FormAutocomplete, FormHeader, FormSelect, MPCategoryAutoCompleteSelect, SuccessButton } from 'core/components';
import GenericMessages from 'core/GenericMessages';
import { CategoryAutoCompleteSelect } from 'core/components/CategoryAutoCompleteSelect';
import { Api, showToast } from 'core';
import { Product } from 'apps/product';

interface CategorySyncProps {
  n11category?: boolean;
  formId: string;
  productsData?: any[];
  setShowCategorySyncDialog: (boolean) => void;
  setShowLoading: (boolean) => void;
  onComplete?: () => void;
}
// @ts-nocheck
const CategorySync: React.FC<CategorySyncProps> = ({
  formId,
  n11category,
  setShowCategorySyncDialog,
  setShowLoading,
  productsData,
  onComplete,
}) => {
  const [templates, setTemplates] = React.useState<any[]>([]);
  const dispatch = useDispatch();
  const inputLabelProps = {
    shrink: true,
  };

  const category = {
    id: '',
    name: '',
  };

  const schema = yup.object({});

  const onCategorySyncSubmit = async (values, { resetForm }) => {
    setShowLoading(true);

    try {
      if (productsData) {
        const subcats: any[] = [
          values.subCategory2,
          values.subCategory3,
          values.subCategory4,
          values.subCategory5,
          values.subCategory6,
        ];
        // burada altkategorilerden null ları cıkarıyoruz.(aşagıda degerlere null atamıstık) En son secilen altkategori bize geliyor
        const modifiedSubCats = subcats.filter((i) => i);
        const subcatNames: any[] = [
          values.targetCategory.name,
          values.subCategory.name,
          values.subCategory2?.name,
          values.subCategory3?.name,
          values.subCategory4?.name,
          values.subCategory5?.name,
          values.subCategory6?.name,
        ];
        // bosları silip kalan isimlere "->" ekliyoruz
        const modifiedSubcatNames = subcatNames.filter((i) => i).join('->');
        const data: any = {
          categoryName: modifiedSubcatNames,
          subcategoryId: modifiedSubCats[modifiedSubCats.length - 1].id,
          extraProfit: values.extraProfit,
          shipmentTemplate: values.templateId,
          categoryIds: productsData.map((pro) => {
            return pro.categoryId;
          }),
        };
        await Api.Order.N11MatchCategories(data);
        if (onComplete) onComplete();
        setShowCategorySyncDialog(false);
        dispatch(
          showToast({
            message: 'Kategori başarıyla eşleştirildi',
          }),
        );
      }
    } catch (e) {
      dispatch(
        showToast({
          message: 'Hata oluştu',
          severity: 'error',
        }),
      );
      setShowLoading(false);
    }

    setShowLoading(false);
  };

  const pullShipmentTemplates = async () => {
    try {
      const response = await Api.Order.getShipmentTemplates();
      const modifiedData: any[] = [];
      response.data.map((template) => modifiedData.push({ name: template }));
      setTemplates(modifiedData);
    } catch (e) {
      console.log(e);
    }
  };
  React.useEffect(() => {
    pullShipmentTemplates();
  }, []);

  return (
    <Formik
      onSubmit={onCategorySyncSubmit}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        targetCategory: {},
        subCategory: {},
        subCategory2: {},
        subCategory3: {},
        subCategory4: {},
        subCategory5: {},
        subCategory6: {},
        extraProfit: '',
        templateId: '',
      }}
    >
      {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
        <Form onSubmit={handleSubmit} id={formId} noValidate>
          <Card>
            <CardContent>
              <Grid direction="row" container spacing={5}>
                <Grid item xs={12} md={12} lg={12}>
                  <MPCategoryAutoCompleteSelect
                    multiple={false}
                    fullWidth
                    placeholder="Kategori Seçiniz"
                    parentId={undefined}
                    onChange={(option, value) => {
                      setFieldValue('targetCategory', value);
                      setFieldValue('subCategory', null);
                      setFieldValue('subCategory2', null);
                      setFieldValue('subCategory3', null);
                      setFieldValue('subCategory4', null);
                      setFieldValue('subCategory5', null);
                      setFieldValue('subCategory6', null);
                    }}
                    error={errors.targetCategory != null}
                    helperText={errors.targetCategory as string}
                  />
                </Grid>
                {
                  // @ts-ignore
                  values.targetCategory?.id && (
                    <Grid item xs={12} md={12} lg={12}>
                      <MPCategoryAutoCompleteSelect
                        multiple={false}
                        fullWidth
                        placeholder="AltKategori Seçiniz-1"
                        // @ts-ignore
                        parentId={parseInt(values?.targetCategory?.id)}
                        onChange={(option, value) => {
                          setFieldValue('subCategory', value);
                          setFieldValue('subCategory2', null);
                          setFieldValue('subCategory3', null);
                          setFieldValue('subCategory4', null);
                          setFieldValue('subCategory5', null);
                          setFieldValue('subCategory6', null);
                        }}
                        showLoading
                        error={errors.subCategory != null}
                        helperText={errors.subCategory as string}
                      />
                    </Grid>
                  )
                }

                {
                  // @ts-ignore
                  values.subCategory?.id && (
                    <Grid item xs={12} md={12} lg={12}>
                      <MPCategoryAutoCompleteSelect
                        multiple={false}
                        fullWidth
                        placeholder="AltKategori Seçiniz-2"
                        // @ts-ignore

                        parentId={parseInt(values?.subCategory?.id)}
                        onChange={(option, value) => {
                          setFieldValue('subCategory2', value);
                        }}
                        error={errors.subCategory2 != null}
                        helperText={errors.subCategory2 as string}
                      />
                    </Grid>
                  )
                }
                {
                  // @ts-ignore
                  values.subCategory2?.id && (
                    <Grid item xs={12} md={12} lg={12}>
                      <MPCategoryAutoCompleteSelect
                        multiple={false}
                        fullWidth
                        placeholder="AltKategori Seçiniz-3"
                        // @ts-ignore

                        parentId={parseInt(values?.subCategory2?.id)}
                        onChange={(option, value) => {
                          setFieldValue('subCategory3', value);
                        }}
                        error={errors.subCategory3 != null}
                        helperText={errors.subCategory3 as string}
                      />
                    </Grid>
                  )
                }
                {
                  // @ts-ignore
                  values.subCategory3?.id && (
                    <Grid item xs={12} md={12} lg={12}>
                      <MPCategoryAutoCompleteSelect
                        multiple={false}
                        fullWidth
                        placeholder="AltKategori Seçiniz-4"
                        // @ts-ignore

                        parentId={parseInt(values?.subCategory3?.id)}
                        onChange={(option, value) => {
                          setFieldValue('subCategory4', value);
                        }}
                        error={errors.subCategory4 != null}
                        helperText={errors.subCategory4 as string}
                      />
                    </Grid>
                  )
                }
                {
                  // @ts-ignore
                  values.subCategory4?.id && (
                    <Grid item xs={12} md={12} lg={12}>
                      <MPCategoryAutoCompleteSelect
                        multiple={false}
                        fullWidth
                        placeholder="AltKategori Seçiniz-5"
                        // @ts-ignore

                        parentId={parseInt(values?.subCategory4?.id)}
                        onChange={(option, value) => {
                          setFieldValue('subCategory5', value);
                        }}
                        error={errors.subCategory5 != null}
                        helperText={errors.subCategory5 as string}
                      />
                    </Grid>
                  )
                }
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    required
                    label="Ekstra Kar Majı"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    placeholder="Ekstra Kar Majı Giriniz"
                    name="extraProfit"
                    onChange={handleChange}
                    value={values.extraProfit}
                    error={errors.extraProfit != null}
                    helperText={errors.extraProfit}
                  />
                </Grid>
                {n11category && (
                  <Grid item xs={12} md={12} lg={12}>
                    <FormAutocomplete
                      placeholder="Teslimat Şablonu Seçiniz"
                      options={templates}
                      optionLabelName="name"
                      equValueName="id"
                      onChange={(event, templateId) => setFieldValue('templateId', templateId.name)}
                      error={errors.templateId != null}
                      helperText={errors.templateId}
                      fullWidth
                    />
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default CategorySync;

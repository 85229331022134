import { Formik } from 'formik';
import React from 'react';
import { Grid, CardContent, Card, CardActions, Box, FormHelperText } from '@material-ui/core';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import * as yup from 'yup';
import { FormHeader, FormTextField, SaveButton, SuccessButton } from 'core/components';
import CodeMirror from '@uiw/react-codemirror';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'codemirror/keymap/sublime';
// eslint-disable-next-line import/no-extraneous-dependencies
import 'codemirror/theme/monokai.css';
import { Api, GenericMessages, showToast } from 'core';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DesignSection } from 'apps/design/models';

const CreateSectionPage = () => {
  const [showLoading, setShowLoading] = React.useState(false);
  const dispatch = useDispatch();
  const { id }: any = useParams();
  const [designSection, setDesignSection] = React.useState<DesignSection>();
  const schema = yup.object({
    name: yup.string().required('Bu Boş Olamaz'),
  });

  const onSectionFormSubmit = async (values, { resetForm }) => {
    setShowLoading(true);
    const newSection = {
      name: values.name,
      content: values.content,
      languageId: 3,
    };
    try {
      // if (id) await Api.Design.updateSection(newSection);
      // else await Api.Design.createSectionPage(newSection);
      dispatch(
        showToast({
          message: id ? 'Başarıyla Güncellendi' : 'Başarıyla oluşturuldu',
        }),
      );
    } catch (e) {
      dispatch(
        showToast({
          message: `Hata oluştu`,
          severity: 'error',
        }),
      );
    } finally {
      setShowLoading(false);
    }
  };

  const pullSection = async () => {
    const response = await Api.Design.getDesignSection(id);
    if (response.data) setDesignSection(response.data);
  };
  React.useEffect(() => {
    pullSection();
  }, []);

  if (id && !designSection) {
    return null;
  }

  return (
    <Formik
      onSubmit={onSectionFormSubmit}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        name: designSection?.name,
        content: designSection?.content,
      }}
    >
      {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
        <form onSubmit={handleSubmit} id="form" noValidate>
          <Card>
            <CardContent>
              <Grid direction="row" container spacing={5}>
                <FormHeader title="Bölüm Bilgileri" />
                <Grid item xs={12} md={6} lg={6}>
                  <FormTextField
                    required
                    label="Bölüm Adı"
                    fullWidth
                    placeholder="Bölüm Adı"
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    error={errors.name != null}
                    helperText={errors.name}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <div
                    style={{
                      height: '400px',
                    }}
                  >
                    <CodeMirror
                      value={values.content}
                      options={{
                        theme: 'monokai',
                        keyMap: 'sublime',
                        mode: 'html',
                      }}
                      onChange={(data, value) => {
                        setFieldValue('content', data.getValue());
                      }}
                    />
                    <FormHelperText error={errors.content != null}>{errors.content}</FormHelperText>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Box alignItems="center" justifyContent="flex-end" display="flex" className="w-100">
                <SaveButton form="form" type="submit" startIcon={<SaveOutlinedIcon />} showLoading={showLoading}>
                  Menü Ekle
                </SaveButton>
              </Box>
            </CardActions>
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default CreateSectionPage;

import { PersonAdd, GroupAdd, AccountCircle, List, PostAdd } from '@material-ui/icons';
import React from 'react';
import { MenuBlockInfo } from 'core/types';
import { Codesandbox } from 'react-feather';

const warehouseSideBarMenuList: MenuBlockInfo = {
  id: 'barcodeMenus',
  title: 'Depo Yönetimi',
  child: [
    {
      id: 'warehouse',
      icon: <Codesandbox />,
      label: 'Depo Yönetimi',
      child: [
        {
          id: 'warehouseBarcode',
          route: '/warehouse/barcode/',
          icon: <PostAdd />,
          label: 'Barkod ile Stok Girişi',
        },

        {
          id: 'warehousePartList',
          route: '/warehouse/partList/',
          icon: <List />,
          label: 'Depo Bölüm Listesi',
        },
        {
          id: 'warehouseList',
          route: '/warehouse/list/',
          icon: <List />,
          label: 'Depo Listesi',
        },
      ],
    },
  ],
};

export default warehouseSideBarMenuList;

import React from 'react';
import { Typography, Box } from '@material-ui/core';

export interface DetailFieldLabelProps {
  title: string;
  label?: string | number;
  horizontal?: boolean;
  labelLink?: string;
}

const DetailFieldLabel: React.FC<DetailFieldLabelProps> = ({ title, label, horizontal, labelLink = null }) => {
  if (horizontal) {
    return (
      <Box display="flex" alignItems="center">
        <Typography variant="subtitle2" className="mr-2">
          {title} :
        </Typography>

        <Typography variant="body2">{labelLink ? <a href={labelLink}>{label}</a> : <span>{label}</span>}</Typography>
      </Box>
    );
  }
  return (
    <React.Fragment>
      <Typography variant="subtitle2" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body2">{labelLink ? <a href={labelLink}>{label}</a> : <span>{label}</span>}</Typography>
    </React.Fragment>
  );
};

export default DetailFieldLabel;

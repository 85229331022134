import { Formik, Form } from 'formik';
import React from 'react';
import { TextField, Grid, CardContent, Card, CardActions, Box, MenuItem } from '@material-ui/core';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { CargoCompanySelect, FormHeader, FormSelect, SuccessButton } from 'core/components';
import GenericMessages from 'core/GenericMessages';

const HepsiburadaSettings = () => {
  const [showLoading, setShowLoading] = React.useState(false);

  const dispatch = useDispatch();
  const inputLabelProps = {
    shrink: true,
  };

  const schema = yup.object({
    apiKey: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
    apiSecret: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
    merchantId: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
    cargoId: yup.number().required(GenericMessages.CANNOT_BE_EMPTY),
  });

  const onHepsiburadaSubmit = async (values, { resetForm }) => {
    setShowLoading(true);
    try {
      console.log('hh');
    } catch (e) {
      setShowLoading(false);
    }
  };

  return (
    <Formik
      onSubmit={onHepsiburadaSubmit}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        apiKey: '',
        apiSecret: '',
        merchantId: '',
        cargoCompany: '',
      }}
    >
      {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
        <Form onSubmit={handleSubmit} id="form" noValidate>
          <Card>
            <CardContent>
              <Grid direction="row" container spacing={5}>
                <FormHeader title="HepsiBurada Ayarları" />
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    required
                    label="Api Key"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    placeholder="Api Key"
                    name="apiKey"
                    onChange={handleChange}
                    value={values.apiKey}
                    error={errors.apiKey != null}
                    helperText={errors.apiKey}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    required
                    label="Api Secret"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    placeholder="Api Secret"
                    name="apiSecret"
                    onChange={handleChange}
                    value={values.apiSecret}
                    error={errors.apiSecret != null}
                    helperText={errors.apiSecret}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    required
                    label="Merchant Id"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    placeholder="Merchant Id"
                    name="merchantId"
                    onChange={handleChange}
                    value={values.merchantId}
                    error={errors.merchantId != null}
                    helperText={errors.merchantId}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <CargoCompanySelect
                    fullWidth
                    name="cargoCompany"
                    onChange={handleChange}
                    value={values.cargoCompany}
                    error={errors.cargoCompany != null}
                    helperText={errors.cargoCompany}
                  />
                </Grid>
              </Grid>
            </CardContent>

            <CardActions>
              <Box alignItems="center" justifyContent="flex-end" display="flex" className="w-100">
                <SuccessButton form="form" type="submit" startIcon={<SaveOutlinedIcon />} showLoading={showLoading}>
                  Ayarları Kaydet
                </SuccessButton>
              </Box>
            </CardActions>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default HepsiburadaSettings;

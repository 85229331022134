import { CreateSupplierForm } from 'apps/supplier';
import React from 'react';

const CreateSupplierPage = () => {
  return (
    <React.Fragment>
      <CreateSupplierForm />
    </React.Fragment>
  );
};

export default CreateSupplierPage;

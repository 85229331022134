import { Color } from '@material-ui/lab/Alert';
import { SnackbarOrigin } from '@material-ui/core';
// import { AppNotification, SubscriptionPayment } from 'core/models';
// import { AnnounceDto } from 'apps/announces';

export const SHOW_TOAST = 'core/showToast';
export const HIDE_TOAST = 'core/hidetoast';
export const REFRESH_GLOBAL_DEALER_BALANCE = 'core/refreshGlobalDealerBalance';
export const SHOW_BALANCE_PAYMENT_DIALOG = 'core/showBalancePaymentDialog';
export const TOGGLE_DRAWER = 'core/toggleDrawer';
export const SET_NOTIFICATIONS = 'core/setNotifications';
export const TOGGLE_MOBILE_DRAWER = 'core/toggleMobileDrawer';
export const ANNOUNCEMENTS = 'core/announcements';
export const SHOW_FULL_SCREEN_LOADING = 'core/showFullscreenLoading';

export interface ToastProps {
  autoHideDuration?: number;
  severity?: Color;
  message: string;
  anchorOrigin?: SnackbarOrigin;
}

export interface CoreState {
  toastProps: ToastProps;
  showToast: boolean;
  dealerBalance?: number;
  isBalancePaymentDialogOpen: boolean;
  isDrawerOpen: boolean;
  // notifications: AppNotification[];
  unreadCount: number;
  isMobileDrawerOpen: boolean;
  // announcements: AnnounceDto[];
  unreadAnnouncementCount: number;
  showFullscreenLoading: boolean;
}

/* export interface PaymentDialogProps {
  show: boolean;
  typeId: PaymentTypeEnum;
  amount?: number;
  showAmount?: boolean;
  rawData?: SubscriptionPayment | number[];
  title?: string;
  reloadOnFinish?: boolean;
  onPaymentComplete?: () => void;
  onPaymentFail?: () => void;
} */

enum PaymentTypeEnum {
  SUBSCRIPTION = 1,
  BALANCE = 2,
  MODULES = 3,
}

export const MaterialTableCurrencySettings = {
  locale: 'tr',
  currencyCode: 'try',
};

export default PaymentTypeEnum;

// @ts-nocheck
/* eslint-disable react/jsx-key */
import React from 'react';
import { Api } from 'core';
import {
  Card,
  Grid,
  CardContent,
  Box,
  Button,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  CardHeader,
  IconButton,
} from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import { FormHeader, DetailFieldLabel } from 'core/components';
import { Client, ClientNote } from 'apps/clients';
import format from 'date-fns/format';
import { ClientNoteList } from 'apps/crm/components';
import { makeStyles } from '@material-ui/core/styles';
import { Visibility, NotInterested } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  image: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const CRMClientDetailPage = () => {
  const { id } = useParams();
  const [client, setClient] = React.useState<Client>();
  const classes = useStyles();
  const history = useHistory();
  const pullClient = async () => {
    const response = await Api.Crm.getClient(id);
    if (response.data) setClient(response.data);
  };

  const clientNote: ClientNote = [
    {
      clientId: '1',

      note: 'Not 1',
      createdAt: new Date(),
    },
  ];

  React.useEffect(() => {
    pullClient();
  }, []);

  return (
    <React.Fragment>
      <Grid container justify="flex-end">
        <Grid item xs={2}>
          <Button startIcon={<NotInterested />} color="error" variant="contained">
            KARA LİSTEYE EKLE
          </Button>
        </Grid>
      </Grid>
      <br />
      <Card>
        {client && (
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={7}>
                <FormHeader title="Müşteri Bilgileri" />
                <Grid container spacing={4}>
                  <Grid item lg={4} md={3} xs={12}>
                    <DetailFieldLabel title="Ad ve Soyad" label={client?.name} />
                  </Grid>
                  <Grid item lg={4} md={3} xs={12}>
                    <DetailFieldLabel title="Durumu" label={client?.isActive ? 'Aktif' : 'Pasif'} />
                  </Grid>
                  <Grid item lg={4} md={3} xs={12}>
                    <DetailFieldLabel title="Email" label={client?.email} />
                  </Grid>
                  <Grid item lg={4} md={3} xs={12}>
                    <DetailFieldLabel title="Telefon Numarası" label={client?.gsm} />
                  </Grid>
                  <Grid item lg={4} md={3} xs={12}>
                    <DetailFieldLabel
                      title="Oluşturulma Tarihi"
                      label={format(Date.parse(client.createdAt), 'yyyy-LL-dd')}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <img src={client.store?.logo} width="30%" />
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <CardHeader title={`${client.name} Kişisel Notlar`} />
                      <CardContent>
                        <ClientNoteList notes={clientNote} id={id} />
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <FormHeader title="Sipariş Listesi" />
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Sipariş Detay</TableCell>
                        <TableCell align="right">Sipariş Kodu</TableCell>
                        <TableCell align="right">Tarih</TableCell>
                        <TableCell align="right">Tutar</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {client.orders.map((order) => (
                        <TableRow>
                          <TableCell>
                            <Button
                              endIcon={<Visibility />}
                              onClick={() => history.push(`/orders/${order.id}`)}
                              color="primary"
                            />
                          </TableCell>
                          <TableCell align="right">{order?.sourceCode}</TableCell>
                          <TableCell align="right">{order?.createdAt}</TableCell>
                          <TableCell align="right">{order?.total}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </CardContent>
        )}
      </Card>
    </React.Fragment>
  );
};

export default CRMClientDetailPage;

import { CardHeader, Card, CardContent, CardActions, Box } from '@material-ui/core';
import { SuccessButton } from 'core';
import React from 'react';
import { Save as SaveIcon } from '@material-ui/icons';
import ClientContact from 'apps/crm/components/ClientContact/ClientContact';

const ClientCommunicationPage = () => {
  const [showLoading, setShowLoading] = React.useState(false);

  const [buttonShowLoading, setButtonShowLoading] = React.useState(false);
  return (
    <React.Fragment>
      <Card>
        <CardHeader title="Müşteri İletişim" />
        <CardContent>
          <ClientContact formId="clientContact" setButtonShowLoading={setButtonShowLoading} />
        </CardContent>
        <CardActions>
          <Box display="flex" className="w-100" justifyContent="flex-end">
            <SuccessButton startIcon={<SaveIcon />} showLoading={buttonShowLoading} form="clientContact" type="submit">
              Gönder
            </SuccessButton>
          </Box>
        </CardActions>
      </Card>
    </React.Fragment>
  );
};

export default ClientCommunicationPage;

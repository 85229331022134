import React from 'react';
import ProductCreateOrUpdateContextData from '../models/ProductCreateOrUpdateContextData';

const ProductCreateOrUpdateContext = React.createContext<ProductCreateOrUpdateContextData>({
  warehouses: [],
  setWarehouses: () => {},
  warehouseShelfParts: [],
  setWarehouseSelfParts: () => {},
  setHasPulledWarehouseShelfParts: (hasPulledWarehouseShelfParts: boolean) => {},
  hasPulledWarehouseShelfParts: false,
  pullShelfParts: (warehouseId: number, categoryId: number) => {},
  clearShelfParts: () => {},
});

export default ProductCreateOrUpdateContext;

import { Chip, Grid } from '@material-ui/core';
import { Product } from 'apps/product/models';
import { FormAutocomplete, FormTextField, ProductAutoCompleteSelect, StripedTable } from 'core/components';
import { FieldArray } from 'formik';
import React from 'react';

interface AdditionalProductStepProps {
  errors: any;
  values: any;
  setFieldValue: (field: string, value: any) => void;
  handleChange: (e: any) => void;
}

const AdditionalProductStep: React.FC<AdditionalProductStepProps> = ({
  errors,
  values,
  setFieldValue,
  handleChange,
}) => {
  const [selectedProducts, setSelectedProducts] = React.useState<Product[]>([]);

  const handleProductsAdded = async (products) => {
    setFieldValue('additionalProducts', products);
    setSelectedProducts(products);
  };

  const selectedProductsToData = (selectedProducts: Product[]) => {
    if (!selectedProducts || selectedProducts.length === 0) {
      return [];
    }

    return selectedProducts.map((product) => ({
      name: product.name,
      price: product.formattedPrice,
      tableKey: `spt${product.id}`,
    }));
  };

  const removeItem = (arrayHelpers, index) => {
    const newSelectedProducts = [...selectedProducts];
    newSelectedProducts.splice(index);
    setSelectedProducts(newSelectedProducts);
    arrayHelpers.remove(index);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ProductAutoCompleteSelect
          placeholder="Ürün ismi giriniz"
          fullWidth
          onChange={(event, value) => handleProductsAdded(value)}
          value={values.additionalProducts}
          optionLabelName="name"
          equValueName="id"
        />
      </Grid>
      <Grid item xs={12}>
        <FieldArray
          name="additionalProducts"
          render={(arrayHelpers) => (
            <StripedTable
              title="Ürünler"
              columns={[
                { field: 'name', name: 'Kombin Ürünün Adı' },
                { field: 'price', name: 'Orijinal Fiyatı' },
                {
                  name: 'Kombin Fiyatı?',
                  render: (value, index) => (
                    <FormTextField
                      name={`additionalProducts.${index}.productCombinePrice`}
                      onChange={handleChange}
                      type="number"
                      error={
                        errors.additionalProducts &&
                        errors.additionalProducts.length > 0 &&
                        errors.additionalProducts[index].productCombinePrice != null
                      }
                      helperText={
                        errors.additionalProducts &&
                        errors.additionalProducts.length > 0 &&
                        errors.additionalProducts[index].productCombinePrice
                      }
                      value={values.additionalProducts[index].productCombinePrice}
                    />
                  ),
                },
                {
                  name: 'İşlem',
                  render: (value, index) => (
                    <Chip label="Sil" color="secondary" onClick={() => removeItem(arrayHelpers, index)} />
                  ),
                },
              ]}
              data={selectedProductsToData(values.additionalProducts)}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default AdditionalProductStep;

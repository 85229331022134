import {
  DialogProps,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
} from '@material-ui/core';
import React from 'react';
import { Save as SaveIcon } from '@material-ui/icons';
import { SuccessButton } from '..';

export interface ConfirmDialogProps extends DialogProps {
  open: boolean;
  children: any;
  handleClose: any;
  formId: string;
  title: string;
  description?: string;
  showLoading?: boolean;
  hideSave?: boolean;
  onSubmit?: (data: any) => void;
  disabled?: boolean;
}

const FormDialog: React.FC<ConfirmDialogProps> = ({
  open,
  children,
  handleClose,
  formId,
  title,
  description,
  showLoading,
  hideSave,
  disabled,
  ...props
}) => {
  return (
    <Dialog aria-labelledby="form-dialog-title" open={open} {...props}>
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText />
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Kapat
        </Button>
        {!hideSave && (
          <SuccessButton
            form={formId}
            startIcon={<SaveIcon />}
            type="submit"
            showLoading={showLoading}
            disabled={disabled}
          >
            Kaydet
          </SuccessButton>
        )}
      </DialogActions>
    </Dialog>
  );
};
export default FormDialog;

import { createSlice } from '@reduxjs/toolkit';
import { showToast, hideToast, toggleDrawer, toggleMobileDrawer, showFullscreenLoading } from './actions';
import PaymentTypeEnum, { CoreState, ToastProps } from './types';

const defaultToastProps: ToastProps = {
  autoHideDuration: 3000,
  message: '',
};

const initialCoreState: CoreState = {
  toastProps: defaultToastProps,
  showToast: false,
  isBalancePaymentDialogOpen: false,
  isDrawerOpen: true,
  unreadCount: 0,
  unreadAnnouncementCount: 0,
  isMobileDrawerOpen: false,
  showFullscreenLoading: false,
};

export const coreSlice = createSlice({
  name: 'core',
  initialState: initialCoreState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(showToast, (state, action) => {
      state.toastProps = { ...defaultToastProps, ...action.payload };
      state.showToast = true;
    });

    builder.addCase(hideToast, (state) => {
      state.showToast = false;
    });

    builder.addCase(toggleDrawer, (state, action) => {
      state.isDrawerOpen = action.payload;
    });

    builder.addCase(toggleMobileDrawer, (state, action) => {
      state.isMobileDrawerOpen = action.payload;
    });
    builder.addCase(showFullscreenLoading, (state, action) => {
      state.showFullscreenLoading = action.payload;
    });
  },
});

export default coreSlice.reducer;

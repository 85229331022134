import React, { useEffect } from 'react';
import { TableState } from 'core/models';
import { TranslatedMaterialTable } from 'core/components';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import ContactlessIcon from '@material-ui/icons/Contactless';
import { ContactlessOutlined, Visibility as VisibilityIcon } from '@material-ui/icons';
import { Grid, Button } from '@material-ui/core';
import { ClientFastContactDialog } from 'apps/crm/components/ClientFastContactDialog';
import { Client } from 'apps/crm';
import { Api } from 'core';

const useStyle = makeStyles(() => {
  return {
    linkButton: {
      background: 'linear-gradient(45deg, #9088d4 30%, #a37eba 92%)',
      border: 5,
      borderRadius: 5,
      color: 'white',
      height: 30,
      width: 220,
    },
  };
});
const CRMClientListPage = () => {
  const [showLoading, setShowLoading] = React.useState(false);
  const history = useHistory();
  const [showFastContact, setShowFastContact] = React.useState(false);
  const [focusedClient, setFocusedClient] = React.useState<Client>();

  const classes = useStyle();
  const onCloseFastContact = () => {
    setShowFastContact(false);
  };
  const [tableState] = React.useState<TableState<Client>>({
    columns: [
      { title: 'İsim', field: 'name', cellStyle: { whiteSpace: 'nowrap' } },

      { title: 'Email', field: 'email', cellStyle: { whiteSpace: 'nowrap' } },

      { title: 'Telefon Numarası', field: 'gsm', cellStyle: { whiteSpace: 'nowrap' } },
      { title: 'Yaş', field: 'age', cellStyle: { whiteSpace: 'nowrap' } },
    ],
    actions: [
      {
        tooltip: 'Detaylar',
        icon: (props) => <VisibilityIcon {...props} />,
        onClick: (event, rowData) => {
          const client = rowData as Client;
          history.push(`/crm/client/detail/${client.id}`);
        },
      },
      (rowData) => ({
        icon: 'delete',
        onClick: () => {},
      }),
      (rowData) => ({
        icon: 'edit',
        tooltip: 'Düzenle',
        onClick: (event, rowData) => {
          const req = rowData as Client;
          history.push(`/crm/client/update/${req.id}`);
        },
      }),
      {
        icon: ContactlessIcon,
        tooltip: 'Hızlı İletişim',
        onClick: (event, rowData) => {
          const client = rowData as Client;
          setShowFastContact(true);
          setFocusedClient(client);
        },
      },
    ],
  });
  return (
    <React.Fragment>
      <Grid container spacing={4}>
        <Grid item>
          <Button
            startIcon={<ContactlessOutlined />}
            className={classes.linkButton}
            variant="contained"
            color="secondary"
            onClick={() => {
              history.push(`/crm/client/contact/`);
            }}
          >
            MÜŞTERİ İLETİŞİM
          </Button>{' '}
        </Grid>
      </Grid>
      <br />
      <ClientFastContactDialog
        clientName={focusedClient?.name}
        clientId={focusedClient?.id}
        formId="clientContact"
        showFastContact={showFastContact}
        onFastContactClose={onCloseFastContact}
      />
      <TranslatedMaterialTable
        isLoading={showLoading}
        actions={tableState.actions}
        columns={tableState.columns}
        data={(q) =>
          new Promise((resolve) => {
            setShowLoading(true);
            Api.Crm.getClients({
              page: q.page,
            })
              .then((response) => response.data)
              .then((data) => {
                if (data) {
                  resolve({
                    page: q.page,
                    totalCount: data.totalCount,
                    data: data.data,
                  });
                }
              });
            setShowLoading(false);
          })
        }
        title="Müşteri Listesi"
      />
    </React.Fragment>
  );
};
export default CRMClientListPage;

import { Formik, Form, FieldArray } from 'formik';
import React from 'react';
import { TextField, Grid, CardContent, Card, CardActions, Box, Button } from '@material-ui/core';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import {
  CategoryAutoCompleteSelect,
  DesignSectionAutoCompleteSelect,
  FormHeader,
  SuccessButton,
} from 'core/components';
import { v4 as uuidv4 } from 'uuid';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Add as AddIcon, Remove as RemoveIcon } from '@material-ui/icons';
import { TreeItem, TreeView } from '@material-ui/lab';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Api } from 'core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 500,
      '& > * + *': {
        marginTop: theme.spacing(3),
      },
    },
  }),
);
const RenderTree = ({ nodes, index, parent, onClick }: { nodes: any; index: number; parent: any; onClick: any }) => {
  return (
    <TreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name} onClick={() => onClick(nodes, index, parent)}>
      {Array.isArray(nodes.children)
        ? nodes.children.map((node, index) => (
            <RenderTree nodes={node} index={index} parent={nodes} onClick={onClick} />
          ))
        : null}
    </TreeItem>
  );
};

const CategoryComp = ({ index, deleteBlock, onChange }) => {
  const [data, setData] = React.useState<any>({ id: 0, name: 'Kök', children: [], type: 'category', block: index });
  const [category, setCategory] = React.useState<any>();
  const [focusedNode, setFocusedNode] = React.useState<any>();
  const [designSection, setDesignSection] = React.useState<any>();
  const classes = useStyles();
  const onNodeClicked = (node, index, parent) => {
    setFocusedNode({
      node,
      index,
      parent,
    });
  };

  const onAdd = (node, category) => {
    if (category) {
      const incomingData = {
        id: category.id,
        name: category.name,
      };

      let newData;
      if (node) {
        if (node.children) {
          node.children.push(incomingData);
        } else {
          node.children = [incomingData];
        }
        newData = { ...data };
        newData.type = 'category';
      } else {
        newData = { ...data };
        newData.type = 'category';
        newData.children.push(incomingData);
      }
      newData.block = index;
      setData(newData);
      onChange(data, index);
    } else {
      return null;
    }
    return null;
  };
  const onDelete = (parent, index) => {
    if (parent) {
      parent.children.splice(index, 1);
      setData({
        ...data,
      });
      onChange(data, index);
    }
  };
  return (
    <Grid container spacing={4}>
      <Grid item xs={5}>
        <CategoryAutoCompleteSelect
          placeholder="Kategori Seçiniz"
          optionLabelName="name"
          equValueName="id"
          onChange={(event, category) => setCategory(category)}
          multiple={false}
          fullWidth
          disabled={designSection}
        />
        <DesignSectionAutoCompleteSelect
          optionLabelName="name"
          equValueName="id"
          onChange={(event, designSections) => {
            setDesignSection(designSections);
            const newData = {
              type: 'cms',
              ids: designSections.map((designSection) => designSection.id),
              block: index,
            };
            setData(newData);
            onChange(newData);
          }}
          multiple
          fullWidth
          disabled={category}
        />
      </Grid>
      {category && (
        <React.Fragment>
          <Grid item xs={2}>
            <Button onClick={() => onAdd(focusedNode ? focusedNode.node : null, category)}>
              Kategoriyi Bloğa Ekle
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Button onClick={() => onDelete(focusedNode.parent, focusedNode.index)}>Kategoriyi Bloktan Sil</Button>
          </Grid>
        </React.Fragment>
      )}
      <Grid item xs={3}>
        <Button onClick={() => deleteBlock(index)}>SİL</Button>
      </Grid>
      <Grid item xs={12}>
        <TreeView
          className={classes.root}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpanded={['root']}
          defaultExpandIcon={<ChevronRightIcon />}
        >
          <RenderTree nodes={data} index={0} parent={null} onClick={onNodeClicked} />
        </TreeView>
      </Grid>
    </Grid>
  );
};

const CreateMenuPage = () => {
  const [showLoading, setShowLoading] = React.useState(false);
  const [blocks, setBlocks] = React.useState<any[]>([]);
  const [totalData, setTotalData] = React.useState<any>({});
  const inputLabelProps = {
    shrink: true,
  };

  const onChange = (data, index) => {
    const newTotalData = {
      ...totalData,
    };
    newTotalData[index] = data;
    setTotalData(newTotalData);
  };

  const onSubmit = async (data) => {
    setShowLoading(true);
    const newData = { ...data, children: Object.values(totalData) };
    newData.languageId = 3;
    await Api.Design.createOrUpdateMenu(newData);
    setShowLoading(false);
  };

  const deleteBlock = (index) => {
    const newBlocks = [...blocks];
    newBlocks.splice(index, 1);
    setBlocks(newBlocks);
  };

  const addBlock = (index) => {
    const newBlocks = [...blocks];
    newBlocks.push(`bi${uuidv4()}`);
    setBlocks(newBlocks);
  };

  const schema = yup.object({});

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        name: '',
        blocks: [],
        order: '',
      }}
    >
      {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
        <Form onSubmit={handleSubmit} id="form" noValidate>
          <Card>
            <CardContent>
              <Grid direction="row" container spacing={5}>
                <FormHeader title="Menü Bilgileri" />
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    required
                    label="Menü Adı"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    placeholder="Menü Adı"
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    error={errors.name != null}
                    helperText={errors.name}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                  <TextField
                    required
                    label="Sıralama"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    placeholder="Sıralama"
                    name="order"
                    onChange={handleChange}
                    value={values.order}
                    error={errors.order != null}
                    helperText={errors.order}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button onClick={() => addBlock(blocks.length)}> EKLE </Button>
                </Grid>

                <Grid item xs={12}>
                  {blocks.map((value, index) => (
                    <React.Fragment key={`cc${value}`}>
                      <Box mb={3} pt>
                        <CategoryComp index={index} deleteBlock={deleteBlock} onChange={onChange} />
                      </Box>
                      <hr />
                    </React.Fragment>
                  ))}
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Box alignItems="center" justifyContent="flex-end" display="flex" className="w-100">
                <SuccessButton form="form" type="submit" startIcon={<SaveOutlinedIcon />} showLoading={showLoading}>
                  Menü Ekle
                </SuccessButton>
              </Box>
            </CardActions>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default CreateMenuPage;

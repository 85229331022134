import React from 'react';
import { Column, MTableToolbar } from 'material-table';
import { Group as GroupIcon, Check, Error, PlayArrow, Sync } from '@material-ui/icons';
import { Grid } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { InfoMiniCard, TranslatedMaterialTable } from 'core/components';
import { TableState } from 'core/models';

const useStyle = makeStyles(() => {
  return {
    countCard: {
      color: '#e17055',
      fontSize: '50px',
      float: 'right',
    },
  };
});
const XmlServicePage = () => {
  const classes = useStyle();
  const tableState1: TableState<any> = {
    columns: [
      { title: 'İşlem No', render: (value) => 'Peliel' },
      { title: 'İşlem Adı', field: 'araba lastigi' },
      { title: 'Durum', render: (value) => 'Tamamlandı' },
      { title: 'Tarih', render: (value) => 'Tamamlandı' },
    ],
  };
  const tableState2: TableState<any> = {
    columns: [{ title: 'Tedarikçi', render: (value) => 'Peliel' }],
    actions: [
      {
        tooltip: 'Ürün Aktarımı Başlat',
        icon: (props) => <PlayArrow {...props} />,
        onClick: (event, rowData) => {},
      },
      {
        icon: (props) => <PlayArrow {...props} />,
        tooltip: 'Kategori Aktarımı Başlat',
        onClick: (event, rowData) => {},
      },
      {
        icon: (props) => <Sync {...props} />,
        tooltip: 'Eşleştir',
        onClick: (event, rowData) => {},
      },
    ],
  };
  return (
    <React.Fragment>
      <Grid container spacing={2} justify="center">
        <Grid item xs={12} md={3}>
          <InfoMiniCard title="Kuyrukta Bekleyen" text="4" icon={<GroupIcon className={classes.countCard} />} />
        </Grid>

        <Grid item xs={12} md={3}>
          <InfoMiniCard title="Tamamlanan" text={`2 `} icon={<Check className={classes.countCard} />} />
        </Grid>
        <Grid item xs={12} md={3}>
          <InfoMiniCard title="Hata Meydana Gelen" text={`6 `} icon={<Error className={classes.countCard} />} />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TranslatedMaterialTable
            columns={tableState1.columns}
            data={[]}
            actions={tableState1.actions}
            components={{
              Toolbar: (props) => (
                <React.Fragment>
                  <MTableToolbar {...props} />
                </React.Fragment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TranslatedMaterialTable
            columns={tableState2.columns}
            data={[]}
            actions={tableState2.actions}
            components={{
              Toolbar: (props) => (
                <React.Fragment>
                  <MTableToolbar {...props} />
                </React.Fragment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default XmlServicePage;

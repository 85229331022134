import React, { useEffect } from 'react';
import { TableState } from 'core/models';
import { TranslatedMaterialTable } from 'core/components';
import { Api } from 'core';
import { Order } from 'apps/crm/models';
import { Visibility as VisibilityIcon } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

const ExitOrderListPage = () => {
  const [showLoading, setShowLoading] = React.useState(false);
  const history = useHistory();
  const [tableState] = React.useState<TableState<Order>>({
    columns: [
      {
        title: 'Sipariş Kodu',
        render: (val) => (val?.client ? val.sourceCode : 'Yok'),
        cellStyle: { whiteSpace: 'nowrap' },
      },
      {
        title: 'Müşteri İsmi',
        render: (val) => (val?.client ? val.client.name : 'Yok'),
        cellStyle: { whiteSpace: 'nowrap' },
      },
      {
        title: 'Durum',
        render: (val) => (val?.client ? val.statusName : 'Yok'),
        cellStyle: { whiteSpace: 'nowrap' },
      },
      {
        title: 'Tutar',
        render: (val) => (val?.client ? `${val.total} ₺` : 'Yok'),
        cellStyle: { whiteSpace: 'nowrap' },
      },
      {
        title: 'Kaynak',
        render: (val) => (val?.products ? val.sourceName : 'Yok'),
        cellStyle: { whiteSpace: 'nowrap' },
      },
      {
        title: 'Mağaza',
        render: (val) => (val?.products ? val.store.name : 'Yok'),
        cellStyle: { whiteSpace: 'nowrap' },
      },
      {
        title: 'Oluşturulma Tarihi',
        render: (val) => (val?.products ? val.createdAt : 'Yok'),
        cellStyle: { whiteSpace: 'nowrap' },
      },
    ],
    actions: [
      (rowData) => ({
        icon: (props) => <VisibilityIcon {...props} />,
        onClick: (event, rowData) => {
          const order = rowData as Order;
          history.push(`/orders/${order.id}`);
        },
      }),
      (rowData) => ({
        icon: 'delete',
        onClick: () => {},
      }),
    ],
  });
  return (
    <TranslatedMaterialTable
      isLoading={showLoading}
      actions={tableState.actions}
      columns={tableState.columns}
      title="Kaçan Sipariş Listesi"
      data={(q) =>
        new Promise((resolve) => {
          setShowLoading(true);
          Api.Order.getExitOrders({
            page: q.page,
          })
            .then((response) => response.data)
            .then((data) => {
              if (data) {
                resolve({
                  page: q.page,
                  totalCount: data.totalCount,
                  data: data.data,
                });
              }
            });
          setShowLoading(false);
        })
      }
    />
  );
};
export default ExitOrderListPage;

import { MenuItem } from '@material-ui/core';
import { CargoCompany } from 'apps/order';
import { Api } from 'core/api';
import React from 'react';
import FormSelect, { FormSelectProps } from '../FormSelect/FormSelect';

export type CargoCompanySelectProps = FormSelectProps;

const CargoCompanySelect: React.FC<CargoCompanySelectProps> = ({ ...formSelectProps }) => {
  const [cargoCompanies, setCargoCompanies] = React.useState<CargoCompany[]>([]);
  const [showLoading, setShowLoading] = React.useState(true);

  React.useEffect(() => {
    Api.Order.getCargoCompanies().then((response) => {
      if (response && response.data) {
        setCargoCompanies(response.data);
      }
      setShowLoading(false);
    });
  }, []);

  return (
    <FormSelect {...formSelectProps} placeholder="Kargo Firması" label="Kargo Firması" showLoading={showLoading}>
      {cargoCompanies.map((cargoCompany) => (
        <MenuItem key={`ccs${cargoCompany.id}`} value={cargoCompany.name}>
          {cargoCompany.name}
        </MenuItem>
      ))}
    </FormSelect>
  );
};

export default CargoCompanySelect;

import { Product } from 'apps/product';
import { ecmsClient } from 'core';
import { ApiResponse } from 'core/models';

const BASE_URL = '/report';

export default {
  URL_GET_WISHLIST_REPORT: `${BASE_URL}/wishlist`,
  async getWishlistReport(): Promise<ApiResponse<Product[]>> {
    const response = await ecmsClient.get(this.URL_GET_WISHLIST_REPORT);
    return response.data;
  },
};

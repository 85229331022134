import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { AssemblyTheme } from 'apps/design/models';
import {
  Api,
  AssemblyLocationAutoCompleteSelect,
  FormTextField,
  GenericMessages,
  SaveButton,
  showToast,
  TranslatedMaterialTable,
} from 'core';
import { TableState } from 'core/models';
import { Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

const ListAssemblyThemePage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [showLoading, setShowLoading] = React.useState(false);
  const [combineFormInitialValues, setCombineFormInitialValues] = React.useState<any>({
    name: '',
    overridePrice: '',
    assemblies: [],
  });
  const [showAssemblyThemesCombineDialog, setShowAssemblyThemesCombineDialog] = React.useState(false);
  const [showFormSaveLoading, setShowFormSaveLoading] = React.useState(false);
  const [tableState] = React.useState<TableState<AssemblyTheme>>({
    columns: [{ title: 'Montaj', field: 'name', cellStyle: { whiteSpace: 'nowrap' } }],

    actions: [
      {
        icon: 'edit',
        tooltip: 'Düzenle',
        position: 'row',
        onClick: (event, rowData) => {
          const req = rowData as AssemblyTheme;
          history.push(`/design/modules/assembly/theme/update/${req.id}`);
        },
      },
      {
        tooltip: 'Remove All Selected Users',
        icon: 'delete',
        onClick: (evt, data) => {
          const assemblyThemes = data as AssemblyTheme[];
          const combinedAssemblies = assemblyThemes.map((assemblyTheme) => assemblyTheme.assemblies).flat();
          setCombineFormInitialValues({
            name: '',
            overridePrice: '',
            assemblies: combinedAssemblies,
          });
          setShowAssemblyThemesCombineDialog(true);

          console.log(assemblyThemes.map((assemblyTheme) => assemblyTheme.assemblies).flat());
        },
      },
    ],
  });

  const hideCombineAssemblyThemesDialog = () => {
    setShowAssemblyThemesCombineDialog(false);
  };

  const onCombineAssemblyThemesSubmit = async (data, { resetForm }) => {
    setShowFormSaveLoading(true);
    const submitData = {
      name: data.name,
      overridePrice: data.overridePrice,
      assemblyIds: data.assemblies.map((assembly) => assembly.id),
    };
    try {
      await Api.Design.createAssmblyTheme(submitData);
      dispatch(
        showToast({
          message: `Birleştirilmiş montaj teması oluşturuldu`,
        }),
      );
      setShowAssemblyThemesCombineDialog(false);
      setCombineFormInitialValues({
        name: '',
        overridePrice: '',
        assemblies: [],
      });
    } catch (e) {
      dispatch(
        showToast({
          message: `Hata oluştu`,
          severity: 'error',
        }),
      );
    } finally {
      setShowFormSaveLoading(false);
    }
  };

  const validationSchema = yup.object({
    overridePrice: yup.number().required(GenericMessages.CANNOT_BE_EMPTY),
    name: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
    assemblies: yup.array().required(GenericMessages.CANNOT_BE_EMPTY),
  });

  return (
    <React.Fragment>
      <TranslatedMaterialTable
        isLoading={showLoading}
        actions={tableState.actions}
        columns={tableState.columns}
        options={{
          selection: true,
        }}
        data={(q) =>
          new Promise((resolve) => {
            setShowLoading(true);
            Api.Design.getAssemblyThemeList({
              page: q.page,
              queryName: q.search,
            })
              .then((response) => response.data)
              .then((data) => {
                if (data) {
                  resolve({
                    page: q.page,
                    totalCount: data.totalCount,
                    data: data.data,
                  });
                }
              });
            setShowLoading(false);
          })
        }
        title="Montaj Tema Listesi"
      />
      <Dialog open={showAssemblyThemesCombineDialog} onClose={hideCombineAssemblyThemesDialog}>
        <DialogTitle>Yeni Birlesik Montaj Teması Oluştur</DialogTitle>
        <DialogContent>
          <Formik
            enableReinitialize
            initialValues={combineFormInitialValues}
            onSubmit={onCombineAssemblyThemesSubmit}
            validationSchema={validationSchema}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({ handleChange, handleSubmit, values, setFieldValue, errors }) => (
              <form onSubmit={handleSubmit} noValidate id="form">
                <Grid direction="row" container spacing={3}>
                  <Grid item xs={12}>
                    <FormTextField
                      required
                      label="Adı"
                      fullWidth
                      placeholder="Adı"
                      name="name"
                      onChange={handleChange}
                      value={values.name}
                      error={errors.name != null}
                      helperText={errors.name}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormTextField
                      required
                      name="overridePrice"
                      label="Fiyat"
                      placeholder="Fiyat"
                      fullWidth
                      margin="dense"
                      onChange={handleChange}
                      value={values.overridePrice}
                      error={errors.overridePrice != null}
                      helperText={errors.overridePrice}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <AssemblyLocationAutoCompleteSelect
                      error={errors.assemblies != null}
                      helperText={errors.assemblies as string}
                      value={values.assemblies}
                      fullWidth
                      onChange={(e, assemblies) => setFieldValue('assemblies', assemblies)}
                    />
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button onClick={hideCombineAssemblyThemesDialog} color="primary">
            İptal
          </Button>
          <SaveButton color="primary" autoFocus form="form" type="submit" showLoading={showFormSaveLoading}>
            Oluştur
          </SaveButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ListAssemblyThemePage;

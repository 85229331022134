import { TextField } from '@material-ui/core';
import { Category } from 'apps/crm/models';
import { Store } from 'apps/store';
import { Role } from 'apps/user';
import { Api } from 'core';
import { FilterRequest } from 'core/models';
import React from 'react';
import { FormAutocomplete, FormAutocompleteProps } from '../FormAutocomplete';

export type RoleAutoCompleteSelectProps = Omit<FormAutocompleteProps, 'options'>;

const RoleAutoCompleteSelect: React.FC<RoleAutoCompleteSelectProps> = ({ onChange, ...autocompleteProps }) => {
  const [roleList, setRoleList] = React.useState<Role[]>([]);
  const [inputValue, setInputValue] = React.useState<string>();

  const pullRoleList = async (filterRoleRequest?: FilterRequest) => {
    const response = await Api.User.getRoles(filterRoleRequest);
    if (response.data) setRoleList(response.data.data);
  };

  React.useEffect(() => {
    pullRoleList();
  }, []);

  const onQueryChage = (event, newInputValue: string) => {
    setInputValue(newInputValue);
    if (newInputValue && newInputValue.length > 0) {
      pullRoleList({
        queryName: newInputValue,
      });
    } else {
      pullRoleList();
    }
  };

  return (
    <FormAutocomplete
      {...autocompleteProps}
      multiple
      size="small"
      options={roleList}
      getOptionLabel={(option) => option?.name}
      onChange={onChange}
      filterSelectedOptions
      inputValue={inputValue}
      onInputChange={onQueryChage}
      placeholder="Roller"
    />
  );
};

export default RoleAutoCompleteSelect;

import { TextField } from '@material-ui/core';
import { Category } from 'apps/crm/models';
import { Api } from 'core';
import { FilterRequest } from 'core/models';
import React from 'react';
import { FormAutocomplete, FormAutocompleteProps } from '../FormAutocomplete';

export type CategoryAutoCompleteSelect = Omit<FormAutocompleteProps, 'options'>;

const CateogoryAutoCompleteSelect: React.FC<CategoryAutoCompleteSelect> = ({ onChange, ...autocompleteProps }) => {
  const [categoryList, setCategoryList] = React.useState<Category[]>([]);
  const [inputValue, setInputValue] = React.useState<string>();
  const [isFirstLoad, setIsFirstLoad] = React.useState(true);

  const pullCategoryList = async (filterCategoryRequest?: FilterRequest) => {
    const response = await Api.Crm.getCategories(filterCategoryRequest);
    if (response.data) setCategoryList(response.data.data);
    setIsFirstLoad(false);
  };

  React.useEffect(() => {
    pullCategoryList();
  }, []);

  const onQueryChage = (event, newInputValue: string) => {
    setInputValue(newInputValue);
    if (newInputValue && newInputValue.length > 0) {
      pullCategoryList({
        queryName: newInputValue,
      });
    } else {
      pullCategoryList();
    }
  };

  return (
    <FormAutocomplete
      {...autocompleteProps}
      multiple={autocompleteProps.multiple}
      size="small"
      showLoading={isFirstLoad}
      options={categoryList}
      optionLabelName="name"
      equValueName="id"
      onChange={onChange}
      filterSelectedOptions
      inputValue={inputValue}
      onInputChange={onQueryChage}
      placeholder={autocompleteProps.placeholder}
    />
  );
};

CateogoryAutoCompleteSelect.defaultProps = {
  multiple: true,
  placeholder: 'Kategori',
};

export default CateogoryAutoCompleteSelect;

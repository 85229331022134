import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  ConfirmDialog,
  FormAutocomplete,
  FormTextField,
  Api,
  HideableLoadingSpinnerBox,
  ClientAutoCompleteSelect,
} from 'core';
import { Formik } from 'formik';
import * as yup from 'yup';
import { showToast } from 'core/store';
import { useDispatch } from 'react-redux';

const createClientContactFormSchema = yup.object({
  // clientId: yup.number().required('Lütfen müşteri seçiniz.'),
  // contactType: yup.number().required('Lütfen tip seçiniz.'),
  message: yup.string().required('Lütfen mesajınızı giriniz.'),
});

interface ClientContactProps {
  formId: string;
  message?: string;
  clientId?: number;
  onCloseFastContact?: () => void;
  setButtonShowLoading?: (boolean) => void;
}

const useStyle = makeStyles((theme) => {
  return {
    messagesBox: {
      position: 'relative',
    },
    messageUpButtton: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
    messageDownButtton: {
      position: 'absolute',
      bottom: 0,
      right: 0,
    },
  };
});

const ClientContact: React.FC<ClientContactProps> = ({
  formId,
  message,
  clientId,
  onCloseFastContact,
  setButtonShowLoading,
}) => {
  const classes = useStyle();
  const [showLoading, setShowLoading] = React.useState(false);

  const [data, setData] = React.useState();
  const [price, setPrice] = React.useState();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const dispatch = useDispatch();

  const submit = async (values) => {
    // const smsPrice = await DealerApi.getSetting('unitSmsPrice');    console.log('sss', values);
    console.log(values);
    setDialogOpen(true);
    setData(values);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const sendMessage = async () => {
    if (setButtonShowLoading) setButtonShowLoading(true);
    handleDialogClose();
    const formData: any = data;
    try {
      const response = await Api.Crm.clientContact(formData);
      if (response.data) {
        dispatch(
          showToast({
            message: `Kullanıcıya mesaj başarıyla gönderilmiştir`,
          }),
        );
        if (onCloseFastContact) onCloseFastContact();
      }
    } catch (err) {
      console.log(err);
      dispatch(
        showToast({
          message: `Mesaj gönderilirken hata oluştu`,
          severity: 'error',
        }),
      );
    }
    if (setButtonShowLoading) setButtonShowLoading(false);
  };

  // if (!showLoading) {
  //   return <HideableLoadingSpinnerBox />;
  // }
  return (
    <Formik
      validationSchema={createClientContactFormSchema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        clientId,
        contactType: 1,
        message,
      }}
      onSubmit={submit}
    >
      {({ handleSubmit, handleChange, errors, values, setFieldValue }) => (
        <form onSubmit={handleSubmit} noValidate id={formId}>
          <Grid container spacing={2}>
            {!clientId && (
              <Grid xs={12} md={6} item>
                <ClientAutoCompleteSelect
                  multiple={false}
                  fullWidth
                  onChange={(event, client) => setFieldValue('clientId', client?.id)}
                  error={errors.clientId != null}
                  helperText={errors.clientId}
                />
              </Grid>
            )}

            <Grid xs={12} md={clientId ? 12 : 6} item>
              <FormAutocomplete
                placeholder="İletişim Tipi"
                options={[
                  { name: 'SMS', id: 1 },
                  { name: 'Flash SMS', id: 2 },
                  { name: 'Robot Arama', id: 3 },
                  { name: 'Email', id: 4 },
                ]}
                optionLabelName="name"
                equValueName="id"
                onChange={(event, contactType) => setFieldValue('contactType', contactType?.id)}
                error={errors.contactType != null}
                helperText={errors.contactType}
                fullWidth
              />
            </Grid>
            <Grid xs={12} md={clientId ? 12 : 6} item>
              <FormTextField
                placeholder="Müşterinize göndermek istediğiniz mesajınızı giriniz"
                label="Mesajınız"
                variant="outlined"
                name="message"
                multiline
                value={values.message}
                onChange={handleChange}
                rows={3}
                rowsMax={5}
                error={errors.message != null}
                helperText={errors.message}
                fullWidth
              />
            </Grid>
          </Grid>
          <ConfirmDialog
            open={dialogOpen}
            title="Mesaj Gönderim Onayı"
            // eslint-disable-next-line no-lone-blocks
            handleConfirm={sendMessage}
            handleClose={handleDialogClose}
          >
            Mesaj Gönderilecektir. <br /> Onaylıyor musunuz ?
          </ConfirmDialog>
        </form>
      )}
    </Formik>
  );
};

export default ClientContact;

import React from 'react';
import { CreateProductForm } from 'apps/product/components';
import { useParams } from 'react-router-dom';
import { Product } from 'apps/product';
import { Api } from 'core';
import { ProductCreateOrUpdateContext } from 'apps/product/context';
import { Warehouse, WarehouseShelfPart } from 'apps/warehouse';

const CreateProductPage = () => {
  const [product, setProduct] = React.useState<Product>();
  const [warehouses, setWarehouses] = React.useState<Warehouse[]>([]);
  const [warehouseShelfParts, setWarehouseSelfParts] = React.useState<WarehouseShelfPart[]>([]);
  const [hasPulledWarehouseShelfParts, setHasPulledWarehouseShelfParts] = React.useState<boolean>(false);
  const [prevId, setPrevId] = React.useState<number | null>(null);
  const { id }: any = useParams();

  React.useEffect(() => {
    if (id) {
      setPrevId(id);
    } else if (prevId) {
      // eslint-disable-next-line no-restricted-globals
      location.reload();
    }
  }, [id]);
  const pullWarehouses = async () => {
    const response = await Api.Warehouse.getWarehouses();
    if (response.data) {
      setWarehouses(response.data);
    }
  };
  const pullShelfParts = async (warehouseId: number, categoryId: number) => {
    const response = await Api.Warehouse.getShelfCode(warehouseId, categoryId);
    if (response.data) {
      setWarehouseSelfParts(response.data);
    }
    setHasPulledWarehouseShelfParts(true);
  };

  const pullProduct = async () => {
    const response = await Api.Product.getProduct(id);
    if (response.data) {
      const product = response.data;

      if (product.categories && product.categories.length > 0 && product.warehouseId) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        pullShelfParts(product.warehouseId, product.categories[0].id!);
      }
      setProduct(product);
    }
  };

  const clearShelfParts = () => {
    setWarehouseSelfParts([]);
    setHasPulledWarehouseShelfParts(false);
  };
  React.useEffect(() => {
    if (id) {
      pullProduct();
    }
    pullWarehouses();
  }, []);

  if (id && !product) {
    return null;
  }
  return (
    <ProductCreateOrUpdateContext.Provider
      value={{
        warehouses,
        setWarehouses,
        warehouseShelfParts,
        setWarehouseSelfParts,
        pullShelfParts,
        hasPulledWarehouseShelfParts,
        setHasPulledWarehouseShelfParts,
        clearShelfParts,
      }}
    >
      <CreateProductForm product={product} />
    </ProductCreateOrUpdateContext.Provider>
  );
};

export default CreateProductPage;

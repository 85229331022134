import { Formik, Form } from 'formik';
import React from 'react';
import { TextField, Grid, CardContent, Card, CardActions, Box } from '@material-ui/core';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { FormHeader, SuccessButton } from 'core/components';
import GenericMessages from 'core/GenericMessages';

const CreateClientClassForm = () => {
  const [showLoading, setShowLoading] = React.useState(false);

  const dispatch = useDispatch();
  const inputLabelProps = {
    shrink: true,
  };

  const schema = yup.object({
    className: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
  });

  const onCreateClassSubmit = async (values, { resetForm }) => {
    setShowLoading(true);
    try {
      console.log('hh');
    } catch (e) {
      setShowLoading(false);
    }
  };

  return (
    <Formik
      onSubmit={onCreateClassSubmit}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        className: '',
      }}
    >
      {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
        <Form onSubmit={handleSubmit} id="form" noValidate>
          <Card>
            <CardContent>
              <FormHeader title="Müşteri Sınıfı Oluştur" />
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  required
                  label="Sınıf Adı"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  InputLabelProps={inputLabelProps}
                  placeholder="Sınıf Adı"
                  name="className"
                  onChange={handleChange}
                  value={values.className}
                  error={errors.className != null}
                  helperText={errors.className}
                />
              </Grid>
            </CardContent>

            <CardActions>
              <Box alignItems="center" justifyContent="flex-end" display="flex" className="w-100">
                <SuccessButton form="form" type="submit" startIcon={<SaveOutlinedIcon />} showLoading={showLoading}>
                  Müşteri Sınıfı Oluştur
                </SuccessButton>
              </Box>
            </CardActions>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default CreateClientClassForm;

import { getRoles } from '@testing-library/react';
/* eslint-disable import/no-anonymous-default-export */
import { Supplier } from 'apps/supplier/models';
import { ecmsClient } from 'core';
import { ApiResponse, FilterRequest, PageResponse } from 'core/models';
import { Role, User } from '../models';

const createUserCreateOrUpdateFormData = (userCreateOrUpdateData) => {
  const formData = new FormData();
  if (userCreateOrUpdateData.name) {
    formData.append('name', userCreateOrUpdateData.name);
  }
  if (userCreateOrUpdateData.email) {
    formData.append('email', userCreateOrUpdateData.email);
  }
  if (userCreateOrUpdateData.storeIds) {
    userCreateOrUpdateData.storeIds.forEach((storeId) => formData.append('storeIds[]', storeId));
  }

  if (userCreateOrUpdateData.roleIds) {
    userCreateOrUpdateData.roleIds.forEach((roleId) => formData.append('roleIds[]', roleId));
  }

  formData.append('currentStoreId', userCreateOrUpdateData.currentStoreId);
  formData.append('currentLanguageId', userCreateOrUpdateData.currentLanguageId);

  if (userCreateOrUpdateData.profilePhotoFile) {
    formData.append('profilePhotoFile', userCreateOrUpdateData.profilePhotoFile);
  }

  if (userCreateOrUpdateData.password) {
    formData.append('password', userCreateOrUpdateData.password);
  }
  return formData;
};

export default {
  URL_USER: '/user/',
  URL_USER_WITH_ID: (id: number) => `/user/${id}`,
  URL_GET_ROLES: '/role',

  async getUsers(filterUserRequest?: FilterRequest): Promise<ApiResponse<PageResponse<User>>> {
    const response = await ecmsClient.get(this.URL_USER, {
      params: filterUserRequest,
    });
    return response.data;
  },

  async getUser(id: number): Promise<ApiResponse<User>> {
    const response = await ecmsClient.get(this.URL_USER_WITH_ID(id));
    return response.data;
  },

  async createOrUpdateUser(user: any): Promise<ApiResponse<User>> {
    const formData = createUserCreateOrUpdateFormData(user);
    const response = await ecmsClient.post(this.URL_USER, formData);
    return response.data;
  },

  async updateUser(userId: number, user): Promise<ApiResponse<User>> {
    const formData = createUserCreateOrUpdateFormData(user);
    const response = await ecmsClient.post(this.URL_USER_WITH_ID(userId), formData);
    return response.data;
  },

  async getRoles(filterRoleRequest?: FilterRequest): Promise<ApiResponse<PageResponse<Role>>> {
    const response = await ecmsClient.get(this.URL_GET_ROLES, {
      params: filterRoleRequest,
    });
    return response.data;
  },
};

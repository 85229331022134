import { ecmsClient } from 'core';
import { ApiResponse } from 'core/models';
import { Warehouse, WarehouseShelfPart } from '../models';

const BASE_URL = '/warehouse';
export default {
  URL_GET_WAREHOUSES: `${BASE_URL}`,
  URL_GET_SHELF_CODE: (warehouseId, categoryId) => `${BASE_URL}/${warehouseId}/${categoryId}`,

  async getWarehouses(): Promise<ApiResponse<Warehouse[]>> {
    const response = await ecmsClient.get(this.URL_GET_WAREHOUSES);
    return response.data;
  },

  async getShelfCode(warehouseId: number, categoryId: number): Promise<ApiResponse<WarehouseShelfPart[]>> {
    const response = await ecmsClient.get(this.URL_GET_SHELF_CODE(warehouseId, categoryId));
    return response.data;
  },
};

/* eslint-disable import/no-anonymous-default-export */
import { ecmsClient } from 'core';
import { ApiResponse, PageResponse, FilterRequest } from 'core/models';
import { Product, ProductCommentFilterRequest, ProductFilterRequest } from '../models';
import ProductComment from '../models/ProductComment';

const BASE_URL = '/product';
export default {
  URL_PRODUCT: '/product/',
  URL_DELETE_PRODUCT: (id: number) => `/product/${id}`,
  URL_UPDATE_PRODUCT: (id: number) => `/product/${id}`,
  URL_PRODUCT_WITH_ID: (id: number) => `/product/${id}`,
  URL_UPLOAD_PRODUCT_IMAGE: (id: number) => `/product/uploadImage/${id}`,
  URL_SET_THUMBNAIL: (id: number, imageId: number) => `/product/setThumbnail/${id}/${imageId}`,
  URL_DELETE_PRODUCT_IMAGE: (id: number, imageId: number) => `product/deleteProductImage/${id}/${imageId}`,
  URL_COMMENTS: `${BASE_URL}/comments`,
  URL_N11_PRODUCTS: 'marketplace/n11/getProducts',

  async getProducts(filterRequest?: ProductFilterRequest): Promise<ApiResponse<PageResponse<Product>>> {
    const response = await ecmsClient.get(this.URL_PRODUCT, {
      params: filterRequest,
    });
    return response.data;
  },

  async getN11Products(filterRequest?: ProductFilterRequest): Promise<ApiResponse<PageResponse<Product>>> {
    const response = await ecmsClient.get(this.URL_N11_PRODUCTS, {
      params: filterRequest,
    });
    return response.data;
  },

  async deleteProduct(productId: number): Promise<ApiResponse<PageResponse<Product>>> {
    const response = await ecmsClient.delete(this.URL_DELETE_PRODUCT(productId));
    return response.data;
  },

  async getProduct(id: number): Promise<ApiResponse<Product>> {
    const response = await ecmsClient.get(this.URL_PRODUCT_WITH_ID(id));

    return response.data;
  },
  async createProduct(data: any): Promise<ApiResponse<Product>> {
    const response = await ecmsClient.post(this.URL_PRODUCT, data);
    return response.data;
  },

  async updateProduct(productId: number, data: any): Promise<ApiResponse<Product>> {
    const response = await ecmsClient.post(this.URL_UPDATE_PRODUCT(productId), data, { timeout: 20000 });
    return response.data;
  },

  async uploadProductImage(productId: number, image: any, isThumbnail?: boolean) {
    const formData = new FormData();
    formData.append('file', image);
    formData.append('isThumbnail', isThumbnail ? '1' : '0');
    const response = await ecmsClient.post(this.URL_UPLOAD_PRODUCT_IMAGE(productId), formData);
    return response.data;
  },

  async deleteProductImage(productId: number, imageId: number) {
    const response = await ecmsClient.post(this.URL_DELETE_PRODUCT_IMAGE(productId, imageId));
    return response.data;
  },
  async setThumbnail(productId: number, imageId: number) {
    const response = await ecmsClient.post(this.URL_SET_THUMBNAIL(productId, imageId));
    return response.data;
  },
  async getComments(
    productCommentFilterRequest: ProductCommentFilterRequest,
  ): Promise<ApiResponse<PageResponse<ProductComment>>> {
    const response = await ecmsClient.get(this.URL_COMMENTS, {
      params: productCommentFilterRequest,
    });
    return response.data;
  },
};

import React from 'react';
import {
  Chip,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@material-ui/core';
import { TableRowDataBase } from 'core';

const useStyles = makeStyles((theme: Theme) => ({
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

export interface StripedTableColumnsProps<T> {
  name: string;
  field?: string;
  render?: (value: T, index: number) => string | JSX.Element;
  colspan?: number;
}

export interface StripedTableProps<T> {
  title?: string;
  data: (T & TableRowDataBase)[];
  columns: StripedTableColumnsProps<T>[];
}

const StripedTable: React.FC<StripedTableProps<any>> = ({ title, data, columns }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      {title && <Typography>{title}</Typography>}
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((header, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <TableCell colSpan={header.colspan} key={`sth${index}`}>
                {header.name}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((rowData, rowIndex) => (
            <TableRow className={classes.tableRow} key={rowData.tableKey}>
              {columns.map((column, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableCell colSpan={column.colspan} key={`${rowData.tableKey}stbc${index}`}>
                  {column.field ? rowData[column.field] : column.render && column.render(rowData, rowIndex)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
};

export default StripedTable;

import { Switch, Route } from 'react-router-dom';
import React from 'react';

import { CreateSupplierPage, SupplierDetailPage, SupplierListPage } from 'apps/supplier';
import { ProductsBatchPage } from 'apps/crm';
import {
  CategoryListPage,
  CreateCategoryPage,
  CreateInstallmentRatePage,
  CreatePaymentTypePage,
  CreateProducerPage,
  CreateShipmentMethodPage,
  GoogleAnalytics,
  HotjarPage,
  N11CategorySyncPage,
  PaymentMethodListPage,
  ProducerListPage,
  ShipmentListPage,
  OrderDetailPage,
  CategoryDetailPage,
  ShipmentDetailPage,
  PaymentMethodDetailPage,
  ProducerDetailPage,
  ExitOrderListPage,
  CargoTagGeneratorPage,
  N11ProductsPage,
  MarketPlaceSyncAllCategoryAttributePage,
  MarketPlaceSyncProductPage,
} from '../pages';
import {
  GittiGidiyorCategorySyncPage,
  GittiGidiyorSettings,
  HepsiBuradaCategorySyncPage,
  HepsiburadaSettings,
  N11Settings,
  TrendyolCategorySyncPage,
  TrendyolManufacturerPage,
} from '..';

interface OrderLayoutProps {
  match: any;
}

const OrderLayout: React.FC<OrderLayoutProps> = ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.path}/supplier/create`} component={CreateSupplierPage} />
      <Route exact path={`${match.path}/supplier/list`} component={SupplierListPage} />
      <Route exact path={`${match.path}/supplier/detail/:id`} component={SupplierDetailPage} />
      <Route exact path={`${match.path}/supplier/update/:id`} component={CreateSupplierPage} />
      <Route exact path={`${match.path}/producer/create`} component={CreateProducerPage} />
      <Route exact path={`${match.path}/producer/update/:id`} component={CreateProducerPage} />
      <Route exact path={`${match.path}/producer/detail/:id`} component={ProducerDetailPage} />
      <Route exact path={`${match.path}/category/create`} component={CreateCategoryPage} />
      <Route exact path={`${match.path}/category/update/:id`} component={CreateCategoryPage} />
      <Route exact path={`${match.path}/shipping/create`} component={CreateShipmentMethodPage} />
      <Route exact path={`${match.path}/shipping/update/:id`} component={CreateShipmentMethodPage} />
      <Route exact path={`${match.path}/shipping/detail/:id`} component={ShipmentDetailPage} />
      <Route exact path={`${match.path}/paymentType/create`} component={CreatePaymentTypePage} />
      <Route exact path={`${match.path}/paymentType/update/:id`} component={CreatePaymentTypePage} />
      <Route exact path={`${match.path}/paymentType/detail/:id`} component={PaymentMethodDetailPage} />
      <Route exact path={`${match.path}/installment/create`} component={CreateInstallmentRatePage} />
      <Route exact path={`${match.path}/integration/googleAnalytics`} component={GoogleAnalytics} />
      <Route exact path={`${match.path}/integration/hotjar`} component={HotjarPage} />
      <Route exact path={`${match.path}/marketplaces/hepsiburada/settings`} component={HepsiburadaSettings} />
      <Route exact path={`${match.path}/marketplaces/gittigidiyor/settings`} component={GittiGidiyorSettings} />
      <Route exact path={`${match.path}/marketplaces/n11/settings`} component={N11Settings} />
      <Route exact path={`${match.path}/marketplaces/n11/products`} component={N11ProductsPage} />
      <Route exact path={`${match.path}/marketplaces/syncProducts`} component={MarketPlaceSyncProductPage} />
      <Route
        exact
        path={`${match.path}/marketplaces/syncAllCategoryAttributes`}
        component={MarketPlaceSyncAllCategoryAttributePage}
      />

      <Route exact path={`${match.path}/marketplaces/trendyol/syncCategory`} component={TrendyolCategorySyncPage} />
      <Route
        exact
        path={`${match.path}/marketplaces/hepsiburada/syncCategory`}
        component={HepsiBuradaCategorySyncPage}
      />
      <Route
        exact
        path={`${match.path}/marketplaces/gittigidiyor/syncCategory`}
        component={GittiGidiyorCategorySyncPage}
      />
      <Route exact path={`${match.path}/marketplaces/n11/syncCategory`} component={N11CategorySyncPage} />
      <Route exact path={`${match.path}/marketplaces/trendyol/syncProducer`} component={TrendyolManufacturerPage} />
      <Route exact path={`${match.path}/producer/list`} component={ProducerListPage} />
      <Route exact path={`${match.path}/category/list`} component={CategoryListPage} />
      <Route exact path={`${match.path}/category/detail/:id`} component={CategoryDetailPage} />
      <Route exact path={`${match.path}/shipping/list`} component={ShipmentListPage} />
      <Route exact path={`${match.path}/paymentType/list`} component={PaymentMethodListPage} />
      <Route exact path={`${match.path}/exitList`} component={ExitOrderListPage} />
      <Route exact path={`${match.path}/:id/cargoTagGenerator`} component={CargoTagGeneratorPage} />
      <Route exact path={`${match.path}/:id`} component={OrderDetailPage} />
    </Switch>
  );
};

export default OrderLayout;

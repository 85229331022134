/* eslint-disable import/no-anonymous-default-export */
import { ecmsClient } from 'core';
import { ApiResponse, PageResponse, FilterRequest } from 'core/models';
import { Option } from '../models';

export default {
  URL_OPTION: '/option/',
  URL_OPTION_BY_ID: (id: number) => `/option/${id}`,

  async createOption(data: any): Promise<ApiResponse<Option>> {
    const response = await ecmsClient.post(this.URL_OPTION, data);
    return response.data;
  },

  async updateOption(id: number, data: any): Promise<ApiResponse<Option>> {
    const response = await ecmsClient.post(this.URL_OPTION_BY_ID(id), data);
    return response.data;
  },

  async getOptions(filterRequest?: FilterRequest): Promise<ApiResponse<PageResponse<Option>>> {
    const response = await ecmsClient.get(this.URL_OPTION, {
      params: filterRequest,
    });
    return response.data;
  },

  async getOption(id: number): Promise<ApiResponse<Option>> {
    const response = await ecmsClient.get(this.URL_OPTION_BY_ID(id));
    return response.data;
  },
};

import { Formik, Form } from 'formik';
import React from 'react';
import { TextField, Grid, CardContent, Card, CardActions, Box } from '@material-ui/core';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import * as yup from 'yup';
import { FormHeader, SuccessButton, UploadButton } from 'core/components';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const CreateBlogForm = () => {
  const [showLoading, setShowLoading] = React.useState(false);
  const [blogLogo, setBlogLogo] = React.useState();
  const blogLogoPreview = React.useRef(null);

  const inputLabelProps = {
    shrink: true,
  };

  const schema = yup.object({
    name: yup.string().required('Bu Boş Olamaz'),
  });

  const onBlogCreateSubmit = async (values, { resetForm }) => {
    setShowLoading(true);
    try {
      console.log('hh');
    } catch (e) {
      setShowLoading(false);
    }
  };
  const onLogoSelected = (event) => {
    const { target } = event;

    if (target.files || target.files.length === 1) {
      const file = target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        const currentElem: any = blogLogoPreview.current;
        if (currentElem) {
          currentElem.src = e.target?.result;
        }
      };

      reader.readAsDataURL(file);
      setBlogLogo(file);
    }
  };

  return (
    <Formik
      onSubmit={onBlogCreateSubmit}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        name: '',
        description: '',
      }}
    >
      {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
        <Form onSubmit={handleSubmit} id="form" noValidate>
          <Card>
            <CardContent>
              <Grid direction="row" container spacing={5}>
                <FormHeader title="Blog Bilgileri" />
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    required
                    label="Blog Başlığı"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    placeholder="İsim"
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    error={errors.name != null}
                    helperText={errors.name}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  {blogLogo != null && (
                    <Grid container justify="center">
                      <img src="#" alt="" height={100} width={100} ref={blogLogoPreview} />
                    </Grid>
                  )}

                  <UploadButton
                    buttonProps={{
                      type: 'button',
                    }}
                    onChange={onLogoSelected}
                  >
                    Fotoğraf Yükle
                  </UploadButton>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <CKEditor
                    placeholder="Açıklama"
                    editor={ClassicEditor}
                    data={values.description}
                    name="description"
                    onChange={(event, editor) => {
                      const data = editor.getData();

                      setFieldValue('description', data);
                    }}
                  />
                </Grid>
              </Grid>
            </CardContent>

            <CardActions>
              <Box alignItems="center" justifyContent="flex-end" display="flex" className="w-100">
                <SuccessButton form="form" type="submit" startIcon={<SaveOutlinedIcon />} showLoading={showLoading}>
                  Blog Ekle
                </SuccessButton>
              </Box>
            </CardActions>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default CreateBlogForm;

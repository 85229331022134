import { CreateSupplierDto, Supplier } from 'apps/supplier';
import { ecmsClient } from 'core';
import { ApiResponse, FilterRequest, PageResponse } from 'core/models';

const supplierCreateOrUpdateFormData = (supplier: CreateSupplierDto) => {
  const formData = new FormData();
  if (supplier.name) {
    formData.append('name', supplier.name);
  }
  if (supplier.email) {
    formData.append('email', supplier.email);
  }
  if (supplier.address) {
    formData.append('address', supplier.address);
  }
  if (supplier.authorizedPerson) {
    formData.append('authorizedPerson', supplier.authorizedPerson);
  }

  if (supplier.additionalProfit) {
    formData.append('additionalProfit', supplier.additionalProfit.toString());
  }

  if (supplier.allowancePercent) {
    formData.append('allowancePercent', supplier.allowancePercent.toString());
  }

  formData.append('storeId', '1');

  if (supplier.gsm) formData.append('gsm', supplier.gsm);

  if (supplier.isOutsource) formData.append('isOutsource', supplier.isOutsource ? '1' : '0');

  if (supplier.wantsNotification) formData.append('wantsNotification', supplier.wantsNotification ? '1' : '0');

  if (supplier.image) {
    formData.append('image', supplier.image);
  }

  if (supplier.returnType) {
    formData.append('returnType', supplier.returnType.toString());
  }

  if (supplier.supplyType) {
    formData.append('supplyType', supplier.supplyType.toString());
  }
  return formData;
};

const SupplierApi = {
  URL_CREATE_SUPPLIER: `/supplier/`,
  URL_SUPPLIER_WITH_ID: (id: number) => `/supplier/${id}`,

  async getSuppliers(filterSupplierRequest?: FilterRequest): Promise<ApiResponse<PageResponse<Supplier>>> {
    const response = await ecmsClient.get(this.URL_CREATE_SUPPLIER, {
      params: filterSupplierRequest,
    });
    return response.data;
  },

  async createSupplier(createSupplierDto: CreateSupplierDto): Promise<ApiResponse<PageResponse<CreateSupplierDto>>> {
    const formData = supplierCreateOrUpdateFormData(createSupplierDto);
    const response = await ecmsClient.post(this.URL_CREATE_SUPPLIER, formData);
    return response.data;
  },

  async updateSupplier(id: number, createSupplierDto: CreateSupplierDto): Promise<ApiResponse<CreateSupplierDto>> {
    const formData = supplierCreateOrUpdateFormData(createSupplierDto);
    const response = await ecmsClient.post(this.URL_SUPPLIER_WITH_ID(id), formData);
    return response.data;
  },

  async getSupplier(id: number): Promise<ApiResponse<Supplier>> {
    const response = await ecmsClient.get(this.URL_SUPPLIER_WITH_ID(id));
    return response.data;
  },
};

export default SupplierApi;

import React from 'react';
import { Box } from '@material-ui/core';
import { useSelector } from 'react-redux';
import HideableLoadingSpinner from '../HideableLoadingSpinner/HideableLoadingSpinner';

const HideableLoadingSpinnerBox = () => {
  const isDrawerOpen = useSelector((state: any) => state.core.isDrawerOpen);
  return (
    <Box
      top="50%"
      justifyContent="center"
      alignItems="center"
      display="flex"
      position="absolute"
      style={{
        width: isDrawerOpen ? `calc(100% - 240px)` : '100%',
      }}
    >
      <HideableLoadingSpinner show />
    </Box>
  );
};

export default HideableLoadingSpinnerBox;

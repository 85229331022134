/* eslint-disable import/no-anonymous-default-export */
import { ecmsClient } from 'core';
import { ApiResponse, PageResponse, FilterRequest } from 'core/models';
import { Category, Client, Order } from '../models';

export default {
  URL_ORDER: '/order/',
  URL_CATEGORY: '/category/',
  URL_CLIENT: '/client/',
  URL_CLIENT_WITH_ID: (id: number) => `/client/${id}`,

  async getOrders(filterRequest?: FilterRequest): Promise<ApiResponse<PageResponse<Order>>> {
    const response = await ecmsClient.get(this.URL_ORDER, {
      params: filterRequest,
    });
    return response.data;
  },

  async getCategories(data: any): Promise<ApiResponse<PageResponse<Category>>> {
    const response = await ecmsClient.get(this.URL_CATEGORY, {
      params: data,
    });
    return response.data;
  },
  async getClients(filterRequest?: FilterRequest): Promise<ApiResponse<PageResponse<Client>>> {
    const response = await ecmsClient.get(this.URL_CLIENT, {
      params: filterRequest,
    });
    return response.data;
  },
  async clientContact(data: any): Promise<ApiResponse<PageResponse<Client>>> {
    const response = await ecmsClient.post(this.URL_CLIENT, {
      data,
    });
    return response.data;
  },

  async getClient(id: number): Promise<ApiResponse<Client>> {
    const response = await ecmsClient.get(this.URL_CLIENT_WITH_ID(id));
    return response.data;
  },

  async createClient(data: any): Promise<ApiResponse<Client>> {
    const response = await ecmsClient.post(this.URL_CLIENT, { data });
    return response.data;
  },

  async updateClient(id: any, data: any): Promise<ApiResponse<Client>> {
    const response = await ecmsClient.post(this.URL_CLIENT_WITH_ID(id), { data });
    return response.data;
  },
};

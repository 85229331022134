import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ProductQuantityByBarcodePage, WarehouseList, WarehousePartList } from '../pages';

export interface WarehouseLayout {
  match?: any;
}

const WarehouseLayout: React.FC<WarehouseLayout> = ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.path}/barcode/`} component={ProductQuantityByBarcodePage} />
      <Route exact path={`${match.path}/partList/`} component={WarehousePartList} />
      <Route exact path={`${match.path}/list/`} component={WarehouseList} />
    </Switch>
  );
};

export default WarehouseLayout;

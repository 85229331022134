import { Button } from '@material-ui/core';
import { Sync } from '@material-ui/icons';
import { CategorySync } from 'apps/order';
import { FormDialog, TranslatedMaterialTable } from 'core/components';
import { TableState } from 'core/models';
import React from 'react';

const HepsiBuradaCategorySyncPage = () => {
  const [showCategorySyncDialog, setShowCategorySyncDialog] = React.useState(false);
  const [showLoading, setShowLoading] = React.useState(false);

  const handleCategorySyncDialogClose = () => {
    setShowCategorySyncDialog(false);
  };
  const handleCategorySyncDialogOpen = () => {
    setShowCategorySyncDialog(true);
  };

  const tempData: any = [
    {
      name: 'Yakıt',
    },
    {
      name: 'Bluetooth',
    },
    {
      name: 'Araba',
    },
    {
      name: 'Oyuncak',
    },
  ];

  const [tableState] = React.useState<TableState<any>>({
    columns: [{ title: 'Ürün', field: 'name', cellStyle: { whiteSpace: 'nowrap' } }],

    actions: [
      {
        tooltip: 'Eşleştir',
        icon: (props) => <Sync {...props} />,
        onClick: (event, rowData) => {
          handleCategorySyncDialogOpen();
        },
      },
    ],
  });
  return (
    <React.Fragment>
      <FormDialog
        handleClose={handleCategorySyncDialogClose}
        open={showCategorySyncDialog}
        formId="HepsiBurada"
        title="Kategori Eşitle"
        showLoading={showLoading}
      >
        <CategorySync
          formId="HepsiBurada"
          setShowCategorySyncDialog={setShowCategorySyncDialog}
          setShowLoading={setShowLoading}
        />
      </FormDialog>
      <TranslatedMaterialTable
        isLoading={showLoading}
        actions={tableState.actions}
        columns={tableState.columns}
        options={{
          selection: true,
        }}
        data={tempData}
        title="HepsiBurada Ürün Listesi"
      />
    </React.Fragment>
  );
};

export default HepsiBuradaCategorySyncPage;

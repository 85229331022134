import React from 'react';
import { Card, Tabs, Tab, Grid } from '@material-ui/core';
import { StoreOption } from 'apps/profile/components';

const ProfilePage = () => {
  const [tab, setTab] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState<JSX.Element>();
  const onTabChanged = (e, index) => {
    setTab(index);
  };

  React.useEffect(() => {
    switch (tab) {
      case 0:
        break;
      case 1:
        setCurrentPage(<StoreOption />);
        break;
      default: {
        break;
      }
    }
  }, [tab]);

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={4} md={2}>
          <Card className="mr-2">
            <Tabs
              value={tab}
              indicatorColor="primary"
              textColor="primary"
              onChange={onTabChanged}
              variant="scrollable"
              scrollButtons="auto"
              orientation="vertical"
            >
              <Tab label="Profil" />
              <Tab label="Mağazam Ayarları" />
            </Tabs>
          </Card>
        </Grid>
        <Grid item xs={8} md={10}>
          {currentPage}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ProfilePage;

import { Box, Button, FormControlLabel, Grid, Radio, Tooltip } from '@material-ui/core';
import { ProductImage } from 'apps/product';
import { HideableLoadingSpinner } from 'core/components';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import React from 'react';
import { Api } from 'core';

interface ImageStepProps {
  errors: any;
  values: any;
  setFieldValue: (field: string, value: any) => void;
  handleChange: (e: any) => void;
}

const ImageComp = ({ data }) => {
  if (data.new) {
    return <img src={data.fileData.data} width="100%" />;
  }

  // eslint-disable-next-line jsx-a11y/alt-text
  return <img src={data.image} width="100%" />;
};
const ImageStep: React.FC<ImageStepProps> = ({ errors, values, setFieldValue, handleChange }) => {
  const [images, setImages] = React.useState<any[]>([]);
  const [thumbnail, setThumbnail] = React.useState('');
  const [showLoading, setShowLoading] = React.useState(false);

  const handleAdd = async (selectedImages) => {
    setShowLoading(true);
    setShowLoading(false);
    const newImages = [...images];
    selectedImages.forEach((value) => {
      newImages.push({
        new: true,
        fileData: value,
      });
    });
    console.log(newImages);
    setImages(newImages);
    setFieldValue('images', newImages);
  };
  const handleDelete = async (idx: number) => {
    setShowLoading(true);
    const focusedImages = [...images];
    if (focusedImages[idx] && !focusedImages[idx].new) {
      const newDeletedImages = [...values.deletedImageIds, focusedImages[idx].id];
      setFieldValue('deletedImageIds', newDeletedImages);
    }
    focusedImages.splice(idx, 1);
    setImages(focusedImages);
    setFieldValue('images', focusedImages);

    setShowLoading(false);
  };

  const setInitialImage = () => {
    if (values.images && values.images.length > 0) {
      const newImages = [...values.images];
      setImages(newImages);
    }
  };
  React.useEffect(() => {
    setInitialImage();
  }, []);

  const handleSetThumbnail = async (thumbnail, index) => {
    setThumbnail(thumbnail);

    if (thumbnail.new) {
      console.log(thumbnail);
      const newImages = [...images];
      console.log(newImages[index]);
      newImages[index].thumbnail = true;
      setImages(newImages);
      setFieldValue('images', newImages);
    } else {
      setFieldValue('thumbnailId', thumbnail.id);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <DropzoneAreaBase
          maxFileSize={11000000}
          filesLimit={10}
          onAdd={handleAdd}
          fileObjects={[]}
          dropzoneText="Ürün görsellerini buraya bırakınız ya da buraya tıklayınız."
        />
        <Grid container xs={12}>
          {showLoading && (
            <Box display="flex" alignItems="center" justifyContent="center" width="100%" marginTop={4}>
              <HideableLoadingSpinner show />
            </Box>
          )}
          {!showLoading &&
            values.images?.map((image, idx) => {
              return (
                <Grid item lg={2} md={4} xs={6} key={`img${idx}`}>
                  <FormControlLabel
                    control={
                      <Radio
                        checked={thumbnail === image}
                        onChange={() => {
                          handleSetThumbnail(image, idx);
                        }}
                      />
                    }
                    label="Küçük resim olarak ayarla"
                  />
                  <Tooltip title="Kaldırmak için görsele tıklayınız">
                    <Button
                      onClick={() => {
                        handleDelete(idx);
                      }}
                    >
                      <ImageComp data={image} />
                    </Button>
                  </Tooltip>
                </Grid>
              );
            })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ImageStep;

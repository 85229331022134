import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';
import { Button } from '@material-ui/core';

const data = {
  id: 'root',
  name: 'Parent',
  children: [
    {
      id: '3',
      name: 'Child - 3',
      path: '1',
      children: [
        {
          id: '4',
          name: 'Child - 4',
        },
        {
          id: '1',
          name: 'Child - 1',
        },
      ],
    },
  ],
};

const useStyles = makeStyles({
  root: {
    height: 110,
    flexGrow: 1,
    maxWidth: 400,
  },
});

const RenderTree = ({ nodes, index, parent, onClick }: { nodes: any; index: number; parent: any; onClick: any }) => {
  return (
    <TreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name} onClick={() => onClick(nodes, index, parent)}>
      {Array.isArray(nodes.children)
        ? nodes.children.map((node, index) => (
            <RenderTree nodes={node} index={index} parent={nodes} onClick={onClick} />
          ))
        : null}
    </TreeItem>
  );
};

const TempMenuCreatePage = () => {
  const [focusedNode, setFocusedNode] = React.useState<any>();
  const classes = useStyles();
  const [data, setData] = React.useState<any>({
    id: 'root',
    name: 'Parent',
    children: [
      {
        id: '3',
        name: 'Child - 3',
        path: '1',
        children: [
          {
            id: '4',
            name: 'Child - 4',
          },
          {
            id: '1',
            name: 'Child - 1',
          },
        ],
      },
    ],
  });
  const onAdd = (node) => {
    const incomingData = {
      id: 5,
      name: 'bugra',
    };
    if (node) {
      if (node.children) {
        node.children.push(incomingData);
      } else {
        node.children = [incomingData];
      }
      setData({ ...data });
    } else {
      const newData = { ...data };
      newData.children.push(incomingData);
      setData(newData);
    }
  };
  const onDelete = (parent, index) => {
    if (parent) {
      parent.children.splice(index, 1);
      setData({
        ...data,
      });
    }
  };
  const onNodeClicked = (node, index, parent) => {
    console.log(node);
    setFocusedNode({
      node,
      index,
      parent,
    });
  };
  return (
    <React.Fragment>
      <TreeView
        className={classes.root}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpanded={['root']}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        <RenderTree nodes={data} index={0} parent={null} onClick={onNodeClicked} />
      </TreeView>
      <Button onClick={() => onAdd(focusedNode.node)}>EKLE</Button>
      <Button onClick={() => onDelete(focusedNode.parent, focusedNode.index)}>SİL</Button>
    </React.Fragment>
  );
};
export default TempMenuCreatePage;

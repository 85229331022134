import { Box, Button, Grid, IconButton, MenuItem } from '@material-ui/core';
import { Add, Cancel, Add as AddIcon, Remove as RemoveIcon } from '@material-ui/icons';
import { Api } from 'core';
import { FormSelect, FormTextField, OptionAutoCompleteSelect } from 'core/components';
import { FieldArray } from 'formik';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

interface SettingStepProps {
  errors: any;
  values: any;
  setFieldValue: (field: string, value: any) => void;
  handleChange: (e: any) => void;
}

const createEmptyOptionInfo = () => {
  return {
    option: {},
    extraPrice: '',
    stockValue: '',
    optionValueId: '',
    key: uuidv4(),
  };
};

const getErrorIndexError = (errors: any[] | undefined, index) => {
  return errors && errors.length > index ? errors[index] : {};
};

const OptionComp = ({ values, handleChange, errors, arrayHelper, setFieldValue }) => {
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        {values.options.map((option, index) => (
          <Grid item xs={12} key={`pi${option.key}`}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <OptionAutoCompleteSelect
                  placeholder="Seçenek ismi giriniz"
                  fullWidth
                  onChange={(event, value) => setFieldValue(`options.${index}.option`, value)}
                  error={getErrorIndexError(errors.options as any[], index)?.option != null}
                  helperText={getErrorIndexError(errors.options as any[], index)?.option}
                  value={values.options[index].option}
                  optionLabelName="name"
                  equValueName="id"
                />
              </Grid>
              {values.options[index].option && (
                <Grid item xs={3}>
                  <FormSelect
                    onChange={(event) => setFieldValue(`options.${index}.optionValueId`, event?.target.value)}
                    name={`options.${index}.optionValueId`}
                    value={values.options[index].optionValueId ? values.options[index].optionValueId : ''}
                    fullWidth
                    placeholder="Seçenek Değeri"
                  >
                    {values.options[index].option.values
                      ? values.options[index].option.values.map((val) => (
                          <MenuItem key={`ptmi${val.id}`} value={val.id}>
                            {val.value}
                          </MenuItem>
                        ))
                      : ''}
                  </FormSelect>
                </Grid>
              )}
              <Grid item xs={3}>
                <FormTextField
                  placeholder="Stok Miktarı"
                  fullWidth
                  type="number"
                  value={option.stockValue}
                  onChange={(e) => setFieldValue(`options.${index}.stockValue`, e.target.value)}
                  name={`options.${index}.option`}
                  error={getErrorIndexError(errors.options as any[], index)?.stockValue != null}
                  helperText={getErrorIndexError(errors.options as any[], index)?.stockValue}
                />
              </Grid>

              <Grid item xs={3}>
                <FormTextField
                  placeholder="Ekstra Fiyat"
                  fullWidth
                  type="number"
                  value={option.extraPrice}
                  onChange={(e) => setFieldValue(`options.${index}.extraPrice`, e.target.value)}
                  name={`options.${index}.option`}
                  error={getErrorIndexError(errors.options as any[], index)?.extraPrice != null}
                  helperText={getErrorIndexError(errors.options as any[], index)?.extraPrice}
                />
              </Grid>
            </Grid>
            <Grid item xs={1}>
              <Box display="flex" alignItems="center" height="100%">
                <Button startIcon={<RemoveIcon />} onClick={() => arrayHelper.remove(index)} color="secondary">
                  Sil
                </Button>
              </Box>
            </Grid>
            <Grid item xs={1} />
          </Grid>
        ))}
        <Grid item xs={12}>
          <Box display="flex" textAlign="right" alignItems="center" height="100%">
            <Button startIcon={<AddIcon />} onClick={() => arrayHelper.push(createEmptyOptionInfo())} color="primary">
              Seçenek Ekle
            </Button>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const SettingStep: React.FC<SettingStepProps> = ({ errors, values, handleChange, setFieldValue }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={12}>
        <FieldArray
          name="options"
          render={(arrayHelper) => (
            <OptionComp
              values={values}
              arrayHelper={arrayHelper}
              errors={errors}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default SettingStep;

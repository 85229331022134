import { PersonAdd, GroupAdd, AccountCircle } from '@material-ui/icons';
import React from 'react';
import { MenuBlockInfo } from 'core/types';
import { User } from 'react-feather';

const userSidebarMenuList: MenuBlockInfo = {
  id: 'userMenus',
  title: 'Personel Yönetimi',
  child: [
    {
      id: 'user',
      icon: <User />,
      label: 'Personel Yönetimi',
      child: [
        {
          id: 'userCreate',
          route: '/users/create/',
          icon: <PersonAdd />,
          label: 'Personel Ekle',
        },

        {
          id: 'userTypeCreate',
          route: '/users/typeCreate/',
          icon: <GroupAdd />,
          label: 'Personel Tipi Ekle',
        },
        {
          id: 'userList',
          route: '/users/list/',
          icon: <AccountCircle />,
          label: 'Personel Listesi',
        },
      ],
    },
  ],
};

export default userSidebarMenuList;

import { TextField } from '@material-ui/core';
import { Category } from 'apps/crm/models';
import { Product } from 'apps/product';
import { Store } from 'apps/store';
import { Api } from 'core';
import { FilterRequest } from 'core/models';
import React from 'react';
import { FormAutocomplete, FormAutocompleteProps } from '../FormAutocomplete';

export type SearchProductAutoCompleteSelectProps = Omit<FormAutocompleteProps, 'options'>;

const SearchProductAutoCompleteSelect: React.FC<SearchProductAutoCompleteSelectProps> = ({
  onChange,
  ...autocompleteProps
}) => {
  const [productList, setProductList] = React.useState<Product[]>([]);
  const [inputValue, setInputValue] = React.useState<string>();
  const [isFirstLoad, setIsFirstLoad] = React.useState(true);
  const pullProductList = async (filterCategoryRequest?: FilterRequest) => {
    const response = await Api.Product.getProducts(filterCategoryRequest);
    if (response.data) setProductList(response.data.data);
    setIsFirstLoad(false);
  };

  React.useEffect(() => {
    pullProductList();
  }, []);

  const onQueryChage = (event, newInputValue: string) => {
    setInputValue(newInputValue);
    if (newInputValue && newInputValue.length > 0) {
      pullProductList({
        queryName: newInputValue,
      });
    } else {
      pullProductList();
    }
  };

  return (
    <FormAutocomplete
      {...autocompleteProps}
      multiple={autocompleteProps.multiple}
      size="small"
      options={productList}
      getOptionLabel={(option) => (option ? option.name : '')}
      onChange={onChange}
      filterSelectedOptions
      inputValue={inputValue}
      onInputChange={onQueryChage}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Ürün Ara"
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    />
  );
};
SearchProductAutoCompleteSelect.defaultProps = {
  multiple: true,
};
export default SearchProductAutoCompleteSelect;

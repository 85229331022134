import React from 'react';
import { FormDialog } from '../FormDialog';

interface MessageDialogProps {
  showDialog: boolean;
  onHideDialog: (event: any) => void;
  formId: string;
  title: string;
  children: any;
}

const MessageDialog: React.FC<MessageDialogProps> = ({ showDialog, onHideDialog, title, children, formId }) => {
  return (
    <FormDialog hideSave open={showDialog} handleClose={onHideDialog} formId={formId} title={title}>
      {children}
    </FormDialog>
  );
};

export default MessageDialog;

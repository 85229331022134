import React from 'react';
import { Card, CardHeader, CardContent, Grid, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'clsx';

interface InfoMiniCardProps {
  text: string;
  subText?: string;
  title: string;
  icon: JSX.Element;
  className?: string;
}

const useStyle = makeStyles(() => {
  return {
    miniInfoCard: {
      height: '178px',
      margin: '0px 10px 0px 10px',
      position: 'relative',
    },
    gridContainer: {
      height: '100%',
    },
  };
});

const InfoMiniCard: React.FC<InfoMiniCardProps> = ({ text, title, icon, className, subText }) => {
  const classes = useStyle();
  return (
    <Card className={classnames(classes.miniInfoCard, className)}>
      <CardHeader title={title} />
      <CardContent>
        <Grid container className={classes.gridContainer}>
          <Grid xs={12}>
            <Typography variant="h4">{text}</Typography>
          </Grid>
          <Grid xs={12} />
        </Grid>
        <Box width="100%" textAlign="right" right="8px" bottom="8px">
          {icon}
        </Box>
      </CardContent>

      <CardContent>
        <Grid container justify="flex-end">
          <Grid item xs={12}>
            <Typography variant="h6">{subText}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default InfoMiniCard;

import { combineReducers, getDefaultMiddleware, configureStore } from '@reduxjs/toolkit';
import { AuthApi, authReducer, setToken, setUserInfo } from 'apps/auth';
import { coreReducer } from 'core';
// import { persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
interface Store {
  store: any;
  rootReducer: any;
}

// TODO: Burasi dinamic olarak cekmeli storelari
const createStore = (): Store => {
  const rootReducer = combineReducers({
    core: coreReducer,
    auth: authReducer,
  });

  const middleware = [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
  ];

  /* const persistConfig = {
    key: 'root',
    storage,
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer); */
  const store = configureStore({
    reducer: rootReducer,
    middleware,
  });

  return {
    store,
    rootReducer,
  };
};

export default createStore;

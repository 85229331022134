import { Formik, Form } from 'formik';
import React from 'react';
import { TextField, Grid, CardContent, Card, CardActions, Box, MenuItem } from '@material-ui/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import * as yup from 'yup';
import {
  FormAutocomplete,
  FormHeader,
  FormSelect,
  FormTextField,
  ProductAutoCompleteSelect,
  SuccessButton,
  TranslatedDatePicker,
  TranslatedTimePicker,
  UploadButton,
} from 'core/components';
import { toDate } from 'date-fns';
import { Api, GenericMessages, showToast } from 'core';
import { useDispatch } from 'react-redux';
import { Campaign } from 'apps/campaign';

interface CreateOrUpdateCampaignFormProps {
  campaign?: Campaign;
}

const CreateOrUpdateCampaignForm: React.FC<CreateOrUpdateCampaignFormProps> = ({ campaign }) => {
  const [campaignPhoto, setCampaignPhoto] = React.useState();
  const [showLoading, setShowLoading] = React.useState(false);
  const campaignPhotoPreview = React.useRef(null);

  // const [showDuplicateMailAlert, setShowDuplicateMailAlert] = React.useState(false);
  const dispatch = useDispatch();
  const inputLabelProps = {
    shrink: true,
  };

  const campaignCreateSchema = yup.object({
    name: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
    isActive: yup.number().required(GenericMessages.CANNOT_BE_EMPTY),
    expirationDate: yup.date().required(GenericMessages.CANNOT_BE_EMPTY),
  });

  // const isMailDuplicate = async (email: string) => {
  //   const mail = email.replace('s;', '');
  //   // eslint-disable-next-line no-return-await
  //   return await Api.User.isMailDuplicate(mail);
  // };

  const onCampaignCreateSubmit = async (data, { resetForm }) => {
    setShowLoading(true);
    try {
      const campaignData: any = {
        languageId: 3,
        name: data.name,
        id: data.productId,
        storeId: 1,
        description: data.description,
        isActive: data.isActive,
        expirationDate: data.expirationDate,
        image: data.image,
        products: data.products,
      };
      if (campaignPhoto) {
        campaignData.imageFile = campaignPhoto;
      }
      const response = campaign
        ? await Api.Campaign.updateCampaign(campaign.id, campaignData)
        : await Api.Campaign.createCampaign(campaignData);
      if (response) {
        dispatch(
          showToast({
            message: `başarıyla ${campaign ? 'güncellendi!' : 'oluşturuldu!'}`,
          }),
        );
      }
    } catch (e) {
      console.log(e);
      dispatch(
        showToast({
          message: `  hata  oluştu`,
          severity: 'error',
        }),
      );
    }
    setShowLoading(false);
  };

  const onCampaignPhotoSelected = (setFieldValue) => (event) => {
    const { target } = event;

    if (target.files && target.files.length === 1) {
      const file = target.files[0];
      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const currentElem: any = campaignPhotoPreview.current;
          if (currentElem) {
            currentElem.src = e.target?.result;
            setFieldValue('image', e.target?.result);
          }
        };

        reader.readAsDataURL(file);

        setCampaignPhoto(file);
      }
    }
  };

  return (
    <Formik
      onSubmit={onCampaignCreateSubmit}
      validationSchema={campaignCreateSchema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        name: campaign ? campaign.name : '',
        description: campaign ? campaign.description : '',
        // eslint-disable-next-line no-nested-ternary
        isActive: campaign ? (campaign.isActive ? '1' : '0') : '',
        productId: campaign ? campaign.id : '',
        expirationDate: campaign ? campaign.expirationDate : '',
        products: campaign?.products
          ? campaign.products.map((product) => ({
              id: product.id,
              name: product.name,
            }))
          : [],
        image: campaign ? campaign.image : '',
      }}
    >
      {({ handleSubmit, handleChange, values, touched, isValid, errors, setFieldValue }) => (
        <form onSubmit={handleSubmit} id="createForm" noValidate>
          <Card>
            <CardContent>
              <Grid direction="row" container spacing={5}>
                <FormHeader title="Kampanya Bilgileri" />
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    required
                    label="Kampanya İsmi"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    placeholder="Kampanya İsmi"
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    error={errors.name != null}
                    helperText={errors.name}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TranslatedDatePicker
                    fullWidth
                    disablePast
                    openTo="date"
                    placeholder="Bitiş tarihini giriniz"
                    format="dd/MM/yyyy"
                    name="endDate"
                    value={values.expirationDate}
                    error={errors.expirationDate != null}
                    helperText={errors.expirationDate}
                    onChange={(date) => setFieldValue('expirationDate', toDate(date!))}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <FormSelect
                    onChange={handleChange}
                    name="isActive"
                    value={values.isActive}
                    fullWidth
                    placeholder="Kampanya Durumu"
                  >
                    <MenuItem value="1">Açık</MenuItem>
                    <MenuItem value="0">Kapalı</MenuItem>
                  </FormSelect>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <ProductAutoCompleteSelect
                    placeholder="Ürünler"
                    onChange={(event, products) => setFieldValue('products', products)}
                    error={errors.products != null}
                    helperText={errors.products as string}
                    value={values.products}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  {(campaignPhoto != null || values.image !== '') && (
                    <Grid container justify="center">
                      <img alt="" height={100} width={100} ref={campaignPhotoPreview} src={values.image} />
                    </Grid>
                  )}
                  <UploadButton
                    buttonProps={{
                      type: 'button',
                    }}
                    onChange={onCampaignPhotoSelected(setFieldValue)}
                  >
                    Kampanya Fotoğrafı Yükle
                  </UploadButton>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <FormTextField
                    multiline
                    rows={6}
                    error={errors.description != null}
                    onChange={handleChange}
                    value={values.description}
                    helperText={errors.description}
                    label="Açıklama"
                    placeholder="Açıklama"
                    variant="outlined"
                    name="description"
                  />
                </Grid>
              </Grid>
            </CardContent>

            <CardActions>
              <Box alignItems="center" justifyContent="flex-end" display="flex" className="w-100">
                <SuccessButton
                  type="submit"
                  startIcon={<SaveOutlinedIcon />}
                  showLoading={showLoading}
                  form="createForm"
                >
                  Kampanya Ekle
                </SuccessButton>
              </Box>
            </CardActions>
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default CreateOrUpdateCampaignForm;

import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { green, purple } from '@material-ui/core/colors';
import classnames from 'clsx';
import LoadingButton, { LoadingButtonProps } from '../LoadingButton/LoadingButton';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      color: theme.palette.getContrastText(purple[500]),
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
  };
});

export type SuccessButtonProps = LoadingButtonProps;

const SuccessButton: React.FC<SuccessButtonProps> = ({ showLoading, children, className, ...buttonProps }) => {
  const classes = useStyles();

  return (
    <LoadingButton {...buttonProps} className={classnames(classes.root, className)} showLoading={showLoading}>
      {children}
    </LoadingButton>
  );
};

export default SuccessButton;

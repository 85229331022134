// @ts-nocheck
import React from 'react';

import { Api, HideableLoadingSpinnerBox } from 'core';
import { Card, Grid, CardContent, Box, Button } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { FormHeader, DetailFieldLabel, FormDialog, TranslatedMaterialTable } from 'core/components';
import { Product } from '../../models';

const ProductDetailPage = () => {
  const { id } = useParams();
  const [product, setProduct] = React.useState<Product>();

  const pullproduct = async () => {
    const response = await Api.Product.getProduct(id);
    if (response.data) setProduct(response.data);
  };

  React.useEffect(() => {
    pullproduct();
  }, []);

  if (!product) {
    return <HideableLoadingSpinnerBox />;
  }
  return (
    <React.Fragment>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <FormHeader title="Ürün Bilgileri" />
            <Grid item lg={2} md={3} xs={12}>
              <DetailFieldLabel title="Ad ve Soyad" label={product.name} />
            </Grid>
            <Grid item lg={2} md={3} xs={12}>
              <DetailFieldLabel title="Model" label={product.model} />
            </Grid>
            <Grid item lg={2} md={3} xs={12}>
              <DetailFieldLabel title="Barkod" label={product.barcode} />
            </Grid>
            <Grid item lg={2} md={3} xs={12}>
              <DetailFieldLabel title="Stok" label={`${product.quantity}`} />
            </Grid>
            <Grid item lg={2} md={3} xs={12}>
              <DetailFieldLabel title="Fiyat" label={`${product.price} TL`} />
            </Grid>
            <Grid item lg={2} md={3} xs={12}>
              <DetailFieldLabel title="Alış Fiyat" label={`${product.purchasePrice} TL`} />
            </Grid>
            <Grid item lg={2} md={3} xs={12}>
              <DetailFieldLabel title="Vergi Oranı" label={`% ${product.taxRate}`} />
            </Grid>
            <Grid item lg={2} md={3} xs={12}>
              <DetailFieldLabel title="Tedarikçi İsmi" label={`${product.supplier?.name}`} />
            </Grid>
            <Grid item lg={2} md={3} xs={12}>
              <DetailFieldLabel title="Tedarikçi Telefon Numarası" label={`${product.supplier.gsm}`} />
            </Grid>
            <Grid item lg={2} md={3} xs={12}>
              <DetailFieldLabel title="Tedarikçi Email" label={`${product.supplier.email}`} />
            </Grid>
            <Grid item lg={2} md={3} xs={12}>
              <DetailFieldLabel title="Oluşturulma Tarihi" label={product.createdAt} />
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <FormHeader title="Resimler" />
          <Grid container spacing={3}>
            {product.images.map((image) => (
              <Grid item lg={2} md={3} xs={12} key={`pi${image.id}`}>
                <img src={image.image} width="100%" />
              </Grid>
            ))}
          </Grid>
        </CardContent>
        <CardContent />
      </Card>
    </React.Fragment>
  );
};

export default ProductDetailPage;

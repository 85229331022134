export default {
  CANNOT_BE_EMPTY: 'Bu alan boş olamaz',
  INVALID_EMAIL: 'Lütfen emailiniz doğru formatta olduğundan emin olunuz',
  PHONE_NO_LENGTH: 'Telefon numarası 10 haneli olmalıdır',
  MUST_BE_NUMBER: 'Değer nümerik olmalıdır',
  CANT_BE_LESS_THAN: (min: number) => `En az ${min} karakterli olmak zorundadır`,
  CANT_BE_MORE_THAN: (max: number) => `En fazla ${max} karakterli olmak zorundadır`,
  GOV_ID_LENGHT: 'Lütfen T.C. Kimlik numaranızı kontrol ediniz.',
  CANT_BE_LESS_THAN_NUM: (min: number) => `En az ${min} olmak zorundadır`,
  CANT_BE_MORE_THAN_NUM: (max: number) => `En fazla ${max} olmak zorundadır`,
  THE_FIELD_CANNOT_BE_EMPTY: (name: string) => `${name} alanı boş bırakılamaz!`,
};

import React, { useEffect } from 'react';
import { Box, Card, CardActions, CardContent, Chip, Grid, MenuItem } from '@material-ui/core';
import * as yup from 'yup';
import { Api, GenericMessages } from 'core';

import {
  CategoryAutoCompleteSelect,
  FormHeader,
  FormSelect,
  FormTextField,
  HideableLoadingSpinnerBox,
  LabeledCheckbox,
  ManufacturerAutoCompleteSelect,
  StoreAutoCompleteSelect,
  SuccessButton,
  SupplierAutoCompleteSelect,
  TranslatedMaterialTable,
} from 'core/components';
import { values } from 'lodash';
import { SourceTypeEnum } from 'apps/crm';
import { Formik } from 'formik';
import { Columns } from 'react-feather';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';

const MarketPlaceSyncProductPage = () => {
  const [showLoading, setShowLoading] = React.useState(false);
  const [sourceTypeId, setSourceTypeId] = React.useState<number>(1);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSourceTypeId(event.target.value as number);
  };
  const pullBatchData = async () => {
    setShowLoading(false);
  };
  const schema = yup.object({
    sourceId: yup.number().required(GenericMessages.CANNOT_BE_EMPTY),
    value: yup.number().required(GenericMessages.CANNOT_BE_EMPTY),
    manufacturerId: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
  });
  const onSubmit = async (values) => {
    // setSaveShowLoading(true);
    // try {
    //   const data = { ...values };
    //   delete data.assemblies;
    //   data.assemblyIds = values.assemblies.map((assembly) => assembly.id);
    //   if (id) {
    //     await Api.Design.updateAssmblyTheme(id, data);
    //   } else {
    //     await Api.Design.createAssmblyTheme(data);
    //   }
    //   dispatch(
    //     showToast({
    //       message: `Başarıyla ${assembly ? 'güncellendi!' : 'oluşturuldu!'}`,
    //     }),
    //   );
    //   history.push('/design/modules/assemblyTheme');
    // } catch (e) {
    //   dispatch(
    //     showToast({
    //       message: `Hata oluştu`,
    //       severity: 'error',
    //     }),
    //   );
    // }
    // setSaveShowLoading(false);
  };
  useEffect(() => {
    pullBatchData();
  }, [sourceTypeId]);

  return (
    <React.Fragment>
      <Card>
        <Formik
          onSubmit={onSubmit}
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            marketplaceId: '',
            sourceTypeId: '',
            supplierId: '',
            manufacturerId: '',
            isDelete: false,
            isTransfer: false,
            categoryId: '',
          }}
        >
          {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
            <form onSubmit={handleSubmit} id="form" noValidate>
              <CardContent>
                <Grid container spacing={3}>
                  <FormHeader title="Toplu Ürün İşlemleri" />
                  {sourceTypeId == SourceTypeEnum.SUPPLIER && (
                    <Grid item xs={7}>
                      <FormSelect
                        name="marketplaceId"
                        onChange={handleChange}
                        value={values.marketplaceId}
                        fullWidth
                        label="Pazaryeri"
                        placeholder="Lütfen Pazaryeri Seçiniz"
                      >
                        <MenuItem value="1">N11 </MenuItem> <MenuItem value="2">Trendyol </MenuItem>
                        <MenuItem value="3">Hepsiburada </MenuItem> <MenuItem value="4">Gittigidiyor</MenuItem>
                      </FormSelect>
                    </Grid>
                  )}
                  {values.marketplaceId && (
                    <Grid item xs={7}>
                      <FormSelect
                        name="sourceTypeId"
                        onChange={handleChange}
                        value={values.sourceTypeId}
                        fullWidth
                        placeholder="Seçenek Türü Seçiniz"
                      >
                        <MenuItem value="1">Tedarikçi Bazlı </MenuItem>
                        <MenuItem value="2">Kategori Bazlı </MenuItem> <MenuItem value="3">Üretici Bazlı </MenuItem>{' '}
                      </FormSelect>
                    </Grid>
                  )}
                  {values.sourceTypeId == '3' && (
                    <Grid item xs={6}>
                      <ManufacturerAutoCompleteSelect
                        style={{ marginTop: '8px' }}
                        fullWidth
                        value={values.manufacturerId}
                        onChange={(event, supp) => {
                          setFieldValue('manufacturerId', supp);
                        }}
                      />
                    </Grid>
                  )}
                  {values.sourceTypeId == '2' && (
                    <Grid item xs={6}>
                      <CategoryAutoCompleteSelect
                        placeholder="Kategori Seçiniz"
                        optionLabelName="name"
                        equValueName="id"
                        onChange={(event, category) => setFieldValue('categoryId', category)}
                        fullWidth
                      />
                    </Grid>
                  )}
                  {values.sourceTypeId == '1' && (
                    <Grid item xs={6}>
                      <SupplierAutoCompleteSelect
                        style={{ marginTop: '8px' }}
                        fullWidth
                        value={values.supplierId}
                        onChange={(event, supp) => {
                          setFieldValue('supplierId', supp);
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <LabeledCheckbox
                      error={errors.isTransfer != null}
                      helperText={errors.isTransfer}
                      label="Ürünleri Aktarma"
                      checked={values?.isTransfer}
                      onChange={(e) => {
                        setFieldValue('isTransfer', e.target.checked);

                        setFieldValue('isDelete', false);
                      }}
                      name="isTransfer"
                    />
                  </Grid>{' '}
                  <Grid item xs={12}>
                    <LabeledCheckbox
                      error={errors.isDelete != null}
                      helperText={errors.isDelete}
                      label="Ürünleri Silme"
                      checked={values?.isDelete}
                      onChange={(e) => {
                        setFieldValue('isDelete', e.target.checked);

                        setFieldValue('isTransfer', false);
                      }}
                      name="isDelete"
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Box alignItems="center" justifyContent="flex-end" display="flex" className="w-100">
                  <SuccessButton form="form" type="submit" startIcon={<SaveOutlinedIcon />} showLoading={showLoading}>
                    Kaydet
                  </SuccessButton>
                </Box>
              </CardActions>
            </form>
          )}
        </Formik>
      </Card>
    </React.Fragment>
  );
};

export default MarketPlaceSyncProductPage;

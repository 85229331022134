import { Column, Action } from 'material-table';
import React, { useEffect } from 'react';
import { Visibility as VisibilityIcon } from '@material-ui/icons';
import { TableState } from 'core/models';
import { Client } from 'apps/crm';
import { TranslatedMaterialTable } from 'core/components';
import { OrderApi } from 'apps/order/api';
import { Supplier } from 'apps/supplier/models';
import { Api } from 'core';
import { useHistory } from 'react-router-dom';

const SupplierListPage = () => {
  const [showLoading, setShowLoading] = React.useState(false);
  const history = useHistory();
  const [tableState] = React.useState<TableState<Supplier>>({
    columns: [
      { title: 'İsim', field: 'name', cellStyle: { whiteSpace: 'nowrap' } },

      { title: 'Adres', field: 'address', cellStyle: { whiteSpace: 'nowrap' } },
      { title: 'E-mail', field: 'email', cellStyle: { whiteSpace: 'nowrap' } },
      { title: 'Telefon', field: 'gsm', cellStyle: { whiteSpace: 'nowrap' } },
    ],
    actions: [
      {
        icon: 'edit',
        tooltip: 'Düzenle',
        onClick: (event, rowData) => {
          const supplier = rowData as Supplier;
          history.push(`/orders/supplier/update/${supplier.id}`);
        },
      },
      {
        tooltip: 'Detaylar',
        icon: (props) => <VisibilityIcon {...props} />,
        onClick: (event, rowData) => {
          const supplier = rowData as Supplier;
          history.push(`/orders/supplier/detail/${supplier.id}`);
        },
      },
    ],
  });
  return (
    <TranslatedMaterialTable
      isLoading={showLoading}
      actions={tableState.actions}
      columns={tableState.columns}
      data={(q) =>
        new Promise((resolve) => {
          setShowLoading(true);
          Api.Supplier.getSuppliers({
            page: q.page,
          })
            .then((response) => response.data)
            .then((data) => {
              if (data) {
                resolve({
                  page: q.page,
                  totalCount: data.totalCount,
                  data: data.data,
                });
              }
            });
          setShowLoading(false);
        })
      }
      title=""
    />
  );
};
export default SupplierListPage;

/* eslint-disable import/no-anonymous-default-export */
import { ecmsClient } from 'core';
import { ApiResponse } from 'core/models';

export default {
  URL_GET_YEARS: '/vehicleData/getYears',
  URL_GET_BRANDS: '/vehicleData/getBrands',
  URL_GET_MODELS: '/vehicleData/getModels',
  URL_MATCH_DATA: '/vehicleData/matchData',
  URL_GET_MATCHED_DATA: '/vehicleData/getMatchedData',
  URL_DELETE_MATCHED_DATA: '/vehicleData/deleteData',

  async getYears(): Promise<ApiResponse<number[]>> {
    const response = await ecmsClient.get(this.URL_GET_YEARS);
    return response.data;
  },
  async getBrands(year: any): Promise<ApiResponse<Array<any>>> {
    const response = await ecmsClient.post(this.URL_GET_BRANDS, { year });
    return response.data;
  },
  async getModels(year: number, brand: string): Promise<ApiResponse<Array<any>>> {
    const response = await ecmsClient.post(this.URL_GET_MODELS, { year, brand });
    return response.data;
  },

  async getDataFromProduct(productId: number): Promise<ApiResponse<any[]>> {
    const response = await ecmsClient.post(this.URL_GET_MATCHED_DATA, { productId });
    return response.data;
  },

  async deleteDataFromProduct(dataId: number): Promise<ApiResponse<any>> {
    const response = await ecmsClient.put(this.URL_DELETE_MATCHED_DATA, { dataId });
    return response.data;
  },

  async matchDataWithProducts(data): Promise<ApiResponse<any>> {
    const response = await ecmsClient.post(this.URL_MATCH_DATA, data);
    return response.data;
  },
};

import React from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { showToast } from 'core/store';
import { FormTextField, LoadingButton } from 'core/components';

const CreateUserTypeForm = () => {
  const [showLoading, setShowLoading] = React.useState(false);
  const [userType, setUserType] = React.useState('');
  const dispatch = useDispatch();

  const sendNewUserType = async () => {
    // if (userType !== '') {
    //   setShowLoading(true);
    //   await UserApi.addUserType(userType);
    //   dispatch(
    //     showToast({
    //       message: `Kullanıcı türü başarıyla oluşturuldu!`,
    //       severity: 'success',
    //     }),
    //   );
    //   setShowLoading(false);
    //   setUserType('');
    // }
  };
  return (
    <Grid container xs={12}>
      <Grid item xs={10} md={6} lg={3}>
        <FormTextField
          label="Yeni Tür"
          placeholder="Örn. Satış Asistanı"
          value={userType}
          onChange={(e) => {
            setUserType(e.target.value);
          }}
        />
      </Grid>
      <Grid item xs={2}>
        <LoadingButton showLoading={showLoading} color="primary" onClick={sendNewUserType}>
          Ekle
        </LoadingButton>
      </Grid>
    </Grid>
  );
};

export default CreateUserTypeForm;

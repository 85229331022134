import MaterialTable, { MaterialTableProps } from 'material-table';
import React from 'react';
import { tr } from 'date-fns/locale';
// eslint-disable-next-line @typescript-eslint/ban-types
export type TranslatedMaterialTableProps<T extends object> = MaterialTableProps<T>;

// eslint-disable-next-line @typescript-eslint/ban-types
const TranslatedMaterialTable = <T extends object>(props: TranslatedMaterialTableProps<T>) => {
  const { options, ...tableProps } = props;

  const newOptions = options ? { ...options } : {};
  newOptions.pageSize = newOptions.pageSize || 15;
  newOptions.padding = newOptions.padding || 'default';
  newOptions.maxBodyHeight = newOptions.maxBodyHeight || '65vh';
  newOptions.emptyRowsWhenPaging = newOptions.emptyRowsWhenPaging || false;
  newOptions.pageSizeOptions = [5, 10, 15, 20];
  return (
    <MaterialTable
      {...tableProps}
      options={newOptions}
      localization={{
        pagination: {
          labelDisplayedRows: '{count} elemanın {from}-{to} aralığı',
          labelRowsSelect: ' satır',
        },
        toolbar: {
          nRowsSelected: '{0} satır seçildi',
          searchPlaceholder: 'Arama',
          searchTooltip: 'Arama',
        },
        header: {
          actions: 'Eylemler',
        },
        body: {
          dateTimePickerLocalization: tr,
          emptyDataSourceMessage: 'Gösterilecek kayıt yok',
          filterRow: {
            filterTooltip: 'Filtre',
          },
          addTooltip: 'Ekle',
          deleteTooltip: 'Sil',
          editTooltip: 'Düzenle',
          editRow: {
            saveTooltip: 'Kaydet',
            cancelTooltip: 'İptal',
            deleteText: 'Sil',
          },
        },
      }}
    />
  );
};

export default TranslatedMaterialTable;

import { Formik, Form } from 'formik';
import React from 'react';
import {
  TextField,
  Grid,
  CardContent,
  Card,
  CardActions,
  Box,
  FormControl,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { FormHeader, SuccessButton, UploadButton } from 'core/components';
import GenericMessages from 'core/GenericMessages';
import ColorPicker from 'material-ui-color-picker';
import { useParams } from 'react-router-dom';
import { CreateOrUpdateStore, Store } from 'apps/store/models';
import { Api, showToast } from 'core';

const CreateStorePage = () => {
  const [showLoading, setShowLoading] = React.useState(false);
  const [storeLogo, setStoreLogo] = React.useState();
  const [faviconLogo, setFaviconLogo] = React.useState();
  const faviconLogoPreview = React.useRef(null);
  const storeLogoPreview = React.useRef(null);
  const [store, setStore] = React.useState<Store>();
  const { id }: any = useParams();
  const dispatch = useDispatch();
  const inputLabelProps = {
    shrink: true,
  };

  const schema = yup.object({
    address: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
    eArchiveInvoicePrefix: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
    orderPrefix: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
    invoicePrefix: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
    city: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
    email: yup.string().required(GenericMessages.CANNOT_BE_EMPTY).email(GenericMessages.INVALID_EMAIL),
    name: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
    domain: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
    gsm: yup
      .string()
      .test('empty', GenericMessages.CANNOT_BE_EMPTY, (val: any) => val && val.toString().length > 0)
      .test('len', GenericMessages.PHONE_NO_LENGTH, (val: any) => val && val.toString().length === 10),
  });

  const onStoreCreateSubmit = async (values, { resetForm }) => {
    setShowLoading(true);
    try {
      const storeData: CreateOrUpdateStore = {
        name: values?.name,
        domain: values?.domain,
        address: values?.address,
        city: values?.city,
        gsm: values?.gsm,
        email: values?.email,
        eArchiveInvoicePrefix: values?.eArchiveInvoicePrefix,
        orderPrefix: values?.orderPrefix,
        invoicePrefix: values?.invoicePrefix,
        geolocation: values?.geolocation,
        hexColorCode: values?.hexColorCode,
        logo: storeLogo || '',
        favicon: faviconLogo || '',
        isActive: values?.isActive,
      };
      if (id) await Api.Store.updateStore(id, storeData);
      else await Api.Store.createStore(storeData);
      dispatch(
        showToast({
          message: `başarıyla ${id ? 'güncellendi!' : 'oluşturuldu!'}`,
        }),
      );
      setShowLoading(false);
    } catch (e) {
      dispatch(
        showToast({
          message: `Hata oluştu`,
          severity: 'error',
        }),
      );
      setShowLoading(false);
    }
  };
  const onLogoSelected = (setFieldValue) => (event) => {
    const { target } = event;

    if (target.files || target.files.length === 1) {
      const file = target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        const currentElem: any = storeLogoPreview.current;
        if (currentElem) {
          currentElem.src = e.target?.result;
          setFieldValue('logo', e.target?.result);
        }
      };

      reader.readAsDataURL(file);
      setStoreLogo(file);
    }
  };
  const onFaviconSelected = (setFieldValue) => (event) => {
    const { target } = event;

    if (target.files || target.files.length === 1) {
      const file = target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        const currentElem: any = faviconLogoPreview.current;
        if (currentElem) {
          currentElem.src = e.target?.result;
          setFieldValue('favicon', e.target?.result);
        }
      };

      reader.readAsDataURL(file);
      setFaviconLogo(file);
    }
  };

  const pullStore = async () => {
    const response = await Api.Store.getStore(id);
    if (response.data) setStore(response.data);
  };
  React.useEffect(() => {
    pullStore();
  }, []);

  if (id && !store) {
    return null;
  }

  return (
    <Formik
      onSubmit={onStoreCreateSubmit}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        name: store?.name,
        domain: store?.domain,
        address: store?.address,
        city: store?.city,
        gsm: store?.gsm,
        email: store?.email,
        eArchiveInvoicePrefix: store?.eArchiveInvoicePrefix,
        orderPrefix: store?.orderPrefix,
        invoicePrefix: store?.invoicePrefix,
        geolocation: store?.geolocation,
        hexColorCode: store?.hexColorCode,
        logo: store?.logo,
        favicon: store?.favicon,
        isActive: store?.isActive,
      }}
    >
      {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
        <Form onSubmit={handleSubmit} id="form" noValidate>
          <Card>
            <CardContent>
              <Grid direction="row" container spacing={5}>
                <FormHeader title="Mağaza Bilgileri" />
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    required
                    label="Mağaza İsmi"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    placeholder="İsim"
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    error={errors.name != null}
                    helperText={errors.name}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    label="Domain"
                    placeholder="Alan Adı"
                    variant="outlined"
                    name="domain"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    onChange={handleChange}
                    value={values.domain}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    required
                    error={errors.email != null}
                    label="E-mail"
                    placeholder="E-mail"
                    type="email"
                    variant="outlined"
                    name="email"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    onChange={handleChange}
                    value={values.email}
                    helperText={errors.email}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    error={errors.geolocation != null}
                    label="Enlem-Boylam"
                    placeholder="(Enlem-Boylam)"
                    variant="outlined"
                    name="geolocation"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    onChange={handleChange}
                    value={values.geolocation}
                    helperText={errors.geolocation}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    required
                    name="gsm"
                    label="Telefon numarası"
                    placeholder="Telefon numarası"
                    variant="outlined"
                    InputLabelProps={inputLabelProps}
                    fullWidth
                    margin="dense"
                    onChange={handleChange}
                    value={values.gsm}
                    error={errors.gsm != null}
                    helperText={errors.gsm}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    required
                    name="city"
                    label="Şehir"
                    placeholder="Şehir"
                    variant="outlined"
                    InputLabelProps={inputLabelProps}
                    fullWidth
                    margin="dense"
                    onChange={handleChange}
                    value={values.city}
                    error={errors.city != null}
                    helperText={errors.city}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    name="address"
                    label="Adres"
                    placeholder="Adres"
                    variant="outlined"
                    InputLabelProps={inputLabelProps}
                    fullWidth
                    margin="dense"
                    onChange={handleChange}
                    value={values.address}
                    error={errors.address != null}
                    helperText={errors.address}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <ColorPicker
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    error={errors.hexColorCode != null}
                    helperText={errors.hexColorCode}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ zIndex: 999 }}
                    label="Renk"
                    placeholder={values.hexColorCode ? `Renk ${values.hexColorCode}` : `Renk`}
                    name="hexColorCode"
                    value={values.hexColorCode}
                    onChange={(color) => {
                      setFieldValue('hexColorCode', color);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormControlLabel
                      value="end"
                      control={
                        <Switch
                          color="primary"
                          checked={values.isActive}
                          onChange={(e) => setFieldValue('isActive', e.target.checked)}
                        />
                      }
                      label="Durumu Seçiniz (Aktif veya Pasif)"
                      labelPlacement="end"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  {(storeLogo != null || values.logo !== '') && (
                    <Grid container justify="center">
                      <img src={values.logo} alt="" height={100} width={100} ref={storeLogoPreview} />
                    </Grid>
                  )}
                  <UploadButton
                    buttonProps={{
                      type: 'button',
                    }}
                    onChange={onLogoSelected(setFieldValue)}
                  >
                    Mağaza Logosu Yükle
                  </UploadButton>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  {(faviconLogo != null || values.favicon !== '') && (
                    <Grid container justify="center">
                      <img src={values.favicon} alt="" height={100} width={100} ref={faviconLogoPreview} />
                    </Grid>
                  )}
                  <UploadButton
                    buttonProps={{
                      type: 'button',
                    }}
                    onChange={onFaviconSelected(setFieldValue)}
                  >
                    Fav Logosu Yükle
                  </UploadButton>
                </Grid>
                <FormHeader title="Sistemsel Bilgileri" />
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    error={errors.orderPrefix != null}
                    label="Sipariş Ön Eki"
                    placeholder="Sipariş Ön Eki"
                    variant="outlined"
                    name="orderPrefix"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    onChange={handleChange}
                    value={values.orderPrefix}
                    helperText={errors.orderPrefix}
                  />
                </Grid>
                <FormHeader title="Fatura Bilgileri" />
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    error={errors.orderPrefix != null}
                    label="Fatura Eki"
                    placeholder="Fatura Eki"
                    variant="outlined"
                    name="invoicePrefix"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    onChange={handleChange}
                    value={values.invoicePrefix}
                    helperText={errors.invoicePrefix}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    error={errors.eArchiveInvoicePrefix != null}
                    label="E-Arşiv Fatura Ön Eki"
                    placeholder="E-Arşiv Fatura Ön Eki"
                    variant="outlined"
                    name="eArchiveInvoicePrefix"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    onChange={handleChange}
                    value={values.eArchiveInvoicePrefix}
                    helperText={errors.eArchiveInvoicePrefix}
                  />
                </Grid>
              </Grid>
            </CardContent>

            <CardActions>
              <Box alignItems="center" justifyContent="flex-end" display="flex" className="w-100">
                <SuccessButton form="form" type="submit" startIcon={<SaveOutlinedIcon />} showLoading={showLoading}>
                  {id ? `Mağaza Güncelle` : 'Mağaza Oluştur'}
                </SuccessButton>
              </Box>
            </CardActions>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default CreateStorePage;

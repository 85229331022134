import React from 'react';
import { Button } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { GlobalStyles } from 'core/components';
import './App.css';
import createStore from 'store/store';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { tr } from 'date-fns/locale';
import App from 'App';
import ReactDOM from 'react-dom';
import { MainLayout, theme } from './core';
import * as serviceWorker from './serviceWorker';

const store = createStore();

// @ts-ignore
document.title = process.env.REACT_APP_TITLE;

ReactDOM.render(
  <BrowserRouter>
    <React.StrictMode>
      <Provider store={store.store}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={tr}>
            <GlobalStyles />
            <App />
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import {
  FormControlProps,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
  Box,
  OutlinedInput,
} from '@material-ui/core';
import React from 'react';
import uniqueId from 'lodash/uniqueId';

export interface FormSelectProps extends FormControlProps {
  name?: string;
  onChange?: (event) => void;
  value?: any;
  formId?: string;
  error?: boolean;
  label?: string;
  helperText?: string;
  placeholder?: string;
  startAdornment?: React.ReactNode;
  showLoading?: boolean;
}

const FormSelect: React.FC<FormSelectProps> = ({
  name,
  onChange,
  value,
  formId,
  error,
  label,
  helperText,
  children,
  startAdornment,
  placeholder,
  showLoading,
  ...formControlProps
}) => {
  const [_formID, _] = React.useState(formId || uniqueId('formID'));

  if (showLoading) {
    return (
      <FormControl
        variant={formControlProps.variant || 'outlined'}
        margin={formControlProps.margin || 'dense'}
        {...formControlProps}
      >
        <InputLabel id={`${_formID}Label`} shrink>
          <Box color="text.disabled">{label}</Box>
        </InputLabel>
        <Select
          labelId={`${_formID}Label`}
          value="loading"
          input={<OutlinedInput label={label} notched labelWidth={0} name={name} id={`${_formID}Outlined`} />}
        >
          <MenuItem selected value="loading" disabled>
            <Box color="text.disabled">Yükleniyor...</Box>
          </MenuItem>
        </Select>
        <FormHelperText error={error}>{helperText}</FormHelperText>
      </FormControl>
    );
  }
  return (
    <FormControl
      variant={formControlProps.variant || 'outlined'}
      margin={formControlProps.margin || 'dense'}
      {...formControlProps}
    >
      <InputLabel id={`${_formID}Label`} shrink>
        <Box color="text.disabled">{label}</Box>
      </InputLabel>
      <Select
        id={formId}
        labelId={`${_formID}Label`}
        value={value || ''}
        onChange={onChange}
        error={error}
        name={name}
        label={label}
        startAdornment={startAdornment}
        displayEmpty
        input={<OutlinedInput label={label} notched labelWidth={0} name={name} id={`${_formID}Outlined`} />}
      >
        {placeholder && (
          <MenuItem disabled selected value="">
            <Box color="text.disabled">{placeholder}</Box>
          </MenuItem>
        )}
        {children}
      </Select>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default FormSelect;

import { Formik, Form } from 'formik';
import React from 'react';
import { TextField, Grid, CardContent, Card, CardActions, Box, MenuItem } from '@material-ui/core';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import * as yup from 'yup';
import { FormHeader, SuccessButton, FormSelect } from 'core/components';
import GenericMessages from 'core/GenericMessages';

interface CreateWarehouseFormProps {
  formId: string;
}

const CreateWarehouseForm: React.FC<CreateWarehouseFormProps> = ({ formId }) => {
  const [showLoading, setShowLoading] = React.useState(false);

  const inputLabelProps = {
    shrink: true,
  };

  const schema = yup.object({
    address: yup.string().required('Adres Alanı Boş olamaz'),
    name: yup.string().required('Bu Boş Olamaz'),
  });

  const onCreateWarehouse = async (values, { resetForm }) => {
    setShowLoading(true);
    try {
      console.log('hh');
    } catch (e) {
      setShowLoading(false);
    }
  };

  return (
    <Formik
      onSubmit={onCreateWarehouse}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        name: '',
        managerId: '',
        address: '',
        location: '',
        desi: '',
      }}
    >
      {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
        <Form onSubmit={handleSubmit} id={formId} noValidate>
          <Card>
            <CardContent>
              <Grid direction="row" container spacing={5}>
                <FormHeader title="Depo Bilgileri" />
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    required
                    label="Adı"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    placeholder="Adı"
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    error={errors.name != null}
                    helperText={errors.name}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormSelect
                    onChange={handleChange}
                    name="managerId"
                    value={values.managerId}
                    fullWidth
                    placeholder="Yetkili Kişi Seçiniz"
                  >
                    <MenuItem value="1">Kadir</MenuItem>
                    <MenuItem value="2">Baymak</MenuItem>
                    <MenuItem value="3">Buğra Çimendur</MenuItem>
                  </FormSelect>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    required
                    name="desi"
                    label="Desi Kapasitesi"
                    placeholder="Desi Kapasitesi"
                    variant="outlined"
                    InputLabelProps={inputLabelProps}
                    fullWidth
                    margin="dense"
                    onChange={handleChange}
                    value={values.desi}
                    error={errors.desi != null}
                    helperText={errors.desi}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    required
                    name="location"
                    label="Lokasyon"
                    placeholder="Lokasyon"
                    variant="outlined"
                    InputLabelProps={inputLabelProps}
                    fullWidth
                    margin="dense"
                    onChange={handleChange}
                    value={values.location}
                    error={errors.location != null}
                    helperText={errors.location}
                  />
                </Grid>

                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    multiline
                    rows={4}
                    name="address"
                    label="Adres"
                    placeholder="Adres"
                    variant="outlined"
                    InputLabelProps={inputLabelProps}
                    fullWidth
                    margin="dense"
                    onChange={handleChange}
                    value={values.address}
                    error={errors.address != null}
                    helperText={errors.address}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default CreateWarehouseForm;

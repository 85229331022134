import { ListItemIcon, ListItemText, Collapse, List } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import React from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { toggleMobileDrawer } from 'core/store';
import { MenuBlockChildrenInfo } from 'core/types';

export interface MenuBlockItemProps {
  menuBlockChildrenInfo: MenuBlockChildrenInfo;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const MenuBlockItem: React.FC<MenuBlockItemProps> = ({ menuBlockChildrenInfo, className }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const hasChild = menuBlockChildrenInfo.child && menuBlockChildrenInfo.child.length > 0;
  // const userInfo: UserInfo = useSelector((state: any) => state.auth.userInfo);

  const classes = useStyles();

  // TODO: Recursive olmali alt menunun alti olursa bu kod ise yaramaz
  const hasMatch =
    hasChild && menuBlockChildrenInfo.child
      ? menuBlockChildrenInfo.child.some((child) => {
          if (!(child.child && child.child.length > 0)) {
            const subMenuMatchOption = {
              path: child.route,
              exact: true,
              strict: false,
            };
            return matchPath(location.pathname, subMenuMatchOption) !== null;
          }
          return false;
        })
      : false;

  const [isSubmenuOpen, setSubmenuOpen] = React.useState(hasMatch);

  const RouterLink = React.useMemo(
    () =>
      React.forwardRef((linkProps, ref: any) => (
        <Link
          ref={ref}
          to={menuBlockChildrenInfo.route || ''}
          {...linkProps}
          onClick={() => dispatch(toggleMobileDrawer(false))}
        />
      )),
    [menuBlockChildrenInfo.route],
  );

  /* if (menuBlockChildrenInfo.permissions) {
    if (!userInfo.hasPermissions(menuBlockChildrenInfo.permissions, menuBlockChildrenInfo.permissionCheckType)) {
      return null;
    }
  } */

  if (!hasChild) {
    if (menuBlockChildrenInfo.route) {
      const matchOption = {
        path: menuBlockChildrenInfo.route,
        exact: true,
        strict: false,
      };
      const match = matchPath(location.pathname, matchOption) !== null;
      return (
        <React.Fragment>
          <ListItem component={RouterLink} button selected={match} className={className}>
            {menuBlockChildrenInfo.icon && <ListItemIcon>{menuBlockChildrenInfo.icon}</ListItemIcon>}
            <ListItemText primary={menuBlockChildrenInfo.label} />
          </ListItem>
        </React.Fragment>
      );
    }
    const onClick = () => {
      /* if (menuBlockChildrenInfo.onClick) {
        menuBlockChildrenInfo.onClick({ dispatch });
      } */
    };
    return (
      <React.Fragment>
        <ListItem button className={className} onClick={onClick}>
          {menuBlockChildrenInfo.icon && <ListItemIcon>{menuBlockChildrenInfo.icon}</ListItemIcon>}
          <ListItemText primary={menuBlockChildrenInfo.label} />
        </ListItem>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <ListItem button className={className} onClick={() => setSubmenuOpen(!isSubmenuOpen)}>
        <ListItemIcon>{menuBlockChildrenInfo.icon}</ListItemIcon>
        <ListItemText primary={menuBlockChildrenInfo.label} />
        {isSubmenuOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      {menuBlockChildrenInfo.child?.map((menuBlockChildrenInfoChild) => (
        <Collapse in={isSubmenuOpen} timeout="auto" unmountOnExit key={menuBlockChildrenInfoChild.id}>
          <List component="div" disablePadding>
            <MenuBlockItem menuBlockChildrenInfo={menuBlockChildrenInfoChild} className={classes.nested} />
          </List>
        </Collapse>
      ))}
    </React.Fragment>
  );
};

export default React.memo(MenuBlockItem);

import React, { useEffect } from 'react';
import { Column, MTableToolbar } from 'material-table';
import { Group as GroupIcon, Receipt, ShowChart } from '@material-ui/icons';
import { Avatar, Chip, Grid, Typography } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import {
  HideableLoadingSpinnerBox,
  InfoMiniCard,
  StoreAutoCompleteSelect,
  TranslatedMaterialTable,
} from 'core/components';
import { Api } from 'core';
import { Store } from 'apps/store/models';
import { Dashboard } from '../models';

interface TableState {
  columns: Column<any>[];
  data: any[];
  actions: any[];
}

const useStyle = makeStyles(() => {
  return {
    countCard: {
      color: '#e17055',
      fontSize: '50px',
      float: 'right',
    },
    root: {
      background: 'linear-gradient(45deg, #af8baf 30%, #968df4 92%)',
      border: 2,
      borderRadius: 5,
      color: 'white',
      height: 60,
      width: 220,
    },
  };
});

const DashboardPage = () => {
  const classes = useStyle();
  const [showLoading, setShowLoading] = React.useState(false);
  const [store, setStore] = React.useState<Store>();

  const [dashboard, setDashboard] = React.useState<Dashboard | null>();

  const tableState: TableState = {
    columns: [
      { title: 'Sipariş Numarası', render: (value) => value.orderId },
      { title: 'Sipariş Kodu', render: (value) => value.orderCode },
      { title: 'Müşteri', render: (value) => value.clientName },
      { title: 'Fiyat', render: (value) => value.amount },
    ],
    data: [],
    actions: [],
  };
  const pullDashboardData = async () => {
    setShowLoading(true);

    const response = store
      ? await Api.Dashboard.getDashboardData(store.id)
          .then((apiResponse) => apiResponse.data)
          .then((dashboardData) => {
            if (dashboardData) setDashboard(dashboardData);
            setShowLoading(false);
          })
      : await Api.Dashboard.getDashboardDefaultData()
          .then((apiResponse) => apiResponse.data)
          .then((dashboardData) => {
            if (dashboardData) setDashboard(dashboardData);
            setShowLoading(false);
          });
  };

  useEffect(() => {
    pullDashboardData();
  }, [store]);
  if (showLoading) {
    return <HideableLoadingSpinnerBox />;
  }
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          {store && <Chip label={store?.name} className={classes.root} avatar={<Avatar src={store?.favicon} />} />}
        </Grid>
        <Grid item xs={2}>
          <StoreAutoCompleteSelect
            placeholder={store ? store.name : 'Mağaza Seçiniz'}
            optionLabelName={store ? store.name : 'name'}
            equValueName="id"
            onChange={(event, store) => {
              setStore(store);
            }}
            fullWidth
            multiple={false}
          />
        </Grid>
      </Grid>
      {dashboard && (
        <Grid container spacing={2} justify="center">
          <Grid item xs={12} md={4}>
            <InfoMiniCard
              title="Aylık Müşteri sayısı"
              text={dashboard.totalMonthlyClient ? dashboard.totalMonthlyClient.toString() : '0'}
              icon={<GroupIcon className={classes.countCard} />}
              subText={`Toplam Müşteri Sayısı: ${
                dashboard.totalClient.toString() ? dashboard.totalClient.toString() : '0'
              }`}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <InfoMiniCard
              title=" Aylık Sipariş Sayısı"
              text={dashboard.totalMonthlyOrder ? dashboard.totalMonthlyOrder.toString() : '0'}
              icon={<Receipt className={classes.countCard} />}
              subText={`Toplam Sipariş Sayısı: ${
                dashboard.totalOrder.toString() ? dashboard.totalOrder.toString() : '0'
              }`}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InfoMiniCard
              title="Stok Sayısı"
              text={dashboard.stock ? dashboard.stock.toLocaleString() : '0'}
              icon={<ShowChart className={classes.countCard} />}
            />
          </Grid>
        </Grid>
      )}
      {dashboard && dashboard.lastFiveOrder.length != null && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TranslatedMaterialTable
              title="Son Gelen Siparişler"
              columns={tableState.columns}
              data={dashboard.lastFiveOrder}
              actions={tableState.actions}
              options={{ search: false }}
              components={{
                Toolbar: (props) => (
                  <React.Fragment>
                    <MTableToolbar {...props} />
                  </React.Fragment>
                ),
              }}
            />
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default DashboardPage;

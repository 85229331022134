import { Formik, Form } from 'formik';
import React from 'react';
import { TextField, Grid, CardContent, Card, CardActions, Box, MenuItem } from '@material-ui/core';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { FormHeader, FormSelect, SuccessButton } from 'core/components';
import GenericMessages from 'core/GenericMessages';

const GittiGidiyorSettings = () => {
  const [showLoading, setShowLoading] = React.useState(false);

  const dispatch = useDispatch();
  const inputLabelProps = {
    shrink: true,
  };

  const schema = yup.object({
    apiKey: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
    apiSecret: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
    loginUser: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
    loginPassword: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
    developerId: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
  });

  const onGittiGidiyorSubmit = async (values, { resetForm }) => {
    setShowLoading(true);
    try {
      console.log('hh');
    } catch (e) {
      setShowLoading(false);
    }
  };

  return (
    <Formik
      onSubmit={onGittiGidiyorSubmit}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        apiKey: '',
        apiSecret: '',
        loginUser: '',
        loginPassword: '',
        developerId: '',
      }}
    >
      {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
        <Form onSubmit={handleSubmit} id="form" noValidate>
          <Card>
            <CardContent>
              <Grid direction="row" container spacing={5}>
                <FormHeader title="GittiGidiyor Ayarları" />
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    required
                    label="Api Key"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    placeholder="Api Key"
                    name="apiKey"
                    onChange={handleChange}
                    value={values.apiKey}
                    error={errors.apiKey != null}
                    helperText={errors.apiKey}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    required
                    label="Api Secret"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    placeholder="Api Secret"
                    name="apiSecret"
                    onChange={handleChange}
                    value={values.apiSecret}
                    error={errors.apiSecret != null}
                    helperText={errors.apiSecret}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    required
                    label="Login User"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    placeholder="Login User"
                    name="loginUser"
                    onChange={handleChange}
                    value={values.loginUser}
                    error={errors.loginUser != null}
                    helperText={errors.loginUser}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    required
                    label="Login Password"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    placeholder="Login Password"
                    name="loginPassword"
                    onChange={handleChange}
                    value={values.loginPassword}
                    error={errors.loginPassword != null}
                    helperText={errors.loginPassword}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    required
                    label="Developer Id"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    placeholder="Developer Id"
                    name="developerId"
                    onChange={handleChange}
                    value={values.developerId}
                    error={errors.developerId != null}
                    helperText={errors.developerId}
                  />
                </Grid>
              </Grid>
            </CardContent>

            <CardActions>
              <Box alignItems="center" justifyContent="flex-end" display="flex" className="w-100">
                <SuccessButton form="form" type="submit" startIcon={<SaveOutlinedIcon />} showLoading={showLoading}>
                  Ayarları Kaydet
                </SuccessButton>
              </Box>
            </CardActions>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default GittiGidiyorSettings;

import { Formik } from 'formik';
import React from 'react';
import { Grid, CardContent, Card, CardActions, Box, FormControl, FormControlLabel, Switch } from '@material-ui/core';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { FormHeader, FormTextField, HideableLoadingSpinnerBox, SaveButton } from 'core/components';
import { Api, GenericMessages, showToast } from 'core';
import { useHistory, useParams } from 'react-router-dom';
import { Client } from 'apps/crm';

const CRMCreateOrUpdateClientPage = () => {
  const [showLoading, setShowLoading] = React.useState(false);
  const [showSaveLoading, setSaveShowLoading] = React.useState(false);
  const dispatch = useDispatch();

  const { id }: any = useParams();
  const [client, setClient] = React.useState<Client>();
  const history = useHistory();

  const pullClient = async () => {
    if (id) {
      setShowLoading(true);
      const response = await Api.Crm.getClient(id);
      if (response.data) {
        setClient(response.data);
      }
      setShowLoading(false);
    }
  };
  React.useEffect(() => {
    pullClient();
  }, [id]);
  const schema = yup.object({
    email: yup.string().email(GenericMessages.INVALID_EMAIL).required(GenericMessages.CANNOT_BE_EMPTY),
    name: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
    gsm: yup
      .string()
      .test('empty', GenericMessages.CANNOT_BE_EMPTY, (val: any) => val && val.toString().length > 0)
      .test('len', GenericMessages.PHONE_NO_LENGTH, (val: any) => val && val.toString().length === 10),
  });

  const onClientSubmit = async (values) => {
    setSaveShowLoading(true);
    try {
      if (id) {
        await Api.Crm.updateClient(id, values);
      } else {
        await Api.Crm.createClient(values);
      }
      dispatch(
        showToast({
          message: `Başarıyla ${client ? 'güncellendi!' : 'oluşturuldu!'}`,
        }),
      );
      history.push('/design/modules/clientTheme');
    } catch (e) {
      dispatch(
        showToast({
          message: `Hata oluştu`,
          severity: 'error',
        }),
      );
    }
    setSaveShowLoading(false);
  };
  if (showLoading) {
    return <HideableLoadingSpinnerBox />;
  }
  return (
    <React.Fragment>
      <Card>
        <Formik
          onSubmit={onClientSubmit}
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            name: client?.name ? client.name : '',
            email: client?.email ? client.email : '',
            gsm: client?.gsm ? client.gsm : '',
            isB2b: client?.isB2b ? client.isB2b : false,
          }}
        >
          {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
            <form onSubmit={handleSubmit} id="form" noValidate>
              <CardContent>
                <Grid direction="row" container spacing={5}>
                  <FormHeader title={client ? 'Müşteri Güncelleme' : 'Müşteri Ekleme'} />
                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormControlLabel
                        value="end"
                        control={
                          <Switch
                            color="primary"
                            checked={values.isB2b}
                            onChange={(e) => setFieldValue('isB2b', e.target.checked)}
                          />
                        }
                        label="Durumu Seçiniz (Aktif veya Pasif)"
                        labelPlacement="end"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormTextField
                      required
                      label="Adı"
                      fullWidth
                      placeholder="Adı"
                      name="name"
                      onChange={handleChange}
                      value={values.name}
                      error={errors.name != null}
                      helperText={errors.name}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={6}>
                    <FormTextField
                      required
                      name="email"
                      label="E-Mail"
                      placeholder="E-Mail"
                      fullWidth
                      margin="dense"
                      onChange={handleChange}
                      value={values.email}
                      error={errors.email != null}
                      helperText={errors.email}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6}>
                    <FormTextField
                      required
                      name="gsm"
                      label="Telefon Numarası"
                      placeholder="Telefon Numarası"
                      fullWidth
                      margin="dense"
                      onChange={handleChange}
                      value={values.gsm}
                      error={errors.gsm != null}
                      helperText={errors.gsm}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </form>
          )}
        </Formik>
        <CardActions>
          <Box alignItems="center" justifyContent="flex-end" display="flex" className="w-100">
            <SaveButton form="form" type="submit" showLoading={showSaveLoading}>
              {id ? 'Müşteri Güncelle' : 'Müşteri Oluştur'}
            </SaveButton>
          </Box>
        </CardActions>
      </Card>
    </React.Fragment>
  );
};

export default CRMCreateOrUpdateClientPage;

/* eslint-disable import/no-anonymous-default-export */
import { AuthApi } from 'apps/auth';
import { BlogApi } from 'apps/blog/api';
import { CampaignApi } from 'apps/campaign/api';
import CrmApi from 'apps/crm/api/Crm';
import { DashboardApi } from 'apps/dashboard/api';
import { DesignApi } from 'apps/design/api';
import { OptionApi } from 'apps/option';
import { OrderApi } from 'apps/order/api';
import { ProductApi, VehicleDataApi } from 'apps/product';
import { ReportApi } from 'apps/report';
import { StoreApi } from 'apps/store/api';
import { SupplierApi } from 'apps/supplier/api';
import { UserApi } from 'apps/user/api';
import { WarehouseApi } from 'apps/warehouse/api';

export default {
  Dashboard: DashboardApi,
  Product: ProductApi,
  Supplier: SupplierApi,
  Option: OptionApi,
  Campaign: CampaignApi,
  Crm: CrmApi,
  Blog: BlogApi,
  Order: OrderApi,
  Store: StoreApi,
  User: UserApi,
  Warehouse: WarehouseApi,
  Auth: AuthApi,
  Vehicle: VehicleDataApi,
  Report: ReportApi,
  Design: DesignApi,
};

import { ecmsClient } from 'core';
import { ApiResponse } from 'core/models';
import { CreateOrUpdateStore, FilterRequestStore, Store } from '../models';

const createOrUpdateStoreFormData = (storeData: CreateOrUpdateStore) => {
  const formData = new FormData();
  if (storeData.name) {
    formData.append('name', storeData.name);
  }
  if (storeData.email) {
    formData.append('email', storeData.email);
  }

  if (storeData.address) {
    formData.append('address', storeData.address);
  }

  if (storeData.logo) {
    formData.append('logoFile', storeData.logo);
  }

  if (storeData.favicon) {
    formData.append('faviconFile', storeData.favicon);
  }
  if (storeData.gsm) formData.append('gsm', storeData.gsm.toString());
  if (storeData.domain) formData.append('domain', storeData.domain);
  if (storeData.eArchiveInvoicePrefix) formData.append('eArchiveInvoicePrefix', storeData.eArchiveInvoicePrefix);
  if (storeData.invoicePrefix) formData.append('invoicePrefix', storeData.invoicePrefix);
  if (storeData.orderPrefix) formData.append('orderPrefix', storeData.orderPrefix);
  if (storeData.hexColorCode) formData.append('hexColorCode', storeData.hexColorCode);
  if (storeData.geolocation) formData.append('geolocation', storeData.geolocation);
  if (storeData.city) formData.append('city', storeData.city);
  formData.append('isActive', storeData.isActive ? '1' : '0');
  return formData;
};

const StoreApi = {
  URL_STORE: '/store',
  URL_STORE_WITH_ID: (id: number) => `/store/${id}`,

  async getStores(filterRequest?: FilterRequestStore) {
    const query = filterRequest?.query;
    const response = await ecmsClient.get(this.URL_STORE, {
      params: {
        page: filterRequest?.page,
        isActive: filterRequest?.isActive,
      },
    });
    return response.data;
  },

  async createStore(storeDto: CreateOrUpdateStore): Promise<ApiResponse<CreateOrUpdateStore>> {
    const storeData = createOrUpdateStoreFormData(storeDto);
    const response = await ecmsClient.post(this.URL_STORE, storeData);
    return response.data;
  },
  async updateStore(id: number, storeDto: CreateOrUpdateStore): Promise<ApiResponse<CreateOrUpdateStore>> {
    const storeData = createOrUpdateStoreFormData(storeDto);
    const response = await ecmsClient.post(this.URL_STORE_WITH_ID(id), storeData);
    return response.data;
  },

  async getStore(id: number): Promise<ApiResponse<Store>> {
    const response = await ecmsClient.get(this.URL_STORE_WITH_ID(id));
    return response.data;
  },
};

export default StoreApi;

import React from 'react';
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  FormControl,
  InputLabel,
  FormHelperText,
  Box,
  OutlinedInput,
  InputAdornment,
  Button,
  TextField,
} from '@material-ui/core';
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@material-ui/core/styles';
import { AccountCircle, Lock as LockIcon, VpnKey as VpnKeyIcon } from '@material-ui/icons';
import { connect, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import * as yup from 'yup';
import { GenericMessages } from 'core';
import { CollapsableAlert } from 'core/components/CollapsableAlert';
import { LoadingButton } from 'core/components';
import style from '../styles/loginPage.module.scss';
import { loginActionThunk } from '../store/thunk';
import { setUserInfo, setToken } from '../store';
import { AuthApi } from '../api';

const useStyles = makeStyles((theme) => {
  return {
    title: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      padding: '8px',
      textAlign: 'center',
    },
  };
});

const LoginPage = () => {
  const loginPageStyle = useStyles();
  const dispatch = useDispatch();
  const [showError, setShowError] = React.useState(false);
  const [showServerError, setShowServerError] = React.useState(false);
  const [showLoading, setShowLoading] = React.useState(false);
  const loginFormSchema = yup.object({
    email: yup.string().email(GenericMessages.INVALID_EMAIL).required(GenericMessages.CANNOT_BE_EMPTY),
    password: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
  });

  const initialValues = {
    email: '',
    password: '',
  };

  const onLoginFormSubmit = async (data) => {
    setShowLoading(true);
    setShowError(false);
    setShowServerError(false);
    try {
      const response = await AuthApi.login({
        email: data.email,
        password: data.password,
      });

      const loginData = response.data;
      if (loginData) {
        dispatch(
          setUserInfo({
            name: loginData.name,
            image: loginData.image,
            languageId: loginData.languageId,
            storeId: loginData.storeId,
            isActive: loginData.isActive,
          }),
        );
      } else {
        setShowError(true);
        setShowLoading(false);
      }
    } catch (e) {
      const errCode = e.response?.data?.statusCode;
      if (errCode && errCode === 401) {
        setShowError(true);
      } else if (errCode && errCode === 403) {
      } else {
        setShowServerError(true);
      }
      setShowLoading(false);
    }
  };

  return (
    <Container maxWidth="xl" className={style.container}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        className={style.loginContainerGrid}
      >
        <Grid item xs={12} md={3}>
          <Box display="flex" alignItems="center" justifyContent="center" height="100%">
            <Card className={style.loginCard}>
              <Typography variant="h5" component="h2" className={loginPageStyle.title}>
                Giriş Yapın
              </Typography>
              <CollapsableAlert show={showError} severity="error">
                Kullanıcı adı veya Şifreniz Yanlış
              </CollapsableAlert>
              <CollapsableAlert show={showServerError} severity="error">
                Bir hata oluştu. Lütfen daha sonra tekrar deneyiniz.
              </CollapsableAlert>

              <Formik
                onSubmit={onLoginFormSubmit}
                validationSchema={loginFormSchema}
                validateOnChange={false}
                validateOnBlur={false}
                initialValues={initialValues}
              >
                {({ handleSubmit, handleChange, errors }) => (
                  <form onSubmit={handleSubmit} noValidate>
                    <CardContent>
                      <Box mt={2}>
                        <Grid container direction="column" spacing={2}>
                          <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel htmlFor="email">Email</InputLabel>
                              <OutlinedInput
                                id="email"
                                type="text"
                                label="Email"
                                name="email"
                                onChange={handleChange}
                                error={errors.email !== null}
                                placeholder="Email"
                                startAdornment={
                                  <InputAdornment position="start">
                                    <AccountCircle />
                                  </InputAdornment>
                                }
                              />
                              <FormHelperText error>{errors.email}</FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel htmlFor="password">Şifre</InputLabel>
                              <OutlinedInput
                                id="password"
                                type="password"
                                name="password"
                                error={errors.password !== null}
                                onChange={handleChange}
                                placeholder="Parola"
                                label="Şifre"
                                startAdornment={
                                  <InputAdornment position="start">
                                    <LockIcon />
                                  </InputAdornment>
                                }
                              />
                              <FormHelperText error>{errors.password}</FormHelperText>
                            </FormControl>
                          </Grid>

                          <Grid item xs={12}>
                            <LoadingButton
                              color="primary"
                              type="submit"
                              variant="contained"
                              disableElevation
                              className="w-100"
                              startIcon={<VpnKeyIcon />}
                              showLoading={showLoading}
                            >
                              Giriş Yap
                            </LoadingButton>
                          </Grid>
                        </Grid>
                      </Box>
                    </CardContent>
                  </form>
                )}
              </Formik>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (email: string, password: string) => dispatch(loginActionThunk({ email, password })),
  };
};

export default connect(null, mapDispatchToProps)(LoginPage);

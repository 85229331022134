import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { CreateUserPage, CreateUserTypePage, UserListPage } from 'apps/user/pages';

export interface UserLayoutProps {
  match?: any;
}

const UserLayout: React.FC<UserLayoutProps> = ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.path}/create/`} component={CreateUserPage} />
      <Route exact path={`${match.path}/update/:id`} component={CreateUserPage} />
      <Route exact path={`${match.path}/list/`} component={UserListPage} />
      <Route exact path={`${match.path}/typeCreate/`} component={CreateUserTypePage} />
    </Switch>
  );
};

export default UserLayout;

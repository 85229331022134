import React from 'react';
import { Grid, Typography } from '@material-ui/core';

interface FormHeaderProps {
  title: string;
  smallTitle?: string;
  smallTitleFunc?: () => string;
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
}

const FormHeader: React.FC<FormHeaderProps> = ({ title, smallTitle, variant, smallTitleFunc }) => {
  return (
    <Grid item xs={12}>
      <Typography variant={variant || 'body1'}>
        {title}
        {smallTitle ||
          (smallTitleFunc && (
            <small>
              <em>{smallTitleFunc ? smallTitleFunc() : smallTitle}</em>
            </small>
          ))}
        <hr />
      </Typography>
    </Grid>
  );
};

export default FormHeader;

/* eslint-disable react/jsx-key */
// @ts-nocheck
import React from 'react';

import { Api } from 'core';
import { Card, Grid, CardContent } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { FormHeader, DetailFieldLabel, FormDialog, TranslatedMaterialTable } from 'core/components';
import { ShipmentMethodDto } from 'apps/order/models';

const ShipmentDetailPage = () => {
  const { id } = useParams();
  const [shipment, setShipment] = React.useState<ShipmentMethodDto>();

  const pullShipment = async () => {
    const response = await Api.Order.getShipmentMethod(id);
    if (response.data) setShipment(response.data);
  };

  React.useEffect(() => {
    pullShipment();
  }, []);

  return (
    <React.Fragment>
      <Card>
        {shipment && (
          <CardContent>
            <Grid container spacing={3}>
              <FormHeader title="Kargo Bilgileri" />
              <Grid item lg={2} md={3} xs={12}>
                <DetailFieldLabel title="İsim" label={shipment?.name} />
              </Grid>
              <Grid item lg={2} md={3} xs={12}>
                <DetailFieldLabel title="Miktar" label={shipment?.amount} />
              </Grid>
              <Grid item lg={2} md={3} xs={12}>
                <DetailFieldLabel title="Aktiflik Durumu" label={shipment?.isActive ? 'Aktif' : 'Pasif'} />
              </Grid>
              <Grid item lg={2} md={3} xs={12}>
                <DetailFieldLabel title="Günlük Limit" label={shipment?.dailyLimit} />
              </Grid>
              <Grid item lg={2} md={3} xs={12}>
                <DetailFieldLabel title="Maksimum Limit" label={shipment?.maxLimit} />
              </Grid>
              <Grid item lg={2} md={3} xs={12}>
                <DetailFieldLabel title="Minimum Limit" label={shipment?.minLimit} />
              </Grid>
            </Grid>
          </CardContent>
        )}
      </Card>
    </React.Fragment>
  );
};

export default ShipmentDetailPage;

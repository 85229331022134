import { Integration } from 'apps/order/components';
import React from 'react';

const GoogleAnalytics = () => {
  return (
    <React.Fragment>
      <Integration title="Google Analytics" buttonName="Google Analytics Ekle" />
    </React.Fragment>
  );
};

export default GoogleAnalytics;

import { Card, CardActions, CardContent, Grid, Box, Button, FormHelperText } from '@material-ui/core';
import { Order } from 'apps/crm';
import { Product } from 'apps/product';
import { Api, FormTextField, HideableLoadingSpinnerBox, SuccessButton, TranslatedMaterialTable } from 'core';
import { TableState } from 'core/models';
import { Formik } from 'formik';
import React from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

const CargoTagGeneratorPage = () => {
  const { id }: any = useParams();
  const [order, setOrder] = React.useState<Order>();
  const [hasError, setHasError] = React.useState(false);
  const [products, setProducts] = React.useState<(Product & { isDone: boolean })[]>();
  const [showLoading, setShowLoading] = React.useState(false);
  const [tableState] = React.useState<TableState<Product>>({
    columns: [
      { title: 'Ürün', field: 'name', cellStyle: { whiteSpace: 'nowrap' } },
      { title: 'Miktar', field: 'quantity', cellStyle: { whiteSpace: 'nowrap' } },
      { title: 'SKU', field: 'sku', cellStyle: { whiteSpace: 'nowrap' } },
      { title: 'Tamamlandı', field: 'isDone', type: 'boolean' },
    ],
  });

  React.useEffect(() => {
    Api.Order.getOrder(id).then((response) => {
      if (response.data) {
        setOrder(response.data);
        const newProducts = response.data.products;
        setProducts(
          newProducts.map((newProduct) => ({
            ...newProduct,
            isDone: false,
          })),
        );
      }
    });
  }, []);

  const onBlur = (e) => {
    const formElem = document.getElementById('barcodeFormSubmit');
    if (formElem) {
      // @ts-ignore
      formElem.click();
    }
  };

  const validationSchema = yup.object({
    barcode: yup.string().required(),
  });

  const onPrintShipmentFormCLicked = () => {
    window.open(Api.Order.getShipmentFormLink(id), '_blank');
  };

  if (!products) {
    return <HideableLoadingSpinnerBox />;
  }

  const onFormSubmit = async (data) => {
    try {
      await Api.Order.checkOrderProductBarcode(id, data.barcode);
      const newProducts = [...products];
      const doneProduct = newProducts.find((product) => product.barcode == data.barcode);
      if (doneProduct) {
        doneProduct.isDone = true;
      }
      setProducts(newProducts);
      setHasError(false);
    } catch (e) {
      setHasError(true);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TranslatedMaterialTable columns={tableState.columns} data={products} />
      </Grid>
      <Grid item xs={6}>
        <Card>
          <CardContent>
            <Formik
              onSubmit={onFormSubmit}
              validationSchema={validationSchema}
              initialValues={{
                barcode: '',
              }}
            >
              {({ handleChange, handleSubmit, values, errors }) => (
                <form onSubmit={handleSubmit} noValidate id="barcodeForm">
                  {hasError && <FormHelperText error>Bu barkodla ürün yok!</FormHelperText>}
                  <FormTextField
                    label="Barkod"
                    placeholder="Barkod"
                    value={values.barcode}
                    onChange={handleChange}
                    onBlur={onBlur}
                    name="barcode"
                  />
                  <input type="submit" hidden id="barcodeFormSubmit" />
                </form>
              )}
            </Formik>
          </CardContent>
          <CardActions>
            <Box width="100%" textAlign="right">
              <SuccessButton onClick={onPrintShipmentFormCLicked} showLoading={showLoading}>
                Kargo Etiketi Bas
              </SuccessButton>
            </Box>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CargoTagGeneratorPage;

import { Formik, Form } from 'formik';
import React from 'react';
import { TextField, Grid, CardContent, Card, CardActions, Box } from '@material-ui/core';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { FormAutocomplete, FormHeader, LabeledCheckbox, SuccessButton, UploadButton } from 'core/components';
import GenericMessages from 'core/GenericMessages';
import ColorPicker from 'material-ui-color-picker';
import { OrderApi } from 'apps/order/api';
import { CreateSupplierDto, Supplier } from 'apps/supplier/models';
import { Api, showToast } from 'core';
import { useParams } from 'react-router-dom';

const CreateSupplierForm = () => {
  const [supplier, setSupplier] = React.useState<Supplier>();
  const [showLoading, setShowLoading] = React.useState(false);
  const [supplierLogo, setSupplierLogo] = React.useState();
  const [faviconLogo, setFaviconLogo] = React.useState();
  const faviconLogoPreview = React.useRef(null);
  const supplierLogoPreview = React.useRef(null);
  const { id }: any = useParams();

  const dispatch = useDispatch();
  const inputLabelProps = {
    shrink: true,
  };

  const schema = yup.object({
    address: yup.string().required('Adres Alanı Boş olamaz'),
    // provinceId: yup.number().required('İl Seçilmesi Gerekmektedir'),
    // districtId: yup.number().required('İlçe Seçilmesi Gerekmektedir'),
    email: yup.string().required(GenericMessages.CANNOT_BE_EMPTY).email(GenericMessages.INVALID_EMAIL),
    name: yup.string().required('Bu Boş Olamaz'),

    // gsm: yup
    //   .string()
    //   .test('empty', GenericMessages.CANNOT_BE_EMPTY, (val) => val && val.toString().length > 0)
    //   .test('len', GenericMessages.PHONE_NO_LENGTH, (val) => val && val.toString().length === 10),
  });

  const onSupplierCreateSubmit = async (values, { resetForm }) => {
    setShowLoading(true);
    console.log(values, id);
    try {
      const newSupplier: CreateSupplierDto = {
        name: values.name,
        address: values.address,
        gsm: values.gsm,
        email: values.email,
        authorizedPerson: values.authorizedPerson,
        additionalProfit: parseInt(values.additionalProfit),
        wantsNotification: values.wantsNotification,
        isOutsource: values.isOutsource,
        allowancePercent: parseInt(values.allowancePercent),
        supplyType: values.supplyType.id,
        returnType: values.returnType.id,
        storeId: 1,
        image: supplierLogo || '',
      };
      if (id) await Api.Supplier.updateSupplier(id, newSupplier);
      else await Api.Supplier.createSupplier(newSupplier);
      dispatch(
        showToast({
          message: `başarıyla ${id ? 'güncellendi!' : 'oluşturuldu!'}`,
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(
        showToast({
          message: `Hata oluştu`,
          severity: 'error',
        }),
      );
      setShowLoading(false);
    }
    setShowLoading(false);
  };
  const onLogoSelected = (setFieldValue) => (event) => {
    const { target } = event;

    if (target.files || target.files.length === 1) {
      const file = target.files[0];
      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const currentElem: any = supplierLogoPreview.current;
          if (currentElem) {
            currentElem.src = e.target?.result;
            setFieldValue('image', e.target?.result);
          }
        };

        reader.readAsDataURL(file);
        setSupplierLogo(file);
      }
    }
  };
  const onFaviconSelected = (event) => {
    const { target } = event;

    if (target.files || target.files.length === 1) {
      const file = target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        const currentElem: any = faviconLogoPreview.current;
        if (currentElem) {
          currentElem.src = e.target?.result;
        }
      };

      reader.readAsDataURL(file);
      setFaviconLogo(file);
    }
  };

  const pullSupplier = async () => {
    if (id) {
      const response = await Api.Supplier.getSupplier(id);
      setSupplier(response.data);
    }
  };
  React.useEffect(() => {
    pullSupplier();
  }, []);

  if (id && !supplier) {
    return null;
  }

  return (
    <Formik
      onSubmit={onSupplierCreateSubmit}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        name: supplier?.name,
        address: supplier?.address,
        gsm: supplier?.gsm,
        email: supplier?.email,
        authorizedPerson: supplier?.authorizedPerson,
        additionalProfit: supplier?.additionalProfit,
        wantsNotification: supplier?.wantsNotification,
        isOutsource: supplier?.isOutsource,
        allowancePercent: supplier?.allowancePercent,
        supplyType: supplier ? { id: supplier?.supplyType } : undefined,
        returnType: supplier ? { id: supplier?.returnType } : undefined,
        image: supplier?.image || '',
      }}
    >
      {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
        <Form onSubmit={handleSubmit} id="form" noValidate>
          <Card>
            <CardContent>
              <Grid direction="row" container spacing={5}>
                <FormHeader title="Tedarikçi Bilgileri" />
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    required
                    label="Tedarikçi İsmi"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    placeholder="İsim"
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    error={errors.name != null}
                    helperText={errors.name}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    label="E-Mail"
                    placeholder="E-Mail"
                    variant="outlined"
                    name="email"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    onChange={handleChange}
                    value={values.email}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    required
                    name="gsm"
                    label="Telefon numarası"
                    placeholder="Telefon numarası"
                    variant="outlined"
                    InputLabelProps={inputLabelProps}
                    fullWidth
                    margin="dense"
                    onChange={handleChange}
                    value={values.gsm}
                    error={errors.gsm != null}
                    helperText={errors.gsm}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    required
                    name="authorizedPerson"
                    label="Yetkili Kişi"
                    placeholder="Yetkili Kişi"
                    variant="outlined"
                    InputLabelProps={inputLabelProps}
                    fullWidth
                    margin="dense"
                    onChange={handleChange}
                    value={values.authorizedPerson}
                    error={errors.authorizedPerson != null}
                    helperText={errors.authorizedPerson}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    name="address"
                    multiline
                    rows={5}
                    label="Adres"
                    placeholder="Adres"
                    variant="outlined"
                    InputLabelProps={inputLabelProps}
                    fullWidth
                    margin="dense"
                    onChange={handleChange}
                    value={values.address}
                    error={errors.address != null}
                    helperText={errors.address}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  {(supplierLogo != null || values.image !== '') && (
                    <Grid container justify="center">
                      <img src={values.image} alt="" height={100} width={100} ref={supplierLogoPreview} />
                    </Grid>
                  )}
                  <UploadButton
                    buttonProps={{
                      type: 'button',
                    }}
                    onChange={onLogoSelected(setFieldValue)}
                  >
                    Tedarikçi Görseli Yükle
                  </UploadButton>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <LabeledCheckbox
                    error={errors.wantsNotification != null}
                    label="Bildirim İsteniyor mu?"
                    checked={values.wantsNotification}
                    onChange={handleChange}
                    name="wantsNotification"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <LabeledCheckbox
                    error={errors.isOutsource != null}
                    label="Dış Kaynak mı?"
                    checked={values?.isOutsource}
                    onChange={handleChange}
                    name="isOutsource"
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormAutocomplete
                    placeholder="Tedarikçi Tipi Seçiniz"
                    options={[
                      { name: 'Müşteriye Kargo', id: 1 },
                      { name: 'Depoya Kargo ', id: 2 },
                    ]}
                    optionLabelName="name"
                    equValueName="id"
                    onChange={(event, supplyType) => setFieldValue('supplyType', supplyType)}
                    error={errors?.supplyType != null}
                    value={values?.supplyType}
                    helperText={errors?.supplyType}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormAutocomplete
                    placeholder="İade Tipi Seçiniz"
                    options={[
                      { name: 'Kabul etmiyor', id: 1 },
                      { name: 'Mütabakat ', id: 2 },
                      { name: 'Kabul Ediyor ', id: 3 },
                    ]}
                    optionLabelName="name"
                    equValueName="id"
                    onChange={(event, returnType) => setFieldValue('returnType', returnType)}
                    error={errors?.returnType != null}
                    value={values.returnType}
                    helperText={errors?.returnType}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    name="additionalProfit"
                    label="Ek Kar Tutarı"
                    placeholder="Ek Kar Tutarı(%)"
                    variant="outlined"
                    InputLabelProps={inputLabelProps}
                    fullWidth
                    margin="dense"
                    onChange={handleChange}
                    value={values.additionalProfit}
                    error={errors.additionalProfit != null}
                    helperText={errors.additionalProfit}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    name="allowancePercent"
                    label="İskonto Tutarı"
                    placeholder="İskonto Tutarı(%)"
                    variant="outlined"
                    InputLabelProps={inputLabelProps}
                    fullWidth
                    margin="dense"
                    onChange={handleChange}
                    value={values.allowancePercent}
                    error={errors.allowancePercent != null}
                    helperText={errors.allowancePercent}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Box alignItems="center" justifyContent="flex-end" display="flex" className="w-100">
                <SuccessButton form="form" type="submit" startIcon={<SaveOutlinedIcon />} showLoading={showLoading}>
                  {id ? 'Tedarikçi Güncelle' : 'Tedarikçi Oluştur'}
                </SuccessButton>
              </Box>
            </CardActions>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default CreateSupplierForm;

import { Card, CardContent, CardHeader, Grid, makeStyles, Tab, Tabs, Typography } from '@material-ui/core';
import React from 'react';
import * as yup from 'yup';
import { Formik, Form } from 'formik';
import { HideableLoadingSpinnerBox, MessageDialog, SuccessButton } from 'core/components';
import {
  AdditionalProductStep,
  CategoryStep,
  DataInfoStep,
  GeneralInfoStep,
  ImageStep,
  SettingStep,
} from 'apps/product/components';
import { GenericMessages, Api, showToast } from 'core';
import { useDispatch } from 'react-redux';
import { Product } from 'apps/product/models';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  cardActions: {
    textAlign: 'end',
    marginTop: 10,
  },
}));

enum PanelsEnum {
  GENERAL = 0,
  DATA,
  CATEGORY,
  IMAGES,
  ADDITIONAL_PRODUCT,
  SETTINGS,
}

interface CreateProductFormProps {
  product?: Product;
}

const CreateProductForm: React.FC<CreateProductFormProps> = ({ product }) => {
  const [activeTab, setActiveTab] = React.useState(0);
  const [showLoading, setShowLoading] = React.useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = React.useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const onProductSubmit = async (data: any) => {
    setShowLoading(true);
    const newProduct = {
      model: data.model,
      quantity: data.quantity,
      barcode: data.barcode,
      status: data.status,
      autoGenerateBarcode: data.autoGenerateBarcode,
      details: [{ languageId: 3, name: data.name, description: data.description }],
      storeIds: data.store.map((store) => store.id),
      warehouseId: parseInt(data.warehouseId),
      manufacturerId: parseInt(data.manufacturer.id),
      minimumBuyAmount: data.minimumQuantity,
      canShip: data.canShip,
      supplierId: parseInt(data.supplier.id),
      price: data.price,
      canAssemble: data.canAssemble,
      specialPrice: data.specialPrice,
      warrantyTime: data.warrantyTime,
      purchasePrice: parseInt(data.purchasePrice),
      isOriginal: data.isOriginal,
      taxRate: data.taxRate,
      isAdvertisement: data.isAdvertisement,
      packageContents: data.packageContents,
      deliveryReadyTime: data.deliveryReadyTime,
      isDomestic: data.isDomestic,
      isShippingFree: data.isShippingFree,
      shippingSize: data.shippingSize,
      videoLink: data.videoLink,
      categoryIds: [],
      // TO-DO isActive kullanıcıya sorulacak eklenecek
      isActive: data.status,
      optionValues: [],
      combines: [],
      shelfPartId: data.shelfPartId ? parseInt(data.shelfPartId, 10) : null,
    };
    if (data.options && data.options.length > 0) {
      newProduct.optionValues = data.options.map((option) => ({
        optionId: option.option.id,
        languageId: option.option.languageId,
        optionValueId: option.optionValueId,
        quantity: option.stockValue,
        price: option.extraPrice,
      }));
    }

    if (data.categories && data.categories.length > 0) {
      newProduct.categoryIds = data.categories.map((category) => category.id);
    }

    if (data.additionalProducts && data.additionalProducts.length > 0) {
      newProduct.combines = data.additionalProducts.map((additionalProduct) => ({
        productId: additionalProduct.id,
        productCombinePrice: additionalProduct.productCombinePrice,
      }));
    }
    let response;
    if (product) {
      response = await Api.Product.updateProduct(product.id, newProduct);
    } else {
      response = await Api.Product.createProduct(newProduct);
    }
    if (response) {
      const newImages = data.images.filter((image) => image.new === true).map((image) => image);
      // eslint-disable-next-line no-restricted-syntax
      for (const newImage of newImages) {
        // eslint-disable-next-line no-await-in-loop
        await Api.Product.uploadProductImage(response.id, newImage.fileData.file, newImage.thumbnail);
      }
    }
    if (data.deletedImageIds.length > 0) {
      data.deletedImageIds.forEach(async (imageId) => {
        await Api.Product.deleteProductImage(data.id, imageId);
      });
    }
    if (data.thumbnailId) {
      await Api.Product.setThumbnail(data.id, data.thumbnailId);
    }

    if (response) {
      dispatch(
        showToast({
          message: product ? 'Başarı ile güncellenmiştir' : 'Başarı ile oluşturulmuştur',
        }),
      );
      setShowLoading(false);
      history.push('/crm/product/list');
    } else {
      dispatch(
        showToast({
          message: `  hata  oluştu`,
          severity: 'error',
        }),
      );
      setShowLoading(false);
    }
  };

  const createProductValidation = yup.object({
    name: yup.string().required(GenericMessages.THE_FIELD_CANNOT_BE_EMPTY('Başlık')),
    model: yup.string().required(GenericMessages.THE_FIELD_CANNOT_BE_EMPTY('Model')),
    quantity: yup
      .number()
      .required(GenericMessages.THE_FIELD_CANNOT_BE_EMPTY('Adet'))
      .typeError(GenericMessages.MUST_BE_NUMBER),
    barcode: yup.string().when('autoGenerateBarcode', {
      is: (autoGenerated) => autoGenerated === true,
      then: yup.string(),
      otherwise: yup.string().required(GenericMessages.THE_FIELD_CANNOT_BE_EMPTY('Barkod')),
    }),
    status: yup.boolean().required(GenericMessages.THE_FIELD_CANNOT_BE_EMPTY('Durum')),
    autoGenerateBarcode: yup.boolean(),
    description: yup.string().required(GenericMessages.THE_FIELD_CANNOT_BE_EMPTY('Açıklama')),
    warehouseId: yup.number(),
    shelfPartId: yup.number().when('warehouseId', {
      is: (warehouseId) => warehouseId && warehouseId != '',
      then: yup
        .number()
        .required(GenericMessages.THE_FIELD_CANNOT_BE_EMPTY('Raf Bölümü'))
        .typeError(GenericMessages.THE_FIELD_CANNOT_BE_EMPTY('Raf Bölümü')),
    }),
    manufacturer: yup.object().required(GenericMessages.THE_FIELD_CANNOT_BE_EMPTY('Üretici')),
    minimumQuantity: yup
      .number()
      .required(GenericMessages.THE_FIELD_CANNOT_BE_EMPTY('En az adet'))
      .typeError(GenericMessages.MUST_BE_NUMBER),
    canShip: yup.boolean().required(GenericMessages.THE_FIELD_CANNOT_BE_EMPTY('Kargolanabilir mi')),
    supplier: yup.object().required(GenericMessages.THE_FIELD_CANNOT_BE_EMPTY('Tedarikçi')),
    price: yup
      .number()
      .required(GenericMessages.THE_FIELD_CANNOT_BE_EMPTY('Fiyat'))
      .typeError(GenericMessages.MUST_BE_NUMBER),
    canAssemble: yup.boolean().required(GenericMessages.THE_FIELD_CANNOT_BE_EMPTY('Montajlanabilir mi')),
    specialPrice: yup.number().typeError(GenericMessages.MUST_BE_NUMBER),
    warrantyTime: yup.number().required(GenericMessages.THE_FIELD_CANNOT_BE_EMPTY('Garanti Süresi')),
    purchasePrice: yup
      .number()
      .required(GenericMessages.THE_FIELD_CANNOT_BE_EMPTY('Alış Fiyatı'))
      .typeError(GenericMessages.MUST_BE_NUMBER),
    isOriginal: yup.boolean().required(GenericMessages.THE_FIELD_CANNOT_BE_EMPTY('Orijinal mi')),
    taxRate: yup.number().required().typeError(GenericMessages.MUST_BE_NUMBER),
    isAdvertisement: yup.boolean().required(GenericMessages.THE_FIELD_CANNOT_BE_EMPTY('Çıkma mı')),
    packageContents: yup.string().required(GenericMessages.THE_FIELD_CANNOT_BE_EMPTY('Paket İçeriği')),
    deliveryReadyTime: yup.number().required(GenericMessages.THE_FIELD_CANNOT_BE_EMPTY('Kargo Hazırlanma Süresi')),
    isDomestic: yup.boolean().required(GenericMessages.THE_FIELD_CANNOT_BE_EMPTY('Yerli Üretim mi')),
    isShippingFree: yup.boolean().required(GenericMessages.THE_FIELD_CANNOT_BE_EMPTY('Ücretsiz Kargo')),
    shippingSize: yup
      .number()
      .required(GenericMessages.THE_FIELD_CANNOT_BE_EMPTY('Desi Boyutu'))
      .typeError(GenericMessages.MUST_BE_NUMBER),
    options: yup.array(
      yup.object({
        option: yup.object().required(GenericMessages.THE_FIELD_CANNOT_BE_EMPTY('Seçenek')),
        optionValueId: yup.number().required(GenericMessages.THE_FIELD_CANNOT_BE_EMPTY('Seçenek Değeri')),
        stockValue: yup.number().required(GenericMessages.THE_FIELD_CANNOT_BE_EMPTY('Stok Değeri')),
        extraPrice: yup.number().required(GenericMessages.THE_FIELD_CANNOT_BE_EMPTY('Ekstra Fiyat')),
      }),
    ),
    additionalProducts: yup.array(
      yup.object({
        productCombinePrice: yup.number().required(GenericMessages.THE_FIELD_CANNOT_BE_EMPTY('Yan Ürün Fiyat')),
      }),
    ),
  });

  const handleTabChange = (e, index) => {
    setActiveTab(index);
  };

  const getTabContent = (handleChange: any, setFieldValue: any, values: any, errors: any) => {
    switch (activeTab) {
      case PanelsEnum.GENERAL:
        return (
          <GeneralInfoStep errors={errors} setFieldValue={setFieldValue} handleChange={handleChange} values={values} />
        );
      case PanelsEnum.DATA:
        return (
          <DataInfoStep errors={errors} setFieldValue={setFieldValue} handleChange={handleChange} values={values} />
        );
      case PanelsEnum.CATEGORY:
        return (
          <CategoryStep errors={errors} setFieldValue={setFieldValue} handleChange={handleChange} values={values} />
        );
      case PanelsEnum.IMAGES:
        return <ImageStep errors={errors} setFieldValue={setFieldValue} handleChange={handleChange} values={values} />;
      case PanelsEnum.ADDITIONAL_PRODUCT:
        return (
          <AdditionalProductStep
            errors={errors}
            setFieldValue={setFieldValue}
            handleChange={handleChange}
            values={values}
          />
        );
      /* case PanelsEnum.SPECS:
        return <SpecStep errors={errors} setFieldValue={setFieldValue} handleChange={handleChange} values={values} />; */
      case PanelsEnum.SETTINGS:
        return (
          <SettingStep errors={errors} setFieldValue={setFieldValue} handleChange={handleChange} values={values} />
        );
      default:
        return (
          <GeneralInfoStep errors={errors} setFieldValue={setFieldValue} handleChange={handleChange} values={values} />
        );
    }
  };

  const getErrorText = (errorValue): any => {
    if (typeof errorValue === 'string') {
      return errorValue;
    }
    if (Array.isArray(errorValue)) {
      return errorValue.map((error) => {
        if (typeof error === 'string') {
          return error;
        }
        if (typeof error === 'object') {
          return Object.values(error).map((value: any) => {
            return (
              <React.Fragment>
                <span>{value}</span>
                <br />
              </React.Fragment>
            );
          });
        }
        return null;
      });
    }
    return null;
  };

  const options: any[] = [];
  if (product && product.options) {
    product.options.forEach((option) =>
      options.push({
        option,
        optionValueId: option.optionValueId,
        stockValue: option.quantity,
        extraPrice: option.price,
        values: option.values,
      }),
    );
  }

  return (
    <React.Fragment>
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab label="Genel" />
        <Tab label="Veri" />
        <Tab label="Kategori" />
        <Tab label="Görsel" />
        <Tab label="Yan Ürün" />
        {/* <Tab label="Özellikler" /> */}
        <Tab label="Seçenekler" />
      </Tabs>
      <Card>
        <CardHeader title="Ürün Ekl" />
        <CardContent>
          <Formik
            initialValues={{
              id: product?.id,
              name: product?.name,
              model: product?.model,
              canShip: product?.canShip ? '1' : '0',
              canAssemble: product?.canAssemble ? '1' : '0',
              price: product?.price,
              specialPrice: product?.specialPrice,
              warrantyTime: product?.warrantyTime,
              purchasePrice: product?.purchasePrice,
              isAdvertisement: product?.isAdvertisement ? '1' : '0',
              shippingSize: product?.shippingSize,
              packageContents: product?.packageContent,
              isDomestic: product?.isDomestic ? '1' : '0',
              isShippingFree: product?.isShippingFree ? '1' : '0',
              videoLink: product?.videoLink,
              deliveryReadyTime: product?.deliveryReadyTime,
              quantity: product?.quantity,
              minimumQuantity: product?.minimumQuantity,
              autoGenerateBarcode: false,
              barcode: product?.barcode,
              status: product?.isActive ? '1' : '0',
              store: product?.stores ? product?.stores : [],
              warehouseId: product?.warehouseId,
              shelfPartId: product?.shelfPartId || '',
              description: product?.detail?.description,
              options,
              additionalProducts: product?.combines || [],
              categories: product?.categories || [],
              images: product?.images || [],
              supplier: product?.supplier,
              manufacturer: product?.manufacturer,
              isOriginal: product?.isOriginal ? '1' : '0',
              taxRate: product?.taxRate ? product?.taxRate : 18,
              deletedImageIds: [],
              thumbnailId: '',
              warehouses: [],
              warehouseShelfParts: [],
              hasPulledWarehouseShelfParts: false,
            }}
            onSubmit={onProductSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validateOnMount={false}
            validationSchema={createProductValidation}
          >
            {({ handleSubmit, errors, handleChange, values, setFieldValue }) => (
              <Form onSubmit={handleSubmit} id="productForm">
                {getTabContent(handleChange, setFieldValue, values, errors)}
                <MessageDialog
                  formId="productForm"
                  onHideDialog={() => {
                    setErrorDialogOpen(false);
                  }}
                  showDialog={Object.keys(errors).length !== 0 && errorDialogOpen}
                  title="Eksik Bilgiler"
                >
                  Lütfen aşağıdaki hataları giderdikten sonra tekrar deneyiniz.
                  {Object.keys(errors).map((key, idx) => (
                    <Typography key={`err-${idx}`} variant="h6" align="center">
                      {getErrorText(errors[key])}
                    </Typography>
                  ))}
                </MessageDialog>
                <Grid item xs={12} className={classes.cardActions}>
                  <SuccessButton
                    type="submit"
                    showLoading={showLoading}
                    onClick={() => {
                      setErrorDialogOpen(true);
                    }}
                  >
                    Kaydet
                  </SuccessButton>
                </Grid>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default CreateProductForm;

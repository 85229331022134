import { Column, Action } from 'material-table';
import React, { useEffect } from 'react';
import { Visibility as VisibilityIcon } from '@material-ui/icons';
import { TableState } from 'core/models';
import { Client } from 'apps/crm';
import { TranslatedMaterialTable } from 'core/components';
import { OrderApi } from 'apps/order/api';
import { Supplier } from 'apps/supplier/models';
import { Api } from 'core';
import { useHistory } from 'react-router-dom';
import { CreateOrUpdateSection, DesignSection } from 'apps/design/models';

const SectionListPage = () => {
  const [showLoading, setShowLoading] = React.useState(false);
  const history = useHistory();

  const [tableState] = React.useState<TableState<DesignSection>>({
    columns: [
      {
        title: 'İsim',
        field: 'name',
        cellStyle: { whiteSpace: 'nowrap' },
      },
      {
        title: 'Content',
        field: 'content',
        cellStyle: { whiteSpace: 'nowrap' },
      },
    ],
    actions: [
      {
        icon: 'edit',
        tooltip: 'Düzenle',
        onClick: (event, rowData) => {
          const section = rowData as DesignSection;
          history.push(`/design/section/update/${section.id}`);
        },
      },
    ],
  });
  return (
    <TranslatedMaterialTable
      isLoading={showLoading}
      actions={tableState.actions}
      columns={tableState.columns}
      data={(q) =>
        new Promise((resolve) => {
          setShowLoading(true);
          Api.Design.getDesignSections({
            page: q.page,
          })
            .then((response) => response.data)
            .then((data) => {
              if (data) {
                resolve({
                  page: q.page,
                  totalCount: data.totalCount,
                  data: data.data,
                });
              }
            });
          setShowLoading(false);
        })
      }
      title=""
    />
  );
};
export default SectionListPage;

import { Formik, Form } from 'formik';
import React from 'react';
import {
  TextField,
  Grid,
  CardContent,
  Card,
  CardActions,
  Box,
  Switch,
  FormControlLabel,
  FormControl,
} from '@material-ui/core';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { FormHeader, StoreAutoCompleteSelect, SuccessButton, UploadButton } from 'core/components';
import GenericMessages from 'core/GenericMessages';
import { Autocomplete } from '@material-ui/lab';
import { Api, showToast } from 'core';
import Store from 'apps/store/models/Store';
import { PaymentMethodDto } from 'apps/order/models';
import { useParams } from 'react-router-dom';

const CreatePaymentMethodForm = () => {
  const [showLoading, setShowLoading] = React.useState(false);
  const [storeList, setStoreList] = React.useState<Store[]>([]);
  const [paymentTypePhoto, setPaymentTypePhoto] = React.useState();
  const [paymentMethod, setPaymentMethod] = React.useState<PaymentMethodDto>();
  const paymentTypePhotoPreview = React.useRef(null);
  const { id }: any = useParams();
  const dispatch = useDispatch();
  const inputLabelProps = {
    shrink: true,
  };

  const schema = yup.object({
    upperLimit: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
    lowerLimit: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
    paymentTypeName: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
  });

  const onPaymentTypeSubmit = async (values, { resetForm }) => {
    setShowLoading(true);
    try {
      const newPaymentMethod: PaymentMethodDto = {
        name: values.paymentTypeName,
        maxLimit: values.upperLimit,
        minLimit: values.lowerLimit,
        isActive: values.isActive,
        image: paymentTypePhoto || '',
        description: values.description,
        storeIds: values.storeIds.map((store) => store.id),
      };
      if (id) await Api.Order.updatePaymentMethod(id, newPaymentMethod);
      else await Api.Order.createPaymentMethod(newPaymentMethod);
      dispatch(
        showToast({
          message: `başarıyla ${id ? 'güncellendi!' : 'oluşturuldu!'}`,
        }),
      );
    } catch (e) {
      dispatch(
        showToast({
          message: `Hata oluştu`,
          severity: 'error',
        }),
      );
      setShowLoading(false);
    }
    setShowLoading(false);
  };
  const onPaymentTypePhotoSelected = (setFieldValue) => (event) => {
    const { target } = event;

    if (target.files || target.files.length === 1) {
      const file = target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        const currentElem: any = paymentTypePhotoPreview.current;
        if (currentElem) {
          currentElem.src = e.target?.result;
          setFieldValue('image', e.target?.result);
        }
      };

      reader.readAsDataURL(file);
      setPaymentTypePhoto(file);
    }
  };

  const pullStoreList = async () => {
    const response = await Api.Store.getStores();
    if (response.data.data) setStoreList(response.data.data);
  };

  React.useEffect(() => {
    pullStoreList();
  }, []);

  const pullPaymentMethod = async () => {
    const response = await Api.Order.getPaymentMethod(id);
    if (response.data) setPaymentMethod(response.data);
  };

  React.useEffect(() => {
    if (id) {
      pullPaymentMethod();
    }
  }, []);

  if (id && !paymentMethod) {
    return null;
  }
  return (
    <Formik
      onSubmit={onPaymentTypeSubmit}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        paymentTypeName: paymentMethod?.name,
        lowerLimit: paymentMethod?.minLimit,
        upperLimit: paymentMethod?.maxLimit,
        storeIds: paymentMethod?.storeIds
          ? paymentMethod.storeIds.map((storeId) => ({
              id: storeId,
            }))
          : [],
        description: paymentMethod?.description,
        isActive: paymentMethod?.isActive,
        image: paymentMethod?.image || '',
      }}
    >
      {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
        <Form onSubmit={handleSubmit} id="form" noValidate>
          <Card>
            <CardContent>
              <Grid direction="row" container spacing={5}>
                <FormHeader title="Ödeme Tipi Ekle" />
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    required
                    label="Ödeme Yöntemi Adı"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    placeholder="Ödeme Yöntemi Adı"
                    name="paymentTypeName"
                    onChange={handleChange}
                    value={values.paymentTypeName}
                    error={errors.paymentTypeName != null}
                    helperText={errors.paymentTypeName}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    required
                    label="Üst Limit"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    placeholder="Üst Limit"
                    name="upperLimit"
                    onChange={handleChange}
                    value={values.upperLimit}
                    error={errors.upperLimit != null}
                    helperText={errors.upperLimit}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    required
                    label="Alt Limit"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    placeholder="Alt Limit"
                    name="lowerLimit"
                    onChange={handleChange}
                    value={values.lowerLimit}
                    error={errors.lowerLimit != null}
                    helperText={errors.lowerLimit}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormControl component="fieldset">
                    <FormControlLabel
                      value="end"
                      control={
                        <Switch
                          color="primary"
                          checked={values.isActive}
                          onChange={(e) => setFieldValue('isActive', e.target.checked)}
                        />
                      }
                      label="Durumu Seçiniz (Aktif veya Pasif)"
                      labelPlacement="end"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <StoreAutoCompleteSelect
                    placeholder="Mağazaları Seçebilirsiniz"
                    optionLabelName="name"
                    equValueName="id"
                    onChange={(event, store) => setFieldValue('storeIds', store)}
                    error={errors.storeIds != null}
                    value={values.storeIds}
                    multiple
                    helperText={errors.storeIds as string}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  {(paymentTypePhoto != null || values.image !== '') && (
                    <Grid container justify="center">
                      <img src={values.image} alt="" height={100} width={100} ref={paymentTypePhotoPreview} />
                    </Grid>
                  )}
                  <UploadButton
                    buttonProps={{
                      type: 'button',
                    }}
                    onChange={onPaymentTypePhotoSelected(setFieldValue)}
                  >
                    Ödeme Yöntem Görseli Yükle
                  </UploadButton>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    multiline
                    rows={6}
                    error={errors.description != null}
                    label="Açıklama"
                    placeholder="Açıklama"
                    variant="outlined"
                    name="description"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    onChange={handleChange}
                    value={values.description}
                    helperText={errors.description}
                  />
                </Grid>
              </Grid>
            </CardContent>

            <CardActions>
              <Box alignItems="center" justifyContent="flex-end" display="flex" className="w-100">
                <SuccessButton form="form" type="submit" startIcon={<SaveOutlinedIcon />} showLoading={showLoading}>
                  {id ? 'ÖDEME TIPI GÜNCELLE' : 'ODEME TIPI EKLE'}
                </SuccessButton>
              </Box>
            </CardActions>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default CreatePaymentMethodForm;

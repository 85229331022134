import React from 'react';
import { FormTextField, LoadingButton } from 'core';
import { Grid, Card, CardContent, Typography, Box } from '@material-ui/core';
import { Send } from '@material-ui/icons';
import { ClientNote } from 'apps/crm/models';

interface ClientNoteListProps {
  notes: ClientNote[];
  id: number;
}

const ClientNoteList: React.FC<ClientNoteListProps> = ({ notes, id }) => {
  const [noteList, setNotesList] = React.useState<ClientNote[]>([]);
  const [note, setNotes] = React.useState('');
  const [showLoading, setShowLoading] = React.useState(false);

  const handleNewNotes = async () => {
    // setShowLoading(true);
    // if (note.length !== 0) {
    //   const response = await ClientApi.addNotes(id, note);
    //   if (response.data) setNotesList([...noteList, response.data]);
    //   setNotes('');
    // }
    // setShowLoading(false);
  };
  //   React.useEffect(() => {
  //     setNotesList(notes);
  //   }, []);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        {noteList.length > 0 &&
          noteList.map((note) => (
            <Card variant="outlined" className="mb-2" key={`clc${note.id}`}>
              <CardContent>
                {note.note}
                <Typography variant="subtitle2" />
              </CardContent>
            </Card>
          ))}

        {noteList.length === 0 && (
          <Card variant="outlined">
            <CardContent>
              <Typography variant="subtitle2">Not bulunamadı.</Typography>
            </CardContent>
          </Card>
        )}

        <Grid container xs={12}>
          <Grid item xs={12} md={10}>
            <FormTextField
              placeholder="Önemli Bilgiler"
              name="note"
              value={note}
              onChange={(e) => {
                setNotes(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={2} justify="flex-end">
            <Box textAlign="right">
              <LoadingButton showLoading={showLoading} color="primary" className="mt-2" onClick={handleNewNotes}>
                Gönder <Send className="ml-1" />
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ClientNoteList;

import { Button } from '@material-ui/core';
import { Sync } from '@material-ui/icons';
import { CategorySync, TrendyolManufacturerSync } from 'apps/order';
import { FormDialog, TranslatedMaterialTable } from 'core/components';
import { TableState } from 'core/models';
import React from 'react';

const TrendyolManufacturerPage = () => {
  const [showProducerSyncDialog, setShowProducerSyncDialog] = React.useState(false);
  const [showLoading, setShowLoading] = React.useState(false);

  const handleProducerSyncDialogClose = () => {
    setShowProducerSyncDialog(false);
  };
  const handleProducerSyncDialogOpen = () => {
    setShowProducerSyncDialog(true);
  };

  const tempData: any = [
    {
      name: 'Yakıt',
    },
    {
      name: 'Bluetooth',
    },
    {
      name: 'Araba',
    },
    {
      name: 'Oyuncak',
    },
  ];

  const [tableState] = React.useState<TableState<any>>({
    columns: [{ title: 'Ürün', field: 'name', cellStyle: { whiteSpace: 'nowrap' } }],

    actions: [
      {
        tooltip: 'Eşleştir',
        icon: (props) => <Sync {...props} />,
        onClick: (event, rowData) => {
          handleProducerSyncDialogOpen();
        },
      },
    ],
  });
  return (
    <React.Fragment>
      <FormDialog
        handleClose={handleProducerSyncDialogClose}
        open={showProducerSyncDialog}
        formId="Trendyol"
        title="Trendyol Üretici Eşitle"
        showLoading={showLoading}
      >
        <TrendyolManufacturerSync
          formId="Trendyol"
          setShowProducerSyncDialog={setShowProducerSyncDialog}
          setShowLoading={setShowLoading}
        />
      </FormDialog>
      <TranslatedMaterialTable
        isLoading={showLoading}
        actions={tableState.actions}
        columns={tableState.columns}
        options={{
          selection: true,
        }}
        data={tempData}
        title="Ürün Listesi"
      />
    </React.Fragment>
  );
};

export default TrendyolManufacturerPage;

import React, { useEffect } from 'react';
import { TableState } from 'core/models';
import { FormTextField, StoreAutoCompleteSelect, TranslatedMaterialTable } from 'core/components';
import { Api } from 'core';
import { Order, OrderFilterRequest } from 'apps/crm/models';
import { Visibility as VisibilityIcon } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { Filter as FilterIcon } from 'react-feather';
import { Formik } from 'formik';

const OrderListPage = () => {
  const [showLoading, setShowLoading] = React.useState(false);
  const [orderFilter, setOrderFilter] = React.useState<OrderFilterRequest & { stores?: any[] }>({});
  const [showFilterDialog, setShowFilterDialog] = React.useState<boolean>(false);
  const tableRef = React.useRef<any>();
  const history = useHistory();
  const [tableState] = React.useState<TableState<Order>>({
    columns: [
      {
        title: 'Sipariş Kodu',
        render: (val) => (val?.client ? val.sourceCode : 'Yok'),
        cellStyle: { whiteSpace: 'nowrap' },
      },
      {
        title: 'Müşteri İsmi',
        render: (val) => (val?.client ? val.client.name : 'Yok'),
        cellStyle: { whiteSpace: 'nowrap' },
      },
      {
        title: 'Durum',
        render: (val) => (val?.client ? <Chip label={val.statusName} color="primary" /> : 'Yok'),
        cellStyle: { whiteSpace: 'nowrap' },
      },
      {
        title: 'Tutar',
        render: (val) => (val?.client ? `${val.total} ₺` : 'Yok'),
        cellStyle: { whiteSpace: 'nowrap' },
      },
      {
        title: 'Kaynak',
        render: (val) => (val?.products ? val.sourceName : 'Yok'),
        cellStyle: { whiteSpace: 'nowrap' },
      },
      {
        title: 'Mağaza',
        render: (val) => (val?.products ? val.store.name : 'Yok'),
        cellStyle: { whiteSpace: 'nowrap' },
      },
      {
        title: 'Oluşturulma Tarihi',
        render: (val) => (val?.products ? val.createdAt : 'Yok'),
        cellStyle: { whiteSpace: 'nowrap' },
      },
    ],
    actions: [
      (rowData) => ({
        icon: (props) => <VisibilityIcon {...props} />,
        onClick: (event, rowData) => {
          const order = rowData as Order;
          history.push(`/orders/${order.id}`);
        },
      }),
      {
        icon: (props) => <FilterIcon {...props} />,
        tooltip: 'Filtrele',
        isFreeAction: true,
        onClick: (event) => {
          setShowFilterDialog(true);
        },
      },
    ],
  });

  const handleFilterDialogClose = () => {
    setShowFilterDialog(false);
  };

  const onFilterSubmit = (data) => {
    console.log(data);
    const newOrderFilter = {
      ...orderFilter,
      ...data,
    };
    setOrderFilter(newOrderFilter);
  };

  React.useEffect(
    () =>
      tableRef?.current?.onQueryChange({
        page: 0,
      }),
    [orderFilter],
  );

  return (
    <React.Fragment>
      <TranslatedMaterialTable
        tableRef={tableRef}
        isLoading={showLoading}
        actions={tableState.actions}
        columns={tableState.columns}
        data={(q) =>
          new Promise((resolve) => {
            setShowLoading(true);
            const filterData: any = {
              ...orderFilter,
              page: q.page,
              queryName: q.search,
            };
            if (orderFilter.stores) {
              filterData.storeIds = filterData.stores.map((store) => store.id);
              delete filterData.stores;
            }
            Api.Crm.getOrders(filterData)
              .then((response) => response.data)
              .then((data) => {
                if (data) {
                  setShowLoading(false);
                  resolve({
                    page: q.page,
                    totalCount: data.totalCount,
                    data: data.data,
                  });
                }
              });
            setShowLoading(false);
          })
        }
        title=""
      />
      <Dialog open={showFilterDialog} onClose={handleFilterDialogClose}>
        <DialogTitle>Filtrele</DialogTitle>
        <Formik
          onSubmit={onFilterSubmit}
          initialValues={{
            minAmount: orderFilter.minAmount || '',
            maxAmount: orderFilter.maxAmount || '',
            stores: orderFilter.stores || [],
          }}
        >
          {({ setFieldValue, handleChange, values, handleSubmit }) => (
            <form noValidate onSubmit={handleSubmit} id="filterForm">
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <FormTextField
                      name="minAmount"
                      label="Min. Tutar"
                      placeholder="Max. Tutar"
                      onChange={handleChange}
                      value={values.minAmount}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormTextField
                      name="maxAmount"
                      label="Max. Tutar"
                      placeholder="Max. Tutar"
                      onChange={handleChange}
                      value={values.maxAmount}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <Box px={1}>
                      <StoreAutoCompleteSelect
                        multiple
                        placeholder="Mağaza"
                        fullWidth
                        value={values.stores}
                        onChange={(event, values) => setFieldValue('stores', values)}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </DialogContent>
            </form>
          )}
        </Formik>

        <DialogActions>
          <Button onClick={handleFilterDialogClose} color="secondary">
            Kapat
          </Button>
          <Button onClick={handleFilterDialogClose} color="primary" autoFocus form="filterForm" type="submit">
            Ara
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
export default OrderListPage;

import { ecmsClient } from 'core';
import { ApiResponse, FilterRequest, PageResponse } from 'core/models';
import { Supplier } from 'apps/supplier';
import { Category, Manufacturer } from 'apps/crm';
import { CreateCategoryForm, CreatePaymentMethodForm } from '../components';
import {
  CargoCompany,
  CreateManufacturerDto,
  N11MatchedCategories,
  OrderActionLog,
  OrderNote,
  PaymentMethodDto,
  ShipmentMethodDto,
} from '../models';
import Order from '../../crm/models/Order';
import { CreatePaynetLink } from '../pages';
import MPCategoryList from '../models/MPCategoryList';

const shipmentCreateOrUpdateFormData = (shipmentData: ShipmentMethodDto) => {
  const formData = new FormData();
  if (shipmentData.name) {
    formData.append('name', shipmentData.name);
  }
  if (shipmentData.maxLimit) {
    formData.append('maxLimit', shipmentData.maxLimit.toString());
  }
  if (shipmentData.storeIds) {
    shipmentData.storeIds.forEach((storeId) => formData.append('storeIds[]', storeId));
  }

  if (shipmentData.minLimit) formData.append('minLimit', shipmentData.minLimit.toString());
  if (shipmentData.dailyLimit) formData.append('dailyLimit', shipmentData.dailyLimit.toString());

  if (shipmentData.image) {
    formData.append('image', shipmentData.image);
  }

  if (shipmentData.amount) formData.append('amount', shipmentData.amount.toString());
  formData.append('isActive', shipmentData.isActive ? '1' : '0');
  return formData;
};

const paymentMethodCreateOrUpdateFormData = (paymentMethodData: PaymentMethodDto) => {
  const formData = new FormData();
  if (paymentMethodData.name) {
    formData.append('name', paymentMethodData.name);
  }
  if (paymentMethodData.maxLimit) {
    formData.append('maxLimit', paymentMethodData.maxLimit.toString());
  }
  if (paymentMethodData.storeIds) {
    paymentMethodData.storeIds.forEach((storeId) => formData.append('storeIds[]', storeId));
  }

  if (paymentMethodData.minLimit) formData.append('minLimit', paymentMethodData.minLimit.toString());

  if (paymentMethodData.image) {
    formData.append('image', paymentMethodData.image);
  }

  if (paymentMethodData.description) formData.append('description', paymentMethodData.description);
  formData.append('isActive', paymentMethodData.isActive ? '1' : '0');
  return formData;
};

const manufacturerCreateOrUpdateFormData = (manufacturer: CreateManufacturerDto) => {
  const formData = new FormData();
  if (manufacturer.name) {
    formData.append('name', manufacturer.name);
  }
  if (manufacturer.companyName) {
    formData.append('companyName', manufacturer.companyName);
  }
  if (manufacturer.address) formData.append('address', manufacturer.address);

  if (manufacturer.image) {
    formData.append('image', manufacturer.image);
  }

  if (manufacturer.history) formData.append('history', manufacturer.history);
  if (manufacturer.origin) formData.append('origin', manufacturer.origin);
  if (manufacturer.storeId) formData.append('storeId', manufacturer.storeId.toString());
  return formData;
};

const setOrderStatusFormData = (data: any) => {
  const formData = new FormData();

  formData.append('status', data.status);

  if (data.packageImage) {
    formData.append('packageImageFile', data.packageImageFile);
  }

  if (data.cargoCode) {
    formData.append('cargoCode', data.cargoCode);
  }

  if (data.cargoBranch) {
    formData.append('cargoBranch', data.cargoBranch);
  }

  if (data.cargoCode) {
    formData.append('cargoCode', data.cargoCode);
  }
  return formData;
};

const OrderApi = {
  // URL yazarken create vs yazmıyoruz artık
  URL_GET_EXIT_ORDERS: '/order/exitList',
  URL_CHECK_ORDER_PRODUCT_BARCODE: (orderId: number) => `/order/${orderId}/checkOrderProductBarcode`,
  URL_CREATE_MANUFACTURER: `/manufacturer/`,
  URL_MANUFACTURER_WITH_ID: (id: number) => `/manufacturer/${id}`,
  URL_PAYMENT_METHOD: '/paymentMethod/',
  URL_PAYMENT_METHOD_WITH_ID: (id: number) => `/paymentMethod/${id}`,
  URL_ORDER_WITH_ID: (id: number) => `/order/${id}`,
  URL_ORDER_NOTE: `/order/notes`,

  URL_ORDER_ACTION_LOG_WITH_ID: (orderId: number) => `/order/actions/${orderId}`,

  URL_SHIPMENT_METHOD: '/shipmentMethod/',
  URL_GET_CARGO_COMPANIES: '/order/getCargoCompanies',
  URL_SET_ORDER_STATUS: (orderId: number) => `/order/setOrderStatus/${orderId}`,
  URL_SHIPMENT_METHOD_WITH_ID: (id: number) => `/shipmentMethod/${id}`,
  URL_CATEGORY: '/category/',
  URL_CATEGORY_WITH_ID: (id: number) => `/category/${id}`,
  URL_ORDER_REFUND: '/order/return',
  URL_CREATE_PAYNET_LINK: '/order/createPaymentLink',
  URL_SEND_EINVOICE: (orderId: number) => `/invoice/create`,
  URL_GET_N11_CATEGORIES: `/marketplace/n11/getCategories`,
  URL_GET_SHIPMENT_TEMPLATES: `/marketplace/n11/getShipmentTemplates`,
  URL_GET_N11_MATCHED_CATEGORIES: `/marketplace/n11/getMatchedCategories`,
  URL_MATCH_CATEGORIES_N11: `/marketplace/n11/matchCategory`,

  async createManufacturer(createManufacturerDto: CreateManufacturerDto): Promise<ApiResponse<CreateManufacturerDto>> {
    const manufacturer = manufacturerCreateOrUpdateFormData(createManufacturerDto);
    const response = await ecmsClient.post(this.URL_CREATE_MANUFACTURER, manufacturer);
    return response.data;
  },

  async orderRefund(orderId: number, returnType: string, amount?: string) {
    const response = await ecmsClient.post(this.URL_ORDER_REFUND, {
      returnType,
      orderId,
      amount: amount || null,
    });
    return response.data;
  },

  async getShipmentTemplates(): Promise<ApiResponse> {
    const response = await ecmsClient.get(this.URL_GET_SHIPMENT_TEMPLATES);
    return response.data;
  },

  async getMpCategory(parentId?: number): Promise<ApiResponse<MPCategoryList[]>> {
    const response = await ecmsClient.get(this.URL_GET_N11_CATEGORIES, { params: { parentId } });
    return response.data;
  },

  async getN11MatchedCategories(
    filterRequest?: FilterRequest,
  ): Promise<ApiResponse<PageResponse<N11MatchedCategories>>> {
    const response = await ecmsClient.get(this.URL_GET_N11_MATCHED_CATEGORIES, { params: filterRequest });
    return response.data;
  },

  async N11MatchCategories(data?: any): Promise<ApiResponse<N11MatchedCategories>> {
    const response = await ecmsClient.post(this.URL_MATCH_CATEGORIES_N11, data);

    return response.data;
  },
  async createPaynetLink(createPaynet: any) {
    const response = await ecmsClient.post(this.URL_CREATE_PAYNET_LINK, {
      clientId: createPaynet.clientId,
      productIds: createPaynet.productIds,
    });
    return response.data;
  },

  async createCategory(categoryDto: Category): Promise<ApiResponse<Category>> {
    const response = await ecmsClient.post(this.URL_CATEGORY, categoryDto);
    return response.data;
  },

  // async sendEInvoice(orderId: number): Promise<ApiResponse<any>>{
  //   const response = await ecmsClient
  // }

  async updateCategory(id: number, categoryDto: Category): Promise<ApiResponse<Category>> {
    const response = await ecmsClient.post(this.URL_CATEGORY_WITH_ID(id), categoryDto);
    return response.data;
  },

  async getCategories(): Promise<ApiResponse<PageResponse<Category>>> {
    const response = await ecmsClient.get(this.URL_CATEGORY);
    return response.data;
  },

  async getCategory(id: number): Promise<ApiResponse<Category>> {
    const response = await ecmsClient.get(this.URL_CATEGORY_WITH_ID(id));
    return response.data;
  },

  async getManufacturer(id: number): Promise<ApiResponse<Manufacturer>> {
    const response = await ecmsClient.get(this.URL_MANUFACTURER_WITH_ID(id));
    return response.data;
  },

  async updateManufacturer(
    id: number,
    updateManufacturerDto: CreateManufacturerDto,
  ): Promise<ApiResponse<CreateManufacturerDto>> {
    const manufacturer = manufacturerCreateOrUpdateFormData(updateManufacturerDto);
    const response = await ecmsClient.post(this.URL_MANUFACTURER_WITH_ID(id), manufacturer);
    return response.data;
  },
  async createPaymentMethod(paymentMethodDto: PaymentMethodDto): Promise<ApiResponse<PaymentMethodDto>> {
    const paymentData = paymentMethodCreateOrUpdateFormData(paymentMethodDto);
    const response = await ecmsClient.post(this.URL_PAYMENT_METHOD, paymentData);
    return response.data;
  },

  async updatePaymentMethod(id: number, paymentMethodDto: PaymentMethodDto): Promise<ApiResponse<PaymentMethodDto>> {
    const paymentData = paymentMethodCreateOrUpdateFormData(paymentMethodDto);
    const response = await ecmsClient.post(this.URL_PAYMENT_METHOD_WITH_ID(id), paymentData);
    return response.data;
  },

  async createShipmentMethod(shippingMethodDto: ShipmentMethodDto): Promise<ApiResponse<ShipmentMethodDto>> {
    const shipmentMethod = shipmentCreateOrUpdateFormData(shippingMethodDto);
    const response = await ecmsClient.post(this.URL_SHIPMENT_METHOD, shipmentMethod);
    return response.data;
  },

  async updateShipmentMethod(
    id: number,
    shippingMethodDto: ShipmentMethodDto,
  ): Promise<ApiResponse<ShipmentMethodDto>> {
    const shipmentMethod = shipmentCreateOrUpdateFormData(shippingMethodDto);
    const response = await ecmsClient.post(this.URL_SHIPMENT_METHOD_WITH_ID(id), shipmentMethod);
    return response.data;
  },

  async getPaymentMethods(filterSupplierRequest?: FilterRequest): Promise<ApiResponse<PageResponse<PaymentMethodDto>>> {
    const response = await ecmsClient.get(this.URL_PAYMENT_METHOD, {
      params: filterSupplierRequest,
    });
    return response.data;
  },

  async getPaymentMethod(id: number): Promise<ApiResponse<PaymentMethodDto>> {
    const response = await ecmsClient.get(this.URL_PAYMENT_METHOD_WITH_ID(id));
    return response.data;
  },

  async getShipmentMethods(
    filterSupplierRequest?: FilterRequest,
  ): Promise<ApiResponse<PageResponse<ShipmentMethodDto>>> {
    const response = await ecmsClient.get(this.URL_SHIPMENT_METHOD, {
      params: filterSupplierRequest,
    });
    return response.data;
  },

  async getShipmentMethod(id: number): Promise<ApiResponse<ShipmentMethodDto>> {
    const response = await ecmsClient.get(this.URL_SHIPMENT_METHOD_WITH_ID(id));
    return response.data;
  },
  async getOrderNotes(orderId: any) {
    console.log('aaa', orderId);

    const response = await ecmsClient.get(this.URL_ORDER_NOTE, {
      params: { orderId },
    });
    console.log(response);

    return response.data;
  },
  async getManufacturers(filterSupplierRequest?: FilterRequest): Promise<ApiResponse<PageResponse<Manufacturer>>> {
    const response = await ecmsClient.get(this.URL_CREATE_MANUFACTURER, {
      params: filterSupplierRequest,
    });
    return response.data;
  },

  async getOrder(id: number): Promise<ApiResponse<Order>> {
    const response = await ecmsClient.get(this.URL_ORDER_WITH_ID(id));
    return response.data;
  },

  async createOrderNote(orderNote: OrderNote): Promise<ApiResponse<OrderNote[]>> {
    console.log(orderNote);

    const response = await ecmsClient.post(this.URL_ORDER_NOTE, orderNote);

    return response.data;
  },

  async getUserOrderActionLog(id: number): Promise<ApiResponse<OrderActionLog[]>> {
    const response = await ecmsClient.get(this.URL_ORDER_ACTION_LOG_WITH_ID(id));
    return response.data;
  },

  async getExitOrders(filterRequest?: FilterRequest): Promise<ApiResponse<PageResponse<Order>>> {
    const response = await ecmsClient.get(this.URL_GET_EXIT_ORDERS, {
      params: filterRequest,
    });
    return response.data;
  },

  async checkOrderProductBarcode(orderId: number, productBarcode: string) {
    const response = await ecmsClient.post(this.URL_CHECK_ORDER_PRODUCT_BARCODE(orderId), {
      barcode: productBarcode,
    });
    return response.data;
  },

  getShipmentFormLink(orderId: number): string {
    return `${ecmsClient.defaults.baseURL}/order/${orderId}/shipmentForm`;
  },

  async setOrdreStatus(orderId: number, data) {
    const formData = setOrderStatusFormData(data);
    const response = await ecmsClient.post(this.URL_SET_ORDER_STATUS(orderId), formData);
    return response.data;
  },

  async getCargoCompanies(): Promise<ApiResponse<CargoCompany[]>> {
    const response = await ecmsClient.get(this.URL_GET_CARGO_COMPANIES);
    return response.data;
  },
};

export default OrderApi;

import React from 'react';
import { TableState } from 'core/models';
import { TranslatedMaterialTable } from 'core/components';
import { OrderApi } from 'apps/order/api';
import { Category, Manufacturer } from 'apps/crm';
import { useHistory } from 'react-router-dom';
import { Api } from 'core';
import { Visibility as VisibilityIcon } from '@material-ui/icons';

const CategoryListPage = () => {
  const [showLoading, setShowLoading] = React.useState(false);
  const history = useHistory();
  const [tableState] = React.useState<TableState<Category>>({
    columns: [{ title: 'İsim', field: 'name', cellStyle: { whiteSpace: 'nowrap' } }],
    actions: [
      {
        icon: 'edit',
        tooltip: 'Düzenle',
        onClick: (event, rowData) => {
          const category = rowData as Category;
          history.push(`/orders/category/update/${category.id}`);
        },
      },
      {
        tooltip: 'Detaylar',
        icon: (props) => <VisibilityIcon {...props} />,
        onClick: (event, rowData) => {
          const category = rowData as Category;
          history.push(`/orders/category/detail/${category.id}`);
        },
      },
    ],
  });
  return (
    <TranslatedMaterialTable
      isLoading={showLoading}
      actions={tableState.actions}
      columns={tableState.columns}
      data={(q) =>
        new Promise((resolve) => {
          setShowLoading(true);
          Api.Crm.getCategories({
            page: q.page,
          })
            .then((response) => response.data)
            .then((data) => {
              if (data) {
                resolve({
                  page: q.page,
                  totalCount: data.totalCount,
                  data: data.data,
                });
              }
            });
          setShowLoading(false);
        })
      }
      title=""
    />
  );
};
export default CategoryListPage;

import {
  CategoryAutoCompleteSelect,
  FormAutocomplete,
  FormHeader,
  LabeledCheckbox,
  SuccessButton,
  TranslatedMaterialTable,
  UploadButton,
} from 'core/components';
import TableState from 'core/models/TableState';
import React from 'react';
import { Formik, Form } from 'formik';
import { TextField, Grid, CardContent, Card, CardActions, Box } from '@material-ui/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Api, showToast } from 'core';
import { Category } from 'apps/crm';

const CreateCategoryForm = () => {
  const attributeRef = React.useRef<any>();
  const { id }: any = useParams();
  const dispatch = useDispatch();
  const [attribute, setAttribute] = React.useState<any>([]);
  const [categoryPhoto, setCategoryPhoto] = React.useState();
  const categoryPhotoPreview = React.useRef(null);
  const [showLoading, setShowLoading] = React.useState(false);
  const [category, setCategory] = React.useState<Category>();
  const onSubmit = async (data) => {
    try {
      const category: Category = {
        parentId: data.parentId.id,
        details: [
          {
            languageId: 3,
            name: data.name,
          },
        ],
      };
      if (id) {
        await Api.Order.updateCategory(id, category);
      } else {
        await Api.Order.createCategory(category);
      }
      dispatch(
        showToast({
          message: `başarıyla ${id ? 'güncellendi!' : 'oluşturuldu!'}`,
        }),
      );
    } catch (err) {
      dispatch(
        showToast({
          message: `Hata oluştu`,
          severity: 'error',
        }),
      );
    }
  };
  const newAttributeTableState: TableState<any> = {
    columns: [
      {
        title: 'Zorunlu mu',
        field: 'required',
        render: (val) => (val.required ? 'Evet' : 'Hayır'),
        editComponent: (tableData) => (
          <LabeledCheckbox
            checked={tableData.rowData.required}
            label=""
            onChange={(e) => {
              if (e) tableData.onChange(e.target.checked);
            }}
            name="required"
          />
        ),
      },
      {
        title: 'Özellik Adı',
        field: 'attributeName',
        validate: (rowData) =>
          !rowData.attributeName || rowData.attributeName.length === 0 ? 'Özellik Boş Olamaz' : '',
      },
    ],
  };

  const onCategoryPhotoSelected = (event) => {
    const { target } = event;

    if (target.files || target.files.length === 1) {
      const file = target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        const currentElem: any = categoryPhotoPreview.current;
        if (currentElem) {
          currentElem.src = e.target?.result;
        }
      };

      reader.readAsDataURL(file);

      setCategoryPhoto(file);
    }
  };

  const categoryCreateSchema = yup.object({
    name: yup.string().required('Ad Boş Olamaz'),
  });

  const pullCategory = async () => {
    const response = await Api.Order.getCategory(id);
    if (response.data) setCategory(response.data);
  };

  React.useEffect(() => {
    if (id) pullCategory();
  }, []);

  if (id && !category) {
    return null;
  }

  return (
    <React.Fragment>
      <Formik
        onSubmit={onSubmit}
        validationSchema={categoryCreateSchema}
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
          name: category?.name,
          // categoryAdminId: '',
          parentId: category ? { id: category?.parentId } : undefined,
        }}
      >
        {({ handleSubmit, handleChange, values, touched, isValid, errors, setFieldValue }) => (
          <Form onSubmit={handleSubmit} id="createForm" noValidate>
            <Card>
              <CardContent>
                {/* <MessageDialog
                  formId="createForm"
                  showDialog={showDuplicateMailAlert}
                  title="Email Hatalı"
                  onHideDialog={() => {
                    setShowDuplicateMailAlert(false);
                  }}
                >
                  Bu kullanıcı mail adresi sistemde bulunmaktadır. Lütfen yeni bir mail adresi giriniz.
                </MessageDialog> */}
                {/* <MessageDialog
                  formId="createForm"
                  showDialog={createUserSuccess}
                  title="Başarılı"
                  onHideDialog={() => {
                    setCreateUserSuccess(false);
                  }}
                >
                  Kullanıcı başarılı bir şekilde oluşturuldu!
                </MessageDialog> */}
                <Grid direction="row" container spacing={5}>
                  <FormHeader title="Kategori Bilgileri" />
                  <Grid item xs={12} md={6} lg={4}>
                    <TextField
                      required
                      label="Kategori Adı"
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      placeholder="Kategori Adı"
                      name="name"
                      onChange={handleChange}
                      value={values.name}
                      error={errors.name != null}
                      helperText={errors.name}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    <CategoryAutoCompleteSelect
                      placeholder="Üst Kategori"
                      optionLabelName="name"
                      equValueName="id"
                      onChange={(event, category) => setFieldValue('parentId', category)}
                      error={errors.parentId != null}
                      value={values.parentId}
                      multiple={false}
                      helperText={errors.parentId}
                      fullWidth
                    />
                  </Grid>

                  {/* <Grid item xs={12} md={6} lg={4}>
                    <FormAutocomplete
                      placeholder="Kategori Yöneticisi seçiniz"
                      options={[
                        { name: 'Bugra', id: 1 },
                        { name: 'Cimedur', id: 2 },
                      ]}
                      optionLabelName="name"
                      equValueName="id"
                      onChange={(categoryAdminId) => setFieldValue('categoryAdminId', categoryAdminId)}
                      error={errors.categoryAdminId != null}
                      helperText={errors.categoryAdminId}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} md={6} lg={4}>
                    {categoryPhoto != null && (
                      <Grid container justify="center">
                        <img src="#" alt="" height={100} width={100} ref={categoryPhotoPreview} />
                      </Grid>
                    )}
                    <UploadButton
                      buttonProps={{
                        type: 'button',
                      }}
                      onChange={onCategoryPhotoSelected}
                    >
                      Kategori Görseli Yükle
                    </UploadButton>
                  </Grid> */}
                </Grid>
              </CardContent>
            </Card>
          </Form>
        )}
      </Formik>
      <Grid item xs={12}>
        <TranslatedMaterialTable
          title="Özellik Ekleme"
          columns={newAttributeTableState.columns}
          actions={newAttributeTableState.actions}
          data={attribute}
          tableRef={attributeRef}
          options={{
            pageSize: Math.max(5, attribute.length),
            search: false,
          }}
          editable={{
            isEditable: () => true,
            isDeletable: () => true,
            onRowAdd: (newData: any) => {
              return new Promise((resolve) => {
                const newAttributeData = [...attribute];
                newAttributeData.push(newData);
                setAttribute(newAttributeData);
                resolve();
              });
            },
            onRowUpdate: (newData: any, oldData?: any) => {
              return new Promise((resolve) => {
                const dataUpdate = [...attribute];
                const index = oldData.tableData.id;
                dataUpdate[index] = newData;
                setAttribute([...dataUpdate]);
                resolve();
              });
            },
            onRowDelete: (oldData: any) => {
              return new Promise((resolve) => {
                const dataDelete = [...attribute];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);
                setAttribute([...dataDelete]);
                resolve();
              });
            },
          }}
        />
      </Grid>
      <Box alignItems="center" justifyContent="flex-end" display="flex" className="w-100">
        <SuccessButton form="createForm" type="submit" startIcon={<SaveOutlinedIcon />} showLoading={showLoading}>
          {id ? 'KATEGORİ GÜNCELLE' : 'KATEGORİ EKLE'}
        </SuccessButton>
      </Box>
    </React.Fragment>
  );
};

export default CreateCategoryForm;

/* eslint-disable react/jsx-key */
// @ts-nocheck
import React from 'react';

import { Api } from 'core';
import { Card, Grid, CardContent } from '@material-ui/core';
import { useParams, useHistory } from 'react-router-dom';
import { FormHeader, DetailFieldLabel } from 'core/components';
import { Campaign } from 'apps/campaign/models';
import { Visibility } from '@material-ui/icons';
import { Store } from 'apps/store/models';

const StoreDetailPage = () => {
  const { id } = useParams();
  const [store, setStore] = React.useState<Store>();
  const history = useHistory();
  const pullStore = async () => {
    const response = await Api.Store.getStore(id);
    if (response.data) setStore(response.data);
  };

  React.useEffect(() => {
    pullStore();
  }, []);

  return (
    <React.Fragment>
      <Card>
        {store && (
          <CardContent>
            <Grid container spacing={3}>
              <FormHeader title="Mağaza Bilgileri" />
              <Grid item lg={2} md={3} xs={12}>
                <DetailFieldLabel title="İsim" label={store.name} />
              </Grid>
              <Grid item lg={2} md={3} xs={12}>
                <DetailFieldLabel title="Aktiflik Durumu" label={store?.isActive ? 'Aktif' : 'Pasif'} />
              </Grid>
              <Grid item lg={2} md={3} xs={12}>
                <DetailFieldLabel title="Email" label={store?.email} />
              </Grid>
              <Grid item lg={2} md={3} xs={12}>
                <DetailFieldLabel title="Telefon No" label={store?.gsm} />
              </Grid>
              <Grid item lg={2} md={3} xs={12}>
                <DetailFieldLabel title="Oluşturulma Tarihi" label={store?.createdAt} />
              </Grid>
            </Grid>
          </CardContent>
        )}
      </Card>
    </React.Fragment>
  );
};

export default StoreDetailPage;

import React from 'react';
import { Fade, DialogTitle, DialogContent, DialogActions, Button, Dialog, Grid, MenuItem } from '@material-ui/core';
import { Api, FormSelect, FormTextField, GenericMessages, ConfirmDialog } from 'core';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { SuccessButton } from 'core/components/Buttons';
import { Save as SaveIcon, Visibility, Send } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Formik } from 'formik';
import * as yup from 'yup';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Fade ref={ref} {...props} />;
});

export interface EinvoiceDialogProp {
  show: boolean;
  onClose: () => void;
  orderId: number;
}

const EinvoiceDialog: React.FC<EinvoiceDialogProp> = ({ show, onClose, orderId }) => {
  const [isLoading, setLoading] = React.useState(false);
  const [einvoiceConfirm, setEinvoiceConfirm] = React.useState(false);
  const [isPreview, setIsPreview] = React.useState<boolean>();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const showDraftInvoice = () => {
    window.open(`https://ecms.tekbas.com/invoice/draft?order=${orderId}`, '_blank');
    setIsPreview(false);
    onClose();
  };

  const downloadRealInvoice = () => {
    window.open(`https://ecms.tekbas.com/invoice/create?order=${orderId}`, '_blank');
    setIsPreview(false);
    setEinvoiceConfirm(false);
    setLoading(false);
    onClose();
  };

  const sendGIB = () => {
    setEinvoiceConfirm(true);
  };

  const onCancel = () => {
    onClose();
    setLoading(false);
  };

  // @ts-ignore
  return (
    <Dialog
      open={show}
      fullScreen={fullScreen}
      TransitionComponent={Transition}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      onClose={onClose}
    >
      <DialogTitle> E-Fatura Oluştur</DialogTitle>
      <DialogContent>
        <ConfirmDialog
          title="E-Fatura Onayı"
          open={einvoiceConfirm}
          handleConfirm={() => {
            downloadRealInvoice();
          }}
          confirmText="Faturayı Gönder"
          handleClose={() => {
            setEinvoiceConfirm(false);
            setLoading(false);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary" disabled={isLoading}>
          İptal
        </Button>
        <Button color="secondary" onClick={showDraftInvoice} startIcon={<Visibility />}>
          Taslak Fatura Göster
        </Button>

        <Button color="primary" onClick={sendGIB} startIcon={<Send />}>
          Faturayı GİB'e Gönder
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EinvoiceDialog;

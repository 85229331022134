import { createSlice } from '@reduxjs/toolkit';
import { AuthState } from './types';
import { setUserInfo, logout, setSessionToken, setToken } from './actions';
import { AuthApi } from '../api';

const initialAuthState: AuthState = {
  isAuth: false,
};

function __setUserInfo(state, userInfo) {
  localStorage.setItem('userInfo', JSON.stringify(userInfo));
  state.userInfo = userInfo;
}

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setUserInfo, (state, action) => {
      const userInfo = action.payload;
      __setUserInfo(state, userInfo);
    });

    builder.addCase(setToken, (state, action) => {
      const token = action.payload;
      console.log(token);
      localStorage.setItem('token', token);
      state.token = token;
    });

    builder.addCase(setSessionToken, (state, action) => {
      const sessionToken = action.payload;
      localStorage.setItem('sessionToken', sessionToken);
      state.sessionToken = sessionToken;
    });

    builder.addCase(logout, (state) => {
      localStorage.removeItem('token');
      localStorage.removeItem('sessionToken');
      localStorage.removeItem('userInfo');
      state.sessionToken = undefined;
      state.userInfo = undefined;
      state.token = undefined;
    });
  },
});

export default authSlice.reducer;

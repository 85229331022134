import { Formik, Form } from 'formik';
import React from 'react';
import { Grid, CardContent, Card, CardActions, Box } from '@material-ui/core';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import * as yup from 'yup';
import {
  ClientAutoCompleteSelect,
  FormHeader,
  FormTextField,
  ProductAutoCompleteSelect,
  SuccessButton,
} from 'core/components';
import { Api, GenericMessages, showToast } from 'core';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const CreateOrderPage = () => {
  const [showLoading, setShowLoading] = React.useState(false);

  const onCreateOrderSubmit = (data) => {
    setShowLoading(true);
    const createOrder: any = {
      productIds: data.products.map((product) => {
        return product.id;
      }),
      clientId: data.client.id,
      shippingGsm: data.shippingGsm,
      shippingProvince: data.shippingProvince,
      shippingDistrict: data.shippingDistrict,
      shippingAddress: data.shippingAddress,
      invoiceGsm: data.shippingInvoiceGsm,
      invoiceAddress: data.invoiceAddress,
      invoiceGovId: data.invoiceGovId,
      invoiceTaxNo: data.invoiceTaxNo,
      invoiceTaxOffice: data.invoiceTaxOffice,
    };
    console.log(createOrder);
    setShowLoading(false);
  };

  const schema = yup.object({
    products: yup
      .array()
      .required(GenericMessages.CANNOT_BE_EMPTY)
      .of(
        yup.object().shape({
          id: yup.number().strict(true).required(GenericMessages.CANNOT_BE_EMPTY),
        }),
      ),
    client: yup.object().shape({
      id: yup.number().required(GenericMessages.CANNOT_BE_EMPTY),
    }),
    shippingGsm: yup
      .string()
      .test('empty', GenericMessages.CANNOT_BE_EMPTY, (val: any) => val && val.toString().length > 0)
      .test('len', GenericMessages.PHONE_NO_LENGTH, (val: any) => val && val.toString().length === 10),
    shippingProvince: yup.number().required(GenericMessages.CANNOT_BE_EMPTY),
    shippingDistrict: yup.number().required(GenericMessages.CANNOT_BE_EMPTY),
    shippingAddress: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
    invoiceAddress: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
    invoiceGsm: yup
      .string()
      .test('empty', GenericMessages.CANNOT_BE_EMPTY, (val: any) => val && val.toString().length > 0)
      .test('len', GenericMessages.PHONE_NO_LENGTH, (val: any) => val && val.toString().length === 10),
    invoiceGovId: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
    invoiceTaxNo: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
    invoiceTaxOffice: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
  });
  return (
    <Formik
      onSubmit={onCreateOrderSubmit}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        client: {},
        products: [],
        shippingAddress: '',
        shippingGsm: '',
        shippingProvince: '',
        shippingDistrict: '',
        invoiceAddress: '',
        invoiceGsm: '',
        invoiceGovId: '',
        invoiceTaxNo: '',
        invoiceTaxOffice: '',
      }}
    >
      {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
        <Form onSubmit={handleSubmit} id="form" noValidate>
          <Card>
            <CardContent>
              <Grid direction="row" container spacing={5}>
                <FormHeader title="Sipariş Bilgileri" />
                <Grid item xs={12} md={6} lg={6}>
                  <ProductAutoCompleteSelect
                    placeholder="Ürünler"
                    onChange={(event, products) => setFieldValue('products', products)}
                    error={errors.products != null}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    helperText={errors.products}
                    value={values.products}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <ClientAutoCompleteSelect
                    placeholder="Müşteri Seçiniz"
                    optionLabelName="name"
                    equValueName="id"
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    helperText={errors.client?.id as string}
                    error={errors.client != null}
                    value={values.client}
                    onChange={(event, client) => setFieldValue('client', client)}
                    multiple={false}
                    fullWidth
                  />
                </Grid>
                <FormHeader title="Kargo Bilgileri" />

                <Grid item xs={12} md={6} lg={6}>
                  <FormTextField
                    required
                    label="Kargo Gsm"
                    fullWidth
                    placeholder="Kargo Gsm"
                    name="shippingGsm"
                    onChange={handleChange}
                    value={values.shippingGsm}
                    error={errors.shippingGsm != null}
                    helperText={errors.shippingGsm}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormTextField
                    required
                    label="Kargo İl"
                    fullWidth
                    placeholder="Kargo İl"
                    name="shippingProvince"
                    onChange={handleChange}
                    value={values.shippingProvince}
                    error={errors.shippingProvince != null}
                    helperText={errors.shippingProvince}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormTextField
                    required
                    label="Kargo İlçe"
                    fullWidth
                    placeholder="Kargo İlçe"
                    name="shippingDistrict"
                    onChange={handleChange}
                    value={values.shippingDistrict}
                    error={errors.shippingDistrict != null}
                    helperText={errors.shippingDistrict}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormTextField
                    required
                    label="Kargo Adresi"
                    fullWidth
                    placeholder="Kargo Adresi"
                    name="shippingAddress"
                    onChange={handleChange}
                    value={values.shippingAddress}
                    error={errors.shippingAddress != null}
                    helperText={errors.shippingAddress}
                    multiline
                    rows={4}
                  />
                </Grid>
                <FormHeader title="Fatura Bilgileri" />
                <Grid item xs={12} md={6} lg={6}>
                  <FormTextField
                    required
                    label="Fatura Gsm"
                    fullWidth
                    placeholder="Fatura Gsm"
                    name="invoiceGsm"
                    onChange={handleChange}
                    value={values.invoiceGsm}
                    error={errors.invoiceGsm != null}
                    helperText={errors.invoiceGsm}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormTextField
                    required
                    label="Fatura TC"
                    fullWidth
                    placeholder="Fatura TC"
                    name="invoiceGovId"
                    onChange={handleChange}
                    value={values.invoiceGovId}
                    error={errors.invoiceGovId != null}
                    helperText={errors.invoiceGovId}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormTextField
                    required
                    label="Fatura Veri No"
                    fullWidth
                    placeholder="Fatura Vergi No"
                    name="invoiceTaxNo"
                    onChange={handleChange}
                    value={values.invoiceTaxNo}
                    error={errors.invoiceTaxNo != null}
                    helperText={errors.invoiceTaxNo}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormTextField
                    required
                    label="Fatura Vergi Daire"
                    fullWidth
                    placeholder="Fatura Vergi Daire"
                    name="invoiceTaxOffice"
                    onChange={handleChange}
                    value={values.invoiceTaxOffice}
                    error={errors.invoiceTaxOffice != null}
                    helperText={errors.invoiceTaxOffice}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <FormTextField
                    required
                    label="Fatura Adresi"
                    fullWidth
                    placeholder="Fatura Adresi"
                    name="invoiceAddress"
                    onChange={handleChange}
                    value={values.invoiceAddress}
                    error={errors.invoiceAddress != null}
                    helperText={errors.invoiceAddress}
                    multiline
                    rows={4}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Box alignItems="center" justifyContent="flex-end" display="flex" className="w-100">
                <SuccessButton form="form" type="submit" startIcon={<SaveOutlinedIcon />} showLoading={showLoading}>
                  Sipariş Oluştur
                </SuccessButton>
              </Box>
            </CardActions>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default CreateOrderPage;

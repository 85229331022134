import { Switch, Route } from 'react-router-dom';
import React from 'react';
import {
  CreateCampaignPage,
  UpdateCampaignPage,
  CampaignListPage,
  CampaignDetailPage,
  CampaignGiftCouponPage,
} from 'apps/campaign';
import { CreateOptionPage, OptionListPage, UpdateOptionPage } from 'apps/option';
import { CreateProductPage, ProductListPage, ProductDetailPage, ProductCommentsPage } from 'apps/product';
import { CreateOrderPage, CreatePaynetLink } from 'apps/order';
import {
  CreateClientClassPage,
  CRMClientListPage,
  CRMCreateOrUpdateClientPage,
  OrderListPage,
  ClientCommunicationPage,
  CRMClientDetailPage,
  BatchBrandModelPage,
  ProductsBatchPage,
} from '../pages';

interface CRMLayoutProps {
  match: any;
}

const CRMLayout: React.FC<CRMLayoutProps> = ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.path}/products/create`} component={CreateProductPage} />
      <Route exact path={`${match.path}/products/update/:id`} component={CreateProductPage} />
      <Route exact path={`${match.path}/option/create`} component={CreateOptionPage} />
      <Route exact path={`${match.path}/client/class/create`} component={CreateClientClassPage} />
      <Route exact path={`${match.path}/campaign/create`} component={CreateCampaignPage} />
      <Route exact path={`${match.path}/campaign/gift/create`} component={CampaignGiftCouponPage} />
      <Route exact path={`${match.path}/campaign/update/:id`} component={UpdateCampaignPage} />
      <Route exact path={`${match.path}/campaign/list`} component={CampaignListPage} />
      <Route exact path={`${match.path}/campaign/detail/:id`} component={CampaignDetailPage} />
      <Route exact path={`${match.path}/client/list`} component={CRMClientListPage} />
      <Route exact path={`${match.path}/option/list`} component={OptionListPage} />
      <Route exact path={`${match.path}/option/update/:id`} component={UpdateOptionPage} />
      <Route exact path={`${match.path}/product/list`} component={ProductListPage} />
      <Route exact path={`${match.path}/product/detail/:id`} component={ProductDetailPage} />
      <Route exact path={`${match.path}/client/detail/:id`} component={CRMClientDetailPage} />
      <Route exact path={`${match.path}/client/update/:id`} component={CRMCreateOrUpdateClientPage} />
      <Route exact path={`${match.path}/client/create/`} component={CRMCreateOrUpdateClientPage} />
      <Route exact path={`${match.path}/order/list/`} component={OrderListPage} />
      <Route exact path={`${match.path}/order/createPaynet/`} component={CreatePaynetLink} />
      <Route exact path={`${match.path}/order/create/`} component={CreateOrderPage} />
      <Route exact path={`${match.path}/products/create/batch/`} component={BatchBrandModelPage} />
      <Route exact path={`${match.path}/products/comments`} component={ProductCommentsPage} />
      <Route exact path={`${match.path}/products/batch`} component={ProductsBatchPage} />
      <Route exact path={`${match.path}/client/contact/`} component={ClientCommunicationPage} />
    </Switch>
  );
};

export default CRMLayout;

import { Book, ListAlt, List, PostAdd, Airplay } from '@material-ui/icons';
import { MenuBlockInfo } from 'core';
import React from 'react';
import { Copy, Cpu, Database, Edit2 } from 'react-feather';

const designSidebarMenuList: MenuBlockInfo = {
  id: 'designMenu',
  title: 'Tasarım',
  child: [
    {
      id: 'menuDesign',
      icon: <Book />,
      label: 'Menü Tasarımı',
      child: [
        {
          id: 'menuDesign',
          icon: <PostAdd />,
          label: 'Menü Tasarımı Ekle',
          route: '/design/menu/create/',
        },
        {
          id: 'menuDesign',
          icon: <List />,
          label: 'Menü Listesi',
          route: '/design/menu/list/',
        },
      ],
    },
    {
      id: 'sectionDesign',
      icon: <ListAlt />,
      label: 'Bölüm Tasarımı',
      child: [
        {
          id: 'sectionDesign',
          route: '/design/section/create',
          icon: <PostAdd />,
          label: 'Bölüm Tasarımı Ekle',
        },
        {
          id: 'sectionDesign',
          route: '/design/section/list',
          icon: <List />,
          label: 'Bölüm Listesi',
        },
      ],
    },
    {
      id: 'sectionDesign',
      icon: <Airplay />,
      label: 'Ürün Slider Tasarımı',
      child: [
        {
          id: 'sectionDesign',
          route: '/design/slider/create',
          icon: <PostAdd />,
          label: 'Ürün Slider Ekle',
        },
        {
          id: 'sectionDesign',
          route: '/design/slider/List',
          icon: <List />,
          label: 'Ürün Slider Listesi',
        },
      ],
    },
    {
      id: 'sectionDesign',
      icon: <Copy />,
      label: 'Görsel Slider Tasarımı',
      child: [
        {
          id: 'sectionDesign',
          route: '/design/bannerSlider/create',
          icon: <PostAdd />,
          label: 'Görsel Slider Tasarımı Ekle',
        },
        {
          id: 'sectionDesign',
          route: '/design/bannerSlider/list',
          icon: <List />,
          label: 'Görsel Slider Listesi',
        },
      ],
    },
    {
      id: 'informationDesing',
      route: '/design/informationPage/create',
      icon: <Edit2 />,
      label: 'Bilgi Sayfaları',
      child: [
        {
          id: 'informationDesing',
          route: '/design/informationPage/create',
          icon: <PostAdd />,
          label: 'Bilgi Sayfası Ekle',
        },
      ],
    },
    {
      id: 'sectionDesign',
      route: '/design/modules',
      icon: <Cpu />,
      label: 'Moduller',
      child: [
        {
          id: 'sectionDesign',
          icon: <ListAlt />,
          label: 'Montaj Yönetimi',
          child: [
            {
              id: 'informationDesing',
              route: '/design/modules/assembly/create',
              icon: <PostAdd />,
              label: 'Montaj Yeri Ekle',
            },
            {
              id: 'informationDesing',
              route: '/design/modules/assembly',
              icon: <List />,
              label: 'Montaj Yerleri',
            },
            {
              id: 'informationDesing',
              route: '/design/modules/assemblyTheme/create',
              icon: <PostAdd />,
              label: 'Montaj Teması Ekle',
            },
            {
              id: 'informationDesing',
              route: '/design/modules/assemblyTheme',
              icon: <List />,
              label: 'Montaj Temaları',
            },
          ],
        },
        {
          id: 'informationDesing',
          route: '/design/modules/xmlService/',
          icon: <ListAlt />,
          label: 'XML Servis Yönetimi',
        },
        {
          id: 'informationDesing',
          route: '/design/modules/excelProductUpdate/',
          icon: <ListAlt />,
          label: 'Excel Ürün Güncelleme',
        },
        {
          id: 'informationDesing',
          icon: <ListAlt />,
          label: 'Koşullu XML Export',
          child: [
            {
              id: 'informationDesing',
              route: '/design/modules/xmlExport/',
              icon: <PostAdd />,
              label: 'Xml Ekle',
            },
            {
              id: 'informationDesing',
              route: '/design/modules/xmlExport/list',
              icon: <List />,
              label: 'Xml Listesi',
            },
          ],
        },
      ],
    },
    {
      id: 'sectionDesign',
      route: '/design/b2bpanel',
      icon: <Database />,
      label: 'B2B Panel Yönetimi',
    },
  ],
};

export default designSidebarMenuList;

import { Store, ListAlt, LibraryAdd, PlusOne as PlusOneIcon, PostAdd, List } from '@material-ui/icons';
import { MenuBlockInfo } from 'core';
import React from 'react';
import { Layers } from 'react-feather';

const productsSidebarMenuList: MenuBlockInfo = {
  id: 'storeMenu',
  title: 'Mağaza İşlemleri',
  child: [
    {
      id: 'store',
      icon: <Store />,
      label: 'Mağaza',
      child: [
        {
          id: 'createStore',
          icon: <Store />,
          label: 'Mağaza Aç',
          route: '/store',
        },
        {
          id: 'storeList',
          route: '/store/list',
          icon: <List />,
          label: 'Mağaza Listesi',
        },
      ],
    },
    // {
    //   id: 'contract',
    //   icon: <Layers />,
    //   label: 'Sözleşmeler',
    //   child: [
    //     {
    //       id: 'createContract',
    //       icon: <PostAdd />,
    //       label: 'Sözleşme Ekle',
    //       route: '/store/contract/',
    //     },
    //     {
    //       id: 'contractList',
    //       icon: <List />,
    //       label: 'Sözleşme Listesi',
    //       route: '/store/contract/list',
    //     },
    //   ],
    // },
  ],
};

export default productsSidebarMenuList;

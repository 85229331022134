import { UserInfo } from '../models';

export const LOGIN = 'auth/Login';
export const SET_USER_INFO = 'auth/setUserInfo';
export const SET_TOKEN = '/auth/setToken';
export const SET_SESSION_TOKEN = '/auth/setSessionToken';
export const LOGOUT = 'auth/logout';
export const REFRESH_USER_INFO = 'auth/refreshUserInfo';

export interface AuthState {
  userInfo?: UserInfo;
  token?: string;
  sessionToken?: string;
  isAuth: boolean;
}

export interface LoginActionPayload {
  email: string;
  password: string;
}

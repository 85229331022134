import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { Box } from '@material-ui/core';

interface DraftEditorProps {
  data: any;
  onChange: (data: any) => void;
}

const DraftEditor: React.FC<DraftEditorProps> = ({ data, onChange }) => {
  const [editorState, setEditorState] = React.useState(
    EditorState.createWithContent(ContentState.createFromBlockArray(htmlToDraft(data || ''))),
  );
  const onDraftChanged = (e) => {
    setEditorState(e);
    const content = draftToHtml(convertToRaw(e.getCurrentContent()));
    onChange(content);
  };
  return (
    <Box border={1} borderColor="#ddd" borderRadius="3px">
      <Editor
        editorState={editorState}
        value={htmlToDraft(data || '')}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={onDraftChanged}
      />
    </Box>
  );
};

export default DraftEditor;

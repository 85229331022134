import React from 'react';
import { FormDialog, HideableLoadingSpinnerBox, TranslatedMaterialTable } from 'core/components';
import { TableState } from '../../../../core/models';
import { Api } from '../../../../core/api';

interface BatchBrandModelFormProps {
  handleVehicleDataDialogClose: any;
  showVehicleDataDialog: any;
  productId: any;
}

const ProductMatchedVehicleDataListDialog: React.FC<BatchBrandModelFormProps> = ({
  handleVehicleDataDialogClose,
  showVehicleDataDialog,
  productId,
}) => {
  const [vehicleData, setVehicleData] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);

  const deleteMatchedProduct = async (id) => {
    const result = await Api.Vehicle.deleteDataFromProduct(id);
    if (result.data) {
      setVehicleData(result.data);
      setLoading(false);
    }
  };
  const tableState: TableState<any> = {
    columns: [
      { title: 'Yıl', field: 'year', cellStyle: { whiteSpace: 'nowrap' } },

      { title: 'Marka', field: 'brand', cellStyle: { whiteSpace: 'nowrap' } },

      { title: 'Model', field: 'model', cellStyle: { whiteSpace: 'nowrap' } },
    ],
    options: { search: false },
    actions: [
      {
        icon: 'delete',
        tooltip: 'Sil',
        onClick: (event, rowData) => {
          const data = rowData;
          const newData = [...vehicleData];
          newData.splice(rowData.tableData.id, 1);
          setVehicleData(newData);
          deleteMatchedProduct(data.id);
        },
      },
    ],
  };
  async function getData(id) {
    const result = await Api.Vehicle.getDataFromProduct(id);
    if (result.data) {
      setVehicleData(result.data);
      setLoading(false);
    }
  }

  React.useEffect(() => {
    getData(productId);
  }, [productId]);

  return (
    <FormDialog
      fullWidth
      open={showVehicleDataDialog}
      title="Eşleştirilmiş Araçlar"
      formId="test"
      handleClose={() => {
        handleVehicleDataDialogClose();
      }}
    >
      <TranslatedMaterialTable
        data={vehicleData}
        title="Eşleştirilmiş Araçlar"
        actions={tableState.actions}
        columns={tableState.columns}
        options={tableState.options}
      />
      {loading && <HideableLoadingSpinnerBox />}
    </FormDialog>
  );
};

export default ProductMatchedVehicleDataListDialog;

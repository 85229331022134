import { Formik, Form } from 'formik';
import React from 'react';
import {
  TextField,
  Grid,
  CardContent,
  Card,
  CardActions,
  Box,
  Switch,
  FormControl,
  FormControlLabel,
} from '@material-ui/core';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import * as yup from 'yup';
import { FormHeader, SuccessButton, UploadButton } from 'core/components';
import GenericMessages from 'core/GenericMessages';
import ColorPicker from 'material-ui-color-picker';

const B2BPanelPage = () => {
  const [showLoading, setShowLoading] = React.useState(false);
  const [backgroundImage, setBackgroundImage] = React.useState();
  const backgroundImagePreview = React.useRef(null);
  const [advertisementImage, setAdvertisementImage] = React.useState();
  const advertisementImagePreview = React.useRef(null);

  const schema = yup.object({
    isActive: yup.boolean(),
  });

  const onSubmit = async (values, { resetForm }) => {
    setShowLoading(true);
    try {
      console.log('hh');
    } catch (e) {
      setShowLoading(false);
    }
  };
  const onBackgroundImageSelected = (event) => {
    const { target } = event;

    if (target.files || target.files.length === 1) {
      const file = target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        const currentElem: any = backgroundImagePreview.current;
        if (currentElem) {
          currentElem.src = e.target?.result;
        }
      };

      reader.readAsDataURL(file);
      setBackgroundImage(file);
    }
  };
  const onAdvertisementImageSelected = (event) => {
    const { target } = event;

    if (target.files || target.files.length === 1) {
      const file = target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        const currentElem: any = advertisementImagePreview.current;
        if (currentElem) {
          currentElem.src = e.target?.result;
        }
      };

      reader.readAsDataURL(file);
      setAdvertisementImage(file);
    }
  };
  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        primaryHexColorCode: '',
        secondaryHexColorCode: '',
        isActive: false,
      }}
    >
      {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
        <Form onSubmit={handleSubmit} id="form" noValidate>
          <Card>
            <CardContent>
              <FormHeader title="B2B Panel" />
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormControlLabel
                      value="end"
                      control={
                        <Switch
                          color="primary"
                          checked={values.isActive}
                          onChange={(e) => setFieldValue('isActive', e.target.checked)}
                        />
                      }
                      label="Durumu Seçiniz (Aktif veya Pasif)"
                      labelPlacement="end"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <ColorPicker
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    error={errors.primaryHexColorCode != null}
                    helperText={errors.primaryHexColorCode}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ zIndex: 999 }}
                    label="Renk"
                    placeholder={values.primaryHexColorCode ? `Renk ${values.primaryHexColorCode}` : `Renk`}
                    name="primaryHexColorCode"
                    value={values.primaryHexColorCode}
                    onChange={(color) => {
                      setFieldValue('primaryHexColorCode', color);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <ColorPicker
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    error={errors.secondaryHexColorCode != null}
                    helperText={errors.secondaryHexColorCode}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    style={{ zIndex: 999 }}
                    label="Renk"
                    placeholder={values.secondaryHexColorCode ? `Renk ${values.secondaryHexColorCode}` : `Renk`}
                    name="secondaryHexColorCode"
                    value={values.secondaryHexColorCode}
                    onChange={(color) => {
                      setFieldValue('secondaryHexColorCode', color);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  {backgroundImage != null && (
                    <Grid container justify="center">
                      <img src="#" alt="" height={100} width={100} ref={backgroundImagePreview} />
                    </Grid>
                  )}

                  <UploadButton
                    buttonProps={{
                      type: 'button',
                    }}
                    onChange={onBackgroundImageSelected}
                  >
                    Arkaplan Görseli Yükle
                  </UploadButton>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  {advertisementImage != null && (
                    <Grid container justify="center">
                      <img src="#" alt="" height={100} width={100} ref={advertisementImagePreview} />
                    </Grid>
                  )}

                  <UploadButton
                    buttonProps={{
                      type: 'button',
                    }}
                    onChange={onAdvertisementImageSelected}
                  >
                    Reklam Banner Görseli Yükle
                  </UploadButton>
                </Grid>
              </Grid>
            </CardContent>

            <CardActions>
              <Box alignItems="center" justifyContent="flex-end" display="flex" className="w-100">
                <SuccessButton form="form" type="submit" startIcon={<SaveOutlinedIcon />} showLoading={showLoading}>
                  Ayarları Kaydet
                </SuccessButton>
              </Box>
            </CardActions>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default B2BPanelPage;

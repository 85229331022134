import { Formik, Form } from 'formik';
import React from 'react';
import { TextField, Grid, CardContent, Card, CardActions, Box, MenuItem } from '@material-ui/core';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import {
  FormAutocomplete,
  FormHeader,
  FormSelect,
  ManufacturerAutoCompleteSelect,
  SuccessButton,
} from 'core/components';
import GenericMessages from 'core/GenericMessages';

interface TrendyolManufacturerSyncProps {
  formId: string;
  setShowProducerSyncDialog: (boolean) => void;
  setShowLoading: (boolean) => void;
}

const TrendyolManufacturerSync: React.FC<TrendyolManufacturerSyncProps> = ({
  formId,
  setShowProducerSyncDialog,
  setShowLoading,
}) => {
  const dispatch = useDispatch();
  const inputLabelProps = {
    shrink: true,
  };

  const schema = yup.object({
    targetCategoryId: yup.number().required(GenericMessages.CANNOT_BE_EMPTY),
    comissionRate: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
    extraProfit: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
    deliveryId: yup.number().required(GenericMessages.CANNOT_BE_EMPTY),
  });

  const onCategorySyncSubmit = async (values, { resetForm }) => {
    setShowLoading(true);
    try {
      console.log('hh');
    } catch (e) {
      setShowLoading(false);
    }

    setShowProducerSyncDialog(false);
    setShowLoading(false);
  };

  return (
    <Formik
      onSubmit={onCategorySyncSubmit}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        manufacturer: '',
        comissionRate: '',
      }}
    >
      {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
        <Form onSubmit={handleSubmit} id={formId} noValidate>
          <Card>
            <CardContent>
              <Grid direction="row" container spacing={5}>
                <Grid item xs={12} md={12} lg={12}>
                  <ManufacturerAutoCompleteSelect
                    fullWidth
                    value={values.manufacturer}
                    error={errors.manufacturer != null}
                    helperText={errors.manufacturer}
                    onChange={(event, value) => setFieldValue('manufacturer', value)}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    required
                    label="Komisyon Oranı"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    placeholder="Komisyon Oranı Giriniz"
                    name="comissionRate"
                    onChange={handleChange}
                    value={values.comissionRate}
                    error={errors.comissionRate != null}
                    helperText={errors.comissionRate}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default TrendyolManufacturerSync;

import * as colors from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';
import shadows from './Shadows';
import typography from './Typography';

const theme = createMuiTheme({
  palette: {
    background: {
      default: '#F4F6F8',
      // paper: colors.common.white,
    },
    primary: {
      main: '#968df4',
    },
    secondary: {
      main: '#ee9190',
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
    },
  },
  shadows,
  typography,
});

export default theme;

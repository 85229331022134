import { Switch, Route } from 'react-router-dom';
import React from 'react';
import { ProfilePage } from '../pages';

export interface ProfileLayoutProps {
  match?: any;
}

const ProfileLayout: React.FC<ProfileLayoutProps> = ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.path}/store/`} component={ProfilePage} />
    </Switch>
  );
};

export default ProfileLayout;

import { Formik, Form } from 'formik';
import React from 'react';
import { TextField, Grid, CardContent, Card, CardActions, Box } from '@material-ui/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import * as yup from 'yup';
import { FormHeader, SuccessButton, TranslatedDatePicker, UploadButton } from 'core/components';
import DateFnsUtils from '@date-io/date-fns';
import { toDate } from 'date-fns';
import { CreateManufacturerDto } from 'apps/order/models';
import { OrderApi } from 'apps/order/api';
import { useParams } from 'react-router-dom';
import { Api, showToast } from 'core';
import { Manufacturer } from 'apps/crm';
import { useSelector, useDispatch } from 'react-redux';
import { UserInfo } from 'apps/auth';

const CreateProducerForm = () => {
  const [createUserSuccess, setCreateUserSuccess] = React.useState(false);
  const [producerPhoto, setProducerPhoto] = React.useState();
  const [showLoading, setShowLoading] = React.useState(false);
  const producerPhotoPreview = React.useRef(null);
  const [manufacturer, setManufacturer] = React.useState<Manufacturer>();
  const userInfo: UserInfo = useSelector((state: any) => state.auth.userInfo);
  const { id }: any = useParams();
  const dispatch = useDispatch();
  // const [showDuplicateMailAlert, setShowDuplicateMailAlert] = React.useState(false);

  const inputLabelProps = {
    shrink: true,
  };

  const producerCreateSchema = yup.object({
    name: yup.string().required('Ad Boş Olamaz'),
    companyName: yup.string().required('Şirket Adı Boş Olamaz'),
    origin: yup.string().required('Menşei Boş Olamaz'),
  });

  // const isMailDuplicate = async (email: string) => {
  //   const mail = email.replace('s;', '');
  //   // eslint-disable-next-line no-return-await
  //   return await Api.User.isMailDuplicate(mail);
  // };

  const onProducerCreateSubmit = async (values, { resetForm }) => {
    setShowLoading(true);
    try {
      //   if (!(await isMailDuplicate(values.email))) {
      const newManufacturer: CreateManufacturerDto = {
        name: values.name,
        companyName: values.companyName,
        address: values.address,
        history: values.history,
        origin: values.origin,
        storeId: userInfo.storeId,
        image: producerPhoto || '',
      };
      if (id) await Api.Order.updateManufacturer(id, newManufacturer);
      else await Api.Order.createManufacturer(newManufacturer);
      dispatch(
        showToast({
          message: `başarıyla ${id ? 'güncellendi!' : 'oluşturuldu!'}`,
        }),
      );
      setCreateUserSuccess(true);
      resetForm({ values: '' }); // TODO: Çalışmıyor
      //   } else {
      //     setShowDuplicateMailAlert(true);
      //   }
      setShowLoading(false);
    } catch (e) {
      dispatch(
        showToast({
          message: `  hata  oluştu`,
          severity: 'error',
        }),
      );
    } finally {
      setShowLoading(false);
    }
    setShowLoading(false);
  };

  const onProducerPhotoSelected = (setFieldValue) => (event) => {
    const { target } = event;

    if (target.files || target.files.length === 1) {
      const file = target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        const currentElem: any = producerPhotoPreview.current;
        if (currentElem) {
          currentElem.src = e.target?.result;
          setFieldValue('image', e.target?.result);
        }
      };

      reader.readAsDataURL(file);

      setProducerPhoto(file);
    }
  };

  const pullManufacturer = async () => {
    const response = await Api.Order.getManufacturer(id);
    if (response.data) setManufacturer(response.data);
  };
  React.useEffect(() => {
    if (id) pullManufacturer();
  }, []);

  if (id && !manufacturer) {
    return null;
  }

  return (
    <Formik
      onSubmit={onProducerCreateSubmit}
      validationSchema={producerCreateSchema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        name: manufacturer?.name,
        companyName: manufacturer?.companyName,
        origin: manufacturer?.origin,
        address: manufacturer?.address,
        history: manufacturer?.history,
        image: manufacturer?.image || '',
      }}
    >
      {({ handleSubmit, handleChange, values, touched, isValid, errors, setFieldValue }) => (
        <Form onSubmit={handleSubmit} id="createForm" noValidate>
          <Card>
            <CardContent>
              <Grid direction="row" container spacing={5}>
                <FormHeader title="Üretici Bilgileri" />
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    required
                    label="Adı"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    placeholder="Adı"
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    error={errors.name != null}
                    helperText={errors.name}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    required
                    name="companyName"
                    label="Şirket Adı"
                    placeholder="Şirket Adı"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    onChange={handleChange}
                    value={values.companyName}
                    error={errors.companyName != null}
                    helperText={errors.companyName}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    required
                    error={errors.origin != null}
                    label="Menşe"
                    placeholder="Menşe"
                    variant="outlined"
                    name="origin"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    onChange={handleChange}
                    value={values.origin}
                    helperText={errors.origin}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    name="address"
                    label="Adres giriniz"
                    placeholder="Adres giriniz"
                    variant="outlined"
                    multiline
                    rows={10}
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    onChange={handleChange}
                    error={errors.address != null}
                    value={values.address}
                    helperText={errors.address}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    name="history"
                    label="Tarihçe"
                    placeholder="Tarihçe"
                    variant="outlined"
                    multiline
                    rows={10}
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    onChange={handleChange}
                    value={values.history}
                    error={errors.history != null}
                    helperText={errors.history}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  {(producerPhoto != null || values.image !== '') && (
                    <Grid container justify="center">
                      <img src={values.image} alt="" height={100} width={100} ref={producerPhotoPreview} />
                    </Grid>
                  )}
                  <UploadButton
                    buttonProps={{
                      type: 'button',
                    }}
                    onChange={onProducerPhotoSelected(setFieldValue)}
                  >
                    Üretici Görseli Yükle
                  </UploadButton>
                </Grid>
              </Grid>
            </CardContent>

            <CardActions>
              <Box alignItems="center" justifyContent="flex-end" display="flex" className="w-100">
                <SuccessButton type="submit" startIcon={<SaveOutlinedIcon />} showLoading={showLoading}>
                  Kaydet
                </SuccessButton>
              </Box>
            </CardActions>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default CreateProducerForm;

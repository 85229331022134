import { createAction } from '@reduxjs/toolkit';
// import { AppNotification } from 'core/models';
// import { AnnounceDto } from 'apps/announces';
import {
  SHOW_TOAST,
  ToastProps,
  HIDE_TOAST,
  SHOW_BALANCE_PAYMENT_DIALOG,
  TOGGLE_DRAWER,
  SET_NOTIFICATIONS,
  TOGGLE_MOBILE_DRAWER,
  ANNOUNCEMENTS,
  SHOW_FULL_SCREEN_LOADING,
  // PaymentDialogProps,
} from './types';

export const showToast = createAction<ToastProps>(SHOW_TOAST);
export const hideToast = createAction(HIDE_TOAST);
// export const showBalancePaymentDialog = createAction<PaymentDialogProps>(SHOW_BALANCE_PAYMENT_DIALOG);
export const toggleDrawer = createAction<boolean>(TOGGLE_DRAWER);
// export const setNotifications = createAction<AppNotification[]>(SET_NOTIFICATIONS);
export const toggleMobileDrawer = createAction<boolean>(TOGGLE_MOBILE_DRAWER);
// export const setAnnouncements = createAction<AnnounceDto[]>(ANNOUNCEMENTS);
export const showFullscreenLoading = createAction<boolean>(SHOW_FULL_SCREEN_LOADING);

import { Formik } from 'formik';
import React from 'react';
import { Grid, CardContent, Card, CardActions, Box } from '@material-ui/core';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import {
  AssemblyLocationAutoCompleteSelect,
  FormHeader,
  FormTextField,
  HideableLoadingSpinnerBox,
  SaveButton,
} from 'core/components';
import { Api, GenericMessages, showToast } from 'core';
import { AssemblyTheme } from 'apps/design/models';
import { useHistory, useParams } from 'react-router-dom';

const CreateAssemblyThemePage = () => {
  const [showLoading, setShowLoading] = React.useState(false);
  const [showSaveLoading, setSaveShowLoading] = React.useState(false);
  const dispatch = useDispatch();

  const { id }: any = useParams();
  const [assembly, setAssembly] = React.useState<AssemblyTheme>();
  const history = useHistory();

  const pullAssemblyTheme = async () => {
    if (id) {
      setShowLoading(true);
      const response = await Api.Design.getAssemblyTheme(id);
      if (response.data) {
        setAssembly(response.data);
      }
      setShowLoading(false);
    }
  };
  React.useEffect(() => {
    pullAssemblyTheme();
  }, [id]);
  const schema = yup.object({
    overridePrice: yup.number().required(GenericMessages.CANNOT_BE_EMPTY),
    name: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
    assemblies: yup.array().required(GenericMessages.CANNOT_BE_EMPTY),
  });

  const onAssemblyCreateSubmit = async (values) => {
    setSaveShowLoading(true);
    try {
      const data = { ...values };
      delete data.assemblies;
      data.assemblyIds = values.assemblies.map((assembly) => assembly.id);
      if (id) {
        await Api.Design.updateAssmblyTheme(id, data);
      } else {
        await Api.Design.createAssmblyTheme(data);
      }
      dispatch(
        showToast({
          message: `Başarıyla ${assembly ? 'güncellendi!' : 'oluşturuldu!'}`,
        }),
      );
      history.push('/design/modules/assemblyTheme');
    } catch (e) {
      dispatch(
        showToast({
          message: `Hata oluştu`,
          severity: 'error',
        }),
      );
    }
    setSaveShowLoading(false);
  };
  if (showLoading) {
    return <HideableLoadingSpinnerBox />;
  }
  return (
    <React.Fragment>
      <Card>
        <Formik
          onSubmit={onAssemblyCreateSubmit}
          validationSchema={schema}
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            name: assembly?.name ? assembly.name : '',
            overridePrice: assembly?.overridePrice ? assembly.overridePrice : '',
            assemblies: assembly?.assemblies || [],
          }}
        >
          {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
            <form onSubmit={handleSubmit} id="form" noValidate>
              <CardContent>
                <Grid direction="row" container spacing={5}>
                  <FormHeader title="Montaj Tema" />
                  <Grid item xs={12} md={6} lg={6}>
                    <FormTextField
                      required
                      label="Adı"
                      fullWidth
                      placeholder="Adı"
                      name="name"
                      onChange={handleChange}
                      value={values.name}
                      error={errors.name != null}
                      helperText={errors.name}
                    />
                  </Grid>

                  <Grid item xs={12} md={6} lg={6}>
                    <FormTextField
                      required
                      name="overridePrice"
                      label="Fiyat"
                      placeholder="Fiyat"
                      fullWidth
                      margin="dense"
                      onChange={handleChange}
                      value={values.overridePrice}
                      error={errors.overridePrice != null}
                      helperText={errors.overridePrice}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <AssemblyLocationAutoCompleteSelect
                      placeholder="Montaj Yerlerini Seçebilirsiniz"
                      optionLabelName="name"
                      equValueName="id"
                      onChange={(event, assemblies) => {
                        setFieldValue('assemblies', assemblies);
                        setFieldValue(
                          'assemblyIds',
                          assemblies.map((assembly) => assembly.id),
                        );
                      }}
                      error={errors.assemblies != null}
                      value={values.assemblies}
                      multiple
                      helperText={errors.assemblies as string}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </form>
          )}
        </Formik>
        <CardActions>
          <Box alignItems="center" justifyContent="flex-end" display="flex" className="w-100">
            <SaveButton form="form" type="submit" showLoading={showSaveLoading}>
              {id ? 'Montaj Teması Güncelle' : 'Montaj Teması Oluştur'}
            </SaveButton>
          </Box>
        </CardActions>
      </Card>
    </React.Fragment>
  );
};

export default CreateAssemblyThemePage;

import React, { useEffect } from 'react';
import { TableState } from 'core/models';
import { TranslatedMaterialTable } from 'core/components';
import { UserApi } from 'apps/user';
import { User } from 'apps/user/models';
import { useHistory } from 'react-router-dom';

const UserListPage = () => {
  const [showLoading, setShowLoading] = React.useState(false);
  const history = useHistory();
  const [tableState] = React.useState<TableState<User>>({
    columns: [{ title: 'İsim', field: 'name', cellStyle: { whiteSpace: 'nowrap' } }],
    actions: [
      {
        icon: 'edit',
        tooltip: 'Düzenle',
        onClick: (event, rowData) => {
          const user = rowData as User;
          history.push(`/users/update/${user.id}`);
        },
      },
    ],
  });
  return (
    <TranslatedMaterialTable
      isLoading={showLoading}
      actions={tableState.actions}
      columns={tableState.columns}
      data={(q) =>
        new Promise((resolve) => {
          setShowLoading(true);
          UserApi.getUsers({
            page: q.page,
          })
            .then((response) => response.data)
            .then((data) => {
              if (data) {
                resolve({
                  page: q.page,
                  totalCount: data.totalCount,
                  data: data.data,
                });
              }
            });
          setShowLoading(false);
        })
      }
      title=""
    />
  );
};
export default UserListPage;

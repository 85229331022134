import { CreateCategoryForm } from 'apps/order/';
import React from 'react';

const CreateCategoryPage = () => {
  return (
    <React.Fragment>
      <CreateCategoryForm />
    </React.Fragment>
  );
};

export default CreateCategoryPage;

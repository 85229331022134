import { Formik, Form } from 'formik';
import React from 'react';
import { TextField, Grid, CardContent, Card, CardActions, Box } from '@material-ui/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import * as yup from 'yup';
import {
  FormHeader,
  RoleAutoCompleteSelect,
  StoreAutoCompleteSelect,
  SuccessButton,
  UploadButton,
} from 'core/components';
import { useSelector } from 'react-redux';
import { UserInfo } from 'apps/auth';
import { User } from 'apps/user/models';
import { Api } from 'core/api';
import { GenericMessages } from 'core';
import Store from 'apps/store/models/Store';
import { useParams } from 'react-router-dom';

const CreateUserForm = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [createUserSuccess, setCreateUserSuccess] = React.useState(false);
  const [user, setUser] = React.useState<User>();
  const [storeList, setStoreList] = React.useState<Store[]>([]);
  const [profilePhoto, setProfilePhoto] = React.useState();
  const [showLoading, setShowLoading] = React.useState(false);
  const profilePhotoPreview = React.useRef(null);
  const userInfo: UserInfo = useSelector((state: any) => state.auth.userInfo);
  const { id }: any = useParams();
  // const [showDuplicateMailAlert, setShowDuplicateMailAlert] = React.useState(false);

  const inputLabelProps = {
    shrink: true,
  };

  let yupSchema: any = {
    name: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
    email: yup.string().required(GenericMessages.CANNOT_BE_EMPTY).email(GenericMessages.INVALID_EMAIL),
    roles: yup.array().required(GenericMessages.CANNOT_BE_EMPTY),
    stores: yup.array().required(GenericMessages.CANNOT_BE_EMPTY),
  };

  if (!id) {
    yupSchema = {
      ...yupSchema,
      password: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
      password2: yup
        .string()
        .required(GenericMessages.CANNOT_BE_EMPTY)
        .oneOf([yup.ref('password')], 'Parolalar Eşleşmiyor'),
    };
  }
  const userCreateSchema = yup.object(yupSchema);

  // const isMailDuplicate = async (email: string) => {
  //   const mail = email.replace('s;', '');
  //   // eslint-disable-next-line no-return-await
  //   return await Api.User.isMailDuplicate(mail);
  // };

  const pullStoreList = async () => {
    const response = await Api.Store.getStores();
    if (response.data.data) setStoreList(response.data.data);
  };

  React.useEffect(() => {
    pullStoreList();
  }, []);

  const pullUser = async () => {
    const response = await Api.User.getUser(id);
    if (response.data) setUser(response.data);
  };
  React.useEffect(() => {
    if (id) {
      pullUser();
    }
  }, []);

  const onUserCreateSubmit = async (values, { resetForm }) => {
    setShowLoading(true);
    try {
      //   if (!(await isMailDuplicate(values.email))) {
      const newUser: any = {
        name: values.name,
        email: values.email,
        storeIds: values.stores.map((store) => store.id),
        roleIds: values.roles.map((role) => role.id),
        currentStoreId: userInfo.storeId,
        currentLanguageId: userInfo.languageId,
      };

      if (!user) {
        newUser.password = values.password;
      }

      if (profilePhoto) {
        newUser.profilePhotoFile = profilePhoto;
      }
      if (id) {
        await Api.User.updateUser(id, newUser);
      } else {
        await Api.User.createOrUpdateUser(newUser);
      }

      setCreateUserSuccess(true);
      resetForm({ values: '' }); // TODO: Çalışmıyor
      //   } else {
      //     setShowDuplicateMailAlert(true);
      //   }
    } catch (e) {
      console.log(e);
    } finally {
      setShowLoading(false);
    }
    window.location.reload();
  };

  if (id && !user) {
    return null;
  }

  const onProfilePhotoSelected = (setFieldValue) => (event) => {
    const { target } = event;

    if (target.files || target.files.length === 1) {
      const file = target.files[0];
      if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          const currentElem: any = profilePhotoPreview.current;
          if (currentElem) {
            currentElem.src = e.target?.result;
            setFieldValue('image', e.target?.result);
          }
        };

        reader.readAsDataURL(file);
        setProfilePhoto(file);
      }
    }
  };

  return (
    <Formik
      onSubmit={onUserCreateSubmit}
      validationSchema={userCreateSchema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        name: user?.name,
        email: user?.email,
        password: '',
        password2: '',
        stores: user?.stores ? user.stores : [],
        roles: user?.roles ? user.roles : [],
        image: user?.image || '',
      }}
    >
      {({ handleSubmit, handleChange, values, touched, isValid, errors, setFieldValue }) => (
        <Form onSubmit={handleSubmit} id="createUser" noValidate>
          <Card>
            <CardContent>
              <Grid direction="row" container spacing={5}>
                <FormHeader title="Kullanıcı Bilgileri" />
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    required
                    label="İsim"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    placeholder="İsim"
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    error={errors.name != null}
                    helperText={errors.name}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    required
                    error={errors.email != null}
                    label="E-mail"
                    placeholder="E-mail"
                    type="email"
                    variant="outlined"
                    name="email"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    onChange={handleChange}
                    value={values.email}
                    helperText={errors.email}
                  />
                </Grid>
                {!user && (
                  <React.Fragment>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        required
                        error={errors.password != null}
                        name="password"
                        id="password"
                        label="Şifre giriniz"
                        placeholder="Şifre giriniz"
                        variant="outlined"
                        type="password"
                        fullWidth
                        margin="dense"
                        InputLabelProps={inputLabelProps}
                        onChange={handleChange}
                        value={values.password}
                        helperText={errors.password}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        required
                        id="password2"
                        name="password2"
                        label="Şifrenizi tekrar giriniz"
                        placeholder="Şifrenizi tekrar giriniz"
                        variant="outlined"
                        type="password"
                        fullWidth
                        margin="dense"
                        InputLabelProps={inputLabelProps}
                        onChange={handleChange}
                        value={values.password2}
                        error={errors.password2 != null}
                        helperText={errors.password2}
                      />
                    </Grid>
                  </React.Fragment>
                )}
                <Grid item xs={12} md={6} lg={4}>
                  <RoleAutoCompleteSelect
                    value={values.roles}
                    error={errors.roles != null}
                    helperText={errors.roles as string}
                    fullWidth
                    onChange={(event, values) => setFieldValue('roles', values)}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <StoreAutoCompleteSelect
                    value={values.stores}
                    error={errors.stores != null}
                    helperText={errors.stores as string}
                    fullWidth
                    onChange={(event, values) => setFieldValue('stores', values)}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  {(profilePhoto != null || values.image !== '') && (
                    <Grid container justify="center">
                      <img src={values.image} alt="" height={100} width={100} ref={profilePhotoPreview} />
                    </Grid>
                  )}
                  <UploadButton
                    buttonProps={{
                      type: 'button',
                    }}
                    onChange={onProfilePhotoSelected(setFieldValue)}
                  >
                    Profil Fotoğrafı Yükle
                  </UploadButton>
                </Grid>
              </Grid>
            </CardContent>

            <CardActions>
              <Box alignItems="center" justifyContent="flex-end" display="flex" className="w-100">
                <SuccessButton
                  type="submit"
                  form="createUser"
                  startIcon={<SaveOutlinedIcon />}
                  showLoading={showLoading}
                >
                  Kaydet
                </SuccessButton>
              </Box>
            </CardActions>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default CreateUserForm;

import React from 'react';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { CreateUserTypeForm } from 'apps/user/components';

const CreateUserTypePage = () => {
  return (
    <Card>
      <CardHeader title="Kullanıcı Türü Ekle" />
      <CardContent>
        <CreateUserTypeForm />
      </CardContent>
    </Card>
  );
};

export default CreateUserTypePage;

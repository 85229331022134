import { Formik, Form } from 'formik';
import React from 'react';
import { Grid, CardContent, Card, MenuItem } from '@material-ui/core';
import {
  CategoryAutoCompleteSelect,
  FormAutocomplete,
  FormSelect,
  FormTextField,
  HideableLoadingSpinnerBox,
  LabeledCheckbox,
} from 'core/components';
import { Product } from 'apps/product';
import { useDispatch } from 'react-redux';
import { Api } from '../../../../core/api';
import { showToast } from '../../../../core/store';

interface BatchBrandModelFormProps {
  formId: string;
  products: Product[];
  setShowBatchBrandModelDialog: (boolean) => void;
  setShowLoading: (boolean) => void;
}

const BatchBrandModelForm: React.FC<BatchBrandModelFormProps> = ({
  formId,
  products,
  setShowBatchBrandModelDialog,
  setShowLoading,
}) => {
  const [years, setYears] = React.useState<any[]>([]);
  const [brands, setBrands] = React.useState<any[]>([]);
  const [models, setModels] = React.useState<any[]>([]);
  const [pageLoading, setPageLoading] = React.useState<boolean>(false);
  const [yearRange, setYearRange] = React.useState<boolean>(false);
  const [year, setYear] = React.useState<number>();
  const [brand, setBrand] = React.useState<string>();

  const dispatch = useDispatch();

  async function getYears() {
    setPageLoading(true);
    const data = await Api.Vehicle.getYears();
    if (data.data) {
      setYears(data.data);
    }
    setPageLoading(false);
  }

  async function getBrands() {
    setPageLoading(true);
    if (year) {
      const data = await Api.Vehicle.getBrands(year);

      if (data.data) {
        const modifiedData: any[] = [];
        data.data.map((brand) => modifiedData.push({ name: brand }));
        setBrands(modifiedData);
      }
      setPageLoading(false);
    }
  }

  async function getModels() {
    setPageLoading(true);
    if (year && brand) {
      const data = await Api.Vehicle.getModels(year, brand);
      if (data.data) {
        const modifiedData: any[] = [];
        data.data.map((model) => modifiedData.push({ name: model }));
        setModels(modifiedData);
        setPageLoading(false);
      }
    }
  }

  React.useEffect(() => {
    getYears();
  }, []);
  React.useEffect(() => {
    getBrands();
  }, [year]);

  React.useEffect(() => {
    getModels();
  }, [brand]);
  /* const schema = yup.object({
    // model: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
    // brand: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
    // year: yup.number().required(GenericMessages.CANNOT_BE_EMPTY),
  }); */

  /* eslint no-empty-pattern: "error" */
  const onSubmit = async (values) => {
    setPageLoading(true);
    setShowLoading(true);
    const productIdArray = products.map((product) => {
      return product.id;
    });
    const categoryIdArray = values.category.map((category) => {
      return category.id;
    });
    try {
      values.productIds = productIdArray;
      Api.Vehicle.matchDataWithProducts(values);
      setShowLoading(false);
      dispatch(
        showToast({
          message: 'Eşleştirme Başarılı',
        }),
      );
    } catch (e) {
      setShowLoading(false);
    }
    setPageLoading(false);
    setShowBatchBrandModelDialog(false);
  };

  return (
    <Formik
      onSubmit={onSubmit}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        model: '',
        brand: '',
        year: '',
        maxYear: '',
        minYear: '',
        category: [],
      }}
    >
      {({ handleSubmit, handleChange, errors, setFieldValue, values }) => (
        <Form onSubmit={handleSubmit} id={formId} noValidate>
          <Card>
            <CardContent>
              <Grid direction="row" container spacing={5}>
                {yearRange ? (
                  <Grid item xs={12} md={12} lg={12}>
                    <Grid container spacing={5}>
                      <Grid item xs={6} md={6} lg={6}>
                        <FormTextField
                          label="En Düşük Yıl"
                          placeholder="En Düşük Yıl"
                          type="number"
                          name="minYear"
                          onChange={(event) => {
                            handleChange(event);
                            setYear(parseInt(event.target.value));
                          }}
                          value={values.minYear}
                        />
                      </Grid>
                      <Grid item xs={6} md={6} lg={6}>
                        <FormTextField
                          label="En Yüksek Yıl"
                          placeholder="En Yüksek Yıl"
                          name="maxYear"
                          onChange={(event) => {
                            handleChange(event);
                          }}
                          value={values.maxYear}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item xs={12} md={12} lg={12}>
                    <FormSelect
                      onChange={(event) => {
                        setFieldValue('year', event.target.value);
                        setYear(parseInt(event.target.value));
                        console.log('xxx', parseInt(event.target.value));
                      }}
                      placeholder="Yıl Seçiniz"
                      label="Yıl"
                      name="year"
                      value={values.year}
                      fullWidth
                    >
                      {years.map((year) => (
                        <MenuItem value={year}>{year}</MenuItem>
                      ))}
                    </FormSelect>
                  </Grid>
                )}

                <Grid item xs={12} md={12} lg={12}>
                  <FormAutocomplete
                    placeholder="Marka Seçiniz"
                    options={brands}
                    optionLabelName="name"
                    onChange={(event, value) => {
                      setFieldValue('brand', value.name);
                      setBrand(value.name);
                    }}
                    error={errors.brand != null}
                    helperText={errors.brand}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <FormAutocomplete
                    placeholder="Model Seçiniz"
                    options={models}
                    optionLabelName="name"
                    onChange={(event, value) => setFieldValue('model', value.name)}
                    error={errors.model != null}
                    helperText={errors.model}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <CategoryAutoCompleteSelect
                    placeholder="Kategori Seçiniz"
                    optionLabelName="name"
                    equValueName="id"
                    onChange={(event, category) => setFieldValue('category', category)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <LabeledCheckbox label="Universal" />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <LabeledCheckbox
                    label="Aralıklı Yıl"
                    onChange={(event) => {
                      setYearRange(event.target.checked);
                    }}
                  />
                </Grid>
              </Grid>
              {pageLoading && <HideableLoadingSpinnerBox />}
            </CardContent>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default BatchBrandModelForm;

import { Formik, Form } from 'formik';
import React from 'react';
import { TextField, Grid, CardContent, Card, CardActions, Box, MenuItem } from '@material-ui/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import * as yup from 'yup';
import {
  CategoryAutoCompleteSelect,
  FormAutocomplete,
  FormHeader,
  FormSelect,
  FormTextField,
  ProductAutoCompleteSelect,
  SuccessButton,
  UploadButton,
} from 'core/components';
import { Api, GenericMessages, showToast } from 'core';
import { useDispatch } from 'react-redux';
import { Campaign } from 'apps/campaign';

const CampaignGiftCouponPage = () => {
  const [showLoading, setShowLoading] = React.useState(false);

  const giftCouponCreateSchema = yup.object({
    name: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
    discountType: yup.number().required(GenericMessages.CANNOT_BE_EMPTY),
    discountAmount: yup.number().required(GenericMessages.CANNOT_BE_EMPTY),
    sourceType: yup.number().required(GenericMessages.CANNOT_BE_EMPTY),
  });

  const onGiftCouponSubmit = (data) => {
    setShowLoading(true);
    const newGiftCoupon: any = {
      campaignName: data.name,
      discountType: data.discountType == '1' ? 'amount' : 'percent',
      discountAmount: data.discountAmount,
      sourceType: data.sourceType == '1' ? 'category' : 'product',
      sourceIds: data.source.map((source) => {
        return source.id;
      }),
    };
    console.log(newGiftCoupon);
    setShowLoading(false);
  };

  return (
    <Formik
      onSubmit={onGiftCouponSubmit}
      validationSchema={giftCouponCreateSchema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        name: '',
        discountType: '',
        discountAmount: '',
        sourceType: '',
        source: [],
      }}
    >
      {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
        <form onSubmit={handleSubmit} id="createForm" noValidate>
          <Card>
            <CardContent>
              <Grid direction="row" container spacing={5}>
                <FormHeader title="Hediye Kupon Bilgileri" />
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    required
                    label="Kampanya İsmi"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    placeholder="Kampanya İsmi"
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    error={errors.name != null}
                    helperText={errors.name}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <FormSelect
                    onChange={handleChange}
                    name="discountType"
                    value={values.discountType}
                    fullWidth
                    placeholder="Kampanya Durumu"
                  >
                    <MenuItem value="1">Tutarsal</MenuItem>
                    <MenuItem value="2">Yüzdesel</MenuItem>
                  </FormSelect>
                </Grid>
                {values.discountType && (
                  <Grid item xs={12} md={6} lg={4}>
                    <TextField
                      required
                      label={values.discountType == '1' ? 'Tutarsal' : 'Yüzdesel'}
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      placeholder={values.discountType == '1' ? 'Tutarsal' : 'Yüzdesel'}
                      name="discountAmount"
                      onChange={handleChange}
                      value={values.discountAmount}
                      error={errors.discountAmount != null}
                      helperText={errors.discountAmount}
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={6} lg={4}>
                  <FormSelect
                    onChange={handleChange}
                    name="sourceType"
                    value={values.sourceType}
                    fullWidth
                    placeholder="Hedef Ürünler"
                  >
                    <MenuItem value="1">Kategori</MenuItem>
                    <MenuItem value="2">Ürün</MenuItem>
                  </FormSelect>
                </Grid>
                {values.sourceType == '1' && (
                  <Grid item xs={12} md={12} lg={12}>
                    <CategoryAutoCompleteSelect
                      placeholder="Kategori Seçiniz"
                      optionLabelName="name"
                      equValueName="id"
                      onChange={(event, category) => setFieldValue('source', category)}
                      fullWidth
                    />
                  </Grid>
                )}
                {values.sourceType == '2' && (
                  <Grid item xs={12} md={12} lg={12}>
                    <ProductAutoCompleteSelect
                      placeholder="Ürünler"
                      onChange={(event, products) => setFieldValue('source', products)}
                      error={errors.source != null}
                      helperText={errors.source as string}
                      value={values.source}
                      fullWidth
                    />
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <CardActions>
              <Box alignItems="center" justifyContent="flex-end" display="flex" className="w-100">
                <SuccessButton
                  type="submit"
                  startIcon={<SaveOutlinedIcon />}
                  showLoading={showLoading}
                  form="createForm"
                >
                  Kupon Oluştur
                </SuccessButton>
              </Box>
            </CardActions>
          </Card>
        </form>
      )}
    </Formik>
  );
};

export default CampaignGiftCouponPage;

/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-key */
// @ts-nocheck
import React from 'react';

import { Api, GenericMessages, UploadButtonWithPreview, CargoCompanySelect, showToast } from 'core';
import {
  Card,
  Grid,
  CardContent,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
  Menu,
  MenuItem,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { Settings } from '@material-ui/icons';
import {
  FormHeader,
  DetailFieldLabel,
  FormDialog,
  FormTextField,
  HideableLoadingSpinnerBox,
  SuccessButton,
  EinvoiceDialog,
  RefundDialog,
} from 'core/components';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { OrderNoteList } from 'apps/order/components';
import { OrderActionLog, Product } from '../../models';
import Order from '../../../crm/models/Order';

const useStyle = makeStyles(() => {
  return {
    status: {
      background: 'linear-gradient(45deg, #af8baf 30%, #968df4 92%)',
      border: 2,
      borderRadius: 5,
      color: 'white',
      height: 30,
      width: 220,
    },
  };
});
const OrderDetailPage = () => {
  const { id } = useParams();
  const history = useHistory();
  const [order, setOrder] = React.useState<Order>();
  const [orderLogs, setOrderLogs] = React.useState<OrderActionLog[]>();

  const [packageImageFile, setPackageImageFile] = React.useState(null);
  const [showSaveLoading, setShowSaveLoading] = React.useState(false);
  const [orderMenuAnchorEl, setOrderMenuAnchorEl] = React.useState(null);
  const [eInvoiceDialog, setEinvoiceDialog] = React.useState(false);
  const [refundDialog, setRefundDialog] = React.useState(false);
  const [notes, setNotes] = React.useState<any[]>([]);

  const dispatch = useDispatch();
  const classes = useStyle();

  const pullOrder = async () => {
    const response = await Api.Order.getOrder(id);
    if (response.data) setOrder(response.data);
  };

  const onGenerateCargoPageClicked = () => {
    history.push(`/orders/${id}/cargoTagGenerator`);
  };

  React.useEffect(() => {
    pullOrder();
  }, []);

  React.useEffect(() => {
    Api.Order.getUserOrderActionLog(id).then((elem) => {
      setOrderLogs(elem.data);
    });
  }, [id]);

  const getNextStatus = () => {
    switch (order.status) {
      case 1:
        return 4;
      case 4:
        return 6;
      case 6:
        return 7;
      case 7:
        return 8;
      default:
        return order.status;
    }
  };

  const onSetStatusSubmit = async (data) => {
    setShowSaveLoading(true);
    data.status = getNextStatus();
    data.packageImageFile = packageImageFile;
    try {
      await Api.Order.setOrdreStatus(id, data);
      await pullOrder();

      dispatch(
        showToast({
          message: 'Ayarlar başarı ile kaydedilmiştir',
        }),
      );
    } catch (e) {
      console.log(e);
      dispatch(
        showToast({
          message: 'Ayarlar kaydedilirken hata oluştu',
          severity: 'error',
        }),
      );
    } finally {
      setShowSaveLoading(false);
    }
  };

  const setStatusLabel = React.useMemo(() => {
    if (!order) {
      return null;
    }
    switch (order.status) {
      case 1:
        return 'Operasyon Onayı Ver';
      case 4:
        return 'Depo Onayı Ver';
      case 6:
        return 'Paketleme Onayı Ver';
      case 7:
        return 'Kargo Onayı Ver';
      default:
        return null;
    }
  }, [order]);

  const showSetStatusForm = React.useMemo(() => {
    if (!order) {
      return false;
    }
    return [1, 4, 6, 7].includes(order.status);
  }, [order]);

  let validationSchema = yup.object();

  if (showSetStatusForm) {
    if (order.status == 6) {
      validationSchema = yup.object({
        packageImage: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
      });
    } else if (order.status == 7) {
      validationSchema = yup.object({
        cargoCompany: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
        cargoBranch: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
        cargoCode: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
      });
    }
  }

  if (!order) {
    return <HideableLoadingSpinnerBox />;
  }

  const showOrderMenuClicked = (event) => {
    setOrderMenuAnchorEl(event.target);
  };

  const closeOrderMenuClicked = () => {
    setOrderMenuAnchorEl(null);
  };

  const handleEinvoiceDialogClose = () => {
    setEinvoiceDialog(false);
  };

  const handleRefundDialogClose = () => {
    setRefundDialog(false);
  };

  return (
    <React.Fragment>
      {eInvoiceDialog && <EinvoiceDialog show onClose={handleEinvoiceDialogClose} orderId={order.id} />}

      {refundDialog && <RefundDialog orderId={order.id} dialog={refundDialog} setDialog={setRefundDialog} />}

      <Card>
        <Box width="100%" textAlign="right">
          <Tooltip title="İşlemler" style={{ textAlign: 'right' }}>
            <IconButton onClick={showOrderMenuClicked}>
              <Settings />
            </IconButton>
          </Tooltip>
        </Box>
        <Menu
          id="orderMenu"
          anchorEl={orderMenuAnchorEl}
          keepMounted
          open={Boolean(orderMenuAnchorEl)}
          onClose={closeOrderMenuClicked}
        >
          <MenuItem onClick={onGenerateCargoPageClicked}>Kargo Etiketi Bastır</MenuItem>

          <MenuItem
            onClick={() => {
              setEinvoiceDialog(true);
            }}
          >
            E Fatura Oluştur
          </MenuItem>

          <MenuItem
            onClick={() => {
              setRefundDialog(true);
            }}
          >
            İade Et
          </MenuItem>
        </Menu>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Chip label={order.statusName} className={classes.status} />
            </Grid>
            <Grid item xs={6}>
              <img src={order.store?.logo} width="30%" />
            </Grid>
            <Grid item xs={12}>
              <FormHeader title="Sipariş Bilgileri" />
              <Grid container spacing={2} style={{ marginTop: '10px' }}>
                <Grid item lg={2} md={3} xs={4}>
                  <DetailFieldLabel
                    title="Ad ve Soyad"
                    label={order.client?.name}
                    labelLink={`/crm/client/detail/${order.client?.id}`}
                  />
                </Grid>
                <Grid item lg={2} md={3} xs={4}>
                  <DetailFieldLabel title="Model" label={order.id} />
                </Grid>
                <Grid item lg={2} md={3} xs={4}>
                  <DetailFieldLabel title="Mağaza" label={order.store?.name} />
                </Grid>
                <Grid item lg={2} md={3} xs={4}>
                  <DetailFieldLabel title="Toplam Tutar" label={order.total} />
                </Grid>
                <Grid item lg={2} md={3} xs={4}>
                  <DetailFieldLabel title="Email" label={order.client.email} />
                </Grid>
                <Grid item lg={2} md={3} xs={4}>
                  <DetailFieldLabel title="Ödeme Tipi" label={order.paymentMethodName} />
                </Grid>
                <Grid item lg={2} md={3} xs={4}>
                  <DetailFieldLabel title="Kaynak İsmi" label={order.sourceName} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <FormHeader title="Fatura Bilgileri" />
              <Grid container spacing={2} style={{ marginTop: '10px' }}>
                <Grid item xs={6}>
                  <DetailFieldLabel title="Fatura Adresi" label={order.invoiceAddress} />
                </Grid>
                <Grid item xs={6}>
                  <DetailFieldLabel title="Fatura Telefon Numarası" label={order.invoiceGsm} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <FormHeader title="Teslimat Bilgileri" />
              <Grid container spacing={2} style={{ marginTop: '10px' }}>
                <Grid item lg={2} md={3} xs={4}>
                  <DetailFieldLabel title="Teslimat Adresi" label={order.shippingAddress} />
                </Grid>
                <Grid item lg={2} md={3} xs={4}>
                  <DetailFieldLabel title="Teslimat Telefon Numarası" label={order.shippingGsm} />
                </Grid>
                <Grid item lg={2} md={3} xs={4}>
                  <DetailFieldLabel title="Kargo Şirketi" label={order.shippingMethodName} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <FormHeader title="Sipariş Durumları" />
              <Grid container spacing={2} style={{ marginTop: '10px' }}>
                <Grid item xs={6}>
                  <DetailFieldLabel title="Durum" label={order.shippingAddress} />
                </Grid>
                <Grid item xs={6}>
                  <DetailFieldLabel title="Tarih" label={order.shippingGsm} />
                </Grid>
                {orderLogs &&
                  orderLogs.map((orderLog) => (
                    <React.Fragment>
                      <Grid item xs={6}>
                        {orderLog.description}
                      </Grid>
                      <Grid item xs={6}>
                        {orderLog.created_at}
                      </Grid>
                    </React.Fragment>
                  ))}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormHeader title="Ürün Bilgileri" />
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>İsim</TableCell>
                      <TableCell align="right">Fiyat</TableCell>
                      <TableCell align="right">Adet</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {order.products?.map((product) => (
                      <TableRow>
                        <TableCell>{product.name}</TableCell>
                        <TableCell align="right">{product.netPrice}</TableCell>
                        <TableCell align="right">{product?.quantity}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            {showSetStatusForm && (
              <Grid item xs={12}>
                <Formik
                  onSubmit={onSetStatusSubmit}
                  initialValues={{
                    packageImage: '',
                    cargoCode: '',
                    cargoBranch: '',
                    cargoCompany: '',
                    packageImageFile: null,
                  }}
                  validationSchema={validationSchema}
                  validateOnBlur={false}
                  validateOnChange={false}
                >
                  {({ handleSubmit, values, setFieldValue, handleChange, errors }) => (
                    <form noValidate onSubmit={handleSubmit} id="setStatusForm">
                      {order.status == 6 && (
                        <UploadButtonWithPreview
                          src={values.packageImage}
                          error={errors.packageImage != null}
                          text="Paket Görseli Yükle"
                          helperText={errors.packageImage}
                          onPhotoChange={(file: any, data: any) => {
                            setFieldValue('packageImage', data);
                            setPackageImageFile(file);
                          }}
                        />
                      )}
                      {order.status == 7 && (
                        <React.Fragment>
                          <CargoCompanySelect
                            fullWidth
                            name="cargoCompany"
                            onChange={handleChange}
                            value={values.cargoCompany}
                            error={errors.cargoCompany != null}
                            helperText={errors.cargoCompany}
                          />
                          <FormTextField
                            value={values.cargoCode}
                            onChange={handleChange}
                            name="cargoCode"
                            placeholder="Kargo Kodu"
                            error={errors.cargoCode != null}
                            helperText={errors.cargoCode}
                          />

                          <FormTextField
                            value={values.cargoBranch}
                            onChange={handleChange}
                            name="cargoBranch"
                            placeholder="Kargo Veriş Şubesi"
                            error={errors.cargoBranch != null}
                            helperText={errors.cargoBranch}
                          />
                        </React.Fragment>
                      )}
                    </form>
                  )}
                </Formik>
                <Grid xs={6}>
                  <OrderNoteList notes={notes} id={id} />
                </Grid>
                <Box width="100%" textAlign="right">
                  <SuccessButton form="setStatusForm" type="submit" showLoading={showSaveLoading}>
                    {setStatusLabel}
                  </SuccessButton>
                </Box>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default OrderDetailPage;

import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Button, { ButtonProps } from '@material-ui/core/Button';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { FormHelperText } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: 'none',
    },
  }),
);
export interface UploadButtonProps {
  onChange?: (event) => void;
  buttonProps?: ButtonProps;
  error?: boolean;
  helperText?: string;
}

const UploadButton: React.FC<UploadButtonProps> = ({ onChange, children, buttonProps, error, helperText }) => {
  const classes = useStyles();

  const inputFileRef = React.useRef<any>(null);

  const defaultButtonProps: ButtonProps = {
    variant: 'contained',
    color: 'primary',
  };

  const _buttonProps = buttonProps ? { ...defaultButtonProps, ...buttonProps } : defaultButtonProps;
  return (
    <div className={classes.root}>
      <input
        accept="image/*"
        className={classes.input}
        id="contained-button-file"
        name="imageUpload"
        multiple
        type="file"
        onChange={onChange}
        ref={inputFileRef}
      />

      <Button
        {..._buttonProps}
        startIcon={<ArrowUpwardIcon />}
        fullWidth
        type="button"
        onClick={() => inputFileRef?.current?.click()}
      >
        {children}
      </Button>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </div>
  );
};

export default UploadButton;

import { Button, Grid } from '@material-ui/core';
import { Client } from 'apps/crm';
import { CategorySync } from 'apps/order';
import { CreateWarehousePart } from 'apps/warehouse/components';
import CreateWareHouseForm from 'apps/warehouse/components/CreateWarehouseForm/CreateWarehouseForm';
import { FormAutocomplete, FormDialog, TranslatedMaterialTable } from 'core/components';
import { TableState } from 'core/models';
import React from 'react';

const WarehouseList = () => {
  const [showCreateWarehouseDialog, setShowCreateWarehouseDialog] = React.useState(false);
  const [showLoading, setShowLoading] = React.useState(false);
  const [warehouseId, setWarehouseId] = React.useState(0);
  const [clients, setClients] = React.useState<Client[]>([]);

  const handleCreateWarehouseDialogClose = () => {
    setShowCreateWarehouseDialog(false);
  };
  const handleCreateWarehouseDialogOpen = () => {
    setShowCreateWarehouseDialog(true);
  };

  const [tableState] = React.useState<TableState<Client>>({
    columns: [
      {
        title: 'İsim',
      },
    ],
    actions: [
      (rowData) => ({
        icon: 'delete',
        onClick: () => {},
      }),
    ],
  });
  return (
    <React.Fragment>
      <FormDialog
        handleClose={handleCreateWarehouseDialogClose}
        open={showCreateWarehouseDialog}
        formId="createWarehouse"
        title="Yeni Bölüm Ekle"
        showLoading={showLoading}
      >
        <CreateWareHouseForm formId="createWarehouse" />
      </FormDialog>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={12}>
          <Button color="primary" onClick={handleCreateWarehouseDialogOpen}>
            Depo Ekle
          </Button>
        </Grid>
      </Grid>
      <TranslatedMaterialTable columns={tableState.columns} actions={tableState.actions} data={clients} />
    </React.Fragment>
  );
};

export default WarehouseList;

import { Formik, Form, FieldArray } from 'formik';
import React from 'react';
import { TextField, Grid, CardContent, Card, CardActions, Box, Button } from '@material-ui/core';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { FormHeader, FormSelect, FormTextField, SuccessButton, UploadButton } from 'core/components';
import GenericMessages from 'core/GenericMessages';
import { Add as AddIcon, Remove as RemoveIcon } from '@material-ui/icons';
import { v4 as uuidv4 } from 'uuid';
import { Autocomplete } from '@material-ui/lab';

type SupplierProps = {
  name: string;
  key: string;
};

type CategoryProps = {
  discount: string;
  name: string;
  key: string;
};

const CreateXmlExportPage = () => {
  const [showLoading, setShowLoading] = React.useState(false);
  const dispatch = useDispatch();
  const inputLabelProps = {
    shrink: true,
  };

  const createEmptyCategoryInfo = (): CategoryProps => {
    return {
      discount: '',
      name: '',
      key: uuidv4(),
    };
  };
  const createEmptySupplierInfo = (): SupplierProps => {
    return {
      name: '',
      key: uuidv4(),
    };
  };
  const [categoryList, setCategoryList] = React.useState<any[]>([
    { name: 'kadirxxxxxxxxxxxxxxxxx', id: 2 },
    { name: 'tereciyyyyyyyyyyyyyyy', id: 3 },
    { name: 'tereci', id: 3 },
    { name: 'tereci', id: 3 },
    { name: 'tereci', id: 3 },
    { name: 'tereci', id: 3 },
    { name: 'tereci', id: 3 },
    { name: 'tereci', id: 3 },
    { name: 'tereci', id: 3 },
    { name: 'tereci', id: 3 },
    { name: 'tereci', id: 3 },
    { name: 'tereci', id: 3 },
    { name: 'tereci', id: 3 },
  ]);
  const getErrorIndexError = (errors: any[] | undefined, index) => {
    return errors && errors.length > index ? errors[index] : {};
  };

  const schema = yup.object({
    optionId: yup.number().required(GenericMessages.CANNOT_BE_EMPTY),
    addCategory: yup.array().of(
      yup.object({
        option: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
      }),
    ),
  });

  const onOptionSubmit = async (values, { resetForm }) => {
    setShowLoading(true);
    try {
      console.log('hh');
    } catch (e) {
      setShowLoading(false);
    }
  };

  const CategoryComp = ({ values, handleChange, errors, arrayHelper, setFieldValue }) => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box display="flex" textAlign="right" alignItems="center" height="100%">
            <Button startIcon={<AddIcon />} onClick={() => arrayHelper.push(createEmptyCategoryInfo())} color="primary">
              Kategori Ekle
            </Button>
          </Box>
        </Grid>
        {values.addCategory.map((option, index) => (
          <Grid item xs={12} key={`pi${option.key}`}>
            <Grid container>
              <Grid item xs={3}>
                <Autocomplete
                  size="medium"
                  id="tags-outlined"
                  options={categoryList}
                  getOptionLabel={(option) => option?.name}
                  onChange={(event, value) => {
                    if (value)
                      value.map((category) => {
                        return category.name;
                      });
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Kategorileri Seçebilirsiniz"
                      placeholder="Kategoriler"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <FormTextField
                placeholder="İndirim Oranı"
                value={option.price}
                onChange={(e) => setFieldValue(`discount.${index}.option`, e.target.value)}
                name={`discount.${index}.option`}
                error={getErrorIndexError(errors.addCategory as any[], index)?.option != null}
                helperText={getErrorIndexError(errors.addCategory as any[], index)?.option}
              />
            </Grid>
            <Grid item xs={2}>
              <Box display="flex" alignItems="center" height="100%" alignContent="flex-end">
                <Button startIcon={<RemoveIcon />} onClick={() => arrayHelper.remove(index)} color="secondary">
                  Sil
                </Button>
              </Box>
            </Grid>
          </Grid>
        ))}
      </Grid>
    );
  };
  const SupplierComp = ({ values, handleChange, errors, arrayHelper, setFieldValue }) => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box display="flex" textAlign="right" alignItems="center" height="100%">
            <Button
              startIcon={<AddIcon />}
              onClick={() => arrayHelper.push(createEmptyCategoryInfo())}
              color="secondary"
            >
              Tedarikçi Ekle
            </Button>
          </Box>
        </Grid>
        {values.addSupplier.map((option, index) => (
          <Grid item xs={12} key={`pi${option.key}`}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Autocomplete
                  size="medium"
                  id="tags-outlined"
                  options={categoryList}
                  getOptionLabel={(option) => option?.name}
                  onChange={(event, value) => {
                    if (value)
                      value.map((supp) => {
                        return supp.name;
                      });
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Tedarikçileri Seçebilirsiniz"
                      placeholder="Tedarikçiler"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <Box display="flex" alignItems="center" height="100%" alignContent="flex-end">
                <Button startIcon={<RemoveIcon />} onClick={() => arrayHelper.remove(index)} color="secondary">
                  Sil
                </Button>
              </Box>
            </Grid>
            <Grid item xs={1} />
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <Formik
      onSubmit={onOptionSubmit}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        name: '',
        addCategory: [createEmptyCategoryInfo()],
        addSupplier: [createEmptySupplierInfo()],
      }}
    >
      {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
        <Form onSubmit={handleSubmit} id="form" noValidate>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <FormHeader title="Koşullu XML Export Bilgileri" />
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    required
                    label=" Adı"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    placeholder=" Adı"
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    error={errors.name != null}
                    helperText={errors.name}
                  />
                </Grid>

                <Grid container>
                  <Grid item xs={12}>
                    <FieldArray
                      name="addCategory"
                      render={(arrayHelper) => (
                        <CategoryComp
                          values={values}
                          arrayHelper={arrayHelper}
                          errors={errors}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <FieldArray
                      name="addSupplier"
                      render={(arrayHelper) => (
                        <SupplierComp
                          values={values}
                          arrayHelper={arrayHelper}
                          errors={errors}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <CardActions>
              <Box alignItems="center" justifyContent="flex-end" display="flex" className="w-100">
                <SuccessButton form="form" type="submit" startIcon={<SaveOutlinedIcon />} showLoading={showLoading}>
                  Kaydet
                </SuccessButton>
              </Box>
            </CardActions>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default CreateXmlExportPage;

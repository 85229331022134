import { CreateClientClassForm } from 'apps/crm/components';
import React from 'react';

const CreateClientClassPage = () => {
  return (
    <React.Fragment>
      <CreateClientClassForm />
    </React.Fragment>
  );
};

export default React.Fragment;

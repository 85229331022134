import { Grid, Card, CardContent, Box, CardActions } from '@material-ui/core';
import React from 'react';
import { FormHeader, SuccessButton, UploadButton } from 'core/components';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';

const ExcelProductUpdatePage = () => {
  const [showLoading, setShowLoading] = React.useState(false);
  const [file, setFile] = React.useState<any>();

  const onSubmitExcelProductUpdate = async () => {
    setShowLoading(true);
    try {
      console.log('hh');
    } catch (e) {
      setShowLoading(false);
    }
  };

  const onFileUploaded = (event) => {
    const { target } = event;

    if (target.files || target.files.length === 1) {
      const file = target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
    }
  };

  return (
    <Card>
      <CardContent>
        <Grid container alignItems="center">
          <Grid item xs={12} md={6} lg={3}>
            <UploadButton
              buttonProps={{
                type: 'button',
              }}
              onChange={onFileUploaded}
            >
              Excel Dosyası Yükle
            </UploadButton>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <UploadButton
              buttonProps={{
                type: 'button',
                color: 'secondary',
              }}
              onChange={onFileUploaded}
            >
              Şablon İndir
            </UploadButton>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Box alignItems="center" justifyContent="flex-end" display="flex" className="w-100">
          <SuccessButton
            onClick={onSubmitExcelProductUpdate}
            startIcon={<SaveOutlinedIcon />}
            showLoading={showLoading}
          >
            Kaydet
          </SuccessButton>
        </Box>
      </CardActions>
    </Card>
  );
};

export default ExcelProductUpdatePage;

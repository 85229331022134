import { AuthApi, setSessionToken, setToken, setUserInfo } from 'apps/auth';
import { Api } from 'core';
import React from 'react';
import { useDispatch } from 'react-redux';
import Routes from 'Routes';

const setupLogin = async (dispatch) => {
  const token = localStorage.getItem('token');
  const sessionToken = localStorage.getItem('sessionToken');
  console.log(token, sessionToken);
  if (token && sessionToken) {
    const userInfoData = localStorage.getItem('userInfo');
    AuthApi.setSessionToken(sessionToken);
    AuthApi.setBearerToken(token);
    dispatch(setToken(token));
    if (userInfoData) {
      try {
        const userInfo = JSON.parse(userInfoData);
        dispatch(setUserInfo(userInfo));
      } catch (e) {
        console.log(e);
      }
    }
  } else {
    const clientTokenResponse = await AuthApi.apiLogin(
      process.env.REACT_APP_CLIENT_SECRET!,
      process.env.REACT_APP_GRANT_TYPE!,
      process.env.REACT_APP_CLIENT_ID!,
    );
    Api.Auth.setBearerToken(clientTokenResponse.access_token);
    const createSessionResponse = await AuthApi.createSessionToken();
    Api.Auth.setSessionToken(createSessionResponse.session_token);
    dispatch(setToken(clientTokenResponse.access_token));
    dispatch(setSessionToken(createSessionResponse.session_token));
  }
};

function App() {
  const [hasToken, setHasToken] = React.useState(false);
  const dispatch = useDispatch();

  React.useEffect(() => {
    setupLogin(dispatch).then(() => {
      setHasToken(true);
    });
  }, []);
  if (!hasToken) {
    return null;
  }
  return <Routes />;
}

export default App;

import { Switch, Route } from 'react-router-dom';
import React from 'react';
import { CreateBlogPage, BlogListPage } from '../pages';

interface BlogLayoutProps {
  match: any;
}

const BlogLayout: React.FC<BlogLayoutProps> = ({ match }) => {
  return (
    <Switch>
      <Route exact path={`${match.path}`} component={CreateBlogPage} />
      <Route exact path={`${match.path}/list`} component={BlogListPage} />
    </Switch>
  );
};

export default BlogLayout;

import { Formik, Form, FieldArray } from 'formik';
import React from 'react';
import {
  TextField,
  Grid,
  CardContent,
  Card,
  CardActions,
  Box,
  Switch,
  FormControl,
  FormControlLabel,
  MenuItem,
  Typography,
  Button,
} from '@material-ui/core';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import {
  FormHeader,
  FormSelect,
  FormTextField,
  SuccessButton,
  UploadButton,
  UploadButtonWithPreview,
} from 'core/components';
import GenericMessages from 'core/GenericMessages';
import { Add as AddIcon, Remove as RemoveIcon } from '@material-ui/icons';
import { v4 as uuidv4 } from 'uuid';

type OptionProps = {
  link: string;
  queue: string;
  sliderImage: any;
  key: string;
};

const CreateBannerSliderPage = () => {
  const [showLoading, setShowLoading] = React.useState(false);
  const sliderImagePreview = React.useRef(null);
  const dispatch = useDispatch();
  const inputLabelProps = {
    shrink: true,
  };

  const createEmptyOptionInfo = (): OptionProps => {
    return {
      link: '',
      queue: '',
      sliderImage: '',
      key: uuidv4(),
    };
  };

  const getErrorIndexError = (errors: any[] | undefined, index) => {
    return errors && errors.length > index ? errors[index] : {};
  };

  const schema = yup.object({
    // optionId: yup.number().required(GenericMessages.CANNOT_BE_EMPTY),
    // addOption: yup.array().of(
    //   yup.object({
    //     option: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
    //   }),
    // ),
  });

  const onOptionSubmit = async (values, { resetForm }) => {
    setShowLoading(true);
    try {
      console.log('hh');
    } catch (e) {
      setShowLoading(false);
    }
  };

  const OptionComp = ({ values, handleChange, errors, arrayHelper, setFieldValue }) => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box display="flex" textAlign="right" alignItems="center" height="100%">
            <Button startIcon={<AddIcon />} onClick={() => arrayHelper.push(createEmptyOptionInfo())} color="primary">
              Görsel Slider Ekle
            </Button>
          </Box>
        </Grid>
        {values.addOption.map((option, index) => (
          <Grid item xs={12} key={`pi${option.key}`}>
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={4}>
                <UploadButtonWithPreview
                  src={option.sliderImage.image}
                  onPhotoChange={(file, image) => {
                    setFieldValue(`addOption.${index}.sliderImage`, {
                      file,
                      image,
                      new: true,
                    });
                  }}
                  text="Görsel Yükle"
                />
              </Grid>
              <Grid item xs={3}>
                <FormTextField
                  placeholder="Link"
                  fullWidth
                  value={option.link}
                  onChange={(e) => setFieldValue(`addOption.${index}.link`, e.target.value)}
                  name={`addOption.${index}.link`}
                  error={getErrorIndexError(errors.addOption as any[], index)?.link != null}
                  helperText={getErrorIndexError(errors.addOption as any[], index)?.link}
                />
              </Grid>
              <Grid item xs={3}>
                <FormTextField
                  placeholder="Sıra"
                  fullWidth
                  value={option.queue}
                  onChange={(e) => setFieldValue(`addOption.${index}.queue`, e.target.value)}
                  name={`addOption.${index}.queue`}
                  error={getErrorIndexError(errors.addOption as any[], index)?.queue != null}
                  helperText={getErrorIndexError(errors.addOption as any[], index)?.queue}
                />
              </Grid>
              <Grid item xs={2}>
                <Box display="flex" alignItems="center" height="100%" alignContent="flex-end">
                  <Button
                    startIcon={<RemoveIcon />}
                    onClick={() => {
                      arrayHelper.remove(index);
                      console.log(option.sliderImage);
                    }}
                    color="secondary"
                  >
                    Sil
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    );
  };

  return (
    <Formik
      onSubmit={onOptionSubmit}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        name: '',
        deleteImageIds: [],
        addOption: [createEmptyOptionInfo()],
      }}
    >
      {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
        <Form onSubmit={handleSubmit} id="form" noValidate>
          <Card>
            <CardContent>
              <Grid container spacing={3}>
                <FormHeader title="Görsel Slider Bilgileri" />
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    required
                    label="Slider Adı"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    placeholder="Slider Adı"
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    error={errors.name != null}
                    helperText={errors.name}
                  />
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FieldArray
                      name="addOption"
                      render={(arrayHelper) => (
                        <OptionComp
                          values={values}
                          arrayHelper={arrayHelper}
                          errors={errors}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>

            <CardActions>
              <Box alignItems="center" justifyContent="flex-end" display="flex" className="w-100">
                <SuccessButton form="form" type="submit" startIcon={<SaveOutlinedIcon />} showLoading={showLoading}>
                  Kaydet
                </SuccessButton>
              </Box>
            </CardActions>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default CreateBannerSliderPage;

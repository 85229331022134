import { Formik, Form } from 'formik';
import React from 'react';
import {
  TextField,
  Grid,
  CardContent,
  Card,
  CardActions,
  Box,
  FormControl,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { FormHeader, SuccessButton, UploadButton } from 'core/components';
import GenericMessages from 'core/GenericMessages';
import ColorPicker from 'material-ui-color-picker';
import { useParams } from 'react-router-dom';
import { CreateOrUpdateStore, Store } from 'apps/store/models';
import { Api, showToast } from 'core';

const StoreOption = () => {
  const [showLoading, setShowLoading] = React.useState(false);
  const [store, setStore] = React.useState<Store>();
  const { id }: any = useParams();
  const dispatch = useDispatch();
  const inputLabelProps = {
    shrink: true,
  };

  const schema = yup.object({
    paynetInstallmentCode: yup.string(),
    paynetSecret: yup.string(),
    paynetPublic: yup.string(),
    netgsmUserName: yup.string(),
    netgsmPassword: yup.string(),
  });

  const onStoreCreateSubmit = async (values, { resetForm }) => {
    // setShowLoading(true);
    // try {
    //   const storeOptionData: CreateOrUpdateStore = {
    //     paynetInstallmentCode: values?.paynetInstallmentCode,
    //     paynetSecret: values?.paynetSecret,
    //     paynetPublic: values?.paynetPublic,
    //     netgsmUserName: values?.netgsmUserName,
    //     netgsmPassword: values?.netgsmPassword,
    //     isActive: values?.isActive,
    //   };
    //   if (id) await Api.Store.updateStore(id, storeOptionData);
    //   else await Api.Store.createStore(storeData);
    //   dispatch(
    //     showToast({
    //       message: `başarıyla ${id ? 'güncellendi!' : 'oluşturuldu!'}`,
    //     }),
    //   );
    //   setShowLoading(false);
    // } catch (e) {
    //   dispatch(
    //     showToast({
    //       message: `Hata oluştu`,
    //       severity: 'error',
    //     }),
    //   );
    //   setShowLoading(false);
    // }
  };

  const pullStore = async () => {
    const response = await Api.Store.getStore(id);
    if (response.data) setStore(response.data);
  };
  React.useEffect(() => {
    pullStore();
  }, []);

  if (id && !store) {
    return null;
  }

  return (
    <Formik
      onSubmit={onStoreCreateSubmit}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        paynetInstallmentCode: '',
        paynetSecret: '',
        paynetPublic: '',
        netgsmUserName: '',
        netgsmPassword: '',
        isActive: false,
      }}
    >
      {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
        <Form onSubmit={handleSubmit} id="form" noValidate>
          <Card>
            <CardContent>
              <Grid direction="row" container spacing={5}>
                <FormHeader title="Mağaza Ayarları " />
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    required
                    label="Paynet Taksit Kodu"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    placeholder="İsim"
                    name="paynetInstallmentCode"
                    onChange={handleChange}
                    value={values.paynetInstallmentCode}
                    error={errors.paynetInstallmentCode != null}
                    helperText={errors.paynetInstallmentCode}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    label="Paynet Secret Kodu"
                    placeholder="Paynet Secret Kodu"
                    variant="outlined"
                    name="paynetSecret"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    onChange={handleChange}
                    value={values.paynetSecret}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    required
                    error={errors.paynetPublic != null}
                    label="Paynet Public Kodu"
                    placeholder="Paynet Public Kodu"
                    variant="outlined"
                    name="paynetPublic"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    onChange={handleChange}
                    value={values.paynetPublic}
                    helperText={errors.paynetPublic}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    required
                    error={errors.paynetPublic != null}
                    label="NetGsm Kullanıcı Adı"
                    placeholder="NetGsm Kullanıcı Adı"
                    variant="outlined"
                    name="netgsmUserName"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    onChange={handleChange}
                    value={values.netgsmUserName}
                    helperText={errors.netgsmUserName}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    required
                    error={errors.netgsmPassword != null}
                    label="Netgsm Şifresi"
                    placeholder="Netgsm Şifresi"
                    variant="outlined"
                    name="netgsmPassword"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    onChange={handleChange}
                    value={values.netgsmPassword}
                    helperText={errors.netgsmPassword}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormControlLabel
                      value="end"
                      control={
                        <Switch
                          color="primary"
                          checked={values.isActive}
                          onChange={(e) => setFieldValue('isActive', e.target.checked)}
                        />
                      }
                      label="Durumu Seçiniz (Aktif veya Pasif)"
                      labelPlacement="end"
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>

            <CardActions>
              <Box alignItems="center" justifyContent="flex-end" display="flex" className="w-100">
                <SuccessButton form="form" type="submit" startIcon={<SaveOutlinedIcon />} showLoading={showLoading}>
                  Mağaza Ayarlarını Kaydet
                </SuccessButton>
              </Box>
            </CardActions>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default StoreOption;

import React from 'react';

import { useParams } from 'react-router-dom';
import { Api } from 'core';
import { HideableLoadingSpinnerBox } from 'core/components';
import { CreateOrUpdateOptionForm } from 'apps/option';
import { Option } from 'apps/option/models';

const UpdateOptionPage = () => {
  // @ts-ignore
  const { id } = useParams();
  const [option, setOption] = React.useState<Option>();
  const [loading, setLoading] = React.useState(false);
  const pullOption = async () => {
    setLoading(true);
    const response = await Api.Option.getOption(id);
    if (response.data) setOption(response.data);

    console.log(response.data);
    setLoading(false);
  };

  React.useEffect(() => {
    pullOption();
  }, []);
  if (loading) {
    return <HideableLoadingSpinnerBox />;
  }
  return <CreateOrUpdateOptionForm option={option} />;
};

export default UpdateOptionPage;

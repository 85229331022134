import { AnyAction } from '@reduxjs/toolkit';
import { ecmsClient } from 'core';
import { ApiResponse, FilterRequest, PageResponse } from 'core/models';
import { Dashboard } from '../models';

const DashboardApi = {
  URL_DASHBOARD_WITH_STORE_ID: (storeId) => `/dashboard/${storeId}`,
  URL_DASHBOARD: `/dashboard/`,

  async getDashboardData(storeId?: any): Promise<ApiResponse<Dashboard>> {
    const response = await ecmsClient.get(this.URL_DASHBOARD_WITH_STORE_ID(storeId));
    return response.data;
  },
  async getDashboardDefaultData(): Promise<ApiResponse<Dashboard>> {
    const response = await ecmsClient.get(this.URL_DASHBOARD);
    return response.data;
  },
};

export default DashboardApi;

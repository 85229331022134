import { Column, Action } from 'material-table';
import React, { useEffect } from 'react';
import { Visibility as VisibilityIcon } from '@material-ui/icons';
import { TableState } from 'core/models';
import { Client } from 'apps/crm';
import { TranslatedMaterialTable } from 'core/components';

const BannerSliderListPage = () => {
  const [clients, setClients] = React.useState<Client[]>([]);

  const [tableState] = React.useState<TableState<Client>>({
    columns: [
      {
        title: 'İsim',
      },
    ],
    actions: [
      (rowData) => ({
        icon: 'delete',
        onClick: () => {},
      }),
    ],
  });
  return <TranslatedMaterialTable columns={tableState.columns} actions={tableState.actions} data={clients} />;
};
export default BannerSliderListPage;

/* eslint-disable eqeqeq */
import Autocomplete, { AutocompleteProps } from '@material-ui/lab/Autocomplete';
import { FormControl } from '@material-ui/core';
import React from 'react';
import classnames from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { FormTextField } from '../FormTextField';
import { FormSelect } from '../FormSelect';

export interface FormAutocompleteProps
  extends Omit<Omit<AutocompleteProps<any, any, undefined, undefined>, 'renderInput'>, 'onChange'> {
  multiple?: boolean;
  error?: boolean;
  helperText?: string;
  formId?: string;
  valueName?: string;
  optionLabelName?: string;
  placeholder?: string;
  renderInput?: (params: any) => JSX.Element;
  onChange?: (event, value) => void;
  equValueName?: string;
  getOptionInInvalid?: (options: any[], value: any) => any;
  isUpperCase?: boolean;
  showLoading?: boolean;
  parentId?: number;
}

const useStyle = makeStyles(() => {
  return {
    autoComplete: {
      // marginTop: '8px',
    },
  };
});

const FormAutocomplete: React.FC<FormAutocompleteProps> = ({
  multiple,
  formId,
  error,
  helperText,
  size,
  valueName,
  optionLabelName,
  getOptionLabel,
  renderInput,
  placeholder,
  getOptionSelected,
  onChange,
  equValueName,
  isUpperCase,
  options,
  showLoading,
  ...autocompleteProps
}) => {
  const classes = useStyle();
  const _getOptionLabel = (option) => {
    let label;
    if (getOptionLabel) {
      label = getOptionLabel(option);
    }
    if (!label && optionLabelName) {
      if (isUpperCase) label = option[optionLabelName]?.toLocaleUpperCase('tr');
      else label = option[optionLabelName];
    }

    if (!label) {
      if (equValueName && optionLabelName && options) {
        const found = options.find((realOption) => realOption[equValueName] == option[equValueName]);
        if (found) {
          label = found[optionLabelName];
        }
      }
    }

    if (label) {
      return label;
    }
    return null;
  };

  const _getOptionSelected = (option, value) => {
    if (getOptionSelected) {
      return getOptionSelected(option, value);
    }
    if (equValueName) {
      // eslint-disable-next-line eqeqeq
      return option[equValueName] == value[equValueName];
    }
    if (valueName) {
      // eslint-disable-next-line eqeqeq
      return option[valueName] == value;
    }

    // eslint-disable-next-line eqeqeq
    return option == value;
  };

  const _onChange = (event, option) => {
    if (onChange) {
      if (valueName) {
        onChange(event, option[valueName]);
      } else {
        onChange(event, option);
      }
    }
  };

  if (showLoading) {
    return (
      <FormSelect showLoading placeholder={placeholder} label={placeholder} fullWidth={autocompleteProps.fullWidth} />
    );
  }

  return (
    <FormControl id={formId} fullWidth={autocompleteProps.fullWidth}>
      <Autocomplete
        {...autocompleteProps}
        multiple={multiple}
        options={options}
        renderInput={(params) =>
          renderInput ? (
            renderInput(params)
          ) : (
            <FormTextField
              {...params}
              placeholder={placeholder}
              error={error}
              helperText={helperText}
              fullWidth={autocompleteProps.fullWidth}
            />
          )
        }
        onChange={_onChange}
        size={size || 'small'}
        // eslint-disable-next-line eqeqeq
        getOptionSelected={_getOptionSelected}
        getOptionLabel={_getOptionLabel}
        className={classnames(classes.autoComplete)}
      />
    </FormControl>
  );
};

export default FormAutocomplete;

import { Book, ListAlt, PostAdd, List } from '@material-ui/icons';
import { MenuBlockInfo } from 'core';
import React from 'react';

const blogSidebarMenuList: MenuBlockInfo = {
  id: 'blogMenu',
  title: 'Blog Yönetimi',
  child: [
    {
      id: 'blog',
      icon: <Book />,
      label: 'Blog Yönetimi',
      child: [
        {
          id: 'blogCreate',
          icon: <PostAdd />,
          label: 'Blog Ekle',
          route: '/blog',
        },
        // {
        //   id: 'blogList',
        //   route: '/blog/list',
        //   icon: <List />,
        //   label: 'Blog Listesi',
        // },
      ],
    },
  ],
};

export default blogSidebarMenuList;

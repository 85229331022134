import { Formik, Form } from 'formik';
import React from 'react';
import { TextField, Grid, CardContent, Card, CardActions, Box, MenuItem } from '@material-ui/core';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { CargoCompanySelect, FormHeader, FormSelect, SuccessButton } from 'core/components';
import GenericMessages from 'core/GenericMessages';
import { Api } from 'core/api';

const N11Settings = () => {
  const [showLoading, setShowLoading] = React.useState(false);

  const dispatch = useDispatch();
  const inputLabelProps = {
    shrink: true,
  };

  const schema = yup.object({
    apiKey: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
    apiSecret: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
    supplierId: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
  });

  const onN11Submit = async (values, { resetForm }) => {
    setShowLoading(true);
    try {
      console.log('hh');
      // const response = await Api.Order.submitN11Settings(values);
    } catch (e) {
      setShowLoading(false);
    }
  };

  return (
    <Formik
      onSubmit={async (values) => alert(JSON.stringify(values, null, 2))}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        apiKey: '',
        apiSecret: '',
        supplierId: '',
        cargoCompany: '',
      }}
    >
      {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
        <Form onSubmit={handleSubmit} id="form" noValidate>
          <Card>
            <CardContent>
              {' '}
              <Grid direction="row" container spacing={5}>
                <FormHeader title="N11 Ayarları" />
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    required
                    label="Api Key"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    placeholder="Api Key"
                    name="apiKey"
                    onChange={handleChange}
                    value={values.apiKey}
                    error={errors.apiKey != null}
                    helperText={errors.apiKey}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    required
                    label="Api Secret"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    placeholder="Api Secret"
                    name="apiSecret"
                    onChange={handleChange}
                    value={values.apiSecret}
                    error={errors.apiSecret != null}
                    helperText={errors.apiSecret}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <TextField
                    required
                    label="Supplier Id"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    placeholder="Supplier Id"
                    name="supplierId"
                    onChange={handleChange}
                    value={values.supplierId}
                    error={errors.supplierId != null}
                    helperText={errors.supplierId}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <CargoCompanySelect
                    fullWidth
                    name="cargoCompany"
                    onChange={handleChange}
                    value={values.cargoCompany}
                    error={errors.cargoCompany != null}
                    helperText={errors.cargoCompany}
                  />
                </Grid>
              </Grid>
            </CardContent>

            <CardActions>
              <Box alignItems="center" justifyContent="flex-end" display="flex" className="w-100">
                <SuccessButton type="submit" startIcon={<SaveOutlinedIcon />} showLoading={showLoading}>
                  Ayarları Kaydet
                </SuccessButton>
              </Box>
            </CardActions>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default N11Settings;

import React from 'react';
import { Grid } from '@material-ui/core';
import { CreateUserForm } from 'apps/user/components';

const CreateUserPage = () => {
  return (
    <React.Fragment>
      <CreateUserForm />
    </React.Fragment>
  );
};
export default CreateUserPage;

import React from 'react';
import { FormHelperText, Grid, MenuItem } from '@material-ui/core';
import { FormTextField } from 'core/components/FormTextField';
import { DraftEditor, FormSelect, LabeledCheckbox, StoreAutoCompleteSelect } from 'core/components';
import { ProductCreateOrUpdateContext } from 'apps/product/context';

interface GeneralInfoStepProps {
  errors: any;
  values: any;
  setFieldValue: (field: string, value: any) => void;
  handleChange: (e: any) => void;
}
const GeneralInfoStep: React.FC<GeneralInfoStepProps> = ({ errors, values, handleChange, setFieldValue }) => {
  const { warehouses, pullShelfParts, warehouseShelfParts, hasPulledWarehouseShelfParts } = React.useContext(
    ProductCreateOrUpdateContext,
  );

  const onWarehouseChange = (e) => {
    handleChange(e);
    if (values.categories && values.categories.length > 0) {
      pullShelfParts(e.target.value, values.categories[0].id);
    }
  };
  return (
    <Grid container spacing={2}>
      <Grid item md={6}>
        <FormTextField
          placeholder="Ürün Başlığı"
          name="name"
          onChange={handleChange}
          value={values.name}
          error={errors.name != null}
          helperText={errors.name}
        />
      </Grid>
      <Grid item md={6}>
        <FormTextField placeholder="Ürün Kodu" name="model" onChange={handleChange} value={values.model} />
      </Grid>
      <Grid item md={6}>
        <FormTextField
          placeholder="Stok Adedi"
          name="quantity"
          onChange={handleChange}
          value={values.quantity}
          error={errors.quantity != null}
          helperText={errors.quantity}
        />
      </Grid>
      <Grid item md={6}>
        <Grid container spacing={4}>
          {!values.autoGenerateBarcode && (
            <Grid item xs={6}>
              <FormTextField
                placeholder="Barkod"
                name="barcode"
                onChange={handleChange}
                value={values.barcode}
                error={errors.barcode != null}
                helperText={errors.barcode}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <LabeledCheckbox
              name="autoGenerateBarcode"
              label="Otomatik oluştur"
              checked={values.autoGenerateBarcode}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={6}>
        <FormSelect
          onChange={handleChange}
          name="status"
          value={values.status}
          fullWidth
          placeholder="Ürün Durumu"
          label="Ürün Durumu"
          error={errors.status != null}
          helperText={errors.status}
        >
          <MenuItem value="1">Açık</MenuItem>
          <MenuItem value="0">Kapalı</MenuItem>
        </FormSelect>
      </Grid>
      <Grid item md={6}>
        <StoreAutoCompleteSelect
          fullWidth
          onChange={(event, values) => setFieldValue('store', values)}
          error={errors.store != null}
          helperText={errors.store as string}
          value={values.store}
        />
      </Grid>
      {/* TODO: Bu Burada gecici oalrak var. Daha sonra componente alinmali */}
      <Grid item md={6}>
        <FormSelect
          fullWidth
          name="warehouseId"
          label="Hangi Depoya Ait"
          onChange={onWarehouseChange}
          value={values.warehouseId}
          placeholder="Hangi Depoya Ait"
          error={errors.warehouseId != null}
          helperText={errors.warehouseId}
        >
          <MenuItem>Yok</MenuItem>
          {warehouses.map((warehouse) => (
            <MenuItem key={`whi${warehouse.id}`} value={warehouse.id}>
              {warehouse.name}
            </MenuItem>
          ))}
        </FormSelect>
      </Grid>
      {values.warehouseId && (
        <Grid item md={6}>
          {hasPulledWarehouseShelfParts && warehouseShelfParts.length === 0 && (
            <FormHelperText error>Bu kategori için atanmış raf yok</FormHelperText>
          )}
          <FormSelect
            fullWidth
            disabled={warehouseShelfParts.length === 0}
            name="shelfPartId"
            label="Raf Bilgisi"
            onChange={handleChange}
            value={values.shelfPartId}
            placeholder="Raf Bilgisi"
            error={errors.shelfPartId != null}
            helperText={errors.shelfPartId}
          >
            {warehouseShelfParts.map((warehouseShelfPart) => (
              <MenuItem key={`wsp${warehouseShelfPart.shelfPartId}`} value={warehouseShelfPart.shelfPartId}>
                {warehouseShelfPart.code}
              </MenuItem>
            ))}
          </FormSelect>
        </Grid>
      )}

      <Grid item xs={12}>
        Ürün Açıklaması
      </Grid>
      <Grid item xs={12}>
        <DraftEditor
          data={values.description}
          onChange={(e) => {
            setFieldValue('description', e);
          }}
        />
      </Grid>
    </Grid>
  );
};

export default GeneralInfoStep;

import OrderLayout from 'apps/order/layout/OrderLayout';
import { StoreLayout } from 'apps/store/layout';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';

import { BlogLayout } from 'apps/blog/layout';
import { DesignLayout } from 'apps/design';
import { UserLayout } from 'apps/user';
import { CRMLayout } from 'apps/crm';
import { WarehouseLayout } from 'apps/warehouse';
import { ReportLayout } from 'apps/report';
import { HomeLayout } from 'apps/dashboard';
import { ProfileLayout } from 'apps/profile';

const MainLayoutRouter: React.FC = () => {
  const dispatch = useDispatch();

  return (
    <Switch>
      <Route exact path="/" component={HomeLayout} />
      <Route strict path="/users" component={UserLayout} />
      <Route strict path="/crm" component={CRMLayout} />
      <Route strict path="/store" component={StoreLayout} />
      <Route strict path="/orders" component={OrderLayout} />
      <Route strict path="/blog" component={BlogLayout} />
      <Route strict path="/design" component={DesignLayout} />
      <Route strict path="/warehouse" component={WarehouseLayout} />
      <Route strict path="/report" component={ReportLayout} />
      <Route strict path="/my" component={ProfileLayout} />
    </Switch>
  );
};
export default MainLayoutRouter;

/* eslint-disable react/jsx-key */
// @ts-nocheck
import React from 'react';

import { Api } from 'core';
import { Card, Grid, CardContent } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { FormHeader, DetailFieldLabel, FormDialog, TranslatedMaterialTable } from 'core/components';
import { Category } from 'apps/crm';

const CategoryDetailPage = () => {
  const { id } = useParams();
  const [category, setCategory] = React.useState<Category>();

  const pullCategory = async () => {
    const response = await Api.Order.getCategory(id);
    if (response.data) setCategory(response.data);
  };

  React.useEffect(() => {
    pullCategory();
  }, []);

  return (
    <React.Fragment>
      <Card>
        {category && (
          <CardContent>
            <Grid container spacing={3}>
              <FormHeader title="Kategori Bilgileri" />
              <Grid item lg={2} md={3} xs={12}>
                <DetailFieldLabel title="Kategori Aktifliği" label={category?.isActive ? 'Aktif' : 'Pasif'} />
              </Grid>
              <Grid item lg={2} md={3} xs={12}>
                <DetailFieldLabel title="Oluşturulma Tarihi" label={category?.createdAt} />
              </Grid>
            </Grid>
          </CardContent>
        )}
      </Card>
    </React.Fragment>
  );
};

export default CategoryDetailPage;

import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import React from 'react';
import SuccessButton, { SuccessButtonProps } from '../SuccessButton/SuccessButton';

type SaveButtonProps = SuccessButtonProps;
const SaveButton: React.FC<SaveButtonProps> = ({ children, ...buttonProps }) => {
  return (
    <SuccessButton startIcon={<SaveOutlinedIcon />} {...buttonProps}>
      {children}
    </SuccessButton>
  );
};

export default SaveButton;

import {
  Equalizer,
  PlusOne as PlusOneIcon,
  List,
  PostAdd,
  Description,
  People,
  Dns,
  Redeem,
  Comment,
} from '@material-ui/icons';
import { MenuBlockInfo } from 'core';
import React from 'react';
import { Trello } from 'react-feather';

const crmSidebarMenuList: MenuBlockInfo = {
  id: 'crmMenu',
  title: 'CRM',
  child: [
    {
      id: 'products',
      icon: <Trello />,
      label: 'Ürün Yönetimi',
      child: [
        {
          id: 'createProduct',
          icon: <PostAdd />,
          label: 'Ürün Ekle',
          route: '/crm/products/create',
        },

        {
          id: 'listProduct',
          icon: <List />,
          label: 'Ürün Listesi',
          route: '/crm/product/list',
        },
        {
          id: 'bulkProduct',
          icon: <List />,
          label: 'Toplu Ürün İşlemleri',
          route: '/crm/products/batch',
        },
        {
          id: 'bulkProduct',
          icon: <List />,
          label: 'Toplu Marka Model Eşleştirme',
          route: '/crm/products/create/batch',
        },
        {
          id: 'brandModelBulkProduct',
          icon: <Comment />,
          label: 'Ürün Yorumları',
          route: '/crm/products/comments',
        },
      ],
    },

    {
      id: 'orders',
      icon: <Description />,
      label: 'Sipariş Yönetimi',
      child: [
        {
          id: 'orderCreate',
          icon: <PostAdd />,
          label: 'Sipariş Oluştur',
          route: '/crm/order/create',
        },
        {
          id: 'orderList',
          icon: <List />,
          label: 'Tüm Siparişler',
          route: '/crm/order/list',
        },

        {
          id: 'exitOrderList',
          icon: <List />,
          label: 'Kaçan Siparişler',
          route: '/orders/exitList',
        },
        {
          id: 'createPaynet',
          icon: <PostAdd />,
          label: 'Paynet Linki Oluştur',
          route: '/crm/order/createPaynet',
        },
      ],
    },

    {
      id: 'clients',
      icon: <People />,
      label: 'Müşteri Yönetimi',
      child: [
        {
          id: 'clientCreate',
          icon: <PostAdd />,
          label: 'Müşteri Oluştur',
          route: '/crm/client/create',
        },
        {
          id: 'clientList',
          icon: <List />,
          label: 'Müşteri Listesi',
          route: '/crm/client/list',
        },

        // {
        //   id: 'clientClass',
        //   icon: <Equalizer />,
        //   label: 'Müşteri Sınıfı',
        //   child: [
        //     {
        //       id: 'createClass',
        //       icon: <PostAdd />,
        //       label: 'Sınıf Ekle',
        //       route: '/crm/client/class/create',
        //     },
        //     {
        //       id: 'classList',
        //       icon: <List />,
        //       label: 'Sınıf Listesi',
        //       route: '/crm/client/class/list',
        //     },
        //   ],
        // },
      ],
    },

    {
      id: 'options',
      icon: <Dns />,
      label: 'Seçenek Yönetimi',
      child: [
        {
          id: 'createOption',
          icon: <PostAdd />,
          label: 'Seçenek Ekle',
          route: '/crm/option/create',
        },
        {
          id: 'optionList',
          icon: <List />,
          label: 'Seçenek Listesi',
          route: '/crm/option/list',
        },
      ],
    },

    {
      id: 'campaign',
      icon: <Redeem />,
      label: 'Kampanya Yönetimi',
      child: [
        {
          id: 'createCampaign',
          label: 'Kampanya Ekle',
          icon: <PostAdd />,
          route: '/crm/campaign/create',
        },
        {
          id: 'campaignList',
          label: 'Kampanya Listesi',
          icon: <List />,
          route: '/crm/campaign/list',
        },
        {
          id: 'createGiftCoupon',
          label: 'Kupon Oluştur',
          icon: <PostAdd />,
          route: '/crm/campaign/gift/create',
        },
      ],
    },
  ],
};

export default crmSidebarMenuList;

import { Formik, Form } from 'formik';
import React from 'react';
import { TextField, Grid, CardContent, Card, CardActions, Box, MenuItem } from '@material-ui/core';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { FormAutocomplete, FormHeader, FormSelect, SuccessButton } from 'core/components';
import GenericMessages from 'core/GenericMessages';

interface CreateWarehousePartProps {
  formId: string;
}

const CreateWarehousePart: React.FC<CreateWarehousePartProps> = ({ formId }) => {
  const [showLoading, setShowLoading] = React.useState(false);

  const dispatch = useDispatch();
  const inputLabelProps = {
    shrink: true,
  };

  const schema = yup.object({
    repositoryName: yup.string().required(GenericMessages.CANNOT_BE_EMPTY),
    rowCount: yup.number().required(GenericMessages.CANNOT_BE_EMPTY),
    columnCount: yup.number().required(GenericMessages.CANNOT_BE_EMPTY),
    warehouseId: yup.number().required(GenericMessages.CANNOT_BE_EMPTY),
  });

  const onCategorySyncSubmit = async (values, { resetForm }) => {
    setShowLoading(true);
    try {
      console.log('hh');
    } catch (e) {
      setShowLoading(false);
    }
  };

  return (
    <Formik
      onSubmit={onCategorySyncSubmit}
      validationSchema={schema}
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        repositoryName: '',
        rowCount: '',
        columnCount: '',
        warehouseId: '',
      }}
    >
      {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
        <Form onSubmit={handleSubmit} id={formId} noValidate>
          <Card>
            <CardContent>
              <Grid direction="row" container spacing={5}>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    required
                    label="Komisyon Oranı"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    placeholder="Dolap Adı"
                    name="repositoryName"
                    onChange={handleChange}
                    value={values.repositoryName}
                    error={errors.repositoryName != null}
                    helperText={errors.repositoryName}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    type="number"
                    required
                    label="Satır Sayısı"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    placeholder="Satır Sayısı"
                    name="rowCount"
                    onChange={handleChange}
                    value={values.rowCount}
                    error={errors.rowCount != null}
                    helperText={errors.rowCount}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <TextField
                    type="number"
                    required
                    label="Bölüm(Sütun) Sayısı"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    InputLabelProps={inputLabelProps}
                    placeholder="Bölüm(Sütun) Sayısı"
                    name="columnCount"
                    onChange={handleChange}
                    value={values.columnCount}
                    error={errors.columnCount != null}
                    helperText={errors.columnCount}
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <FormAutocomplete
                    placeholder="Depo Seçiniz"
                    options={[
                      { name: 'Mitsubishi 1', id: 1 },
                      { name: 'Adana 2 ', id: 2 },
                      { name: 'Honda 3 ', id: 2 },
                    ]}
                    optionLabelName="name"
                    equValueName="id"
                    onChange={(event, warehouseId) => setFieldValue('warehouseId', warehouseId)}
                    error={errors.warehouseId != null}
                    helperText={errors.warehouseId}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default CreateWarehousePart;

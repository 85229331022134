import { TextField } from '@material-ui/core';
import { Category } from 'apps/crm/models';
import { Product } from 'apps/product/models';
import { CreateManufacturerDto } from 'apps/order';
import { Api } from 'core';
import { FilterRequest } from 'core/models';
import React from 'react';
import { FormAutocomplete, FormAutocompleteProps } from '../FormAutocomplete';

export type ManufacturerAutoCompleteSelectProps = Omit<FormAutocompleteProps, 'options'>;

const ManufacturerAutoCompleteSelect: React.FC<ManufacturerAutoCompleteSelectProps> = ({
  onChange,
  ...autocompleteProps
}) => {
  const [manufacturerList, setManufacturerList] = React.useState<CreateManufacturerDto[]>([]);
  const [inputValue, setInputValue] = React.useState<string>();

  const pullManufacturers = async (filterOptionRequest?: FilterRequest) => {
    const response = await Api.Order.getManufacturers(filterOptionRequest);

    if (response.data) {
      setManufacturerList(response.data.data);
    }
  };

  React.useEffect(() => {
    pullManufacturers();
  }, []);

  const onQueryChage = (event, newInputValue: string) => {
    setInputValue(newInputValue);
    if (newInputValue && newInputValue.length > 0) {
      pullManufacturers({
        queryName: newInputValue,
      });
    } else {
      pullManufacturers();
    }
  };

  return (
    <FormAutocomplete
      {...autocompleteProps}
      options={manufacturerList}
      getOptionLabel={(option) => option?.name}
      onChange={onChange}
      filterSelectedOptions
      inputValue={inputValue}
      onInputChange={onQueryChage}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Üretici Seçimi"
          placeholder="Üreticiler"
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    />
  );
};

export default ManufacturerAutoCompleteSelect;

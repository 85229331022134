import { TextField } from '@material-ui/core';
import { Supplier } from 'apps/supplier';
import { Api } from 'core';
import { FilterRequest } from 'core/models';
import React from 'react';
import { FormAutocomplete, FormAutocompleteProps } from '../FormAutocomplete';

export type SupplierAutoCompleteSelectProps = Omit<FormAutocompleteProps, 'options'>;

const SupplierAutoCompleteSelect: React.FC<SupplierAutoCompleteSelectProps> = ({ onChange, ...autocompleteProps }) => {
  const [supplierList, setSupplierList] = React.useState<Supplier[]>([]);
  const [inputValue, setInputValue] = React.useState<string>();

  const pullSuppliers = async (filterOptionRequest?: FilterRequest) => {
    const response = await Api.Supplier.getSuppliers(filterOptionRequest);

    if (response.data) {
      setSupplierList(response.data.data);
    }
  };

  React.useEffect(() => {
    pullSuppliers();
  }, []);

  const onQueryChage = (event, newInputValue: string) => {
    setInputValue(newInputValue);
    if (newInputValue && newInputValue.length > 0) {
      pullSuppliers({
        queryName: newInputValue,
      });
    } else {
      pullSuppliers();
    }
  };

  return (
    <FormAutocomplete
      {...autocompleteProps}
      size="small"
      options={supplierList}
      getOptionLabel={(option) => option?.name}
      onChange={onChange}
      filterSelectedOptions
      inputValue={inputValue}
      onInputChange={onQueryChage}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label="Tedarikçi Seçimi"
          placeholder="Tedarikçiler"
          InputLabelProps={{
            shrink: true,
          }}
        />
      )}
    />
  );
};

export default SupplierAutoCompleteSelect;

import React from 'react';
import { Fade, DialogTitle, DialogContent, DialogActions, Button, Dialog, Grid, MenuItem } from '@material-ui/core';
import { Api, FormSelect, FormTextField, GenericMessages, ConfirmDialog, FormDialog } from 'core';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { SuccessButton } from 'core/components/Buttons';
import { Cancel } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Formik } from 'formik';
import * as yup from 'yup';
import { PartlyRefund } from 'apps/order';
import { showToast } from 'core/store';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Fade ref={ref} {...props} />;
});

export interface RefundDialogProps {
  dialog: boolean;
  setDialog: (dialog: boolean) => void;
  orderId: number;
}

const RefundDialog: React.FC<RefundDialogProps> = ({ dialog, setDialog, orderId }) => {
  const [refundDialog, setRefundDialog] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [partlyRefundDialog, setPartlyRefundDialog] = React.useState(false);
  const [fullyRefundDialog, setFullyRefundDialog] = React.useState(false);
  const dispatch = useDispatch();

  const fullyRefund = async () => {
    try {
      const response = await Api.Order.orderRefund(orderId, 'fully', undefined);
      if (response.status == 'success') {
        dispatch(
          showToast({
            message: `Başarıyla iade edildi`,
          }),
        );
      } else {
        dispatch(
          showToast({
            message: `İade edilemedi`,
            severity: 'error',
          }),
        );
      }
      setFullyRefundDialog(false);
      setDialog(false);
    } catch (err) {
      dispatch(
        showToast({
          message: `Hata oluştu`,
          severity: 'error',
        }),
      );
    }
  };

  const handleFullyRefundDialogClose = () => {
    setFullyRefundDialog(false);
  };

  const handleFullyRefundDialogOpen = () => {
    setFullyRefundDialog(true);
  };

  const handleClose = () => {
    setDialog(false);
  };

  const handleRefundDialogClose = () => {
    setRefundDialog(false);
  };

  const handlePartlyRefundDialogClose = () => {
    setPartlyRefundDialog(false);
  };

  const handlePartlyRefundDialogOpen = () => {
    setPartlyRefundDialog(true);
  };

  // @ts-ignore
  return (
    <React.Fragment>
      <ConfirmDialog
        title="Tam iade"
        open={fullyRefundDialog}
        handleConfirm={() => {
          fullyRefund();
        }}
        confirmText="İade et"
        handleClose={() => {
          setFullyRefundDialog(false);
        }}
      >
        Ürünü tam iade etmek istediğinizden emin misiniz?
      </ConfirmDialog>
      <FormDialog
        handleClose={handlePartlyRefundDialogClose}
        open={partlyRefundDialog}
        formId="partlyRefund"
        title="Kısmı İade Et"
      >
        <PartlyRefund
          orderId={orderId}
          setDialog={setDialog}
          dialog={dialog}
          setPartlyRefundDialog={setPartlyRefundDialog}
          partlyRefundDialog={partlyRefundDialog}
        />
      </FormDialog>

      <Dialog
        open={dialog}
        fullScreen={fullScreen}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        onClose={handleClose}
        fullWidth
      >
        <DialogTitle style={{ textAlign: 'center' }}>Lütfen iade tipini seçiniz.</DialogTitle>
        <DialogActions>
          <Button onClick={handlePartlyRefundDialogOpen} color="primary" style={{ textAlign: 'left' }}>
            Kısmi İade
          </Button>
          <div style={{ flex: '1 0 0' }} />
          <Button color="primary" onClick={handleFullyRefundDialogOpen}>
            Tam İade
          </Button>
          <div style={{ flex: '1 1 0' }} />
          <Button color="secondary" onClick={handleClose} startIcon={<Cancel />}>
            İptal Et
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default RefundDialog;

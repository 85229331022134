import { Button, Grid } from '@material-ui/core';
import { Client } from 'apps/crm';
import { CategorySync } from 'apps/order';
import { CreateWarehousePart } from 'apps/warehouse/components';
import { FormAutocomplete, FormDialog, TranslatedMaterialTable } from 'core/components';
import { TableState } from 'core/models';
import React from 'react';

const WarehousePartList = () => {
  const [showCreatePartDialog, setShowCreatePartDialog] = React.useState(false);
  const [showLoading, setShowLoading] = React.useState(false);
  const [warehouseId, setWarehouseId] = React.useState(0);
  const [clients, setClients] = React.useState<Client[]>([]);
  const handleCreatePartSyncDialogClose = () => {
    setShowCreatePartDialog(false);
  };
  const handleCreatePartSyncDialogOpen = () => {
    setShowCreatePartDialog(true);
  };
  const [tableState] = React.useState<TableState<Client>>({
    columns: [
      {
        title: 'İsim',
      },
    ],
    actions: [
      (rowData) => ({
        icon: 'delete',
        onClick: () => {},
      }),
    ],
  });
  return (
    <React.Fragment>
      <FormDialog
        handleClose={handleCreatePartSyncDialogClose}
        open={showCreatePartDialog}
        formId="createPart"
        title="Yeni Bölüm Ekle"
        showLoading={showLoading}
      >
        <CreateWarehousePart formId="createPart" />
      </FormDialog>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={12}>
          <Button color="primary" onClick={handleCreatePartSyncDialogOpen}>
            Yeni Bölüm Ekle
          </Button>
        </Grid>
        <Grid item xs={6} lg={6}>
          <FormAutocomplete
            placeholder="Depo Seçiniz"
            options={[
              { name: 'Mitsubishi', id: 1 },
              { name: 'Adana ', id: 2 },
              { name: 'Honda', id: 2 },
            ]}
            optionLabelName="name"
            equValueName="id"
            onChange={(event, warehouseId) => setWarehouseId(warehouseId)}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} lg={6}>
          <Button color="primary">Filtrele</Button>
        </Grid>
      </Grid>
      <TranslatedMaterialTable columns={tableState.columns} actions={tableState.actions} data={clients} />
    </React.Fragment>
  );
};

export default WarehousePartList;
